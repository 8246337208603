
import { RouteNames } from '@/models/Enum';
import { Product } from '@/models/Interface';
import { Options, Vue } from 'vue-class-component';
import ProductApplicationParameter from '../ProductApplicationParameter/ProductApplicationParameter.vue';
import ProductDownloads from '../ProductDownloads/ProductDownloads.vue';
import ProductGeneral from '../ProductGeneral/ProductGeneral.vue';
import ProductSpares from '../ProductSpares/ProductSpares.vue';
import ProductTechnicalInfo from '../ProductTechnicalInfo/ProductTechnicalInfo.vue';
import ProductToolApplicability from '../ProductToolApplicability/ProductToolApplicability.vue';
import ProductRelatedArticles from '../ProductRelatedArticles/ProductRelatedArticles.vue';

@Options({
  components: {
    ProductGeneral,
    ProductDownloads,
    ProductSpares,
    ProductTechnicalInfo,
    ProductToolApplicability,
    ProductApplicationParameter,
    ProductRelatedArticles,
  },
  props: {
    product: Object,
  },
})
export default class ProductDetails extends Vue {
  routeNames = RouteNames;

  goToCalculator(product: Product) {
    const calculatorToolValues = JSON.stringify(product.calculatorToolValues);
    localStorage.calculatorToolValues = calculatorToolValues;
    this.$router.push({
      name: RouteNames.Calculator,
      params: { type: product.calculatorType },
    });
  }
}
