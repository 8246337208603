import { Language, LanguageList } from '@/models/Interface';
import { defineStore } from 'pinia';

export default defineStore('languages', {
  state: (): LanguageList => ({ languages: [] }),
  getters: {
    getLanguageList: (state) => state.languages,
  },
  actions: {
    setLanguageList(languages: Language[]) {
      this.languages = languages;
    },
    getLanguageByTag(tag: string) {
      return this.languages.find((element) => element.tag === tag);
    },
    getLanguageByLabel(label: string) {
      return this.languages.find((element) => element.label === label);
    },
    getBrowserLanguage() {
      const browserLanguageTag = navigator.language.substring(0, 2);
      const browserLanguage = this.getLanguageByTag(browserLanguageTag);
      return browserLanguage;
    },
    getDefaultLanguage() {
      const browserLanguage = this.getBrowserLanguage();
      if (browserLanguage) {
        return browserLanguage;
      }
      const defaultTag = 'en';
      const defaultLanguage = this.getLanguageByTag(defaultTag);
      if (defaultLanguage) {
        return defaultLanguage;
      }
      return this.languages[0];
    },
  },
});
