import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "product-details__content"
}
const _hoisted_2 = { class: "product-details__columns" }
const _hoisted_3 = {
  key: 0,
  class: "product-details__column"
}
const _hoisted_4 = { class: "product-details__title font-size-20 color-black" }
const _hoisted_5 = {
  key: 1,
  class: "product-details__column"
}
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  key: 1,
  class: "product-details__workpiece-material"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 3,
  class: "product-details__workpiece-material"
}

export function render(_ctx, _cache) {
  const _component_ProductTable = _resolveComponent("ProductTable")
  const _component_ProductMatrix = _resolveComponent("ProductMatrix")

  return (_ctx.product.toolApplicability)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.product.toolApplicability.attributes)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('product_toolApplicability_title')), 1),
                (_ctx.product.toolApplicability.attributes)
                  ? (_openBlock(), _createBlock(_component_ProductTable, {
                      key: 0,
                      table: _ctx.product.toolApplicability.attributes
                    }, null, 8, ["table"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.product.toolApplicability.engineType || _ctx.product.toolApplicability.workpieceMaterial)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_ctx.product.toolApplicability.engineTypeTitle && _ctx.product.toolApplicability.engineType)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "product-details__title font-size-20 color-black",
                      innerHTML: _ctx.product.toolApplicability.engineTypeTitle
                    }, null, 8, _hoisted_6))
                  : _createCommentVNode("", true),
                (_ctx.product.toolApplicability.engineType)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.product.toolApplicability.engineType, (element) => {
                        return (_openBlock(), _createBlock(_component_ProductMatrix, { matrix: element }, null, 8, ["matrix"]))
                      }), 256))
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.product.toolApplicability.workpieceMaterialTitle && _ctx.product.toolApplicability.workpieceMaterial)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      class: "product-details__title font-size-20 color-black",
                      innerHTML: _ctx.product.toolApplicability.workpieceMaterialTitle
                    }, null, 8, _hoisted_8))
                  : _createCommentVNode("", true),
                (_ctx.product.toolApplicability.workpieceMaterial)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.product.toolApplicability.workpieceMaterial, (element) => {
                        return (_openBlock(), _createBlock(_component_ProductMatrix, { matrix: element }, null, 8, ["matrix"]))
                      }), 256))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}