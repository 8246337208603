import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { RouteNames } from '@/models/Enum';
import LanguagesStore from '@/stores/languages';
import StartView from '../views/StartView/StartView.vue';
import CalculatorView from '../views/CalculatorView/CalculatorView.vue';
import SearchView from '../views/SearchView/SearchView.vue';
import SupportView from '../views/SupportView/SupportView.vue';
import ProductView from '../views/ProductView/ProductView.vue';
import MobileView from '../views/MobileView/MobileView.vue';
import ProductGeneral from '../components/ProductGeneral/ProductGeneral.vue';
import ProductTechnicalInfo from '../components/ProductTechnicalInfo/ProductTechnicalInfo.vue';
import ProductToolApplicability from '../components/ProductToolApplicability/ProductToolApplicability.vue';
import ProductSpares from '../components/ProductSpares/ProductSpares.vue';
import ProductApplicationParameter from '../components/ProductApplicationParameter/ProductApplicationParameter.vue';
import ProductRelatedArticles from '../components/ProductRelatedArticles/ProductRelatedArticles.vue';
import ProductDownloads from '../components/ProductDownloads/ProductDownloads.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:language/',
    name: RouteNames.Start,
    component: StartView,
  },
  {
    path: '/:language/calculator/:type?/:material?/:procedure?',
    name: RouteNames.Calculator,
    component: CalculatorView,
  },
  {
    path: '/:language/search',
    name: RouteNames.Search,
    component: SearchView,
  },
  {
    path: '/:language/support',
    name: RouteNames.Support,
    component: SupportView,
  },
  // {
  //   path: '/:language/product/:id/:tab?',
  //   name: RouteNames.Product,
  //   component: ProductView,
  // },
  {
    path: '/:language/product/:id',
    name: RouteNames.Product,
    component: ProductView,
    redirect: { name: RouteNames.ProductGeneral },
    children: [
      {
        path: 'general',
        name: RouteNames.ProductGeneral,
        component: ProductGeneral,
      },
      {
        path: 'technical-info',
        name: RouteNames.ProductTechnicalInfo,
        component: ProductTechnicalInfo,
      },
      {
        path: 'tool-applicability',
        name: RouteNames.ProductToolApplicability,
        component: ProductToolApplicability,
      },
      {
        path: 'spares',
        name: RouteNames.ProductSpares,
        component: ProductSpares,
      },
      {
        path: 'application-parameter',
        name: RouteNames.ProductApplicationParameter,
        component: ProductApplicationParameter,
      },
      {
        path: 'related-articles',
        name: RouteNames.ProductRelatedArticles,
        component: ProductRelatedArticles,
      },
      {
        path: 'downloads',
        name: RouteNames.ProductDownloads,
        component: ProductDownloads,
      },
    ],
  },
  {
    path: '/app',
    name: RouteNames.App,
    component: MobileView,
  },
  {
    path: '/:pathMatch(.*)',
    redirect: { path: '/en' },
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from) => {
  if (to.name === RouteNames.App) {
    return true;
  }
  const languagesStore = LanguagesStore();
  if (!to.name) {
    const languageTag = to.path.split('/')[1];
    const currentLanguage = languagesStore.getLanguageByTag(languageTag);
    if (!currentLanguage) {
      const browserLanguage = languagesStore.getBrowserLanguage();
      if (browserLanguage) {
        return {
          name: RouteNames.Start,
          params: { language: browserLanguage.tag },
        };
      }
      const defaultLanguage = languagesStore.getDefaultLanguage();
      return {
        name: RouteNames.Start,
        params: { language: defaultLanguage.tag },
      };
    }
    return true;
  }
  const { params } = to;
  if (params.language) {
    const currentLanguage = languagesStore.getLanguageByTag(params.language as string);
    // const currentLanguage = languages.find((language) => language.tag === params.language);
    if (!currentLanguage) {
      const defaultLanguage = languagesStore.getDefaultLanguage();
      const path = `/${defaultLanguage.tag}${to.path}`;
      return { path };
    }
  }
  return true;
});

export default router;
