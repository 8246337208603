
import LoaderElement from '@/elements/LoaderElement/LoaderElement.vue';
import ButtonElement from '@/elements/ButtonElement/ButtonElement.vue';
import TextInputElement from '@/elements/TextInputElement/TextInputElement.vue';
import { Options, Vue } from 'vue-class-component';
import Scan from '../Scan/Scan.vue';

@Options({
  components: {
    TextInputElement,
    ButtonElement,
    Scan,
    LoaderElement,
  },
  props: {
    isCollapsed: Boolean,
    loading: Boolean,
    errorMsg: String,
  },
})
export default class Search extends Vue {
  id = '';

  clearId() {
    this.id = '';
  }
}
