
import TextInputElement from '@/elements/TextInputElement/TextInputElement.vue';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    TextInputElement,
  },
  props: {
    quantity: Object,
    lastRow: { type: Boolean, default: false },
    compare: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    isResult: { type: Boolean, default: false },
    isConversion: { type: Boolean, default: false },
    t1: String,
    t2: String,
  },
})
export default class CalculatorRow extends Vue {
}
