
import DropdownElement from '@/elements/DropdownElement/DropdownElement.vue';
import TextInputElement from '@/elements/TextInputElement/TextInputElement.vue';
import Calculate from '@/services/Calculate';
import { Options, Vue } from 'vue-class-component';
import CalculatorRow from '../CalculatorRow/CalculatorRow.vue';
import CalculatorUnitInput from '../CalculatorUnitInput/CalculatorUnitInput.vue';

@Options({
  components: {
    DropdownElement,
    CalculatorUnitInput,
    TextInputElement,
    CalculatorRow,
  },
  props: {
  },
  computed: {
    unitValues() {
      const unitValues: string[] = [];
      const units: { label: string; unit: string; }[] = Object.values(this.physicalQuantity.units);
      units.forEach((outputUnit) => {
        const value = Calculate.conversion(
          this.physicalQuantity,
          this.inputUnit,
          outputUnit,
          parseFloat(this.inputValue),
        ).toString();
        unitValues.push(value);
        // unitValues.push(`${value} ${outputUnit.unit}`);
      });
      return unitValues;
    },
    physicalQuantity() {
      const quantities = Object.values(Calculate.physicalQuantities);
      let index = quantities.findIndex((quantity) => quantity.label === this.selectedQuantity);
      if (index < 0) {
        index = 0;
      }
      return quantities[index];
    },
    inputUnit() {
      const units: { label: string; unit: string; }[] = Object.values(this.physicalQuantity.units);
      let index = units.findIndex((unit) => unit.unit === this.selectedInputUnit);
      if (index < 0) {
        index = 0;
      }
      return units[index];
    },
    physicalQuantityOptions() {
      return Object.values(Calculate.physicalQuantities).map((quantity) => quantity.label);
    },
    inputUnitOptions() {
      const units: { label: string; unit: string; }[] = Object.values(this.physicalQuantity.units);
      return units.map((unit) => unit.unit);
    },
  },
  watch: {
    selectedQuantity: { handler: 'resetSelectedUnit' },
  },
})
export default class CalculatorUnitConversion extends Vue {
  physicalQuantityOptions!: string[];

  inputUnitOptions!: string[];

  selectedQuantity = '';

  selectedInputUnit = '';

  inputValue = '';

  created() {
    [this.selectedQuantity] = this.physicalQuantityOptions;
    [this.selectedInputUnit] = this.inputUnitOptions;
  }

  resetSelectedUnit() {
    [this.selectedInputUnit] = this.inputUnitOptions;
  }
}
