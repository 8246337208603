import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "product-download-item__text" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx, _cache) {
  const _component_SvgElement = _resolveComponent("SvgElement")

  return (_openBlock(), _createElementBlock("div", {
    class: "product-download-item cursor-pointer",
    onClick: _cache[0] || (_cache[0] = $event => (_ctx.download()))
  }, [
    _createVNode(_component_SvgElement, {
      src: `/icons/${_ctx.file.type}.svg`,
      class: "product-download-item__type-icon"
    }, null, 8, ["src"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "font-size-14 color-black",
        innerHTML: _ctx.file.label
      }, null, 8, _hoisted_2)
    ]),
    _createVNode(_component_SvgElement, {
      src: "/icons/download.svg",
      class: "product-download-item__download-icon fill-primary"
    })
  ]))
}