import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-area-element__label"
}
const _hoisted_2 = ["for", "innerHTML"]
const _hoisted_3 = {
  key: 0,
  class: "font-size-14 font-weight-bold color-primary"
}
const _hoisted_4 = ["id", "placeholder", "disabled", "value"]
const _hoisted_5 = {
  key: 1,
  class: "text-input-element__error"
}
const _hoisted_6 = { class: "font-size-12 color-red" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["text-area-element", { 'has-error-label' : _ctx.error }, { 'has-error' : _ctx.errorLabel !== '' }])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("label", {
            for: _ctx.id,
            class: "font-size-14 font-weight-bold color-tertiary-1",
            innerHTML: _ctx.label
          }, null, 8, _hoisted_2),
          (_ctx.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, " *"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("textarea", {
      class: _normalizeClass(["text-area-element__text", { 'disabled' : _ctx.disabled }]),
      id: _ctx.id,
      placeholder: _ctx.placeholder,
      disabled: _ctx.disabled,
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = $event => (_ctx.$emit('update:modelValue', $event.target.value))),
      onKeyup: _cache[1] || (_cache[1] = $event => (_ctx.$emit('keyup', $event)))
    }, null, 42, _hoisted_4),
    (_ctx.errorLabel !== '')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.errorLabel), 1)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}