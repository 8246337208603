
import ButtonElement from '@/elements/ButtonElement/ButtonElement.vue';
import SvgElement from '@/elements/SvgElement/SvgElement.vue';
import { RouteNames } from '@/models/Enum';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    ButtonElement,
    SvgElement,
  },
  props: {
  },
})
export default class SupportSuccess extends Vue {
  goToSearch() {
    this.$router.push({ name: RouteNames.Search });
  }
}
