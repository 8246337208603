import { Product, ProductState } from '@/models/Interface';
import { defineStore } from 'pinia';

export default defineStore('product', {
  state: (): ProductState => ({ product: null }),
  getters: {
    getProduct: (state) => state.product,
  },
  actions: {
    setProduct(product: Product | null) {
      this.product = product;
    },
    deleteProduct() {
      this.product = null;
    },
  },
});
