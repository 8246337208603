
import Calculator from '@/components/Calculator/Calculator.vue';
import LegalInformation from '@/components/LegalInformation/LegalInformation.vue';
import TabMenu from '@/components/TabMenu/TabMenu.vue';
import TabMenuButton from '@/components/TabMenuButton/TabMenuButton.vue';
import TabMenuItem from '@/components/TabMenuItem/TabMenuItem.vue';
import {
  CalculatorRouteMaterial,
  CalculatorRouteProcedure,
  CalculatorRouteType,
  EtrackerAreas,
  RouteNames,
} from '@/models/Enum';
import { CalculatorTool } from '@/models/Interface';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    TabMenu,
    TabMenuItem,
    TabMenuButton,
    Calculator,
    LegalInformation,
  },
  watch: {
    '$route.path': {
      handler: 'onPathChanged',
    },
  },
})
export default class CalculatorView extends Vue {
  compare = false;

  calculatorTool1: CalculatorTool = {
    averageChipThickness: '',
    chipSheetLength: '',
    cutPath: '',
    cutterStepDepth: '',
    cuttingDepth: '',
    cuttingSpeed: '',
    diameter: '',
    feedPath: '',
    feedPerRotation: '',
    feedRate: '',
    maximumChipThickness: '',
    protrusionSawBlade: '',
    rotationalSpeed: '',
    toothCount: '',
    toothFeed: '',
  }

  calculatorTool2: CalculatorTool = {
    averageChipThickness: '',
    chipSheetLength: '',
    cutPath: '',
    cutterStepDepth: '',
    cuttingDepth: '',
    cuttingSpeed: '',
    diameter: '',
    feedPath: '',
    feedPerRotation: '',
    feedRate: '',
    maximumChipThickness: '',
    protrusionSawBlade: '',
    rotationalSpeed: '',
    toothCount: '',
    toothFeed: '',
  }

  reset() {
    this.calculatorTool1 = {
      averageChipThickness: '',
      chipSheetLength: '',
      cutPath: '',
      cutterStepDepth: '',
      cuttingDepth: '',
      cuttingSpeed: '',
      diameter: '',
      feedPath: '',
      feedPerRotation: '',
      feedRate: '',
      maximumChipThickness: '',
      protrusionSawBlade: '',
      rotationalSpeed: '',
      toothCount: '',
      toothFeed: '',
    };
    this.calculatorTool2 = {
      averageChipThickness: '',
      chipSheetLength: '',
      cutPath: '',
      cutterStepDepth: '',
      cuttingDepth: '',
      cuttingSpeed: '',
      diameter: '',
      feedPath: '',
      feedPerRotation: '',
      feedRate: '',
      maximumChipThickness: '',
      protrusionSawBlade: '',
      rotationalSpeed: '',
      toothCount: '',
      toothFeed: '',
    };
    this.reloadView();
  }

  calculatorRouteType = CalculatorRouteType;

  calculatorRouteMaterial = CalculatorRouteMaterial;

  calculatorRouteProcedure = CalculatorRouteProcedure;

  calculatorTypes = [
    { name: CalculatorRouteType.Mill, icon: '/icons/mill.svg' },
    { name: CalculatorRouteType.Saw, icon: '/icons/saw.svg' },
    { name: CalculatorRouteType.Unit, icon: '/icons/unit.svg' },
  ]

  type = CalculatorRouteType.Mill;

  material: CalculatorRouteMaterial | null = CalculatorRouteMaterial.Wood;

  procedure: CalculatorRouteProcedure | null = CalculatorRouteProcedure.CuttingSpeed;

  reload = false;

  created() {
    this.getCalculatorToolValues();
    this.onPathChanged();
  }

  static setEtrackerPath(type: string, material: string | null, procedure: string | null) {
    const typeIndex = Object.keys(EtrackerAreas).findIndex((el) => el === type);
    const typeLabel = Object.values(EtrackerAreas)[typeIndex];
    let path = `${EtrackerAreas.calculator}/${typeLabel}`;
    if (material && procedure && type !== CalculatorRouteType.Unit) {
      const materialIndex = Object.keys(EtrackerAreas)
        .findIndex((el) => el === material);
      const materialLabel = Object.values(EtrackerAreas)[materialIndex];
      const procedureIndex = Object.keys(EtrackerAreas)
        .findIndex((el) => el === procedure);
      const procedureLabel = Object.values(EtrackerAreas)[procedureIndex];
      path = `${EtrackerAreas.calculator}/${typeLabel}/${materialLabel}/${procedureLabel}`;
    }
    document.dispatchEvent(new CustomEvent('etrackerPageAreaChanged', { detail: path }));
  }

  onPathChanged() {
    if (this.$route.name === RouteNames.Calculator) {
      const { type, material, procedure } = this.$route.params;
      if (type !== this.type || material !== this.material || procedure !== this.procedure) {
        this.type = Object.values(CalculatorRouteType).includes(type as CalculatorRouteType)
          ? type as CalculatorRouteType
          : this.type;
        this.material = Object.values(CalculatorRouteMaterial)
          .includes(material as CalculatorRouteMaterial)
          ? material as CalculatorRouteMaterial
          : this.material;
        this.procedure = Object.values(CalculatorRouteProcedure)
          .includes(procedure as CalculatorRouteProcedure)
          ? procedure as CalculatorRouteProcedure
          : this.procedure;
        this.goTo(this.type, this.material, this.procedure, true);
      }
      CalculatorView.setEtrackerPath(this.type, this.material, this.procedure);
    }
  }

  selectType(type: string) {
    this.goTo(type, this.material, this.procedure);
  }

  selectMaterial(material: string) {
    this.goTo(this.type, material, this.procedure);
  }

  selectProcedure(procedure: string) {
    this.goTo(this.type, this.material, procedure);
  }

  goTo(type: string, material: string | null, procedure: string | null, replace?: boolean) {
    this.type = type as CalculatorRouteType ?? CalculatorRouteType.Mill;
    this.material = material as CalculatorRouteMaterial ?? CalculatorRouteMaterial.Wood;
    this.procedure = procedure as CalculatorRouteProcedure ?? CalculatorRouteProcedure.CuttingSpeed;
    if (type === CalculatorRouteType.Unit) {
      this.material = null;
      this.procedure = null;
    }
    this.$router.push({
      name: RouteNames.Calculator,
      params: { type: this.type, material: this.material, procedure: this.procedure },
      replace: replace ?? false,
    });
    this.reloadView();
  }

  reloadView() {
    this.reload = true;
    setTimeout(() => {
      this.reload = false;
    }, 1);
  }

  getCalculatorToolValues() {
    const { calculatorToolValues } = localStorage;
    let calculatorTool: CalculatorTool | null = null;
    if (calculatorToolValues) {
      try {
        calculatorTool = JSON.parse(calculatorToolValues);
      } finally {
        if (calculatorTool) {
          this.calculatorTool1 = calculatorTool;
        }
      }
    }
    localStorage.removeItem('calculatorToolValues');
  }
}
