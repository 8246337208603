import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "product-details__content"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "product-details__columns" }
const _hoisted_4 = {
  key: 0,
  class: "product-details__column full-width small-gap"
}

export function render(_ctx, _cache) {
  const _component_ProductRelatedArticleItem = _resolveComponent("ProductRelatedArticleItem")
  const _component_UseVirtualList = _resolveComponent("UseVirtualList")

  return (_ctx.product.relatedArticles)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "product-details__title font-size-20 color-black",
          innerHTML: _ctx.product.relatedArticles.title
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.product.relatedArticles.items && _ctx.product.relatedArticles.items.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_UseVirtualList, {
                  list: _ctx.product.relatedArticles.items,
                  options: {
          itemHeight: 48,
          overscan: 5,
        },
                  height: "100%"
                }, {
                  default: _withCtx((props) => [
                    _createVNode(_component_ProductRelatedArticleItem, {
                      relatedArticle: props.data
                    }, null, 8, ["relatedArticle"])
                  ]),
                  _: 1
                }, 8, ["list"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}