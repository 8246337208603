
import { DownloadFile } from '@/models/Interface';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
  props: {
    file: Object,
  },
})
export default class ProductDownloadItem extends Vue {
  file!: DownloadFile;

  download() {
    window.open(this.file.url, '_blank', 'noopener');
    // document.dispatchEvent(new CustomEvent('etrackerDownloadEvent', {
    //   detail: {
    //     object: 'object',
    //     action: 'action',
    //     subType: 'subType',
    //     value: 'value',
    //   },
    // }));
  }
}
