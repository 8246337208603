
import { Options, Vue } from 'vue-class-component';
import { VueRecaptcha } from 'vue-recaptcha';
import LanguageStore from '@/stores/languages';

@Options({
  components: {
    VueRecaptcha,
  },
  props: {
  },
  watch: {
    '$i18n.locale': {
      handler: 'changeLanguage',
    },
  },
})
export default class ReCaptcha extends Vue {
  siteKey = '6LdEp-MkAAAAAI_1U0ruoj7XuP61gTTcBlSPQ1x3 ';

  languageTag = 'en';

  created() {
    this.setLangauge();
  }

  setLangauge() {
    const languageStore = LanguageStore();
    const language = languageStore.getLanguageByTag(this.$i18n.locale);
    this.languageTag = language ? language.tag : 'en';
  }

  changeLanguage() {
    this.setLangauge();
    const reCaptchaEl = document.querySelector('[title="reCAPTCHA"]');
    if (reCaptchaEl) {
      const srcAttribute = reCaptchaEl.getAttribute('src');
      if (srcAttribute) {
        const currentLang = srcAttribute.match(/hl=(.*?)&/)?.pop();
        if (currentLang !== this.languageTag) {
          reCaptchaEl.setAttribute('src', srcAttribute.replace(/hl=(.*?)&/, `hl=${this.languageTag}&`));
        }
      }
    }
  }

  handleError() {
    this.$emit('onResult', false);
  }

  handleSuccess(response: string) {
    this.$emit('onResult', true);
  }
}
