
import ButtonElement from '@/elements/ButtonElement/ButtonElement.vue';
import CheckboxElement from '@/elements/CheckboxElement/CheckboxElement.vue';
import DropdownElement from '@/elements/DropdownElement/DropdownElement.vue';
import LoaderElement from '@/elements/LoaderElement/LoaderElement.vue';
import TextAreaElement from '@/elements/TextAreaElement/TextAreaElement.vue';
import TextInputElement from '@/elements/TextInputElement/TextInputElement.vue';
import { CustomerData, SupportRequest } from '@/models/Interface';
import SdkService from '@/services/SdkService';
import { Options, Vue } from 'vue-class-component';
import ReCaptcha from '../ReCaptcha/ReCaptcha.vue';

@Options({
  components: {
    TextInputElement,
    DropdownElement,
    TextAreaElement,
    ButtonElement,
    LoaderElement,
    ReCaptcha,
    CheckboxElement,
  },
  props: {
    selectedBranch: Object,
    customerData: Object,
  },
})
export default class SupportForm extends Vue {
  customerData!: CustomerData;

  // selectedBranch!: Branch | null;

  customerId = '';

  customerIdErrorMessage = '';

  email = '';

  emailErrorMessage = '';

  phoneNumber = '';

  phoneNumberErrorMessage = '';

  subject = '';

  subjectErrorMessage = '';

  message = '';

  messageErrorMessage = '';

  subjectOptions = [
    'support_subject_callback',
    'support_subject_agentVisit',
    'support_subject_toolService',
    'support_subject_consulting',
  ]

  loading = false;

  patternCustomerId = /^\d+$/;

  patternEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  sendingFailed = false;

  privacyPolicyAccepted = false;

  errorPrivacyPolicy = false;

  reCaptchaResult: boolean | null = null;

  created() {
    this.customerId = this.customerData.id ?? '';
    this.email = this.customerData.email ?? '';
    this.phoneNumber = this.customerData.phone ?? '';
  }

  validate() {
    let valid = true;
    if (!this.reCaptchaResult) {
      this.reCaptchaResult = false;
      valid = false;
    }
    if (!this.privacyPolicyAccepted) {
      this.errorPrivacyPolicy = true;
      valid = false;
    }
    if (this.customerId !== '' && !this.customerId.match(this.patternCustomerId)) {
      this.customerIdErrorMessage = 'support_errorCustomerId';
      valid = false;
    }
    if (this.email === '') {
      this.emailErrorMessage = 'support_errorEmailEmpty';
      valid = false;
    } else if (!this.email.match(this.patternEmail)) {
      this.emailErrorMessage = 'support_errorEmailInvalid';
      valid = false;
    }
    if (this.phoneNumber === '') {
      this.phoneNumberErrorMessage = 'support_errorPhoneNumber';
      valid = false;
    }
    if (this.subject === '') {
      this.subjectErrorMessage = 'support_errorSubject';
      valid = false;
    }
    if (this.message === '') {
      this.messageErrorMessage = 'support_errorMessage';
      valid = false;
    }
    return valid;
  }

  send(
    labelXPress: string,
    labelRequest: string,
    labelSubject: string,
    labelBranch: string,
    labelCustomerId: string,
    labelPhoneNumber: string,
    labelMessage: string,
  ) {
    const customerData: CustomerData = {
      id: this.customerId,
      email: this.email,
      phone: this.phoneNumber,
      branch: this.customerData.branch,
    };
    this.$emit('saveCustomerData', customerData);
    const valid = this.validate();
    if (valid && this.customerData.branch) {
      const subject = `${labelXPress} ${labelRequest}: ${labelSubject}`;
      const body = `

${labelBranch}: ${this.customerData.branch.location} (${this.customerData.branch.email})

${labelCustomerId}: ${this.customerId}
${labelPhoneNumber}: ${this.phoneNumber}

_____________________________________________

${labelMessage}:

${this.message}
`;
      this.loading = true;
      const supportRequest: SupportRequest = {
        sender: this.email,
        // recipient: 'mspaney@leitz.org',
        recipient: this.customerData.branch.email,
        subject,
        body,
      };
      this.sendingFailed = false;
      let requestSuccessful = false;
      setTimeout(() => {
        if (!requestSuccessful) {
          this.sendingFailed = true;
          this.loading = false;
          this.$emit('saveCustomerData', customerData);
        }
      }, 10000);
      SdkService.sendSupportRequest(supportRequest, (success) => {
        requestSuccessful = true;
        this.loading = false;
        if (success) {
          this.$emit('sentSuccessfully', customerData);
        } else {
          this.sendingFailed = true;
          this.$emit('saveCustomerData', customerData);
        }
      });
      // setTimeout(() => {
      //   this.loading = false;
      //   requestSuccessful = true;
      //   this.$emit('sentSuccessfully', customerData);
      //   // this.sendingFailed = true;
      //   // this.$emit('saveCustomerData', customerData);
      // }, 2000);
    }
  }

  openWebsite(url: string) {
    console.log('openWebsite', this.privacyPolicyAccepted);
    window.open(url, '_blank', 'noopener');
  }
}
