
import { RouteNames, TabNames } from '@/models/Enum';
import { Options, Vue } from 'vue-class-component';
import SidebarFooter from '../SidebarFooter/SidebarFooter.vue';
import SidebarItem from '../SidebarItem/SidebarItem.vue';

@Options({
  components: {
    SidebarItem,
    SidebarFooter,
  },
  props: {
  },
})
export default class Sidebar extends Vue {
  routeNames = RouteNames;

  tabNames = TabNames;
}
