
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
  props: {
    id: { type: String, default: 'input' },
    placeholder: { type: String, default: '' },
    label: String,
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    prefixedIcon: String,
    modelValue: { type: String, default: '' },
    errorLabel: { type: String, default: '' },
    error: { type: Boolean, default: false },
  },
})
export default class TextAreaElement extends Vue {
}
