import { Branch } from '@/models/Interface';

export const fr = {
  calculator_calculation: 'Calcul',
  calculator_calculationOf_chipSheetLengthAndChipThickness: 'Calcul de la longueur de l\'arc de coupe et de l\'épaisseur du copeau',
  calculator_calculationOf_chipThicknessAndChipSheetLength: 'Calcul de l\'épaisseur du copeau et de la longueur de l\'arc de coupe',
  calculator_calculationOf_cutPath: 'Calcul de la longueur de coupe',
  calculator_calculationOf_cutterStepDepth: 'Calcul de la profondeur de pas du couteau',
  calculator_calculationOf_cuttingSpeed: 'Calcul de la vitesse de coupe',
  calculator_calculationOf_feedPerTooth: 'Calcul de la vitesse d\'avance par dent',
  calculator_calculationOf_feedRate: 'Calcul de la vitesse d\'avance',
  calculator_calculationOf_rotationalSpeed: 'Calcul de la vitesse de rotation',
  calculator_calculationType_cuttingSpeed: 'Vitesse de coupe',
  calculator_calculationType_rotationalSpeed: 'Vitesse de rotation',
  calculator_compareTools: 'Comparer les outils',
  calculator_material_metal: 'Métal',
  calculator_material_title: 'Matériau',
  calculator_material_wood: 'Bois et matériaux à base de bois',
  calculator_mill: 'Fraisage',
  calculator_parameterType_averageChipThickness: 'Épaisseur moyenne des copeaux',
  calculator_parameterType_chipSheetLength: 'Longueur de l\'arc de coupe',
  calculator_parameterType_cutPath: 'Longueur de coupe',
  calculator_parameterType_cutterStepDepth: 'Profondeur des marques de coupe par rapport à l\'avance par dent',
  calculator_parameterType_cuttingDepthMill: 'Profondeur de coupe radiale',
  calculator_parameterType_cuttingDepthSaw: 'Profondeur de coupe',
  calculator_parameterType_cuttingSpeed: 'Vitesse de coupe',
  calculator_parameterType_diameter: 'Diamètre',
  calculator_parameterType_feedPath: 'Distance d\'alimentation',
  calculator_parameterType_feedPerRotation: 'Avance par tour',
  calculator_parameterType_feedRate: 'Vitesse d\'avance',
  calculator_parameterType_maximumChipThickness: 'Epaisseur maximale des copeaux',
  calculator_parameterType_protrusionSawBlade: 'Dépassement de la lame de scie circulaire',
  calculator_parameterType_rotationalSpeed: 'Vitesse de rotation',
  calculator_parameterType_toothCount: 'Nombre de dents',
  calculator_parameterType_toothFeed: 'Avance par dent',
  calculator_quantityType_area: 'Surface',
  calculator_quantityType_energy: 'Energie',
  calculator_quantityType_length: 'Longueur',
  calculator_quantityType_performance: 'Puissance',
  calculator_quantityType_power: 'Dimensions',
  calculator_quantityType_pressure: 'Pression',
  calculator_quantityType_temperature: 'Température',
  calculator_quantityType_torque: 'Couple de serrage',
  calculator_quantityType_velocity: 'Vitesse',
  calculator_quantityType_volume: 'Volumes',
  calculator_quantityType_weight: 'Poids',
  calculator_recommendation_blockboards: 'Panneaux lattés',
  calculator_recommendation_castAluminumAlloys: 'Alliages d\'aluminium de fonderie',
  calculator_recommendation_cementBondedWoodMaterials: 'Matériaux à base de bois liés au ciment',
  calculator_recommendation_favorableRange: 'Zone favorable',
  calculator_recommendation_fineSizingChip: 'Copeaux de finition',
  calculator_recommendation_group_aluminiumCastWroughtAlloys: 'Alliages d\'Aluminium de fonderie et corroyés',
  calculator_recommendation_group_brassShortLongChipping: 'Laiton, copeaux courts et longs',
  calculator_recommendation_group_bronze: 'Bronze',
  calculator_recommendation_group_constructionSteels: 'Acier de construction',
  calculator_recommendation_group_copperAlloys: 'Cuivre, alliages de cuivre',
  calculator_recommendation_group_quenchedAndTemperedNitridingSteels: 'Acier de trempe et de nitruration',
  calculator_recommendation_group_springBearingSteels: 'Aciers pour ressorts et roulements',
  calculator_recommendation_group_stainlessSteels: 'Acier inoxydable',
  calculator_recommendation_gypsumMaterials: 'Matériaux à base de plâtre',
  calculator_recommendation_hardboard: 'Panneaux de fibres durs',
  calculator_recommendation_hardwoodCrosswise: 'Bois dur en travers',
  calculator_recommendation_headline_cuttingSpeed: 'Vitesse de coupe',
  calculator_recommendation_headline_group: 'Groupe de matériaux',
  calculator_recommendation_headline_material: 'Matériaux',
  calculator_recommendation_headline_tensileStrength: 'Résistance à la traction',
  calculator_recommendation_headline_toothFeed: 'Avance par dent',
  calculator_recommendation_HPLCoveredChipboard: 'Panneaux de particules revêtus de HPL',
  calculator_recommendation_OSBBoards: 'Panneaux OSB',
  calculator_recommendation_plasticCoatedChipboard: 'Panneaux de particules revêtus de mélamine',
  calculator_recommendation_plywood: 'Contre-plaqué',
  calculator_recommendation_pressedLaminatedWood: 'Bois de placage stratifié (LVL)',
  calculator_recommendation_roughingChip: 'Copeaux ébauchés',
  calculator_recommendation_sizingChip: 'Copeaux de finition',
  calculator_recommendation_softwoodCrosswise: 'Bois tendre en travers',
  calculator_recommendation_solidWoodLengthwise: 'Bois massifs en long',
  calculator_recommendation_thermoplastics: 'Thermoplastiques',
  calculator_recommendation_thermosettingPlastics: 'Duroplastiques',
  calculator_recommendation_title_general: 'Valeurs recommandées',
  calculator_recommendation_title_NE: 'Paramètres de coupe recommandés pour l\'usinage des métaux non ferreux',
  calculator_recommendation_title_steel: 'Paramètres de coupe recommandés pour l\'usinage de l\'acier',
  calculator_recommendation_title_toothFeed: 'Avance par dent recommandée',
  calculator_recommendation_veneeredPanels: 'Panneaux plaqués',
  calculator_recommendation_wroughtAluminumAlloys: 'Alliages d\'aluminium corroyés',
  calculator_reset: 'Réinitialiser',
  calculator_resetCalculator: 'Réinitialiser le calculateur',
  calculator_saw: 'Sciage',
  calculator_tabs_mill: 'Fraisage',
  calculator_tabs_saw: 'Sciage',
  calculator_tabs_unit: 'Conversion de l\'unité',
  calculator_title: 'Calcul',
  calculator_titleMill: 'Calcul - Fraisage',
  calculator_titleSaw: 'Calcul - Sciage',
  calculator_titleUnit: 'Cacul - Unité',
  calculator_tool: 'Outil',
  calculator_toolParameters: 'Paramètres de l\'outil',
  calculator_unit: 'Unité',
  calculator_unitConversion: 'Conversion de l\'unité',
  calculator_units: 'Unités',
  calculator_unitToConvert: 'Unité à convertir',
  calculator_unitType_bar: 'Bar',
  calculator_unitType_britishThermalUnit: 'Unité thermique britannique',
  calculator_unitType_centimeter: 'Centimètre',
  calculator_unitType_cubicFeet: 'Pieds cube',
  calculator_unitType_cubicInch: 'Pouce cube',
  calculator_unitType_cubicMeter: 'Mètre cube',
  calculator_unitType_degreeCelsius: 'Degré Celsius',
  calculator_unitType_degreeFahrenheit: 'Degré Fahrenheit',
  calculator_unitType_feet: 'Pied',
  calculator_unitType_feetPerMinute: 'Pied par minute',
  calculator_unitType_footPound: 'Pied-livre',
  calculator_unitType_gallon: 'Gallon US',
  calculator_unitType_gram: 'Gramme',
  calculator_unitType_horsepower: 'puissance en chevaux',
  calculator_unitType_inch: 'Pouce',
  calculator_unitType_inchPerMinute: 'Pouce par minute',
  calculator_unitType_inchPerSecond: 'Pocue par secontde',
  calculator_unitType_joule: 'Joule',
  calculator_unitType_kelvin: 'Kelvin',
  calculator_unitType_kilocalories: 'Kilocalories',
  calculator_unitType_kilogram: 'Kilogramme',
  calculator_unitType_kilopond: 'Kilopond',
  calculator_unitType_kilowatt: 'Kilowatt',
  calculator_unitType_kilowattHour: 'Kilowatt heures',
  calculator_unitType_liter: 'Litre',
  calculator_unitType_meter: 'Mètre',
  calculator_unitType_meterPerMinute: 'Mètre par minute',
  calculator_unitType_meterPerSecond: 'Mètre par seconde',
  calculator_unitType_metricHorsepower: 'Puissance en chevaux',
  calculator_unitType_micrometer: 'Micromètre',
  calculator_unitType_millimeter: 'Millimète',
  calculator_unitType_newton: 'Newton',
  calculator_unitType_newtonMeter: 'Mètre Newton',
  calculator_unitType_ounce: 'Once',
  calculator_unitType_ounceForce: 'Once-force',
  calculator_unitType_ounceForceFeet: 'Once-force pied',
  calculator_unitType_pascal: 'Pascal',
  calculator_unitType_pound: 'Livre-force',
  calculator_unitType_poundForce: 'Livre-force',
  calculator_unitType_poundForceFeet: 'Livre-force pied',
  calculator_unitType_poundForceInch: 'Livre-force pouce',
  calculator_unitType_poundForcePerSquareInch: 'Livre-force par pouce carré',
  calculator_unitType_squareCentimeter: 'Centimètre carré',
  calculator_unitType_squareFeet: 'Pieds carrés',
  calculator_unitType_squareInch: 'Pouce carré',
  calculator_unitType_squareMeter: 'Mètre carré',
  calculator_unitType_squareMillimeter: 'Millimètre carré',
  calculator_unitType_standardAtmosphere: 'Atmosphère physique',
  calculator_unitType_tonne: 'Tonne',
  calculator_unitType_watt: 'Watt',
  favorites_noFavorites: 'Vous n\'avez pas encore enregistré de produits dans vos favoris.',
  favorites_titlePlural: 'Favoris',
  favorites_titleSingular: 'Favori',
  language: 'Français',
  legalInformation_imprint: 'Mentions légales',
  legalInformation_privacyPolicy: 'Protection des données personnelles',
  legalInformation_title: 'Informations légales',
  leitzxpert: 'LeitzXPert',
  link_imprint: 'https://www.leitz.org/fr-fr/mentions-legales',
  link_privacyPolicy: 'https://www.leitz.org/fr-fr/protection-des-donnees',
  navigation_appLanguage: 'Langue de l\'application',
  navigation_apps: 'Application',
  navigation_back: 'Retour',
  navigation_calculator: 'Calcul',
  navigation_cancel: 'Annuler',
  navigation_close: 'Fermer',
  navigation_done: 'Terminé',
  navigation_favorites: 'Favoris',
  navigation_language: 'Langue',
  navigation_productsearch: 'Recherche produit',
  navigation_profile: 'Profil',
  navigation_search: 'Recherche',
  navigation_section: 'Domaine',
  navigation_support: 'Support',
  noMobile_appName: 'LeitzXPert',
  noMobile_availableFor: 'Disponible pour Android et iOS',
  noMobile_availableSoon: 'Bientôt disponible',
  noMobile_notAvailable: 'Cette page n\'est pas optimisée pour les appareils mobiles et pour les écrans dont la largeur d\'affichage est inférieure à 960&nbsp;pixels et ne peut donc pas être affichée.',
  noMobile_recommendation: 'Nous vous recommandons d\'installer notre application gratuite LeitzXPert disponible dans le store',
  noMobile_title: 'Cette page n\'est pas optimisée pour les appareils mobiles et les petits écrans',
  product_applicationParameter_title: 'Paramètres d\'utilisation',
  product_backTo: 'Retour vers',
  product_general_details: 'Détails',
  product_general_images: 'Images de produits &amp; vidéos',
  product_general_imagesNoMedia: 'Pas d\'images de produits &amp; vidéos disponibles',
  product_general_maximizeDescription: 'Voir la description',
  product_general_minimizeDescription: 'Réduire la description',
  product_general_sketches: 'Croquis &amp; diagrammes',
  product_general_sketchesNoMedia: 'Pas de croquis &amp; Diagrammes disponibles',
  product_general_title: 'Information générale',
  product_noImage: 'Aucune image disponible',
  product_noInformation: 'Pas d\'information disponible',
  product_notFound: 'Le produit n\'a pas été trouvé',
  product_productsearch: 'Recherche produit',
  product_relatedArticles_title: 'Dimensions supplémentaires',
  product_relatedArticles_toProduct: 'Vers le produit',
  product_spares_availability_availableAtShortNotice: 'Disponible à court terme',
  product_spares_availability_availableExStock: 'Disponible en stock',
  product_spares_availability_productionToOrder: 'Fabrication sur commande',
  product_spares_titleBlade: 'Couteaux de rechange',
  product_spares_titleGeneral: 'Pièces de rechange',
  product_spares_titleSaw: 'Lames de scie circulaire de rechange',
  product_tabs_applicationParameter_noData: 'Il n\'y a pas de paramètres d\'application disponibles pour ce produit',
  product_tabs_applicationParameter: 'Paramètres d\'utilisation',
  product_tabs_downloads_noData: 'Il n\'y a pas de téléchargements disponibles pour ce produit',
  product_tabs_downloads: 'Téléchargements',
  product_tabs_general: 'Général',
  product_tabs_link_noData: 'Il n\'y a pas de lien vers le site web pour ce produit.',
  product_tabs_link: 'Lien vers le site web',
  product_tabs_relatedArticles_noData: 'Il n\'y a pas d\'autres dimensions disponibles pour ce produit',
  product_tabs_relatedArticles: 'Dimensions supplémentaires',
  product_tabs_spares_noData: 'Il n\'y a pas de pièces de rechange disponibles pour ce produit',
  product_tabs_spares: 'Pièces de rechange',
  product_tabs_technicalInfo_noData: 'Il n\'y a pas d\'informations techniques disponibles pour ce produit',
  product_tabs_technicalInfo: 'Informations techniques',
  product_tabs_toolApplicability_noData: 'Il n\'y a pas d\'outillage disponible pour ce produit',
  product_tabs_toolApplicability: 'Applications de l\'outil',
  product_technicalInfo_title: 'Informations techniques',
  product_toolApplicability_title: 'Applications de l\'outil',
  product_tools: 'Outils',
  productsearch_actions_addToFavorites: 'Ajouter aux Favoris',
  productsearch_actions_delete: 'Supprimer',
  productsearch_actions_details: 'Détails',
  productsearch_actions_removeFromFavorites: 'Supprimer des favories',
  productsearch_actions_removeFromHistory: 'Supprimer de l\'historique',
  productsearch_code: 'Code',
  productsearch_codeInput_text: 'Scannez le numéro d\'identification du produit pour obtenir les données du produit',
  productsearch_codeInput_title: 'Data Matrix',
  productsearch_description: 'Cette recherche permet de retrouver un produit par son numéro d\'identification ou son numéro de série en tapant manuellement le numéro dans le champ de saisie ou en utilisant un lecteur compatible.',
  productsearch_error_code_text: 'Le code n\'a pas pu être scanné. Veuillez réessayer',
  productsearch_error_noProduct_textLong: 'Aucune donnée produit n\'a pu être trouvée avec ce numéro, veuillez réessayer avec un numéro différent',
  productsearch_error_noProduct_textShort: 'Aucun produit n\'a pu être trouvé avec l\'ID ou le numéro de série saisi',
  productsearch_error_noProduct_title: 'Aucun résultat pour ce numéro d\'identification',
  productsearch_error_notEnoughDigits_textLong: 'Veuillez entrer au moins 4 caractères',
  productsearch_error_notEnoughDigits_textShort: 'au moins 4 caractères',
  productsearch_error_rfid_text: 'La RFID n\'a pas pu être lue. Veuillez réessayer',
  productsearch_error_unknown_text: 'Une erreur inconnue s\'est produite, veuillez réessayer ou contacter notre équipe d\'assistance',
  productsearch_error_unknown_title: 'Une erreur est survenue',
  productsearch_history: 'Historique',
  productsearch_historyEmpty: 'Vous n\'avez pas encore recherché de produits. Les produits les plus récemment recherchés sont listés ici par ordre chronologique.',
  productsearch_id: 'Nr identification',
  productsearch_idInput_text: 'Entrez le numéro d\'identification du produit pour obtenir les données du produit',
  productsearch_idInput_title: 'Entrez le N° d\'identification',
  productsearch_inputPlaceholder: 'Entrez le N° d\'identification',
  productsearch_inputPlaceholderIDSerial: 'Entrez le N° d\'identification / le numéro de série',
  productsearch_loadProduct: 'Charger les données produit',
  productsearch_openCamera: 'Ouvrir la caméra',
  productsearch_readerInstruction: 'Connectez votre lecteur pour scanner les codes-barres.',
  productsearch_readerNotConnected: 'Lecteur non connecté',
  productsearch_readerNotRecognised: 'Appareil non reconnu',
  productsearch_rfid_disabledText: 'Pour pouvoir rechercher des produits à l\'aide de la RFID, la NFC doit être activée. Pour ce faire, accédez aux paramètres de votre smartphone.',
  productsearch_rfid_disabledTitle: 'NFC non activé',
  productsearch_rfid_openScanner: 'Ouvrir le scanner RFID',
  productsearch_rfid_ready: 'Prêt à être scanner',
  productsearch_rfid: 'RFID',
  productsearch_rfidInput_text: 'Tenez votre smartphone ou un scanner RFID devant le produit pour obtenir vos données.',
  productsearch_rfidInput_title: 'Numérisation de produits RFID',
  productsearch_showHistory: 'Afficher l\'historique',
  productsearch_title: 'Recherche produit',
  productsearch_tryAgain: 'Essayer à nouveau',
  start_appFunctions: 'Fonctionnalité de l\'application',
  start_button: 'Démarrer maintenant',
  start_calculator_description: 'Déterminez les données d\'utilisation importantes avec nos programmes de calcul pratiques',
  start_calculator_title: 'Calcul',
  start_favorites_description: 'Enregistrez les produits que vous recherchez de manière pratique et simple',
  start_favorites_title: 'Favoris',
  start_productsearch_description: 'Découvrez toutes les données d\'outils, les instructions et les brochures de produits de notre programme standard',
  start_productsearch_title: 'Recherche produit',
  start_skip: 'sauter',
  start_solutions: 'Des solutions dans de nouvelles dimensions',
  start_support_description: 'Contactez rapidement et facilement l\'équipe d\'assistance Leitz',
  start_support_title: 'Support',
  start_welcomeAt: 'Bienvenue chez',
  support_agreeToPrivacy: 'Je suis d\'accord avec la politique de confidentialité.',
  support_branch: 'Agence',
  support_branchEdit: 'Modifier l\'établissement',
  support_contactDetails: 'Coordonnées de l\'établissement',
  support_contactType: 'Siège social du groupe Leitz',
  support_customerNumber: 'Numéro de client',
  support_dataInfo_text: 'Nous avons besoin de vos données pour une demande d\'assistance rapide, afin que vous ne deviez pas saisir ces données à plusieurs reprises lors d\'une demande.',
  support_dataInfo_title: 'Pourquoi avons-nous besoin de vos données ?',
  support_eMail: 'E-mail',
  support_errorCustomerId: 'Le numéro de client ne peut être composé que de chiffres',
  support_errorEmailEmpty: 'L\'e-mail ne doit pas être vide',
  support_errorEmailInvalid: 'L\'e-mail doit être valide',
  support_errorMessage: 'Le message ne doit pas être vide',
  support_errorPhoneNumber: 'Nr de téléphone obligatoire',
  support_errorSubject: 'Le thème doit être sélectionné',
  support_inputPlaceholderPleaseSelect: 'Veuillez sélectionner',
  support_inputPlaceholderPleaseType: 'Veuillez saisir',
  support_inputPlaceholderSelect: 'Choisir',
  support_inputPlaceholderSelectBranch: 'Sélectionner les filiales',
  support_inputPlaceholderTypeHere: 'Entrer ici',
  support_leitzBranch: 'Filiale de Leitz',
  support_message: 'Message',
  support_myData: 'Mes données',
  support_noBranchSelected: 'Pour lancer une demande, vous devez d\'abord sélectionner un établissement.',
  support_phone: 'Téléphone',
  support_privacyInformation: 'Les données soumises seront collectées uniquement dans le but de traiter votre demande. En soumettant le formulaire, vous acceptez notre politique de confidentialité.',
  support_privacyLink: 'Vers la déclaration de protection des données',
  support_ProfileNotFilled: 'Pour lancer une demande, vous devez d\'abord saisir toutes les données nécessaires dans votre profil.',
  support_request: 'Demande',
  support_requiredFields: 'Les champs marqués d\'un * sont obligatoires.',
  support_sendingFailed: 'Votre demande n\'a malheureusement pas pu être envoyée. Veuillez réessayer plus tard.',
  support_sendRequest: 'Envoyer la demande',
  support_sent_backToSearch: 'Retour à la recherche de produits',
  support_sent_newRequest: 'Lancer une nouvelle demande',
  support_sent_team: 'Votre équipe LeitzXPert',
  support_sent_thankyou: 'Merci pour votre demande. Nous faisons de notre mieux pour le traiter dans les plus brefs délais.',
  support_sent_title: 'Votre demande à été envoyé',
  support_subject_agentVisit: 'Visite de représentants',
  support_subject_callback: 'Rappel',
  support_subject_consulting: 'Consultation',
  support_subject_toolService: 'Outils de service',
  support_subject: 'Thème',
  support_title: 'Support',
  support_titleXpressRequest: 'Support - Demande XPress',
  support_toProfile: 'Vers le profil',
  support_xpress: 'LeitzXPert',
  support_xpressRequest: 'Demande LeitzXPert',
  tracking_allow: 'Autoriser',
  tracking_deny: 'Refuser',
  tracking_information: 'Afin d\'améliorer en permanence l\'expérience d\'utilisation de notre application, nous utilisons la collecte de données anonymes. Souhaitez-vous nous soutenir dans cette démarche ?',
  tracking_title: 'Aidez nous',
  version: 'Version',
};

export const branchesFR: Branch[] = [
  {
    name: 'Leitz GmbH & Co. KG',
    location: 'Autriche - Riedau',
    email: 'office.riedau@leitz.org',
    phone: '+43 (0) 7764 8200 0',
    fax: '+43 (0) 7764 8200 111',
    website: 'http://www.leitz.at',
    address: `Leitzstraße 80
    4752 Riedau
    `,
    country: 'Autriche',
  },
  {
    name: 'Leitz Tooling Systems Pty. Ltd.',
    location: 'Australie - Melbourne',
    email: 'sales@leitz.com.au',
    phone: '+61 (0) 3 9760 40 00',
    fax: '+61 (0) 3 9760 40 99',
    website: 'http://www.leitz.com.au',
    address: `2/55 Barry Street
    Bayswater VIC 3153
    `,
    country: 'Australie',
  },
  {
    name: 'Leitz-Service N.V.',
    location: 'Belgique - Grimbergen',
    email: 'contact-be@leitz.org',
    phone: '+32 (0) 2251 60 47',
    fax: '+32 (0) 2252 14 36',
    website: 'https://www.leitz.org',
    address: `Industrieweg 15
    1850 Grimbergen
    `,
    country: 'Belgique',
  },
  {
    name: 'Leitz Ferramentas e Sistemas de Precisão',
    location: 'Brésil - Sao Sebastiao do Cai',
    email: 'contact@leitz.com.br',
    phone: '+55 51 3635 6600',
    fax: '',
    website: 'http://www.leitz.org',
    address: `Rua Leitz, 50 - Bairro Angico - CEP 95760-000
    Junto à Rodovia RS 122, Km 14
    Sao Sebastiao do Cai - RS
    `,
    country: 'Brésil',
  },
  {
    name: 'Leitz Tooling Systems LP',
    location: 'Canada - Vaughan',
    email: 'infocanada@leitz.org',
    phone: '+1 (0) 905 760 0375',
    fax: '+1 (0) 905 760 8125',
    website: 'https://www.leitz.org/en-ca/',
    address: `435 Four Valley Drive
    Vaughan, Ontario L4K 5X5
    `,
    country: 'Canada',
  },
  {
    name: 'Leitz GmbH',
    location: 'Suisse - Lenzburg',
    email: 'kontakt-ch@leitz.org',
    phone: '+41 (0) 62 886 39 39',
    fax: '+41 (0) 62 886 39 40',
    website: 'http://www.leitz.org',
    address: `Hardstrasse 2
    Postfach 448
    5600 Lenzburg
    `,
    country: 'Suisse',
  },
  {
    name: 'Leitz Tooling Systems (China) Co., Ltd.',
    location: 'Chine - Nanjing',
    email: 'leitzchina@leitz.com.cn',
    phone: '+86 (0) 25 52103111',
    fax: '+86 (0) 25 52103777',
    website: 'http://www.leitz.com.cn',
    address: `No.9 Shengtong Road, Moling Sub-District, Jiangning Zone, 211111 Nanjing, Jiangsu Province
    `,
    country: 'Chine',
  },
  {
    name: 'Leitz-nástroje s.r.o.',
    location: 'République tchèque - Prague',
    email: 'leitz@leitz.cz',
    phone: '+420 24 14 82 699',
    fax: '+420 24 14 80 786',
    website: 'http://www.leitz.org',
    address: `Leitzova 1267/1
    Praha 5, Košíře
    `,
    country: 'République tchèque',
  },
  {
    name: 'Leitz Werkzeugdienst GmbH',
    location: 'Allemagne - Bielefeld',
    email: 'service-hotline@leitz.org',
    phone: '+49 (0) 5219 240 30',
    fax: '+49 (0) 5219 240 310',
    website: 'https://www.leitz.org/de-de/',
    address: `Lübberbrede 13
    33719 Bielefeld
    `,
    country: 'Allemagne',
  },
  {
    name: 'Emil Leitz GmbH',
    location: 'Allemagne - Oberkochen',
    email: 'service-hotline@leitz.org',
    phone: '+49 (0) 7364 950 0',
    fax: '+49 (0) 7364 950 660',
    website: 'https://www.leitz.org/de-de',
    address: `Leitzstraße 2
    73447 Oberkochen
    `,
    country: 'Allemagne',
  },
  {
    name: 'Leitz Werkzeugdienst GmbH',
    location: 'Allemagne - Troisdorf',
    email: 'service-hotline@leitz.org',
    phone: '+49 (0) 2241 947 10',
    fax: '+49 (0) 2241 947 132',
    website: 'https://www.leitz.org/de-de',
    address: `Industriestraße 12
    53842 Troisdorf
    `,
    country: 'Allemagne',
  },
  {
    name: 'Herramientas Leitz S.L.',
    location: 'Espagne - Vilassar de Dalt (Barcelone)',
    email: 'ventas@leitz.org',
    phone: '+34 937 508 417',
    fax: '+34 937 507 925',
    website: 'https://www.leitz.org/es-es/',
    address: `Narcís Monturiol, 11-13
    08339 Vilassar de Dalt (Barcelona)
    `,
    country: 'Espagne',
  },
  {
    name: 'Leitz Kes Metalli Oy',
    location: 'Finlande - Uurainen',
    email: 'leitz@leitzkesmetalli.fi',
    phone: '+358 (40) 18 80 55 0',
    fax: '+358 (40) 60 33 59 3',
    website: 'http://www.leitz.org/fi/',
    address: `Hitsaajantie 7
    41230 Uurainen
    `,
    country: 'Finlande',
  },
  {
    name: 'Leitz S.à.r.l., Colmar',
    location: 'France - Colmar',
    email: 'leitz-france@leitz.org',
    phone: '+33 (0) 3 892 108 00',
    fax: '',
    website: 'http://www.leitz.fr',
    address: `8, Rue Emile Schwoerer
    68012 Colmar Cedex
    `,
    country: 'France',
  },
  {
    name: 'Leitz Tooling UK Ltd.',
    location: 'Grande-Bretagne - Harlow',
    email: 'salesuk@leitz.org',
    phone: '+44 (0) 1279 454 530',
    fax: '+44 (0) 1279 454 509',
    website: 'https://www.leitz.org/en-gb',
    address: `Flex Meadow, The Pinnacles
    Harlow,
    Essex, CM19 5TN
    `,
    country: 'Grande-Bretagne',
  },
  {
    name: 'Leitz orodja d.o.o.',
    location: 'Croatie - Kranj (Slovénie)',
    email: 'leitz@leitz.si',
    phone: '+386 (0) 4 238 12 10',
    fax: '+386 (0) 4 238 12 22',
    website: 'http://www.leitz.org',
    address: `Savska cesta 14
    4000 Kranj
    `,
    country: 'Slovénie',
  },
  {
    name: 'Leitz Hungária Szerszám Kft.',
    location: 'Hongrie - Érd',
    email: 'leitz@leitz.hu',
    phone: '+36 (0) 23 521 900',
    fax: '',
    website: 'http://www.leitz.org',
    address: `Kis-Duna u. 6.
    2030 Érd
    `,
    country: 'Hongrie',
  },
  {
    name: 'Leitz Tooling Systems India Pvt Ltd.',
    location: 'Inde - Bangalore',
    email: 'info@leitzindia.com',
    phone: '+91 (0) 90 083 025 82',
    fax: '',
    website: 'http://www.leitz.org/en-in/',
    address: `486 C, 14th Cross, 4th Phase,
    Peenya Industrial Area,
    Bangalore 560 058
    Karnataka
    `,
    country: 'Inde',
  },
  {
    name: 'Leitz Italia S.r.l.',
    location: 'Italie - Lana',
    email: 'lana@leitz.org',
    phone: '+39 0473 55 28 00',
    fax: '+39 0473 56 21 39',
    website: 'http://www.leitz.org',
    address: `Zona Industriale 9
    39011 Lana (BZ)
    `,
    country: 'Italie',
  },
  {
    name: 'Leitz Tooling Co. Ltd.',
    location: 'Japon - Yokohama',
    email: 'info@leitz.co.jp',
    phone: '+81 (0) 455 33 3020',
    fax: '+81 (0) 455 33 3021',
    website: 'http://www.leitz.co.jp',
    address: `2-7-2, Kita-Shinyokohama,
    Kohoku-ku, Yokohama 223-0059
    `,
    country: 'Japon',
  },
  {
    name: 'Leitz Baltic UAB',
    location: 'Lituanie  - Kaunas',
    email: 'baltic@leitz.org',
    phone: '+370 37 281 289',
    fax: '',
    website: 'http://www.leitz.org',
    address: `Kaštonų g. 56,
    54310  Giraitė, Kauno r.
    `,
    country: 'Lituanie',
  },
  {
    name: 'Leitz-Service SARL',
    location: 'Luxembourg - Holzem',
    email: 'contact-lu@leitz.org',
    phone: '+352 (0) 399 550',
    fax: '+352 (0) 399 852',
    website: 'http://www.leitz.org',
    address: `28 Route de Capellen
    8279 Holzem
    `,
    country: 'Luxembourg',
  },
  {
    name: 'Leitz México S.A. de C.V.',
    location: 'Mexique - El Marqués',
    email: 'info@leitz.com.mx',
    phone: '+52 (0) 442 296 6870',
    fax: '+52 (0) 442 296 6882',
    website: 'https://www.leitz.org/es-mx/',
    address: `Av. Acueducto No. 15
    Parque Industrial Bernardo Quintana
    C.P. 76246 El Marqués, Querétaro
    `,
    country: 'Mexique',
  },
  {
    name: 'Leitz Tooling (M) Sdn. Bhd.',
    location: 'Malaisie - Petaling',
    email: 'sales_m@leitztools.com',
    phone: '+603 80 81 22 26',
    fax: '+603 80 81 12 26',
    website: 'http://www.leitztools.com',
    address: `No. 15, Jalan TP 3/2
    UEP Industrial Park USJ
    47600 Subang Jaya
    Selangor
    `,
    country: 'Malaisie',
  },
  {
    name: 'Leitz Service BV',
    location: 'Pays-Bas - Elst',
    email: 'elst@leitz-service.org',
    phone: '+31 (0)481 748 100',
    fax: '+31 (0)481 748 110',
    website: 'http://www.leitz.org',
    address: `Platinaweg 7-9
    6662 PR Elst
    `,
    country: 'Pays-Bas',
  },
  {
    name: 'Leitz Tooling NZ Ltd.',
    location: 'Nouvelle-Zélande - Penrose',
    email: 'leitz@leitz.co.nz',
    phone: '+64 800 578 665',
    fax: '+64 800 568 6652',
    website: 'http://www.leitz.co.nz',
    address: `Unit 9B Hamlin’s Hill Commercial Centre
    930 Great South Road
    Penrose 1061
    Auckland
    `,
    country: 'Nouvelle-Zélande',
  },
  {
    name: 'Leitz Polska Sp. z o.o.',
    location: 'Pologne - Radomsko',
    email: 'leitz@leitz.pl',
    phone: '+48 (0) 44 683 03 88',
    fax: '+48 (0) 44 683 04 77',
    website: 'http://www.leitz.pl',
    address: `ul. Duńska 4
    97-500 Radomsko
    `,
    country: 'Pologne',
  },
  {
    name: 'Leitz Romania S.R.L.',
    location: 'Roumanie - Brasov',
    email: 'office-brasov@leitz.org',
    phone: '+40 (0) 268 406 246',
    fax: '',
    website: 'http://www.leitz.org',
    address: `B-dul Grivitei Nr. 1 W
    500177 Brasov
    `,
    country: 'Roumanie',
  },
  {
    name: 'OOO Leitz Instrumenti',
    location: 'Russie - Moscou',
    email: 'info@leitz.ru',
    phone: '+7 495 120 11 70',
    fax: '',
    website: 'http://www.leitz.ru',
    address: `Geroya Rossii Solomatina st. 6, bld.9A
    pos. Zavoda Mosrentgen,
    108820, Moscow, Russia
    `,
    country: 'Russie',
  },
  {
    name: 'Leitz Tooling Asia Pte Ltd.',
    location: 'Singapour - Singapour',
    email: 'sales@leitztools.com',
    phone: '+65 64 62 53 68',
    fax: '+65 64 62 12 09',
    website: 'https://www.leitz.org/en-sg',
    address: `1 Clementi Loop #04-04
    Singapore 129 808
    `,
    country: 'Singapour',
  },
  {
    name: 'Leitz orodja d.o.o.',
    location: 'Slovénie - Kranj',
    email: 'leitz@leitz.si',
    phone: '+386 (0) 4 238 12 10',
    fax: '+386 (0) 4 238 12 22',
    website: 'http://www.leitz.org',
    address: `Savska cesta 14
    4000 Kranj
    `,
    country: 'Slovénie',
  },
  {
    name: 'Leitz-nástroje s.r.o.',
    location: 'Slovaquie - Bratislava',
    email: 'leitz@leitz.sk',
    phone: '+421 (0) 2 52 62 00 24',
    fax: '+421 (0) 2 52 62 00 26',
    website: 'http://www.leitz.org',
    address: `Stará Vajnorská 90
    831 04 Bratislava
    `,
    country: 'Slovaquie',
  },
  {
    name: 'Leitz Tooling Co., Ltd',
    location: 'Thaïlande - Bangkok',
    email: 'san@leitztools.com',
    phone: '+66 (0) 2 807 59 90',
    fax: '+66 (0) 2 807 59 94',
    website: 'http://www.leitzthailand.com',
    address: `6 Phutthamonthon Sai 3 Rd
    Nongkangplu, Nongkaem
    Bangkok 10160
    `,
    country: 'Thaïlande',
  },
  {
    name: 'Leitz Kesici Takımlar San. Tic. A.Ş.',
    location: 'Turquie - Istanbul',
    email: 'leitz@leitz.com.tr',
    phone: '+90 262 677 17 27',
    fax: '+90 262 677 17 30',
    website: 'https://www.leitz.org/tr/',
    address: `Gebze Organize Sanayi Bölgesi
    1600. Sok. No: 1602
    41400 Gebze / Kocaeli
    `,
    country: 'Turquie',
  },
  {
    name: 'Leitz Tooling Asia Pte Ltd (Branch Office)',
    location: 'Taïwan - Ville de Tainan',
    email: 'leitztaiwan@leitztools.com',
    phone: '+886 (0) 932 723 087',
    fax: '',
    website: 'http://www.leitz.org',
    address: `6F., No. 31, Sec. 3,
    Dongmen Rd.,
    East Dist., Tainan City 70172
    `,
    country: 'Taïwan',
  },
  {
    name: 'Leitz Instrumenty Ukraina LLC',
    location: 'Ukraine - Kiev',
    email: 'kiev@leitz.org',
    phone: '+38 (0) 67 218 28 69',
    fax: '+38 (0) 67 218 28 69',
    website: 'http://www.leitz.com.ua',
    address: `Petropawlivska Borschagivka
    Wul. Soborna 2-B
    08130 Kiev
    `,
    country: 'Ukraine',
  },
  {
    name: 'Leitz Tooling Systems LP',
    location: 'ÉTATS-UNIS - Grand Rapids',
    email: 'insidesales@leitz.org',
    phone: '+1 800 253 6070',
    fax: '+1 800 752 9391',
    website: 'https://www.leitz.org/en-us/',
    address: `4301 East Paris Avenue, S.E.
    Grand Rapids, MI 49512
    `,
    country: 'ÉTATS-UNIS',
  },
  {
    name: 'Leitz Tooling Vietnam Company Limited',
    location: 'Vietnam - Ho Chi Minh Ville',
    email: 'sales_ltv@leitz.co.th',
    phone: '+84 (0)25 136 829 44',
    fax: '+84 (0)25 136 829 45',
    website: 'https://www.leitz.org/vi/',
    address: `Rental factory C-4, Road N1-2, Long Duc Industrial Park,
    An Phuoc ward, Long Thanh district,
    Dong Nai province,
    Vietnam, 810000
    `,
    country: 'Vietnam',
  },
];
