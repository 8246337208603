
import { Options, Vue } from 'vue-class-component';
import LanguageStore from '@/stores/languages';

@Options({
  components: {
  },
  props: {
    type: String,
    title: String,
  },
  watch: {
    '$i18n.locale': {
      handler: 'setRecommendation',
    },
  },
})
export default class Recommendation extends Vue {
  recommendationDE = {
    toothFeedMillWood: [
      { label: 'calculator_recommendation_favorableRange', value: '0,3 - 1,5 mm' },
      { label: 'calculator_recommendation_fineSizingChip', value: '0,3 - 0,8 mm' },
      { label: 'calculator_recommendation_sizingChip', value: '0,8 - 2,5 mm' },
      { label: 'calculator_recommendation_roughingChip', value: '2,5 - 5,0 mm' },
    ],
    toothFeedSawWood: [
      { label: 'calculator_recommendation_solidWoodLengthwise', value: '0,20 - 0,90 mm' },
      { label: 'calculator_recommendation_softwoodCrosswise', value: '0,10 - 0,20 mm' },
      { label: 'calculator_recommendation_hardwoodCrosswise', value: '0,05 - 0,15 mm' },
      { label: 'calculator_recommendation_OSBBoards', value: '0,10 - 0,20 mm' },
      { label: 'calculator_recommendation_veneeredPanels', value: '0,03 - 0,10 mm' },
      { label: 'calculator_recommendation_blockboards', value: '0,03 - 0,10 mm' },
      { label: 'calculator_recommendation_plasticCoatedChipboard', value: '0,03 - 0,06 mm' },
      { label: 'calculator_recommendation_HPLCoveredChipboard', value: '0,02 - 0,10 mm' },
      { label: 'calculator_recommendation_plywood', value: '0,05 - 0,10 mm' },
      { label: 'calculator_recommendation_wroughtAluminumAlloys', value: '0,03 - 0,10 mm' },
      { label: 'calculator_recommendation_hardboard', value: '0,03 - 0,08 mm' },
      { label: 'calculator_recommendation_cementBondedWoodMaterials', value: '0,02 - 0,05 mm' },
      { label: 'calculator_recommendation_pressedLaminatedWood', value: '0,02 - 0,06 mm' },
      { label: 'calculator_recommendation_gypsumMaterials', value: '0,10 - 0,20 mm' },
      { label: 'calculator_recommendation_thermoplastics', value: '0,05 - 0,10 mm' },
      { label: 'calculator_recommendation_thermosettingPlastics', value: '0,02 - 0,05 mm' },
      { label: 'calculator_recommendation_castAluminumAlloys', value: '0,01 - 0,04 mm' },
    ],
    cuttingParametersNE: [
      {
        group: 'calculator_recommendation_group_aluminiumCastWroughtAlloys', tensileStrength: '≤ 500', cuttingSpeed: '1500 - 4000', toothFeed: '0,02 - 0,07',
      },
      {
        group: 'calculator_recommendation_group_copperAlloys', tensileStrength: '≤ 400', cuttingSpeed: '180 - 500', toothFeed: '0,04 - 0,12',
      },
      {
        group: 'calculator_recommendation_group_brassShortLongChipping', tensileStrength: '≤ 600', cuttingSpeed: '150 - 400', toothFeed: '0,07 - 0,10',
      },
      {
        group: 'calculator_recommendation_group_bronze', tensileStrength: '≤ 850', cuttingSpeed: '100 - 400', toothFeed: '0,05 - 0,10',
      },
    ],
    cuttingParametersSteel: [
      {
        group: 'calculator_recommendation_group_constructionSteels', material: 'St 44, St 52, St 37', cuttingSpeed: '115 - 150', toothFeed: '0,09 - 0,15',
      },
      {
        group: 'calculator_recommendation_group_quenchedAndTemperedNitridingSteels', material: 'C 45,C 60, 42 CrMo 4, 34<br>CrNiMo 6<br>34 CrAlNi 7', cuttingSpeed: '95 - 120', toothFeed: '0,08 - 0,15',
      },
      {
        group: 'calculator_recommendation_group_springBearingSteels', material: '50 CrV 4<br>100 Cr 6', cuttingSpeed: '70 - 100', toothFeed: '0,07 - 0,12',
      },
      {
        group: 'calculator_recommendation_group_stainlessSteels', material: 'X 40 CrMoV 51<br>X 5 CrNiMo 17 12 2<br>X 20 Cr 13<br>X 2 CrNIMo 18 14 3', cuttingSpeed: '50 - 80', toothFeed: '0,04 - 0,08',
      },
    ],
  };

  recommendationEN = {
    toothFeedMillWood: [
      { label: 'calculator_recommendation_favorableRange', value: '0.3 - 1.5 mm' },
      { label: 'calculator_recommendation_fineSizingChip', value: '0.3 - 0.8 mm' },
      { label: 'calculator_recommendation_sizingChip', value: '0.8 - 2.5 mm' },
      { label: 'calculator_recommendation_roughingChip', value: '2.5 - 5.0 mm' },
    ],
    toothFeedSawWood: [
      { label: 'calculator_recommendation_solidWoodLengthwise', value: '0.20 - 0.90 mm' },
      { label: 'calculator_recommendation_softwoodCrosswise', value: '0.10 - 0.20 mm' },
      { label: 'calculator_recommendation_hardwoodCrosswise', value: '0.05 - 0.15 mm' },
      { label: 'calculator_recommendation_OSBBoards', value: '0.10 - 0.20 mm' },
      { label: 'calculator_recommendation_veneeredPanels', value: '0.03 - 0.10 mm' },
      { label: 'calculator_recommendation_blockboards', value: '0.03 - 0.10 mm' },
      { label: 'calculator_recommendation_plasticCoatedChipboard', value: '0.03 - 0.06 mm' },
      { label: 'calculator_recommendation_HPLCoveredChipboard', value: '0.02 - 0.10 mm' },
      { label: 'calculator_recommendation_plywood', value: '0.05 - 0.10 mm' },
      { label: 'calculator_recommendation_wroughtAluminumAlloys', value: '0.03 - 0.10 mm' },
      { label: 'calculator_recommendation_hardboard', value: '0.03 - 0.08 mm' },
      { label: 'calculator_recommendation_cementBondedWoodMaterials', value: '0.02 - 0.05 mm' },
      { label: 'calculator_recommendation_pressedLaminatedWood', value: '0.02 - 0.06 mm' },
      { label: 'calculator_recommendation_gypsumMaterials', value: '0.10 - 0.20 mm' },
      { label: 'calculator_recommendation_thermoplastics', value: '0.05 - 0.10 mm' },
      { label: 'calculator_recommendation_thermosettingPlastics', value: '0.02 - 0.05 mm' },
      { label: 'calculator_recommendation_castAluminumAlloys', value: '0.01 - 0.04 mm' },
    ],
    cuttingParametersNE: [
      {
        group: 'calculator_recommendation_group_aluminiumCastWroughtAlloys', tensileStrength: '≤ 500', cuttingSpeed: '1500 - 4000', toothFeed: '0.02 - 0.07',
      },
      {
        group: 'calculator_recommendation_group_copperAlloys', tensileStrength: '≤ 400', cuttingSpeed: '180 - 500', toothFeed: '0.04 - 0.12',
      },
      {
        group: 'calculator_recommendation_group_brassShortLongChipping', tensileStrength: '≤ 600', cuttingSpeed: '150 - 400', toothFeed: '0.07 - 0.10',
      },
      {
        group: 'calculator_recommendation_group_bronze', tensileStrength: '≤ 850', cuttingSpeed: '100 - 400', toothFeed: '0.05 - 0.10',
      },
    ],
    cuttingParametersSteel: [
      {
        group: 'calculator_recommendation_group_constructionSteels', material: 'St 44, St 52, St 37', cuttingSpeed: '115 - 150', toothFeed: '0.09 - 0.15',
      },
      {
        group: 'calculator_recommendation_group_quenchedAndTemperedNitridingSteels', material: 'C 45,C 60, 42 CrMo 4, 34<br>CrNiMo 6<br>34 CrAlNi 7', cuttingSpeed: '95 - 120', toothFeed: '0.08 - 0.15',
      },
      {
        group: 'calculator_recommendation_group_springBearingSteels', material: '50 CrV 4<br>100 Cr 6', cuttingSpeed: '70 - 100', toothFeed: '0.07 - 0.12',
      },
      {
        group: 'c alculator_recommendation_group_stainlessSteels', material: 'X 40 CrMoV 51<br>X 5 CrNiMo 17 12 2<br>X 20 Cr 13<br>X 2 CrNIMo 18 14 3', cuttingSpeed: '50 - 80', toothFeed: '0.04 - 0.08',
      },
    ],
  };

  recommendation = this.recommendationEN;

  created() {
    this.setRecommendation();
  }

  setRecommendation() {
    const languageStore = LanguageStore();
    const language = languageStore.getLanguageByTag(this.$i18n.locale);
    if (language && language.flag === 2) {
      this.recommendation = this.recommendationDE;
    } else {
      this.recommendation = this.recommendationEN;
    }
  }
}
