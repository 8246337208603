
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
  props: {
    modelValue: Boolean,
  },
})
export default class ToggleElement extends Vue {
}
