import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, withCtx as _withCtx, createBlock as _createBlock, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "search" }
const _hoisted_2 = { class: "search__content" }
const _hoisted_3 = { class: "search__title" }
const _hoisted_4 = {
  key: 0,
  class: "font-size-14 color-tertiary-2"
}
const _hoisted_5 = { class: "search__form" }
const _hoisted_6 = { class: "search__input" }
const _hoisted_7 = { class: "search__input-icons" }
const _hoisted_8 = { class: "search__button" }
const _hoisted_9 = {
  key: 1,
  class: "search__error font-size-12 color-red"
}

export function render(_ctx, _cache) {
  const _component_SvgElement = _resolveComponent("SvgElement")
  const _component_TextInputElement = _resolveComponent("TextInputElement")
  const _component_ButtonElement = _resolveComponent("ButtonElement")
  const _component_LoaderElement = _resolveComponent("LoaderElement")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SvgElement, {
          src: "/icons/search.svg",
          class: "search__icon fill-primary"
        }),
        _createElementVNode("div", {
          class: _normalizeClass(`font-size-${_ctx.isCollapsed ? '20' : '28'} color-black`)
        }, _toDisplayString(_ctx.$t('productsearch_title')), 3)
      ]),
      (!_ctx.isCollapsed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('productsearch_description')), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_TextInputElement, {
            modelValue: _ctx.id,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = $event => ((_ctx.id) = $event)),
              _cache[1] || (_cache[1] = $event => (_ctx.$emit('update')))
            ],
            placeholder: _ctx.$t('productsearch_inputPlaceholderIDSerial'),
            prefixedIcon: "/icons/hash.svg",
            onKeyup: _cache[2] || (_cache[2] = _withKeys($event => (_ctx.$emit('searchProduct', _ctx.id)), ["enter"]))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_SvgElement, {
                  src: "/icons/qrcode.svg",
                  class: "search__input-icon fill-primary"
                }),
                _createVNode(_component_SvgElement, {
                  src: "/icons/rfid.svg",
                  class: "search__input-icon fill-primary"
                })
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_ButtonElement, {
            label: _ctx.$t('productsearch_loadProduct'),
            disabled: _ctx.loading,
            onClick: _cache[3] || (_cache[3] = $event => (_ctx.$emit('searchProduct', _ctx.id)))
          }, null, 8, ["label", "disabled"]),
          _createVNode(_Transition, { mode: "out-in" }, {
            default: _withCtx(() => [
              (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_LoaderElement, {
                    key: 0,
                    class: "search__loader"
                  }))
                : (_ctx.errorMsg)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.errorMsg), 1))
                  : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}