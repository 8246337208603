const zh = {
  calculator_calculation: '计算',
  calculator_calculationOf_chipSheetLengthAndChipThickness: '切削弧长和切削深度计算',
  calculator_calculationOf_chipThicknessAndChipSheetLength: '切削弧长和切削深度计算',
  calculator_calculationOf_cutPath: '所有刀齿加工路径总长计算',
  calculator_calculationOf_cutterStepDepth: '理论表面刀痕深度计算',
  calculator_calculationOf_cuttingSpeed: '切削速度计算',
  calculator_calculationOf_feedPerTooth: '每齿进给量计算',
  calculator_calculationOf_feedRate: '进料速度计算',
  calculator_calculationOf_rotationalSpeed: '转速计算',
  calculator_calculationType_cuttingSpeed: '切削速度',
  calculator_calculationType_rotationalSpeed: '转速',
  calculator_compareTools: '刀具对比',
  calculator_material_metal: '金属',
  calculator_material_title: '材料',
  calculator_material_wood: '木材和木材衍生材',
  calculator_mill: '铣削',
  calculator_parameterType_averageChipThickness: '平均切削深度',
  calculator_parameterType_chipSheetLength: '切削弧长',
  calculator_parameterType_cutPath: '切削路径总长',
  calculator_parameterType_cutterStepDepth: '每齿的刀痕深度',
  calculator_parameterType_cuttingDepthMill: '径向切削深度',
  calculator_parameterType_cuttingDepthSaw: '切削深度',
  calculator_parameterType_cuttingSpeed: '切削速度',
  calculator_parameterType_diameter: '直径',
  calculator_parameterType_feedPath: '行进距离',
  calculator_parameterType_feedPerRotation: '每转进给量',
  calculator_parameterType_feedRate: '进料速度',
  calculator_parameterType_maximumChipThickness: '最大切削深度',
  calculator_parameterType_protrusionSawBlade: '锯片伸出板材高度',
  calculator_parameterType_rotationalSpeed: '转速',
  calculator_parameterType_toothCount: '齿数',
  calculator_parameterType_toothFeed: '每齿进给量',
  calculator_quantityType_area: '面积',
  calculator_quantityType_energy: '能耗',
  calculator_quantityType_length: '长度',
  calculator_quantityType_performance: '功率',
  calculator_quantityType_power: '质量',
  calculator_quantityType_pressure: '压力',
  calculator_quantityType_temperature: '温度',
  calculator_quantityType_torque: '扭矩',
  calculator_quantityType_velocity: '速度',
  calculator_quantityType_volume: '体积',
  calculator_quantityType_weight: '重量',
  calculator_recommendation_blockboards: '细木工板',
  calculator_recommendation_castAluminumAlloys: '铸造铝合金',
  calculator_recommendation_cementBondedWoodMaterials: '水泥纤维板',
  calculator_recommendation_favorableRange: '通常的合理范围',
  calculator_recommendation_fineSizingChip: '高等级精加工',
  calculator_recommendation_group_aluminiumCastWroughtAlloys: '铸造铝合金和锻造铝合金',
  calculator_recommendation_group_brassShortLongChipping: '黄铜，短加工或长加工',
  calculator_recommendation_group_bronze: '青铜',
  calculator_recommendation_group_constructionSteels: '结构钢',
  calculator_recommendation_group_copperAlloys: '铜及铜合金',
  calculator_recommendation_group_quenchedAndTemperedNitridingSteels: '回火和氮化钢',
  calculator_recommendation_group_springBearingSteels: '弹簧和滚柱轴承钢',
  calculator_recommendation_group_stainlessSteels: '不锈钢',
  calculator_recommendation_gypsumMaterials: '石膏',
  calculator_recommendation_hardboard: '硬质纤维板',
  calculator_recommendation_hardwoodCrosswise: '硬木横向加工',
  calculator_recommendation_headline_cuttingSpeed: '切削速度',
  calculator_recommendation_headline_group: '材料组',
  calculator_recommendation_headline_material: '材料',
  calculator_recommendation_headline_tensileStrength: '抗拉强度',
  calculator_recommendation_headline_toothFeed: '每齿进给量',
  calculator_recommendation_HPLCoveredChipboard: 'HPL贴面刨花板',
  calculator_recommendation_OSBBoards: '定向刨花板',
  calculator_recommendation_plasticCoatedChipboard: '三聚氰胺贴面刨花板',
  calculator_recommendation_plywood: '多层板',
  calculator_recommendation_pressedLaminatedWood: '单板层压材',
  calculator_recommendation_roughingChip: '粗加工',
  calculator_recommendation_sizingChip: '精加工',
  calculator_recommendation_softwoodCrosswise: '软木横向加工',
  calculator_recommendation_solidWoodLengthwise: '实木纵向加工',
  calculator_recommendation_thermoplastics: '热塑性塑料',
  calculator_recommendation_thermosettingPlastics: '硬质塑料',
  calculator_recommendation_title_general: '推荐值',
  calculator_recommendation_title_NE: '有色金属加工推荐切削参数',
  calculator_recommendation_title_steel: '钢材加工推荐切削参数',
  calculator_recommendation_title_toothFeed: '每齿进给量推荐值',
  calculator_recommendation_veneeredPanels: '饰面板',
  calculator_recommendation_wroughtAluminumAlloys: '锻造铝合金',
  calculator_reset: '重置',
  calculator_resetCalculator: '重启计算器',
  calculator_saw: '锯片',
  calculator_tabs_mill: '铣削',
  calculator_tabs_saw: '推台锯',
  calculator_tabs_unit: '单位转换',
  calculator_title: '计算',
  calculator_titleMill: '铣削计算',
  calculator_titleSaw: '锯切计算',
  calculator_titleUnit: '计算-单位',
  calculator_tool: '刀具',
  calculator_toolParameters: '刀具参数',
  calculator_unit: '单位',
  calculator_unitConversion: '单位转换',
  calculator_units: '单位',
  calculator_unitToConvert: '需要转换的单位',
  calculator_unitType_bar: '巴',
  calculator_unitType_britishThermalUnit: '英制热量单位',
  calculator_unitType_centimeter: '厘米',
  calculator_unitType_cubicFeet: '立方英尺',
  calculator_unitType_cubicInch: '立方英寸',
  calculator_unitType_cubicMeter: '立方米',
  calculator_unitType_degreeCelsius: '摄氏度',
  calculator_unitType_degreeFahrenheit: '华氏度',
  calculator_unitType_feet: '英尺',
  calculator_unitType_feetPerMinute: '每分钟英尺',
  calculator_unitType_footPound: '尺磅',
  calculator_unitType_gallon: '加仑',
  calculator_unitType_gram: '克',
  calculator_unitType_horsepower: '马力',
  calculator_unitType_inch: '英寸',
  calculator_unitType_inchPerMinute: '每分钟英寸',
  calculator_unitType_inchPerSecond: '每秒英寸',
  calculator_unitType_joule: '焦耳',
  calculator_unitType_kelvin: '绝对温标',
  calculator_unitType_kilocalories: '千卡',
  calculator_unitType_kilogram: '公斤',
  calculator_unitType_kilopond: '千克力',
  calculator_unitType_kilowatt: '千瓦',
  calculator_unitType_kilowattHour: '千瓦时',
  calculator_unitType_liter: '升',
  calculator_unitType_meter: '米',
  calculator_unitType_meterPerMinute: '米每分钟',
  calculator_unitType_meterPerSecond: '米每秒',
  calculator_unitType_metricHorsepower: '马力',
  calculator_unitType_micrometer: '微米',
  calculator_unitType_millimeter: '毫米',
  calculator_unitType_newton: '牛顿',
  calculator_unitType_newtonMeter: '牛顿米',
  calculator_unitType_ounce: '盎司',
  calculator_unitType_ounceForce: '盎司力',
  calculator_unitType_ounceForceFeet: '盎司力英尺',
  calculator_unitType_pascal: '帕斯卡',
  calculator_unitType_pound: '磅力',
  calculator_unitType_poundForce: '磅力',
  calculator_unitType_poundForceFeet: '磅力英尺',
  calculator_unitType_poundForceInch: '磅力英寸',
  calculator_unitType_poundForcePerSquareInch: '磅力每平方英寸',
  calculator_unitType_squareCentimeter: '平方英寸',
  calculator_unitType_squareFeet: '平方英尺',
  calculator_unitType_squareInch: '平方英寸',
  calculator_unitType_squareMeter: '平方米',
  calculator_unitType_squareMillimeter: '平方毫米',
  calculator_unitType_standardAtmosphere: '物理大气压',
  calculator_unitType_tonne: '吨',
  calculator_unitType_watt: '瓦特',
  favorites_noFavorites: '您尚未将任何产品保存到收藏',
  favorites_titlePlural: '收藏',
  favorites_titleSingular: '收藏',
  language: '中文',
  legalInformation_imprint: '印记',
  legalInformation_privacyPolicy: '隐私',
  legalInformation_title: '法律信息',
  leitzxpert: 'LeitzXPert',
  link_imprint: 'https://www.leitz.com.cn/zh/corporate-information',
  link_privacyPolicy: 'https://www.leitz.com.cn/zh/privacy',
  navigation_appLanguage: '应用程序语言',
  navigation_apps: '应用程序',
  navigation_back: '返回',
  navigation_calculator: '计算',
  navigation_cancel: '取消',
  navigation_close: '关闭',
  navigation_done: '完成',
  navigation_favorites: '收藏',
  navigation_language: '语言',
  navigation_productsearch: '产品搜索',
  navigation_profile: '设置',
  navigation_search: '搜索',
  navigation_section: '栏目',
  navigation_support: '支持',
  noMobile_appName: 'LeitzXPert',
  noMobile_availableFor: '适用于Android和iOS',
  noMobile_availableSoon: '很快获取',
  noMobile_notAvailable: '此页面未针对移动设备和显示宽度低于960像素的屏幕进行优化，因此无法显示。',
  noMobile_recommendation: '我们建议从Apple应用商店安装我们的免费LeitzXPert应用程序。',
  noMobile_title: '此页面未针对移动设备和小屏幕进行优化。',
  product_applicationParameter_title: '应用程序参数',
  product_backTo: '返回',
  product_general_details: '细节',
  product_general_images: '产品图片&amp;视频',
  product_general_imagesNoMedia: '无产品图片视频可用',
  product_general_maximizeDescription: '显示描述',
  product_general_minimizeDescription: '减少描述',
  product_general_sketches: '草图和图表',
  product_general_sketchesNoMedia: '无草图可用',
  product_general_title: '一般信息',
  product_noImage: '无可用的图像',
  product_noInformation: '无相关信息',
  product_notFound: '未找到产品',
  product_productsearch: '产品搜索',
  product_relatedArticles_title: '其他尺寸',
  product_relatedArticles_toProduct: '产品',
  product_spares_availability_availableAtShortNotice: '可在短时间内提供',
  product_spares_availability_availableExStock: '现货供应',
  product_spares_availability_productionToOrder: '定制',
  product_spares_titleBlade: '备用刀片',
  product_spares_titleGeneral: '备件',
  product_spares_titleSaw: '备用圆锯片',
  product_tabs_applicationParameter_noData: '本产品无应用参数',
  product_tabs_applicationParameter: '应用程序参数',
  product_tabs_downloads_noData: '此产品无可用的下载',
  product_tabs_downloads: '下载',
  product_tabs_general: '一般性问题',
  product_tabs_link_noData: '这个产品无网站链接',
  product_tabs_link: '连结至网站',
  product_tabs_relatedArticles_noData: '该产品没有其他尺寸信息',
  product_tabs_relatedArticles: '其他尺寸',
  product_tabs_spares_noData: '这个产品无备件',
  product_tabs_spares: '备件',
  product_tabs_technicalInfo_noData: '这个产品无技术资料',
  product_tabs_technicalInfo: '技术信息',
  product_tabs_toolApplicability_noData: '无适合该产品的工具',
  product_tabs_toolApplicability: '刀具适用性',
  product_technicalInfo_title: '技术信息',
  product_toolApplicability_title: '工具适用性',
  product_tools: '刀具',
  productsearch_actions_addToFavorites: '添加到收藏夹',
  productsearch_actions_delete: '删除',
  productsearch_actions_details: '细节',
  productsearch_actions_removeFromFavorites: '从收藏夹中删除',
  productsearch_actions_removeFromHistory: '从历史中删除',
  productsearch_code: '代码',
  productsearch_codeInput_text: '扫描产品识别号，获取产品数据',
  productsearch_codeInput_title: '数据库',
  productsearch_description: '此搜索允许您通过在输入字段中手动输入数字或使用兼容的读取器，通过其标识号或序列号查找产品。',
  productsearch_error_code_text: '无法扫描代码，请再试一次。',
  productsearch_error_noProduct_textLong: '这个号码找不到产品数据，请用不同的号码再试一次',
  productsearch_error_noProduct_textShort: '输入的ID或序列号找不到任何产品',
  productsearch_error_noProduct_title: '这个ID号无结果',
  productsearch_error_notEnoughDigits_textLong: '请输入至少4个字符',
  productsearch_error_notEnoughDigits_textShort: '至少4个字符',
  productsearch_error_rfid_text: '无法读取RFID，请再试一次。',
  productsearch_error_unknown_text: '发生未知错误，请再试一次或联系我们',
  productsearch_error_unknown_title: '发生错误',
  productsearch_history: '历史',
  productsearch_historyEmpty: '您还未搜索到任何产品，最近搜索的产品在这里按时间顺序列出。',
  productsearch_id: 'ID 号',
  productsearch_idInput_text: '输入产品ID号，获取产品数据。',
  productsearch_idInput_title: '输入产品ID号',
  productsearch_inputPlaceholder: '输入产品ID号',
  productsearch_inputPlaceholderIDSerial: '输入产品ID号/序列号',
  productsearch_loadProduct: '加载产品数据',
  productsearch_openCamera: '打开相机',
  productsearch_readerInstruction: '连接阅读器扫描条形码',
  productsearch_readerNotConnected: '读取器未连接',
  productsearch_readerNotRecognised: '设备无法识别',
  productsearch_rfid_disabledText: '要搜索使用RFID的产品，必须激活NFC。要做到这一点，请先调用智能手机上的设置。',
  productsearch_rfid_disabledTitle: 'NFC未启用',
  productsearch_rfid_openScanner: '打开RFID扫描器',
  productsearch_rfid_ready: '准备好扫描',
  productsearch_rfid: 'PRID',
  productsearch_rfidInput_text: '将智能手机或RFID扫描仪放在产品上以获取数据',
  productsearch_rfidInput_title: 'RFID产品扫描',
  productsearch_showHistory: '显示历史',
  productsearch_title: '产品搜索',
  productsearch_tryAgain: '再试一次',
  start_appFunctions: '应用程序功能',
  start_button: '现在就开始',
  start_calculator_description: '用我们的实用计算器找出重要的应用数据',
  start_calculator_title: '计算',
  start_favorites_description: '以一种方便、简单的方式保存您正在寻找的产品',
  start_favorites_title: '收藏',
  start_productsearch_description: '发现我们标准程序的所有工具数据，说明书和产品手册。',
  start_productsearch_title: '产品搜索',
  start_skip: '跳过',
  start_solutions: '新维度的解决方案',
  start_support_description: '快速方便地联系蓝帜的支持团队',
  start_support_title: '支持',
  start_welcomeAt: '欢迎访问',
  support_agreeToPrivacy: '我同意隐私政策',
  support_branch: '分公司',
  support_branchEdit: '更换分公司',
  support_contactDetails: '分公司联络资料',
  support_contactType: '蓝帜集团总部',
  support_customerNumber: '客户编号',
  support_dataInfo_text: '我们需要您的信息来帮助您尽快得到支持，您不必在之后的寻求支持时重复输入此数据。',
  support_dataInfo_title: '我们为什么需要您的信息?',
  support_eMail: '电子邮件',
  support_errorCustomerId: '客户编号只能由数字组成',
  support_errorEmailEmpty: '电子邮件不能是空的',
  support_errorEmailInvalid: '电子邮件必须有效',
  support_errorMessage: '消息不能为空',
  support_errorPhoneNumber: '电话号码不能为空',
  support_errorSubject: '必须选择主题',
  support_inputPlaceholderPleaseSelect: '请选择',
  support_inputPlaceholderPleaseType: '请输入',
  support_inputPlaceholderSelect: '选择',
  support_inputPlaceholderSelectBranch: '选择分公司',
  support_inputPlaceholderTypeHere: '在这里输入',
  support_leitzBranch: '蓝帜分公司',
  support_message: '消息',
  support_myData: '我的信息',
  support_noBranchSelected: '要开始查询，你必须首先选择一个分公司',
  support_phone: '电话号码',
  support_privacyInformation: '所提交的资料只会被收集作处理您的要求之用。通过提交表格，您同意我们的隐私政策。',
  support_privacyLink: '隐私政策',
  support_ProfileNotFilled: '要开始查询，您必须首先在您的个人资料中输入所有必需的数据。',
  support_request: '请求',
  support_requiredFields: '带*的字段是必填的',
  support_sendingFailed: '您的请求无法发送，请稍后再试。',
  support_sendRequest: '发送请求',
  support_sent_backToSearch: '返回产品搜索',
  support_sent_newRequest: '启动新请求',
  support_sent_team: '你的LeitzXPert团队',
  support_sent_thankyou: '谢谢您的询价，我们会尽最大努力尽快处理。',
  support_sent_title: '您的请求已发送',
  support_subject_agentVisit: '代表访问',
  support_subject_callback: '回调',
  support_subject_consulting: '咨询',
  support_subject_toolService: '刀具服务',
  support_subject: '主题',
  support_title: '支持',
  support_titleXpressRequest: '支持- XPress请求',
  support_toProfile: '转到概要文件',
  support_xpress: 'LeitzXPress',
  support_xpressRequest: 'LeitzXPress请求',
  tracking_allow: '允许',
  tracking_deny: '否认',
  tracking_information: '为了不断改善我们应用程序的用户体验，我们使用匿名数据收集。你愿意支持我们吗?',
  tracking_title: '帮助我们',
  version: '版本号',
};

export default zh;
