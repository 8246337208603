
import ButtonElement from '@/elements/ButtonElement/ButtonElement.vue';
import { RouteNames } from '@/models/Enum';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    ButtonElement,
  },
  props: {
  },
})
export default class Welcome extends Vue {
  routeNames = RouteNames
}
