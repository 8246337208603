import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src", "onClick"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["product-spare-images", { 'has-2-columns' : _ctx.media.length > 1 }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.media, (medium, index) => {
      return (_openBlock(), _createElementBlock("img", {
        src: medium.url,
        onerror: "this.src='/icons/no_product_image.svg'",
        class: "product-spare-images__image cursor-pointer",
        onClick: $event => (_ctx.$emit('selectMedium', index))
      }, null, 8, _hoisted_1))
    }), 256))
  ], 2))
}