
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
  props: {
    tabs: { type: Object, default: [] },
    modelValue: { type: String, default: '' },
    // modelValue: { type: Number, default: 0 },
    isLocalised: { type: Boolean, default: false },
    localisedPath: { type: String, default: '' },
  },
})
export default class TabControlElement extends Vue {
}
