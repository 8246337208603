
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
  props: {
    id: { type: String, default: 'input' },
    placeholder: { type: String, default: '' },
    label: String,
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    prefixedIcon: String,
    type: { type: String, default: 'text' },
    alignment: { type: String, default: 'left' },
    isSmall: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    highlighted: { type: Boolean, default: false },
    modelValue: { type: String, default: '' },
    decimalPlaces: Number,
    errorLabel: { type: String, default: '' },
    error: { type: Boolean, default: false },
  },
})
export default class TextInputElement extends Vue {
}
