
import { Medium } from '@/models/Interface';
import { Options, Vue } from 'vue-class-component';
import NoImage from '../NoImage/NoImage.vue';
import ProductMediaModal from '../ProductMediaModal/ProductMediaModal.vue';
import ProductMediaPreview from '../ProductMediaPreview/ProductMediaPreview.vue';

@Options({
  components: {
    ProductMediaPreview,
    ProductMediaModal,
    NoImage,
  },
  props: {
    product: Object,
  },
})
export default class ProductGeneral extends Vue {
  openMediaModal(media: Medium[], index: number, showProductInfo: boolean) {
    (this.$refs.mediaModal as ProductMediaModal).open(media, index, showProductInfo);
  }
}
