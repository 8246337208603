
import LanguageStore from '@/stores/languages';
import ContextMenu from '@/elements/ContextMenu/ContextMenu.vue';
import { Options, Vue } from 'vue-class-component';
import { RouteNames } from '@/models/Enum';
import { Language } from '@/models/Interface';

@Options({
  components: {
    ContextMenu,
  },
  props: {
  },
})
export default class SidebarFooter extends Vue {
  menuOpen = false;

  menuOptions: string[] = [];

  currentLanguage!: Language;

  created() {
    const languageStore = LanguageStore();
    const languages = languageStore.getLanguageList;
    this.menuOptions = languages.map((language) => language.label);
    // this.menuOptions = ['Deutsch', 'English', 'Français', 'Italiano', 'Espanol'];
    this.currentLanguage = languageStore.getDefaultLanguage();
  }

  mounted() {
    setTimeout(() => {
      const languageStore = LanguageStore();
      const languageTag = this.$route.params.language;
      if (languageTag && typeof languageTag === 'string') {
        if (languageTag !== this.currentLanguage.tag) {
          const language = languageStore.getLanguageByTag(languageTag);
          if (language) {
            this.currentLanguage = language;
          }
        }
      }
      this.$forceUpdate();
    }, 1);
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  selectOption(index: number) {
    const languageStore = LanguageStore();
    const language = languageStore.getLanguageByLabel(this.menuOptions[index]);
    if (language) {
      // this.$i18n.locale = language.tag;
      const { name } = this.$route;
      this.$router.push({ name: name ?? RouteNames.Start, params: { language: language.tag } });
      this.currentLanguage = language;
      // document.dispatchEvent(new CustomEvent('etrackerClickEvent', {
      //   detail: {
      //     object: language.label,
      //     category: 'Sprache',
      //     action: 'Auswahl',
      //   },
      // }));
    }
  }
}
