
import { CalculatorTool } from '@/models/Interface';
import Calculate from '@/services/Calculate';
import { Options, Vue } from 'vue-class-component';
import CalculatorHeadRow from '../CalculatorHeadRow/CalculatorHeadRow.vue';
import CalculatorRow from '../CalculatorRow/CalculatorRow.vue';

@Options({
  components: {
    CalculatorHeadRow,
    CalculatorRow,
  },
  props: {
    compare: Boolean,
    calculatorTool1: Object,
    calculatorTool2: Object,
  },
  computed: {
    quantities() {
      return Calculate.calculatorQuantities;
    },
    t1CuttingSpeed() {
      return Calculate
        .cuttingSpeedMetal(
          parseFloat(this.calculatorTool1.diameter),
          parseFloat(this.calculatorTool1.rotationalSpeed),
        )?.toString() ?? '';
    },
    t2CuttingSpeed() {
      return Calculate
        .cuttingSpeedMetal(
          parseFloat(this.calculatorTool2.diameter),
          parseFloat(this.calculatorTool2.rotationalSpeed),
        )?.toString() ?? '';
    },
    t1ToothFeed() {
      return Calculate
        .toothFeedMetal(
          parseFloat(this.calculatorTool1.feedRate),
          parseFloat(this.calculatorTool1.rotationalSpeed),
          parseFloat(this.calculatorTool1.toothCount),
        )?.toString() ?? '';
    },
    t2ToothFeed() {
      return Calculate
        .toothFeedMetal(
          parseFloat(this.calculatorTool2.feedRate),
          parseFloat(this.calculatorTool2.rotationalSpeed),
          parseFloat(this.calculatorTool2.toothCount),
        )?.toString() ?? '';
    },
    t1FeedPerRotation() {
      return Calculate
        .feedPerRotation(
          parseFloat(this.calculatorTool1.toothCount),
          parseFloat(this.t1ToothFeed),
        )?.toString() ?? '';
    },
    t2FeedPerRotation() {
      return Calculate
        .feedPerRotation(
          parseFloat(this.calculatorTool2.toothCount),
          parseFloat(this.t2ToothFeed),
        )?.toString() ?? '';
    },
    t1CutterStepDepth() {
      return Calculate
        .cutterStepDepth(
          parseFloat(this.calculatorTool1.diameter),
          parseFloat(this.t1ToothFeed),
        )?.toString() ?? '';
    },
    t2CutterStepDepth() {
      return Calculate
        .cutterStepDepth(
          parseFloat(this.calculatorTool2.diameter),
          parseFloat(this.t2ToothFeed),
        )?.toString() ?? '';
    },
    t1ChipSheetLength() {
      return Calculate
        .chipSheetLengthMill(
          parseFloat(this.calculatorTool1.diameter),
          parseFloat(this.calculatorTool1.cuttingDepth),
        )?.toString() ?? '';
    },
    t2ChipSheetLength() {
      return Calculate
        .chipSheetLengthMill(
          parseFloat(this.calculatorTool2.diameter),
          parseFloat(this.calculatorTool2.cuttingDepth),
        )?.toString() ?? '';
    },
    t1AverageChipThickness() {
      return Calculate
        .averageChipThicknessMill(
          parseFloat(this.t1ToothFeed),
          parseFloat(this.calculatorTool1.cuttingDepth),
          parseFloat(this.t1ChipSheetLength),
        )?.toString() ?? '';
    },
    t2AverageChipThickness() {
      return Calculate
        .averageChipThicknessMill(
          parseFloat(this.t2ToothFeed),
          parseFloat(this.calculatorTool2.cuttingDepth),
          parseFloat(this.t2ChipSheetLength),
        )?.toString() ?? '';
    },
    t1MaximumChipThickness() {
      return Calculate
        .maximumChipThicknessMill(
          parseFloat(this.t1ToothFeed),
          parseFloat(this.calculatorTool1.diameter),
          parseFloat(this.calculatorTool1.cuttingDepth),
        )?.toString() ?? '';
    },
    t2MaximumChipThickness() {
      return Calculate
        .maximumChipThicknessMill(
          parseFloat(this.t2ToothFeed),
          parseFloat(this.calculatorTool2.diameter),
          parseFloat(this.calculatorTool2.cuttingDepth),
        )?.toString() ?? '';
    },
    t1CutPath() {
      return Calculate
        .cutPathMillMetal(
          parseFloat(this.calculatorTool1.feedPath),
          parseFloat(this.calculatorTool1.feedRate),
          parseFloat(this.calculatorTool1.rotationalSpeed),
          parseFloat(this.calculatorTool1.diameter),
          parseFloat(this.calculatorTool1.cuttingDepth),
        )?.toString() ?? '';
    },
    t2CutPath() {
      return Calculate
        .cutPathMillMetal(
          parseFloat(this.calculatorTool2.feedPath),
          parseFloat(this.calculatorTool2.feedRate),
          parseFloat(this.calculatorTool2.rotationalSpeed),
          parseFloat(this.calculatorTool2.diameter),
          parseFloat(this.calculatorTool2.cuttingDepth),
        )?.toString() ?? '';
    },
  },
})
export default class CalculatorMillMetalCuttingSpeed extends Vue {
  calculatorTool1!: CalculatorTool;

  calculatorTool12!: CalculatorTool;
}
