
import LegalInformation from '@/components/LegalInformation/LegalInformation.vue';
import Welcome from '@/components/Welcome/Welcome.vue';
import { EtrackerAreas } from '@/models/Enum';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    Welcome,
    LegalInformation,
  },
  props: {
  },
})
export default class StartView extends Vue {
  imageLoaded = false;

  created() {
    document.dispatchEvent(new CustomEvent('etrackerPageAreaChanged', { detail: EtrackerAreas.start }));
  }
}
