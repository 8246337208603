import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mobile-app" }
const _hoisted_2 = {
  key: 1,
  class: "mobile-app__content"
}

export function render(_ctx, _cache) {
  const _component_LoaderElement = _resolveComponent("LoaderElement")
  const _component_AppLinks = _resolveComponent("AppLinks")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoaderElement, {
          key: 0,
          class: "mobile-app__loader",
          type: "grid"
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_AppLinks)
        ]))
  ]))
}