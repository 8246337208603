/* eslint-disable import/no-cycle */
import {
  Product, ProductPreview, Spare, Branch, SupportRequest,
} from '@/models/Interface';
import ApiService from './ApiService';
import MappingService from './MappingService';

export default class SdkService {
  static getProductPreview(
    id: string,
    language: number | null,
    callback: (product: ProductPreview | null) => void,
  ) {
    ApiService.getArticle(id, language, (articleSource) => {
      if (!articleSource) {
        callback(null);
      } else {
        const productId = MappingService.getProductId(articleSource);
        if (!productId) {
          callback(null);
        } else {
          ApiService.getProduct(productId, language, (productSource) => {
            if (!productSource) {
              callback(null);
            } else {
              const productPreview = MappingService.mapProductPreview(articleSource, productSource);
              callback(productPreview);
            }
          });
        }
      }
    });
  }

  static getProduct(
    id: string,
    lang: number | null,
    callback: (product: Product | null) => void,
  ) {
    const language = lang ?? 3;
    ApiService.getArticle(id, language, (articleSource) => {
      if (!articleSource) {
        callback(null);
      } else {
        const productId = MappingService.getProductId(articleSource);
        if (!productId) {
          callback(null);
        } else {
          ApiService.getProduct(productId, language, (productSource) => {
            if (!productSource) {
              callback(null);
            } else {
              const product = MappingService.mapProduct(
                articleSource,
                productSource,
                language,
              );
              this.getRelatedArticles(
                productId,
                productSource,
                language,
                (relatedArticles) => {
                  if (product.relatedArticles) {
                    if (relatedArticles && relatedArticles.length > 0) {
                      product.relatedArticles.items = relatedArticles;
                    } else {
                      product.relatedArticles = null;
                    }
                  }
                },
              );
              const items = product.spares?.items ?? null;
              if (items) {
                let countResults = 0;
                const spareItems: (Spare | null)[] = items;
                spareItems.forEach((spare, index) => {
                  if (spare) {
                    this.getSpare(
                      spare,
                      language,
                      (filledSpare) => {
                        spareItems[index] = filledSpare;
                        countResults += 1;
                        if (countResults >= items.length) {
                          const filteredSpareItems = spareItems.filter((item) => item);
                          if (product.spares?.items) {
                            product.spares.items = filteredSpareItems as Spare[];
                          }
                          callback(product);
                        }
                      },
                    );
                  } else {
                    countResults += 1;
                    if (countResults >= items.length) {
                      const filteredSpareItems = spareItems.filter((item) => item);
                      if (product.spares?.items) {
                        product.spares.items = filteredSpareItems as Spare[];
                      }
                      callback(product);
                    }
                  }
                });
              } else {
                callback(product);
              }
            }
          });
        }
      }
    });
  }

  static getSpare(
    spare: Spare,
    language: number,
    callback: (product: Spare | null) => void,
  ) {
    ApiService.getArticle(spare.id, language, (articleSource) => {
      if (!articleSource) {
        callback(null);
      } else {
        const filledSpare = MappingService.mapSpare(
          articleSource,
          spare,
          language,
        );
        callback(filledSpare);
      }
    });
  }

  static getRelatedArticles(
    productId: string,
    productSource: any,
    language: number,
    callback: (articles: any[] | null) => void,
  ) {
    ApiService.getRelatedArticles(productId, language, (relatedArticlesSource) => {
      if (!relatedArticlesSource) {
        callback(null);
      } else {
        const relatedArticles = MappingService.mapRelatedArticles(
          relatedArticlesSource,
          productSource,
          language,
        );
        callback(relatedArticles);
      }
    });
  }

  static getProductBySerialNumber(
    serialNumber: string,
    language: number | null,
    callback: (product: Product | null) => void,
  ) {
    let articleIdSource: any;
    ApiService.getArticleIdByNR(serialNumber, (articleIdSourceNR) => {
      if (articleIdSourceNR) {
        articleIdSource = articleIdSourceNR;
      }
      ApiService.getArticleIdByUID(serialNumber, (articleIdSourceUID) => {
        if (articleIdSourceUID) {
          articleIdSource = articleIdSourceUID;
        }
        if (articleIdSource) {
          const articleId = MappingService.getArticleId(articleIdSource);
          if (!articleId) {
            callback(null);
          } else {
            SdkService.getProduct(articleId, language, (product) => {
              callback(product);
            });
          }
        } else {
          callback(null);
        }
      });
    });
  }

  static getBranches(
    language: number | null,
    callback: (branches: Branch[] | null) => void,
  ) {
    const branches = MappingService.mapBranches(language);
    callback(branches);
  }

  static sendSupportRequest(
    supportRequest: SupportRequest,
    callback: (success: boolean) => void,
  ) {
    ApiService.sendSupportRequest(supportRequest, (response) => {
      callback(true);
    });
  }
}
