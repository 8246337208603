import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = {
  key: 0,
  class: "product-matrix__content"
}
const _hoisted_3 = {
  key: 1,
  class: "product-matrix__preview"
}

export function render(_ctx, _cache) {
  const _component_SvgElement = _resolveComponent("SvgElement")
  const _component_ProductMatrix = _resolveComponent("ProductMatrix", true)

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["product-matrix", `level-${_ctx.level}`])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["product-matrix__label", { 'cursor-pointer' : _ctx.level === 1 && _ctx.matrix.children.length > 0 }]),
      onClick: _cache[0] || (_cache[0] = $event => (_ctx.expanded = !_ctx.expanded))
    }, [
      (_ctx.level > 1)
        ? (_openBlock(), _createBlock(_component_SvgElement, {
            key: 0,
            src: "/icons/arrow_corner.svg",
            class: "product-matrix__tree-icon fill-tertiary-2"
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.level > 2 ? 'font-size-12 color-tertiary-1' : 'font-size-14 color-black'),
        innerHTML: _ctx.matrix.label
      }, null, 10, _hoisted_1),
      (_ctx.level === 1 && _ctx.matrix.children.length > 0)
        ? (_openBlock(), _createBlock(_component_SvgElement, {
            key: 1,
            src: "/icons/chevron_right.svg",
            class: _normalizeClass(["product-matrix__expand-icon fill-tertiary-2", { 'is-expanded' : _ctx.expanded }])
          }, null, 8, ["class"]))
        : _createCommentVNode("", true)
    ], 2),
    _createVNode(_Transition, { mode: "out-in" }, {
      default: _withCtx(() => [
        (_ctx.expanded || _ctx.level > 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.matrix.children, (child) => {
                return (_openBlock(), _createBlock(_component_ProductMatrix, {
                  matrix: child,
                  level: _ctx.level + 1
                }, null, 8, ["matrix", "level"]))
              }), 256))
            ]))
          : (_ctx.matrix.children.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_SvgElement, {
                  src: "/icons/arrow_corner.svg",
                  class: "product-matrix__tree-icon fill-tertiary-2"
                }),
                _createVNode(_component_SvgElement, {
                  src: "/icons/more.svg",
                  class: "product-matrix__more fill-tertiary-3 cursor-pointer",
                  onClick: _cache[1] || (_cache[1] = $event => (_ctx.expanded = !_ctx.expanded))
                })
              ]))
            : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}