
import { Options, Vue } from 'vue-class-component';
import vClickOutside from 'click-outside-vue3';

@Options({
  components: {
  },
  props: {
    id: { type: String, default: 'input' },
    placeholder: String,
    label: String,
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    isSmall: { type: Boolean, default: false },
    isLocalised: { type: Boolean, default: false },
    localisedPath: { type: String, default: '' },
    options: { type: Object, default: [] },
    isObject: { type: Boolean, default: false },
    optionPath: { type: String, default: '' },
    modelValue: { type: String, default: '' },
    modelValueObject: { type: Object, default: null },
    errorLabel: { type: String, default: '' },
    error: { type: Boolean, default: false },
  },
  watch: {
    disabled: {
      handler: 'onDisabledChanged',
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
})
export default class DropdownElement extends Vue {
  dropdownOpen = false;

  onDisabledChanged(disabled: boolean) {
    if (disabled) {
      this.$emit('update:modelValue', '');
    }
  }

  selectOption(option: string) {
    this.$emit('update:modelValue', option);
    this.dropdownOpen = false;
  }

  selectOptionObject(option: any) {
    this.$emit('update:modelValueObject', option);
    this.dropdownOpen = false;
  }

  onClickOutside() {
    this.dropdownOpen = false;
  }
}
