
import ContextMenu from '@/elements/ContextMenu/ContextMenu.vue';
import LanguageStore from '@/stores/languages';
import { RouteNames } from '@/models/Enum';
import { HistoryProduct, ProductPreview } from '@/models/Interface';
import SdkService from '@/services/SdkService';
import { Options, Vue } from 'vue-class-component';
import LoaderElement from '@/elements/LoaderElement/LoaderElement.vue';

@Options({
  components: {
    ContextMenu,
    LoaderElement,
  },
  props: {
    historyProduct: Object,
  },
  watch: {
    '$i18n.locale': {
      handler: 'getProductPreview',
    },
  },
})
export default class HistoryItem extends Vue {
  historyProduct!: HistoryProduct;

  menuOpen = false;

  menuOptions = ['details', 'delete'];

  product: ProductPreview | null = null;

  error = false;

  mounted() {
    this.getProductPreview();
  }

  getProductPreview() {
    this.error = false;
    const languageStore = LanguageStore();
    const language = languageStore.getLanguageByTag(this.$i18n.locale);
    const languageNumber = language ? language.flag : null;
    const id = this.historyProduct.productId;
    SdkService.getProductPreview(id, languageNumber, (productPreview) => {
      if (productPreview) {
        this.product = productPreview;
      } else {
        this.product = null;
        this.error = true;
      }
    });
  }

  openProduct() {
    this.$emit('openProduct', this.historyProduct.productId);
    this.$router.push({ name: RouteNames.Product, params: { id: this.historyProduct.productId } });
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  selectOption(index: number) {
    switch (this.menuOptions[index]) {
      case 'details':
        this.openProduct();
        break;
      case 'delete':
        this.$emit('deleteHistoryItem', this.historyProduct.historyId);
        break;
      default: break;
    }
  }
}
