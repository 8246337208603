import { Branch } from '@/models/Interface';

export const de = {
  calculator_calculation: 'Berechnung',
  calculator_calculationOf_chipSheetLengthAndChipThickness: 'Berechnung der Spanbogenlänge und Spandicke',
  calculator_calculationOf_chipThicknessAndChipSheetLength: 'Berechnung der Spandicke und Spanbogenlänge',
  calculator_calculationOf_cutPath: 'Berechnung des Schnittweges',
  calculator_calculationOf_cutterStepDepth: 'Berechnung der Messerschritttiefe',
  calculator_calculationOf_cuttingSpeed: 'Berechnung der Schnittgeschwindigkeit',
  calculator_calculationOf_feedPerTooth: 'Berechnung des Zahnvorschubes',
  calculator_calculationOf_feedRate: 'Berechnung der Vorschubgeschwindigkeit',
  calculator_calculationOf_rotationalSpeed: 'Berechnung der Drehzahl',
  calculator_calculationType_cuttingSpeed: 'Schnittgeschwindigkeit',
  calculator_calculationType_rotationalSpeed: 'Drehzahl',
  calculator_compareTools: 'Werkzeuge vergleichen',
  calculator_material_metal: 'Metalle',
  calculator_material_title: 'Material',
  calculator_material_wood: 'Holz und Holzwerkstoffe',
  calculator_mill: 'Fräsen',
  calculator_parameterType_averageChipThickness: 'Mittlere Spandicke',
  calculator_parameterType_chipSheetLength: 'Spanbogenlänge',
  calculator_parameterType_cutPath: 'Schnittweg (Wirkweg) einer Schneide',
  calculator_parameterType_cutterStepDepth: 'Messerschritttiefe bezogen auf Zahnvorschub',
  calculator_parameterType_cuttingDepthMill: 'Radiale Zerspanungstiefe',
  calculator_parameterType_cuttingDepthSaw: 'Schnitttiefe',
  calculator_parameterType_cuttingSpeed: 'Schnittgeschwindigkeit',
  calculator_parameterType_diameter: 'Durchmesser',
  calculator_parameterType_feedPath: 'Vorschubweg',
  calculator_parameterType_feedPerRotation: 'Vorschub pro Umdrehung',
  calculator_parameterType_feedRate: 'Vorschubgeschwindigkeit',
  calculator_parameterType_maximumChipThickness: 'Maximale Spandicke',
  calculator_parameterType_protrusionSawBlade: 'Überstand des Kreissägeblatts',
  calculator_parameterType_rotationalSpeed: 'Drehzahl',
  calculator_parameterType_toothCount: 'Zähnezahl',
  calculator_parameterType_toothFeed: 'Zahnvorschub',
  calculator_quantityType_area: 'Fläche',
  calculator_quantityType_energy: 'Energie',
  calculator_quantityType_length: 'Länge',
  calculator_quantityType_performance: 'Leistung',
  calculator_quantityType_power: 'Masse',
  calculator_quantityType_pressure: 'Druck',
  calculator_quantityType_temperature: 'Temperatur',
  calculator_quantityType_torque: 'Drehmoment',
  calculator_quantityType_velocity: 'Geschwindigkeit',
  calculator_quantityType_volume: 'Volumen',
  calculator_quantityType_weight: 'Gewicht',
  calculator_recommendation_blockboards: 'Tischlerplatten',
  calculator_recommendation_castAluminumAlloys: 'Aluminiumgusslegierungen',
  calculator_recommendation_cementBondedWoodMaterials: 'Zementgebundene Holzwerkstoffe',
  calculator_recommendation_favorableRange: 'Günstiger Bereich',
  calculator_recommendation_fineSizingChip: 'Feinschlichtspan',
  calculator_recommendation_group_aluminiumCastWroughtAlloys: 'Aluminium Guss und Knetlegierungen',
  calculator_recommendation_group_brassShortLongChipping: 'Messing, kurz- und langspanend',
  calculator_recommendation_group_bronze: 'Bronze',
  calculator_recommendation_group_constructionSteels: 'Baustähle',
  calculator_recommendation_group_copperAlloys: 'Kupfer, Kupferlegierungen',
  calculator_recommendation_group_quenchedAndTemperedNitridingSteels: 'Vergütungs- und Nitrierstähle',
  calculator_recommendation_group_springBearingSteels: 'Feder- und Wälzlagerstähle',
  calculator_recommendation_group_stainlessSteels: 'Nichtrostende Stähle',
  calculator_recommendation_gypsumMaterials: 'Gipswerkstoffe',
  calculator_recommendation_hardboard: 'Hartfaserplatten',
  calculator_recommendation_hardwoodCrosswise: 'Hartholz quer',
  calculator_recommendation_headline_cuttingSpeed: 'Schnittgeschwindigkeit',
  calculator_recommendation_headline_group: 'Materialgruppe',
  calculator_recommendation_headline_material: 'Materialien',
  calculator_recommendation_headline_tensileStrength: 'Zugfestigkeit',
  calculator_recommendation_headline_toothFeed: 'Zahnvorschub',
  calculator_recommendation_HPLCoveredChipboard: 'HPL-beschichtete Spanplatten',
  calculator_recommendation_OSBBoards: 'OSB-Platten',
  calculator_recommendation_plasticCoatedChipboard: 'Melamin-beschichtete Spanplatten',
  calculator_recommendation_plywood: 'Sperrholz',
  calculator_recommendation_pressedLaminatedWood: 'Furnierschichtholz',
  calculator_recommendation_roughingChip: 'Schruppspan',
  calculator_recommendation_sizingChip: 'Schlichtspan',
  calculator_recommendation_softwoodCrosswise: 'Weichholz quer',
  calculator_recommendation_solidWoodLengthwise: 'Vollholz längs',
  calculator_recommendation_thermoplastics: 'Thermoplastische Kunststoffe',
  calculator_recommendation_thermosettingPlastics: 'Duroplastische Kunststoffe',
  calculator_recommendation_title_general: 'Empfehlungswerte',
  calculator_recommendation_title_NE: 'Schnittwerteempfehlungen für die NE-Bearbeitung',
  calculator_recommendation_title_steel: 'Schnittwerteempfehlungen für die Stahlbearbeitung',
  calculator_recommendation_title_toothFeed: 'Empfehlung des Zahnvorschubes',
  calculator_recommendation_veneeredPanels: 'Furnierte Platten',
  calculator_recommendation_wroughtAluminumAlloys: 'Aluminiumknetlegierungen',
  calculator_reset: 'zurücksetzen',
  calculator_resetCalculator: 'Rechner zurücksetzen',
  calculator_saw: 'Sägen',
  calculator_tabs_mill: 'Fräsen',
  calculator_tabs_saw: 'Sägen',
  calculator_tabs_unit: 'Einheiten-Umrechnung',
  calculator_title: 'Rechner',
  calculator_titleMill: 'Rechner - Fräsen',
  calculator_titleSaw: 'Rechner - Sägen',
  calculator_titleUnit: 'Rechner - Einheit',
  calculator_tool: 'Werkzeug',
  calculator_toolParameters: 'Werkzeugparameter',
  calculator_unit: 'Einheit',
  calculator_unitConversion: 'Einheit Umrechnung',
  calculator_units: 'Einheiten',
  calculator_unitToConvert: 'Umzurechnende Einheit',
  calculator_unitType_bar: 'Bar',
  calculator_unitType_britishThermalUnit: 'British thermal unit',
  calculator_unitType_centimeter: 'Zentimeter',
  calculator_unitType_cubicFeet: 'Kubikfuß',
  calculator_unitType_cubicInch: 'Kubikzoll',
  calculator_unitType_cubicMeter: 'Kubikmeter',
  calculator_unitType_degreeCelsius: 'Grad Celsius',
  calculator_unitType_degreeFahrenheit: 'Grad Fahrenheit',
  calculator_unitType_feet: 'Fuß (foot)',
  calculator_unitType_feetPerMinute: 'Fuß pro Minute',
  calculator_unitType_footPound: 'Foot-pound',
  calculator_unitType_gallon: 'US Gallone',
  calculator_unitType_gram: 'Gramm',
  calculator_unitType_horsepower: 'Horsepower',
  calculator_unitType_inch: 'Zoll (inch)',
  calculator_unitType_inchPerMinute: 'Zoll pro Minute',
  calculator_unitType_inchPerSecond: 'Zoll pro Sekunde',
  calculator_unitType_joule: 'Joule',
  calculator_unitType_kelvin: 'Kelvin',
  calculator_unitType_kilocalories: 'Kilokalorien',
  calculator_unitType_kilogram: 'Kilogramm',
  calculator_unitType_kilopond: 'Kilopond',
  calculator_unitType_kilowatt: 'Kilowatt',
  calculator_unitType_kilowattHour: 'Kilowattstunden',
  calculator_unitType_liter: 'Liter',
  calculator_unitType_meter: 'Meter',
  calculator_unitType_meterPerMinute: 'Meter pro Minute',
  calculator_unitType_meterPerSecond: 'Meter pro Sekunde',
  calculator_unitType_metricHorsepower: 'Pferdestärke',
  calculator_unitType_micrometer: 'Mikrometer',
  calculator_unitType_millimeter: 'Millimeter',
  calculator_unitType_newton: 'Newton',
  calculator_unitType_newtonMeter: 'Newtonmeter',
  calculator_unitType_ounce: 'Unze (ounce)',
  calculator_unitType_ounceForce: 'Ounce-force',
  calculator_unitType_ounceForceFeet: 'Ounce-force foot',
  calculator_unitType_pascal: 'Pascal',
  calculator_unitType_pound: 'Pfund',
  calculator_unitType_poundForce: 'Pound-force',
  calculator_unitType_poundForceFeet: 'Pound-force foot',
  calculator_unitType_poundForceInch: 'Pound-force inch',
  calculator_unitType_poundForcePerSquareInch: 'Pound-force per square inch',
  calculator_unitType_squareCentimeter: 'Quadratzentimeter',
  calculator_unitType_squareFeet: 'Quadratfuß',
  calculator_unitType_squareInch: 'Quadratzoll',
  calculator_unitType_squareMeter: 'Quadratmeter',
  calculator_unitType_squareMillimeter: 'Qadratmillimeter',
  calculator_unitType_standardAtmosphere: 'Physikalische Atmosphäre',
  calculator_unitType_tonne: 'Tonne',
  calculator_unitType_watt: 'Watt',
  favorites_noFavorites: 'Sie haben noch keine Produkte als Favoriten abgespeichert.',
  favorites_titlePlural: 'Favoriten',
  favorites_titleSingular: 'Favorit',
  language: 'Deutsch',
  legalInformation_imprint: 'Impressum',
  legalInformation_privacyPolicy: 'Datenschutz',
  legalInformation_title: 'Rechtliche Informationen',
  leitzxpert: 'LeitzXPert',
  link_imprint: 'https://www.leitz.org/de/impressum',
  link_privacyPolicy: 'https://www.leitz.org/de/datenschutz',
  navigation_appLanguage: 'App Sprache',
  navigation_apps: 'Apps',
  navigation_back: 'Zurück',
  navigation_calculator: 'Rechner',
  navigation_cancel: 'Abbrechen',
  navigation_close: 'Schließen',
  navigation_done: 'Fertig',
  navigation_favorites: 'Favoriten',
  navigation_language: 'Sprache',
  navigation_productsearch: 'Produktsuche',
  navigation_profile: 'Profil',
  navigation_search: 'Suche',
  navigation_section: 'Bereich',
  navigation_support: 'Support',
  noMobile_appName: 'LeitzXPert',
  noMobile_availableFor: 'Für Android und iOS verfügbar',
  noMobile_availableSoon: 'In Kürze verfügbar',
  noMobile_notAvailable: 'Diese Seite ist für mobile Endgeräte und für Bildschirme mit einer Displaybreite unter 960&nbsp;Pixeln nicht optimiert und kann daher nicht angezeigt werden.',
  noMobile_recommendation: 'Wir empfehlen Ihnen, unsere kostenlose LeitzXPert-App aus dem Store zu installieren.',
  noMobile_title: 'Diese Seite ist nicht für mobile Endgeräte und kleine Bildschirme optimiert',
  product_applicationParameter_title: 'Einsatzparameter',
  product_backTo: 'Zurück zur',
  product_general_details: 'Details',
  product_general_images: 'Produktbilder &amp; Videos',
  product_general_imagesNoMedia: 'Keine Produktbilder &amp; Videos verfügbar',
  product_general_maximizeDescription: 'Beschreibung anzeigen',
  product_general_minimizeDescription: 'Beschreibung minimieren',
  product_general_sketches: 'Skizzen &amp; Diagramme',
  product_general_sketchesNoMedia: 'Keine Skizzen &amp; Diagramme Verfügbar',
  product_general_title: 'Allgemeine Informationen',
  product_noImage: 'Kein Bild vorhanden',
  product_noInformation: 'Keine Informationen verfügbar',
  product_notFound: 'Produkt wurde nicht gefunden',
  product_productsearch: 'Produktsuche',
  product_relatedArticles_title: 'Weitere Abmessungen',
  product_relatedArticles_toProduct: 'Zum Produkt',
  product_spares_availability_availableAtShortNotice: 'Kurzfristig lieferbar',
  product_spares_availability_availableExStock: 'Ab Lager lieferbar',
  product_spares_availability_productionToOrder: 'Fertigung auf Bestellung',
  product_spares_titleBlade: 'Ersatzmesser',
  product_spares_titleGeneral: 'Ersatzteile',
  product_spares_titleSaw: 'Ersatzkreissägeblätter',
  product_tabs_applicationParameter_noData: 'Es sind keine Einsatzparameter für dieses Produkt verfügbar',
  product_tabs_applicationParameter: 'Einsatz&shy;parameter',
  product_tabs_downloads_noData: 'Es sind keine Downloads für dieses Produkt verfügbar',
  product_tabs_downloads: 'Downloads',
  product_tabs_general: 'Allgemein',
  product_tabs_link_noData: 'Es ist kein Link zur Webseite für dieses Produkt verfügbar',
  product_tabs_link: 'Link zur Webseite',
  product_tabs_relatedArticles_noData: 'Es sind keine weiteren Abmessungen für dieses Produkt verfügbar',
  product_tabs_relatedArticles: 'Weitere Abmessungen',
  product_tabs_spares_noData: 'Es sind keine Ersatzteile für dieses Produkt verfügbar',
  product_tabs_spares: 'Ersatz&shy;teile',
  product_tabs_technicalInfo_noData: 'Es ist keine technische Info für dieses Produkt verfügbar',
  product_tabs_technicalInfo: 'Technische Info',
  product_tabs_toolApplicability_noData: 'Es ist keine Werkzeugeignung für dieses Produkt verfügbar',
  product_tabs_toolApplicability: 'Werkzeug&shy;eignung',
  product_technicalInfo_title: 'Technische Infos',
  product_toolApplicability_title: 'Werkzeugeignung',
  product_tools: 'Werkzeuge',
  productsearch_actions_addToFavorites: 'Zu Favoriten hinzufügen',
  productsearch_actions_delete: 'Löschen',
  productsearch_actions_details: 'Details',
  productsearch_actions_removeFromFavorites: 'Aus Favoriten entfernen',
  productsearch_actions_removeFromHistory: 'Aus Historie entfernen',
  productsearch_code: 'Code',
  productsearch_codeInput_text: 'Produkt Identifikationsnummer Scannen um die Daten des Produkts zu erhalten',
  productsearch_codeInput_title: 'Data Matrix',
  productsearch_description: 'Mit dieser Suche können Sie ein Produkt über die Identifikationsnummer oder die Seriennummer finden, indem Sie die Nummer manuell in das Eingabefeld tippen oder ein kompatibles Lesegerät verwenden',
  productsearch_error_code_text: 'Der Code konnte nicht gescannt werden. Bitte versuchen Sie es erneut',
  productsearch_error_noProduct_textLong: 'Es wurden unter dieser Nummer keine Produktdaten gefunden, bitte versuchen Sie es erneut mit einer anderen Nummer',
  productsearch_error_noProduct_textShort: 'Es wurde kein Produkt mit der eingegeben ID oder Seriennummer gefunden',
  productsearch_error_noProduct_title: 'Kein Ergebnis zu dieser ID-Nummer',
  productsearch_error_notEnoughDigits_textLong: 'Bitte mindestens 4 Zeichen eingeben',
  productsearch_error_notEnoughDigits_textShort: 'mindestens 4 Zeichen',
  productsearch_error_rfid_text: 'RFID konnte nicht erkannt werden. Bitte versuchen Sie es erneut',
  productsearch_error_unknown_text: 'Es ist ein unbekannter Fehler aufgetreten, bitte versuchen Sie es erneut oder kontaktieren Sie unseren Support',
  productsearch_error_unknown_title: 'Es ist ein Fehler aufgetreten',
  productsearch_history: 'Historie',
  productsearch_historyEmpty: 'Sie haben noch keine Produkte gesucht. Die zuletzt gesuchten Produkte werden hier chronologisch aufgelistet.',
  productsearch_id: 'ID-Nummer',
  productsearch_idInput_text: 'Produkt Identifikationsnummer eingeben um die Daten des Produkts zu erhalten',
  productsearch_idInput_title: 'ID-Nummerneingabe',
  productsearch_inputPlaceholder: 'ID-Nummer eingeben',
  productsearch_inputPlaceholderIDSerial: 'ID-Nummer/Seriennummer eingeben',
  productsearch_loadProduct: 'Produktdaten laden',
  productsearch_openCamera: 'Kamera öffnen',
  productsearch_readerInstruction: 'Schliessen Sie Ihr Lesegerät an um die Barcodes zu Scannen.',
  productsearch_readerNotConnected: 'Lesegerät nicht angeschlossen',
  productsearch_readerNotRecognised: 'Gerät nicht erkannt',
  productsearch_rfid_disabledText: 'Damit Sie Produkte mithilfe von RFID suchen können, muss NFC aktiviert sein. Rufen Sie dazu die Einstellungen auf Ihrem Smartphone auf.',
  productsearch_rfid_disabledTitle: 'NFC nicht aktiviert',
  productsearch_rfid_openScanner: 'RFID Scanner öffnen',
  productsearch_rfid_ready: 'Bereit zum Scannen',
  productsearch_rfid: 'RFID',
  productsearch_rfidInput_text: 'Halten Sie ihr Smartphone oder ein RFID Scanner an das Produkt um Ihre Daten zu erhalten',
  productsearch_rfidInput_title: 'RFID Produktscan',
  productsearch_showHistory: 'Historie anzeigen',
  productsearch_title: 'Produktsuche',
  productsearch_tryAgain: 'Erneut versuchen',
  start_appFunctions: 'App Funktionen',
  start_button: 'Jetzt beginnen',
  start_calculator_description: 'Ermitteln Sie wichtige Einsatzdaten mit unseren praktischen Berechnungsprogrammen',
  start_calculator_title: 'Rechner',
  start_favorites_description: 'Speichern Sie bequem und übersichtlich Ihre gesuchten Produkte',
  start_favorites_title: 'Favoriten',
  start_productsearch_description: 'Entdecken Sie alle Werkzeugdaten, Anleitungen und Produktprospekte zu unserem Standardprogramm',
  start_productsearch_title: 'Produktsuche',
  start_skip: 'Überspringen',
  start_solutions: 'Lösungen in neuen Dimensionen',
  start_support_description: 'Kontaktieren Sie schnell und unkompliziert das Support-Team von Leitz',
  start_support_title: 'Support',
  start_welcomeAt: 'Willkommen bei',
  support_agreeToPrivacy: 'Ich habe die Datenschutzerklärung zur Kenntnis genommen.',
  support_branch: 'Niederlassung',
  support_branchEdit: 'Niederlassung ändern',
  support_contactDetails: 'Kontaktdaten Niederlassung',
  support_contactType: 'Zentrale der Leitz Firmengruppe',
  support_customerNumber: 'Kundennummer',
  support_dataInfo_text: 'Wir benötigen Ihre Daten für eine schnelle Supportanfrage, so müssen Sie bei einer Anfrage diese Daten nicht immer wieder neu eingeben.',
  support_dataInfo_title: 'Für was benötigen wir Ihre Daten?',
  support_eMail: 'Email',
  support_errorCustomerId: 'Kundennummer darf nur aus Zahlen bestehen',
  support_errorEmailEmpty: 'Email darf nicht leer sein',
  support_errorEmailInvalid: 'Email muss gültig sein',
  support_errorMessage: 'Nachricht darf nicht leer sein',
  support_errorPhoneNumber: 'Telefonnummer darf nicht leer sein',
  support_errorSubject: 'Thema muss ausgewählt sein',
  support_inputPlaceholderPleaseSelect: 'Bitte auswählen',
  support_inputPlaceholderPleaseType: 'Bitte eingeben',
  support_inputPlaceholderSelect: 'Auswählen',
  support_inputPlaceholderSelectBranch: 'Niederlassungen auswählen',
  support_inputPlaceholderTypeHere: 'Hier eingeben',
  support_leitzBranch: 'Leitz Niederlassung',
  support_message: 'Nachricht',
  support_myData: 'Meine Daten',
  support_noBranchSelected: 'Um eine Anfrage zu starten müssen Sie zuerst eine Niederlassung auswählen.',
  support_phone: 'Telefon',
  support_privacyInformation: 'Die abgesendeten Daten werden nur zum Zweck der Bearbeitung Ihres Anliegens verarbeitet. Mit dem Absenden des Formulars erklären Sie sich mit unseren Datenschutzrichtlinien einverstanden.',
  support_privacyLink: 'Zur Datenschutzerklärung',
  support_ProfileNotFilled: 'Um eine Anfrage zu starten müssen zuerst alle benötigten Daten im Profil angelegt werden.',
  support_request: 'Anfrage',
  support_requiredFields: 'Die mit * gekennzeichneten Felder sind Pflichtfelder.',
  support_sendingFailed: 'Ihre Anfrage konnte leider nicht gesendet werden. Bitte versuchen Sie es später erneut.',
  support_sendRequest: 'Anfrage senden',
  support_sent_backToSearch: 'Zurück zur Produktsuche',
  support_sent_newRequest: 'Neue Anfrage starten',
  support_sent_team: 'Ihr LeitzXPert Team',
  support_sent_thankyou: 'Danke für Ihre Anfrage. Wir bemühen uns, diese so schnell wie möglich zu bearbeiten.',
  support_sent_title: 'Ihre Anfrage wurde gesendet',
  support_subject_agentVisit: 'Vertreterbesuch',
  support_subject_callback: 'Rückruf',
  support_subject_consulting: 'Beratung',
  support_subject_toolService: 'Werkzeugservice',
  support_subject: 'Thema',
  support_title: 'Support',
  support_titleXpressRequest: 'Support - XPress Anfrage',
  support_toProfile: 'Zum Profil',
  support_xpress: 'LeitzXPress',
  support_xpressRequest: 'LeitzXPress Anfrage',
  tracking_allow: 'Erlauben',
  tracking_deny: 'Ablehnen',
  tracking_information: 'Um das Nutzungserlebnis unserer App ständig verbessern zu können, nutzen wir die Erfassung von anonymen Daten. Möchten Sie uns dabei unterstützen?',
  tracking_title: 'Helfen Sie uns',
  version: 'Version',
};

export const branchesDE: Branch[] = [
  {
    name: 'Leitz GmbH & Co. KG',
    location: 'Österreich - Riedau',
    email: 'office.riedau@leitz.org',
    phone: '+43 (0) 7764 8200 0',
    fax: '+43 (0) 7764 8200 111',
    website: 'http://www.leitz.at',
    address: `Leitzstraße 80
    4752 Riedau
    `,
    country: 'Österreich',
  },
  {
    name: 'Leitz Tooling Systems Pty. Ltd.',
    location: 'Australien - Melbourne',
    email: 'sales@leitz.com.au',
    phone: '+61 (0) 3 9760 40 00',
    fax: '+61 (0) 3 9760 40 99',
    website: 'http://www.leitz.com.au',
    address: `2/55 Barry Street
    Bayswater VIC 3153
    `,
    country: 'Australien',
  },
  {
    name: 'Leitz-Service N.V.',
    location: 'Belgien - Grimbergen',
    email: 'contact-be@leitz.org',
    phone: '+32 (0) 2251 60 47',
    fax: '+32 (0) 2252 14 36',
    website: 'https://www.leitz.org',
    address: `Industrieweg 15
    1850 Grimbergen
    `,
    country: 'Belgien',
  },
  {
    name: 'Leitz Ferramentas e Sistemas de Precisão',
    location: 'Brasilien - São Sebastião do Caí',
    email: 'contact@leitz.com.br',
    phone: '+55 51 3635 6600',
    fax: '',
    website: 'http://www.leitz.org',
    address: `Rua Leitz, 50 - Bairro Angico - CEP 95760-000
    Junto à Rodovia RS 122, Km 14
    São Sebastião do Caí - RS
    `,
    country: 'Brasilien',
  },
  {
    name: 'Leitz Tooling Systems LP',
    location: 'Kanada - Vaughan',
    email: 'infocanada@leitz.org',
    phone: '+1 (0) 905 760 0375',
    fax: '+1 (0) 905 760 8125',
    website: 'https://www.leitz.org/en-ca/',
    address: `435 Four Valley Drive
    Vaughan, Ontario L4K 5X5
    `,
    country: 'Kanada',
  },
  {
    name: 'Leitz GmbH',
    location: 'Schweiz - Lenzburg',
    email: 'kontakt-ch@leitz.org',
    phone: '+41 (0) 62 886 39 39',
    fax: '+41 (0) 62 886 39 40',
    website: 'http://www.leitz.org',
    address: `Hardstrasse 2
    Postfach 448
    5600 Lenzburg
    `,
    country: 'Schweiz',
  },
  {
    name: 'Leitz Tooling Systems (China) Co., Ltd.',
    location: 'China - Nanjing',
    email: 'leitzchina@leitz.com.cn',
    phone: '+86 (0) 25 52103111',
    fax: '+86 (0) 25 52103777',
    website: 'http://www.leitz.com.cn',
    address: `No.9 Shengtong Road, Moling Sub-District, Jiangning Zone, 211111 Nanjing, Jiangsu Province
    `,
    country: 'China',
  },
  {
    name: 'Leitz-nástroje s.r.o.',
    location: 'Tschechien - Prag',
    email: 'leitz@leitz.cz',
    phone: '+420 24 14 82 699',
    fax: '+420 24 14 80 786',
    website: 'http://www.leitz.org',
    address: `Leitzova 1267/1
    Praha 5, Košíře
    `,
    country: 'Tschechien',
  },
  {
    name: 'Leitz Werkzeugdienst GmbH',
    location: 'Deutschland - Bielefeld',
    email: 'service-hotline@leitz.org',
    phone: '+49 (0) 5219 240 30',
    fax: '+49 (0) 5219 240 310',
    website: 'https://www.leitz.org/de-de/',
    address: `Lübberbrede 13
    33719 Bielefeld
    `,
    country: 'Deutschland',
  },
  {
    name: 'Emil Leitz GmbH',
    location: 'Deutschland - Oberkochen',
    email: 'service-hotline@leitz.org',
    phone: '+49 (0) 7364 950 0',
    fax: '+49 (0) 7364 950 660',
    website: 'https://www.leitz.org/de-de',
    address: `Leitzstraße 2
    73447 Oberkochen
    `,
    country: 'Deutschland',
  },
  {
    name: 'Leitz Werkzeugdienst GmbH',
    location: 'Deutschland - Troisdorf',
    email: 'service-hotline@leitz.org',
    phone: '+49 (0) 2241 947 10',
    fax: '+49 (0) 2241 947 132',
    website: 'https://www.leitz.org/de-de',
    address: `Industriestraße 12
    53842 Troisdorf
    `,
    country: 'Deutschland',
  },
  {
    name: 'Herramientas Leitz S.L.',
    location: 'Spanien - Vilassar de Dalt (Barcelona)',
    email: 'ventas@leitz.org',
    phone: '+34 937 508 417',
    fax: '+34 937 507 925',
    website: 'https://www.leitz.org/es-es/',
    address: `Narcís Monturiol, 11-13
    08339 Vilassar de Dalt (Barcelona)
    `,
    country: 'Spanien',
  },
  {
    name: 'Leitz Kes Metalli Oy',
    location: 'Finnland - Uurainen',
    email: 'leitz@leitzkesmetalli.fi',
    phone: '+358 (40) 18 80 55 0',
    fax: '+358 (40) 60 33 59 3',
    website: 'http://www.leitz.org/fi/',
    address: `Hitsaajantie 7
    41230 Uurainen
    `,
    country: 'Finnland',
  },
  {
    name: 'Leitz S.à.r.l., Colmar',
    location: 'Frankreich - Colmar',
    email: 'leitz-france@leitz.org',
    phone: '+33 (0) 3 892 108 00',
    fax: '',
    website: 'http://www.leitz.fr',
    address: `8, Rue Emile Schwoerer
    68012 Colmar Cedex
    `,
    country: 'Frankreich',
  },
  {
    name: 'Leitz Tooling UK Ltd.',
    location: 'Großbritannien - Harlow',
    email: 'salesuk@leitz.org',
    phone: '+44 (0) 1279 454 530',
    fax: '+44 (0) 1279 454 509',
    website: 'https://www.leitz.org/en-gb',
    address: `Flex Meadow, The Pinnacles
    Harlow,
    Essex, CM19 5TN
    `,
    country: 'Großbritannien',
  },
  {
    name: 'Leitz orodja d.o.o.',
    location: 'Kroatien - Kranj (Slowenien)',
    email: 'leitz@leitz.si',
    phone: '+386 (0) 4 238 12 10',
    fax: '+386 (0) 4 238 12 22',
    website: 'http://www.leitz.org',
    address: `Savska cesta 14
    4000 Kranj
    `,
    country: 'Slowenien',
  },
  {
    name: 'Leitz Hungária Szerszám Kft.',
    location: 'Ungarn - Érd',
    email: 'leitz@leitz.hu',
    phone: '+36 (0) 23 521 900',
    fax: '',
    website: 'http://www.leitz.org',
    address: `Kis-Duna u. 6.
    2030 Érd
    `,
    country: 'Ungarn',
  },
  {
    name: 'Leitz Tooling Systems India Pvt Ltd.',
    location: 'Indien - Bangalore',
    email: 'info@leitzindia.com',
    phone: '+91 (0) 90 083 025 82',
    fax: '',
    website: 'http://www.leitz.org/en-in/',
    address: `486 C, 14th Cross, 4th Phase,
    Peenya Industrial Area,
    Bangalore 560 058
    Karnataka
    `,
    country: 'Indien',
  },
  {
    name: 'Leitz Italia S.r.l.',
    location: 'Italien - Lana',
    email: 'lana@leitz.org',
    phone: '+39 0473 55 28 00',
    fax: '+39 0473 56 21 39',
    website: 'http://www.leitz.org',
    address: `Zona Industriale 9
    39011 Lana (BZ)
    `,
    country: 'Italien',
  },
  {
    name: 'Leitz Tooling Co. Ltd.',
    location: 'Japan - Yokohama',
    email: 'info@leitz.co.jp',
    phone: '+81 (0) 455 33 3020',
    fax: '+81 (0) 455 33 3021',
    website: 'http://www.leitz.co.jp',
    address: `2-7-2, Kita-Shinyokohama,
    Kohoku-ku, Yokohama 223-0059
    `,
    country: 'Japan',
  },
  {
    name: 'Leitz Baltic UAB',
    location: 'Litauen  - Kaunas',
    email: 'baltic@leitz.org',
    phone: '+370 37 281 289',
    fax: '',
    website: 'http://www.leitz.org',
    address: `Kaštonų g. 56,
    54310  Giraitė, Kauno r.
    `,
    country: 'Litauen',
  },
  {
    name: 'Leitz-Service SARL',
    location: 'Luxemburg - Holzem',
    email: 'contact-lu@leitz.org',
    phone: '+352 (0) 399 550',
    fax: '+352 (0) 399 852',
    website: 'http://www.leitz.org',
    address: `28 Route de Capellen
    8279 Holzem
    `,
    country: 'Luxemburg',
  },
  {
    name: 'Leitz México S.A. de C.V.',
    location: 'Mexiko - El Marqués',
    email: 'info@leitz.com.mx',
    phone: '+52 (0) 442 296 6870',
    fax: '+52 (0) 442 296 6882',
    website: 'https://www.leitz.org/es-mx/',
    address: `Av. Acueducto No. 15
    Parque Industrial Bernardo Quintana
    C.P. 76246 El Marqués, Querétaro
    `,
    country: 'Mexiko',
  },
  {
    name: 'Leitz Tooling (M) Sdn. Bhd.',
    location: 'Malaysia - Petaling',
    email: 'sales_m@leitztools.com',
    phone: '+603 80 81 22 26',
    fax: '+603 80 81 12 26',
    website: 'http://www.leitztools.com',
    address: `No. 15, Jalan TP 3/2
    UEP Industrial Park USJ
    47600 Subang Jaya
    Selangor
    `,
    country: 'Malaysia',
  },
  {
    name: 'Leitz Service BV',
    location: 'Niederlande - Elst',
    email: 'elst@leitz-service.org',
    phone: '+31 (0)481 748 100',
    fax: '+31 (0)481 748 110',
    website: 'http://www.leitz.org',
    address: `Platinaweg 7-9
    6662 PR Elst
    `,
    country: 'Niederlande',
  },
  {
    name: 'Leitz Tooling NZ Ltd.',
    location: 'Neuseeland - Penrose',
    email: 'leitz@leitz.co.nz',
    phone: '+64 800 578 665',
    fax: '+64 800 568 6652',
    website: 'http://www.leitz.co.nz',
    address: `Unit 9B Hamlin’s Hill Commercial Centre
    930 Great South Road
    Penrose 1061
    Auckland
    `,
    country: 'Neuseeland',
  },
  {
    name: 'Leitz Polska Sp. z o.o.',
    location: 'Polen - Radomsko',
    email: 'leitz@leitz.pl',
    phone: '+48 (0) 44 683 03 88',
    fax: '+48 (0) 44 683 04 77',
    website: 'http://www.leitz.pl',
    address: `ul. Duńska 4
    97-500 Radomsko
    `,
    country: 'Polen',
  },
  {
    name: 'Leitz Romania S.R.L.',
    location: 'Rumänien - Brasov',
    email: 'office-brasov@leitz.org',
    phone: '+40 (0) 268 406 246',
    fax: '',
    website: 'http://www.leitz.org',
    address: `B-dul Grivitei Nr. 1 W
    500177 Brasov
    `,
    country: 'Rumänien',
  },
  {
    name: 'OOO Leitz Instrumenti',
    location: 'Russland - Moskau',
    email: 'info@leitz.ru',
    phone: '+7 495 120 11 70',
    fax: '',
    website: 'http://www.leitz.ru',
    address: `Geroya Rossii Solomatina st. 6, bld.9A
    pos. Zavoda Mosrentgen,
    108820, Moscow, Russia
    `,
    country: 'Russland',
  },
  {
    name: 'Leitz Tooling Asia Pte Ltd.',
    location: 'Singapur - Singapur',
    email: 'sales@leitztools.com',
    phone: '+65 64 62 53 68',
    fax: '+65 64 62 12 09',
    website: 'https://www.leitz.org/en-sg',
    address: `1 Clementi Loop #04-04
    Singapore 129 808
    `,
    country: 'Singapur',
  },
  {
    name: 'Leitz orodja d.o.o.',
    location: 'Slowenien - Kranj',
    email: 'leitz@leitz.si',
    phone: '+386 (0) 4 238 12 10',
    fax: '+386 (0) 4 238 12 22',
    website: 'http://www.leitz.org',
    address: `Savska cesta 14
    4000 Kranj
    `,
    country: 'Slowenien',
  },
  {
    name: 'Leitz-nástroje s.r.o.',
    location: 'Slowakei - Bratislava',
    email: 'leitz@leitz.sk',
    phone: '+421 (0) 2 52 62 00 24',
    fax: '+421 (0) 2 52 62 00 26',
    website: 'http://www.leitz.org',
    address: `Stará Vajnorská 90
    831 04 Bratislava
    `,
    country: 'Slowakei',
  },
  {
    name: 'Leitz Tooling Co., Ltd',
    location: 'Thailand - Bangkok',
    email: 'san@leitztools.com',
    phone: '+66 (0) 2 807 59 90',
    fax: '+66 (0) 2 807 59 94',
    website: 'http://www.leitzthailand.com',
    address: `6 Phutthamonthon Sai 3 Rd
    Nongkangplu, Nongkaem
    Bangkok 10160
    `,
    country: 'Thailand',
  },
  {
    name: 'Leitz Kesici Takımlar San. Tic. A.Ş.',
    location: 'Türkei - Istanbul',
    email: 'leitz@leitz.com.tr',
    phone: '+90 262 677 17 27',
    fax: '+90 262 677 17 30',
    website: 'https://www.leitz.org/tr/',
    address: `Gebze Organize Sanayi Bölgesi
    1600. Sok. No: 1602
    41400 Gebze / Kocaeli
    `,
    country: 'Türkei',
  },
  {
    name: 'Leitz Tooling Asia Pte Ltd (Branch Office)',
    location: 'Taiwan - Tainan City',
    email: 'leitztaiwan@leitztools.com',
    phone: '+886 (0) 932 723 087',
    fax: '',
    website: 'http://www.leitz.org',
    address: `6F., No. 31, Sec. 3,
    Dongmen Rd., 
    East Dist., Tainan City 70172
    `,
    country: 'Taiwan',
  },
  {
    name: 'Leitz Instrumenty Ukraina LLC',
    location: 'Ukraine - Kiew',
    email: 'kiev@leitz.org',
    phone: '+38 (0) 67 218 28 69',
    fax: '+38 (0) 67 218 28 69',
    website: 'http://www.leitz.com.ua',
    address: `Petropawlivska Borschagivka
    Wul. Soborna 2-B
    08130 Kiew
    `,
    country: 'Ukraine',
  },
  {
    name: 'Leitz Tooling Systems LP',
    location: 'USA - Grand Rapids',
    email: 'insidesales@leitz.org',
    phone: '+1 800 253 6070',
    fax: '+1 800 752 9391',
    website: 'https://www.leitz.org/en-us/',
    address: `4301 East Paris Avenue, S.E.
    Grand Rapids, MI 49512
    `,
    country: 'USA',
  },
  {
    name: 'Leitz Tooling Vietnam Company Limited',
    location: 'Vietnam - Ho-Chi-Minh-Stadt',
    email: 'sales_ltv@leitz.co.th',
    phone: '+84 (0)25 136 829 44',
    fax: '+84 (0)25 136 829 45',
    website: 'https://www.leitz.org/vi/',
    address: `Rental factory C-4, Road N1-2, Long Duc Industrial Park,
    An Phuoc ward, Long Thanh district,
    Dong Nai province,
    Vietnam, 810000
    `,
    country: 'Vietnam',
  },
];
