
import { Options, Vue } from 'vue-class-component';
import TabMenuItem from '../TabMenuItem/TabMenuItem.vue';

@Options({
  components: {
    TabMenuItem,
  },
  props: {
    items: Object,
    selectedTab: Number,
    uppercase: { type: Boolean, default: false },
    isLocalised: { type: Boolean, default: false },
    localisedPath: { type: String, default: '' },
  },
})
export default class TabMenu extends Vue {
}
