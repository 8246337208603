
import LegalInformation from '@/components/LegalInformation/LegalInformation.vue';
import SupportBranch from '@/components/SupportBranch/SupportBranch.vue';
import SupportForm from '@/components/SupportForm/SupportForm.vue';
import SupportSuccess from '@/components/SupportSuccess/SupportSuccess.vue';
import { EtrackerAreas } from '@/models/Enum';
import { Branch, CustomerData } from '@/models/Interface';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    SupportBranch,
    SupportForm,
    SupportSuccess,
    LegalInformation,
  },
})
export default class SupportView extends Vue {
  customerData: CustomerData = {
    id: null,
    email: null,
    phone: null,
    branch: null,
  };

  sentSuccessfully = false;

  created() {
    document.dispatchEvent(new CustomEvent('etrackerPageAreaChanged', { detail: EtrackerAreas.support }));
    this.sentSuccessfully = false;
    this.getCustomerData();
  }

  onBranchChange(branch: Branch | null) {
    this.customerData.branch = branch;
    this.saveCustomerData(this.customerData);
  }

  onSendSuccessfully(customerData: CustomerData) {
    this.sentSuccessfully = true;
    this.saveCustomerData(customerData);
  }

  saveCustomerData(customerData: CustomerData) {
    this.customerData = customerData;
    localStorage.customerData = JSON.stringify(this.customerData);
  }

  getCustomerData() {
    const customerDataString = localStorage.customerData;
    try {
      this.customerData = JSON.parse(customerDataString);
    } catch {
      this.customerData = {
        id: null,
        email: null,
        phone: null,
        branch: null,
      };
      this.saveCustomerData(this.customerData);
    }
  }
}
