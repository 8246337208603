
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
  props: {
    label: String,
    disabled: { type: Boolean, default: false },
    title: { type: String, default: '' },
    variant: { type: String, default: 'primary' },
  },
})
export default class ButtonElement extends Vue {
}
