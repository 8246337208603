import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["onClick", "innerHTML"]

export function render(_ctx, _cache) {
  const _directive_click_outside = _resolveDirective("click-outside")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["context-menu", _ctx.alignment])
  }, [
    _createVNode(_Transition, { mode: "out-in" }, {
      default: _withCtx(() => [
        (_ctx.open)
          ? _withDirectives((_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "context-menu__content",
              style: _normalizeStyle(_ctx.minWidth ? `min-width: ${_ctx.minWidth};` : ''),
              onFocusout: _cache[0] || (_cache[0] = (...args) => (_ctx.onClickOutside && _ctx.onClickOutside(...args))),
              tabindex: "auto"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(["context-menu__option font-size-14 font-weight-bold cursor-pointer", [
        { 'is-active' : _ctx.activeOption && _ctx.activeOption === option },
        _ctx.activeOption && _ctx.activeOption === option ? 'color-primary' : 'color-tertiary-1'
      ]]),
                  onClick: $event => (_ctx.selectOption(index)),
                  innerHTML: _ctx.isLocalised ? _ctx.$t(`${_ctx.localisedPath}${option}`) : option
                }, null, 10, _hoisted_1))
              }), 256))
            ], 36)), [
              [_directive_click_outside, _ctx.onClickOutside]
            ])
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}