import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "product-details__content" }
const _hoisted_2 = { class: "product-details__title font-size-20 color-black" }
const _hoisted_3 = { class: "product-details__columns" }
const _hoisted_4 = {
  key: 0,
  class: "product-details__column if-2-columns"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 3,
  class: "product-details__divider"
}
const _hoisted_7 = { class: "product-details__column" }
const _hoisted_8 = { class: "product-details__tags" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = {
  key: 0,
  class: "product-details__pictograms"
}
const _hoisted_13 = { class: "product-details__pictogram" }
const _hoisted_14 = ["src"]
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = { class: "product-details__description" }
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = {
  key: 1,
  class: "product-details__column if-1-column"
}
const _hoisted_21 = ["src"]
const _hoisted_22 = {
  key: 2,
  class: "product-details__divider"
}

export function render(_ctx, _cache) {
  const _component_NoImage = _resolveComponent("NoImage")
  const _component_ProductMediaPreview = _resolveComponent("ProductMediaPreview")
  const _component_ProductMediaModal = _resolveComponent("ProductMediaModal")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('product_general_title')), 1),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.product.productMedia || _ctx.product.productImagesAndVideos || _ctx.product.sketchesAndDiagrams)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.product.productMedia)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.product.productMedia[0].url,
                  onerror: "this.src='/icons/no_product_image.svg'",
                  class: "product-details__product-image cursor-pointer",
                  onClick: _cache[0] || (_cache[0] = $event => (_ctx.openMediaModal([_ctx.product.productMedia[0]], 0, true)))
                }, null, 8, _hoisted_5))
              : (_openBlock(), _createBlock(_component_NoImage, { key: 1 })),
            (_ctx.product.productImagesAndVideos)
              ? (_openBlock(), _createBlock(_component_ProductMediaPreview, {
                  key: 2,
                  title: _ctx.$t('product_general_images'),
                  files: _ctx.product.productImagesAndVideos,
                  onSelectMedium: _cache[1] || (_cache[1] = $event => (_ctx.openMediaModal(_ctx.product.productImagesAndVideos, $event, false)))
                }, null, 8, ["title", "files"]))
              : _createCommentVNode("", true),
            (_ctx.product.productImagesAndVideos && _ctx.product.sketchesAndDiagrams)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6))
              : _createCommentVNode("", true),
            (_ctx.product.sketchesAndDiagrams)
              ? (_openBlock(), _createBlock(_component_ProductMediaPreview, {
                  key: 4,
                  title: _ctx.$t('product_general_sketches'),
                  files: _ctx.product.sketchesAndDiagrams,
                  onSelectMedium: _cache[2] || (_cache[2] = $event => (_ctx.openMediaModal(_ctx.product.sketchesAndDiagrams, $event, false)))
                }, null, 8, ["title", "files"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", {
            class: "font-size-14 color-primary",
            innerHTML: _ctx.product.id
          }, null, 8, _hoisted_9),
          _createElementVNode("div", {
            class: "font-size-14 color-white",
            innerHTML: _ctx.product.dimensions
          }, null, 8, _hoisted_10)
        ]),
        _createElementVNode("div", {
          class: "font-size-20 color-black",
          innerHTML: _ctx.product.title
        }, null, 8, _hoisted_11),
        (_ctx.product.pictograms)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.product.pictograms, (pictogram, index) => {
                return (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("img", {
                    src: pictogram.url,
                    onerror: "this.src='/icons/no_product_image.svg'",
                    class: "product-details__pictogram-image"
                  }, null, 8, _hoisted_14),
                  _createElementVNode("div", {
                    class: "product-details__pictogram-info font-size-14 color-tertiary-1",
                    innerHTML: pictogram.label
                  }, null, 8, _hoisted_15)
                ]))
              }), 256))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "font-size-16 color-black",
          innerHTML: _ctx.product.subtitle
        }, null, 8, _hoisted_16),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.product.productDescription, (descriptionEntry) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_17, [
            _createElementVNode("div", {
              class: "font-size-16 color-black",
              innerHTML: descriptionEntry.title
            }, null, 8, _hoisted_18),
            _createElementVNode("div", {
              class: "font-size-14 color-tertiary-2",
              innerHTML: descriptionEntry.text
            }, null, 8, _hoisted_19)
          ]))
        }), 256))
      ]),
      (_ctx.product.productMedia || _ctx.product.productImagesAndVideos || _ctx.product.sketchesAndDiagrams)
        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
            (_ctx.product.productMedia)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.product.productMedia[0].url,
                  onerror: "this.src='/icons/no_product_image.svg'",
                  class: "product-details__product-image"
                }, null, 8, _hoisted_21))
              : _createCommentVNode("", true),
            (_ctx.product.productImagesAndVideos)
              ? (_openBlock(), _createBlock(_component_ProductMediaPreview, {
                  key: 1,
                  title: _ctx.$t('product_general_images'),
                  files: _ctx.product.productImagesAndVideos,
                  onSelectMedium: _cache[3] || (_cache[3] = $event => (_ctx.openMediaModal(_ctx.product.productImagesAndVideos, $event, false)))
                }, null, 8, ["title", "files"]))
              : _createCommentVNode("", true),
            (_ctx.product.productImagesAndVideos && _ctx.product.sketchesAndDiagrams)
              ? (_openBlock(), _createElementBlock("div", _hoisted_22))
              : _createCommentVNode("", true),
            (_ctx.product.sketchesAndDiagrams)
              ? (_openBlock(), _createBlock(_component_ProductMediaPreview, {
                  key: 3,
                  title: _ctx.$t('product_general_sketches'),
                  files: _ctx.product.sketchesAndDiagrams,
                  onSelectMedium: _cache[4] || (_cache[4] = $event => (_ctx.openMediaModal(_ctx.product.sketchesAndDiagrams, $event, false)))
                }, null, 8, ["title", "files"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_ProductMediaModal, {
      ref: "mediaModal",
      id: _ctx.product.id,
      dimensions: _ctx.product.dimensions
    }, null, 8, ["id", "dimensions"])
  ]))
}