import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "product-relatedarticle-item" }
const _hoisted_2 = { class: "product-relatedarticle-item__content" }
const _hoisted_3 = { class: "product-relatedarticle-item__id" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "product-relatedarticle-item__dimensions" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "product-relatedarticle-item__buttons" }
const _hoisted_9 = { class: "button__text" }
const _hoisted_10 = {
  key: 0,
  class: "product-relatedarticle-item__extended-content"
}
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = {
  key: 1,
  class: "font-size-12 color-tertiary-1"
}

export function render(_ctx, _cache) {
  const _component_SvgElement = _resolveComponent("SvgElement")
  const _component_RouterLink = _resolveComponent("RouterLink")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "product-relatedarticle-item__header",
        onClick: _cache[1] || (_cache[1] = $event => (_ctx.expanded = !_ctx.expanded))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", {
            class: "font-size-12 color-primary",
            innerHTML: _ctx.relatedArticle.id
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", {
          class: "product-relatedarticle-item__title font-size-14 color-black",
          innerHTML: _ctx.relatedArticle.title
        }, null, 8, _hoisted_5),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", {
            class: "font-size-12 color-tertiary-1",
            innerHTML: _ctx.relatedArticle.dimensions
          }, null, 8, _hoisted_7)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_RouterLink, {
            to: { name: _ctx.routeNames.ProductGeneral, params: { id: _ctx.relatedArticle.id }  },
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
            class: "button cursor-pointer is-secondary"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('product_relatedArticles_toProduct')), 1),
              _createVNode(_component_SvgElement, {
                src: "/icons/external_link.svg",
                class: _normalizeClass(["button__icon", [{ 'is-expanded' : _ctx.expanded }]])
              }, null, 8, ["class"])
            ]),
            _: 1
          }, 8, ["to"]),
          _createVNode(_component_SvgElement, {
            src: "/icons/chevron_right.svg",
            class: _normalizeClass(["product-relatedarticle-item__arrow fill-tertiary-2", [{ 'is-expanded' : _ctx.expanded }]])
          }, null, 8, ["class"])
        ])
      ]),
      _createVNode(_Transition, null, {
        default: _withCtx(() => [
          (_ctx.expanded)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                (_ctx.relatedArticle.attributes)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.relatedArticle.attributes, (attribute, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(["product-relatedarticle-item__row", [
                index % 4 === 0 || (index - 1) % 4 === 0 ? 'highlighted' : '',
              ]])
                      }, [
                        _createElementVNode("div", {
                          class: "font-size-12 color-tertiary-2",
                          innerHTML: attribute.label
                        }, null, 8, _hoisted_11),
                        _createElementVNode("div", {
                          class: "font-size-12 color-black",
                          innerHTML: attribute.value
                        }, null, 8, _hoisted_12)
                      ], 2))
                    }), 256))
                  : (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.$t('product_noInformation')), 1))
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}