/* eslint-disable no-shadow */

export enum RouteNames {
    Start = 'start',
    Calculator = 'calculator',
    Search = 'search',
    Support = 'support',
    Product = 'product',
    ProductGeneral = 'general',
    ProductTechnicalInfo = 'technicalInfo',
    ProductToolApplicability = 'toolApplicability',
    ProductSpares = 'spares',
    ProductApplicationParameter = 'applicationParameter',
    ProductRelatedArticles = 'relatedArticles',
    ProductDownloads = 'downloads',
    App = 'app',
    Link = 'link',
    Mill = 'mill',
    Saw = 'saw',
    Unit = 'unit',
}

export enum TabNames {
    General = 'general',
    TechnicalInfo = 'technicalInfo',
    ToolApplicability = 'toolApplicability',
    Spares = 'spares',
    ApplicationParameter = 'applicationParameter',
    RelatedArticles = 'relatedArticles',
    Downloads = 'downloads',
    Link = 'link',
    Mill = 'mill',
    Saw = 'saw',
    Unit = 'unit',
}

export enum MediaType {
    Image = 'image',
    Video = 'video',
    YouTube = 'youtube'
}

export enum SpareType {
    General = 'general',
    Blade = 'blade',
    Saw = 'saw'
}

export enum ProductCalculatorType {
    Mill = 'mill',
    Saw = 'saw',
}

export enum CalculatorRouteType {
    Mill = 'mill',
    Saw = 'saw',
    Unit = 'unit',
}

export enum CalculatorRouteMaterial {
    Wood = 'wood',
    Metal = 'metal',
}

export enum CalculatorRouteProcedure {
    CuttingSpeed = 'cuttingSpeed',
    RotationalSpeed = 'rotationalSpeed',
}

export enum EtrackerAreas {
    start = 'Start',
    calculator = 'Rechner',
    search = 'Suche',
    support = 'Support',
    product = 'Produkt',
    mill = 'Fräsen',
    saw = 'Sägen',
    unit = 'Einheit',
    wood = 'Holz',
    metal = 'Metall',
    cuttingSpeed = 'Schnittgeschwindigkeit',
    rotationalSpeed = 'Drehzahl',
    area = 'Fläche',
    energy = 'Energie',
    length = 'Länge',
    performance = 'Leistung',
    power = 'Masse',
    pressure = 'Druck',
    temperature = 'Temperatur',
    torque = 'Drehmoment',
    velocity = 'Geschwindigkeit',
    volume = 'Volumen',
    weight = 'Gewicht',
    general = 'Allgemein',
    technicalInfo = 'TechnischeInfo',
    toolApplicability = 'Werkzeugeignung',
    spares = 'Ersatzteile',
    applicationParameter = 'Einsatzparameter',
    relatedArticles = 'Produkttabelle',
    downloads = 'Downloads',
}
