import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sidebar-item__label font-size-10 text-align-center" }

export function render(_ctx, _cache) {
  const _component_SvgElement = _resolveComponent("SvgElement")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sidebar-item cursor-pointer", { 'is-active' : _ctx.isActive }])
  }, [
    _createVNode(_component_SvgElement, {
      src: _ctx.iconSrc,
      class: "sidebar-item__icon"
    }, null, 8, ["src"]),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.label), 1)
  ], 2))
}