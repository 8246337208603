import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "no-image" }
const _hoisted_2 = { class: "no-image__text font-size-18 color-tertiary-2" }

export function render(_ctx, _cache) {
  const _component_SvgElement = _resolveComponent("SvgElement")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SvgElement, {
      src: "/icons/no_image.svg",
      class: "no-image__icon fill-tertiary-2"
    }),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('product_noImage')), 1)
  ]))
}