import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "scan" }
const _hoisted_2 = { class: "scan__prompt font-size-12 color-primary" }
const _hoisted_3 = { class: "scan__text font-size-14 color-black" }
const _hoisted_4 = { class: "scan__text font-size-12 color-tertiary-2" }

export function render(_ctx, _cache) {
  const _component_SvgElement = _resolveComponent("SvgElement")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('productsearch_readerNotRecognised')), 1),
    _createVNode(_component_SvgElement, {
      src: "/icons/scan_failed.svg",
      class: "scan__icon"
    }),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('productsearch_readerNotConnected')), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('productsearch_readerInstruction')), 1)
  ]))
}