
import { SpareType } from '@/models/Enum';
import { Medium } from '@/models/Interface';
import { Options, Vue } from 'vue-class-component';
import NoImage from '../NoImage/NoImage.vue';
import ProductMediaModal from '../ProductMediaModal/ProductMediaModal.vue';
import ProductSpareImages from '../ProductSpareImages/ProductSpareImages.vue';
import ProductSpareItem from '../ProductSpareItem/ProductSpareItem.vue';
import ProductTabEmptyState from '../ProductTabEmptyState/ProductTabEmptyState.vue';

@Options({
  components: {
    ProductSpareImages,
    ProductSpareItem,
    ProductTabEmptyState,
    ProductMediaModal,
    NoImage,
  },
  props: {
    product: Object,
  },
})
export default class ProductSpares extends Vue {
  spareType = SpareType

  sparesBladeExpanded = false;

  sparesSawExpanded = false;

  sparesGeneralExpanded = false;

  spareListToggles = [
    { type: SpareType.Blade, label: 'product_spares_titleBlade', expanded: false },
    { type: SpareType.Saw, label: 'product_spares_titleSaw', expanded: false },
    { type: SpareType.General, label: 'product_spares_titleGeneral', expanded: false },
  ]

  openMediaModal(media: Medium[], index: number) {
    // this.images = images;
    (this.$refs.mediaModal as ProductMediaModal).open(media, index, false);
  }
}
