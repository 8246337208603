
import { Options, Vue } from 'vue-class-component';
import ProductTabEmptyState from '../ProductTabEmptyState/ProductTabEmptyState.vue';

@Options({
  components: {
    ProductTabEmptyState,
  },
  props: {
    matrix: Object,
    level: { type: Number, default: 1 },
  },
})
export default class ProductMatrix extends Vue {
  expanded = false;
}
