import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "history" }
const _hoisted_2 = {
  key: 0,
  class: "empty-state"
}
const _hoisted_3 = { class: "font-size-24 color-tertiary-2 text-align-center" }
const _hoisted_4 = { class: "font-size-14 color-tertiary-2 text-align-center" }
const _hoisted_5 = {
  key: 1,
  class: "history__items"
}
const _hoisted_6 = { class: "history__heading font-size-24 color-black" }

export function render(_ctx, _cache) {
  const _component_SvgElement = _resolveComponent("SvgElement")
  const _component_HistoryItem = _resolveComponent("HistoryItem")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.historyProducts.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_SvgElement, {
            src: "/icons/history.svg",
            class: "empty-state__icon fill-tertiary-2"
          }),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('productsearch_history')), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('productsearch_historyEmpty')), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.historyProducts.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('productsearch_history')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.historyProducts, (historyProduct, index) => {
            return (_openBlock(), _createBlock(_component_HistoryItem, {
              key: index,
              historyProduct: historyProduct,
              onDeleteHistoryItem: _cache[0] || (_cache[0] = $event => (_ctx.$emit('deleteHistoryItem', $event))),
              onOpenProduct: _cache[1] || (_cache[1] = $event => (_ctx.$emit('openProduct', $event)))
            }, null, 8, ["historyProduct"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}