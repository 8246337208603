import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "support-success" }
const _hoisted_2 = { class: "support-success__content" }
const _hoisted_3 = { class: "support-success__text" }
const _hoisted_4 = { class: "font-size-24 color-black text-align-center" }
const _hoisted_5 = { class: "font-size-16 color-tertiary-2 text-align-center" }
const _hoisted_6 = { class: "font-size-16 color-tertiary-2 text-align-center" }
const _hoisted_7 = { class: "support-success__buttons" }

export function render(_ctx, _cache) {
  const _component_SvgElement = _resolveComponent("SvgElement")
  const _component_ButtonElement = _resolveComponent("ButtonElement")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SvgElement, {
        src: "/icons/send_mail.svg",
        class: "support-success__icon fill-green"
      }),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('support_sent_title')), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('support_sent_thankyou')), 1),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('support_sent_team')), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_ButtonElement, {
          label: _ctx.$t('support_sent_backToSearch'),
          onClick: _cache[0] || (_cache[0] = $event => (_ctx.goToSearch())),
          disabled: _ctx.disabled
        }, null, 8, ["label", "disabled"]),
        _createElementVNode("div", {
          class: "font-size-16 color-primary text-align-center cursor-pointer",
          onClick: _cache[1] || (_cache[1] = $event => (_ctx.$emit('newRequest')))
        }, _toDisplayString(_ctx.$t('support_sent_newRequest')), 1)
      ])
    ])
  ]))
}