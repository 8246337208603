import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "start-view" }
const _hoisted_2 = { class: "start-view__background" }
const _hoisted_3 = {
  key: 0,
  class: "start-view__background-gradient"
}
const _hoisted_4 = {
  key: 1,
  class: "start-view__background-placeholder"
}

export function render(_ctx, _cache) {
  const _component_Welcome = _resolveComponent("Welcome")
  const _component_LegalInformation = _resolveComponent("LegalInformation")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: "/images/background_small.jpg",
        class: "start-view__background-image",
        onLoad: _cache[0] || (_cache[0] = $event => (_ctx.imageLoaded = true))
      }, null, 32),
      (_ctx.imageLoaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3))
        : (_openBlock(), _createElementBlock("div", _hoisted_4))
    ]),
    _createVNode(_component_Welcome),
    _createVNode(_component_LegalInformation, { class: "start-view__legal-information" })
  ]))
}