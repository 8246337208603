import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx, _cache) {
  const _component_SvgElement = _resolveComponent("SvgElement")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tab-menu-button", [
    { 'has-icon' : _ctx.icon },
    { 'is-disabled' : _ctx.disabled }
  ]])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["tab-menu-button__content cursor-pointer", { 'is-disabled' : _ctx.disabled }]),
      onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('buttonClicked')))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["tab-menu-button__label font-size-14 font-weight-bold color-primary", { 'text-transform-uppercase' : _ctx.uppercase }]),
        innerHTML: _ctx.label
      }, null, 10, _hoisted_1),
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_SvgElement, {
            key: 0,
            src: _ctx.icon,
            class: "tab-menu-button__icon fill-primary",
            style: _normalizeStyle(`width: ${_ctx.iconSize}; height: ${_ctx.iconSize};`)
          }, null, 8, ["src", "style"]))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}