
import { Options, Vue } from 'vue-class-component';
import LoaderElement from '@/elements/LoaderElement/LoaderElement.vue';
import AppLinks from '../AppLinks/AppLinks.vue';

@Options({
  components: {
    AppLinks,
    LoaderElement,
  },
  props: {
  },
})
export default class MobileApp extends Vue {
  loading = true;

  created() {
    this.getUserAgent();
  }

  getUserAgent() {
    const { userAgent } = navigator;
    if (/windows phone/i.test(userAgent)) {
      console.log('UserAgent is Windows Phone');
    } else if (/android/i.test(userAgent)) {
      console.log('UserAgent is Android');
      window.location.href = process.env.VUE_APP_LINK_ANDROID;
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      console.log('UserAgent is iOS');
      window.location.href = process.env.VUE_APP_LINK_IOS;
    } else {
      console.log('UserAgent is not mobile');
    }
    this.loading = false;
  }
}
