import { SupportRequest } from '@/models/Interface';
import axios, { AxiosResponse } from 'axios';
import MappingService from './MappingService';

export default class ApiService {
  // PRODUCTION ------------------------------------------------------------------------------------
  // private static urlArticle = 'https://es.leitz.ops.exconcept.com/article/_search';

  // private static urlProduct = 'https://es.leitz.ops.exconcept.com/product/_search';

  // private static urlSerialNumber = 'https://es.leitz.ops.exconcept.com/serial_number/_search';

  // private static urlSupportToken = 'https://webserver.leitz.ops.exconcept.com/api/login_check';

  // private static urlSupportRequest = 'https://webserver.leitz.ops.exconcept.com/api/secure/json/email';

  // private static basicToken = 'eHBlcnQtcHJvZDp6cXhfenFlNUVSVTB5ZHE2cWpn';

  // private static usernameSupport = 'ergowerk';

  // private static passwordSupport = 'send2LeitzXPert';

  // -----------------------------------------------------------------------------------------------

  // STAGING ---------------------------------------------------------------------------------------
  private static urlArticle = 'https://stage.es.leitz.ops.exconcept.com/article/_search'; // staging

  private static urlProduct = 'https://stage.es.leitz.ops.exconcept.com/product/_search'; // staging

  private static urlSerialNumber = 'https://stage.es.leitz.ops.exconcept.com/serial_number/_search'; // staging

  private static urlSupportToken = 'https://stage.webserver.leitz.ops.exconcept.com/api/login_check';

  private static urlSupportRequest = 'https://stage.webserver.leitz.ops.exconcept.com/api/secure/json/email'; // staging

  private static basicToken = 'eHBlcnQtc3RhZ2U6cmpGUUhiQWhIM3B6ZXgtLVNB'; // staging

  private static usernameSupport = 'ergowerk'; // staging

  private static passwordSupport = 'send2LeitzXPert'; // staging

  static getArticle(
    id: string,
    language: number | null,
    callback: (response: AxiosResponse) => void,
  ) {
    let trimmedId = id.toString();
    while (trimmedId.charAt(0) === '0') {
      trimmedId = trimmedId.substring(1);
    }
    const jsonString = `
      {
        "query": {
          "match": {
            "id.keyword": {
              "query": "${trimmedId}-${language ?? 3}"
            }
          }
        }
      }
    `;
    const json = JSON.parse(jsonString);
    axios.post(
      this.urlArticle,
      json,
      { headers: { Authorization: `Basic ${this.basicToken}` } },
    ).then((response) => callback(MappingService.getContent(response)));
  }

  static getProduct(
    id: string,
    language: number | null,
    callback: (response: AxiosResponse) => void,
  ) {
    const jsonString = `
      {
        "query": {
          "match": {
            "id.keyword": {
              "query": "${id}-${language ?? 3}"
            }
          }
        }
      }
    `;
    const json = JSON.parse(jsonString);
    axios.post(
      this.urlProduct,
      json,
      { headers: { Authorization: `Basic ${this.basicToken}` } },
    ).then((response) => callback(MappingService.getContent(response)));
  }

  static getArticleIdByNR(
    serialNumber: string,
    callback: (response: AxiosResponse) => void,
  ) {
    const jsonString = `
      {
        "query": {
          "match": {
            "SNR_NR.keyword": {
              "query": "${serialNumber}"
            }
          }
        }
      }
    `;
    const json = JSON.parse(jsonString);
    axios.post(
      this.urlSerialNumber,
      json,
      { headers: { Authorization: `Basic ${this.basicToken}` } },
    ).then((response) => callback(MappingService.getContent(response)));
  }

  static getArticleIdByUID(
    serialNumber: string,
    callback: (response: AxiosResponse) => void,
  ) {
    const jsonString = `
      {
        "query": {
          "match": {
            "SNR_UID.keyword": {
              "query": "${serialNumber}"
            }
          }
        }
      }
    `;
    const json = JSON.parse(jsonString);
    axios.post(
      this.urlSerialNumber,
      json,
      { headers: { Authorization: `Basic ${this.basicToken}` } },
    ).then((response) => callback(MappingService.getContent(response)));
  }

  static getRelatedArticles(
    productId: string,
    language: number | null,
    callback: (response: AxiosResponse) => void,
  ) {
    let trimmedId = productId.toString();
    while (trimmedId.charAt(0) === '0') {
      trimmedId = trimmedId.substring(1);
    }
    const jsonString = `
      {
        "query": {
          "match": {
            "product_id": {
              "query": "${trimmedId}-${language ?? 3}"
            }
          }
        },
        "size": 10000
      }
    `;
    const json = JSON.parse(jsonString);
    axios.post(
      this.urlArticle,
      json,
      { headers: { Authorization: `Basic ${this.basicToken}` } },
    ).then((response) => callback(MappingService.getContentArray(response)));
  }

  static getSupportToken(
    callback: (token: string | null) => void,
  ) {
    const credentials = {
      username: this.usernameSupport,
      password: this.passwordSupport,
    };
    axios.post(
      this.urlSupportToken,
      credentials,
    ).then((response) => {
      const token: string | null = response?.data?.token ?? null;
      callback(token);
    });
  }

  static sendSupportRequest(
    supportRequest: SupportRequest,
    callback: (response: any) => void,
  ) {
    ApiService.getSupportToken((token) => {
      if (!token) {
        callback(false);
      } else {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        axios.post(
          this.urlSupportRequest,
          supportRequest,
          { headers },
        ).then((response) => {
          callback(response);
        });
      }
    });
  }
}
