import { Branch } from '@/models/Interface';

export const nl = {
  calculator_calculation: 'Berekening',
  calculator_calculationOf_chipSheetLengthAndChipThickness: 'Berekening van de spaanbooglengte en spaandikte',
  calculator_calculationOf_chipThicknessAndChipSheetLength: 'Berekening van de spaandikte en spaanbooglengte',
  calculator_calculationOf_cutPath: 'Berekening van de snijlengte',
  calculator_calculationOf_cutterStepDepth: 'Berekening van de messenslagdiepte',
  calculator_calculationOf_cuttingSpeed: 'Berekening van de snijsnelheid',
  calculator_calculationOf_feedPerTooth: 'Berekening van de aanvoer per tand',
  calculator_calculationOf_feedRate: 'Berekening van de aanvoersnelheid',
  calculator_calculationOf_rotationalSpeed: 'Berekening van het toerental',
  calculator_calculationType_cuttingSpeed: 'Snijsnelheid',
  calculator_calculationType_rotationalSpeed: 'Toerental',
  calculator_compareTools: 'Gereedschap vergelijken',
  calculator_material_metal: 'Metalen',
  calculator_material_title: 'Materialen',
  calculator_material_wood: 'Hout en houtmateriaal',
  calculator_mill: 'Frezen',
  calculator_parameterType_averageChipThickness: 'Gemiddelde spaandikte',
  calculator_parameterType_chipSheetLength: 'Spaanbooglengte',
  calculator_parameterType_cutPath: 'Snijlengte van één snede',
  calculator_parameterType_cutterStepDepth: 'Messenslagdiepte in relatie tot aanvoer per tand',
  calculator_parameterType_cuttingDepthMill: 'Radiale verspaningsdiepte',
  calculator_parameterType_cuttingDepthSaw: 'Snijdiepte',
  calculator_parameterType_cuttingSpeed: 'Snijsnelheid',
  calculator_parameterType_diameter: 'Diameter',
  calculator_parameterType_feedPath: 'Aanvoerweg',
  calculator_parameterType_feedPerRotation: 'Aanvoer per omwenteling',
  calculator_parameterType_feedRate: 'Aanvoersnelheid',
  calculator_parameterType_maximumChipThickness: 'Maximale spaandikte',
  calculator_parameterType_protrusionSawBlade: 'Overstand van het zaagblad',
  calculator_parameterType_rotationalSpeed: 'Toerental',
  calculator_parameterType_toothCount: 'Aantal tanden',
  calculator_parameterType_toothFeed: 'Aanvoer per tand',
  calculator_quantityType_area: 'Oppervlakte',
  calculator_quantityType_energy: 'Energie',
  calculator_quantityType_length: 'Lengte',
  calculator_quantityType_performance: 'Vermogen',
  calculator_quantityType_power: 'Massa',
  calculator_quantityType_pressure: 'Druk',
  calculator_quantityType_temperature: 'Temperatuur',
  calculator_quantityType_torque: 'Aandraaimoment',
  calculator_quantityType_velocity: 'Snelheid',
  calculator_quantityType_volume: 'Volume',
  calculator_quantityType_weight: 'Gewicht',
  calculator_recommendation_blockboards: 'Meubelplaten',
  calculator_recommendation_castAluminumAlloys: 'Aluminium gietlegeringen',
  calculator_recommendation_cementBondedWoodMaterials: 'Cementgebonden houtmaterialen',
  calculator_recommendation_favorableRange: 'Gunstig bereik',
  calculator_recommendation_fineSizingChip: 'Fijne spaan',
  calculator_recommendation_group_aluminiumCastWroughtAlloys: 'Gegoten aluminium en gesmede legeringen',
  calculator_recommendation_group_brassShortLongChipping: 'Messing, kort- en langspanend',
  calculator_recommendation_group_bronze: 'Brons',
  calculator_recommendation_group_constructionSteels: 'Bouwstaal',
  calculator_recommendation_group_copperAlloys: 'Koper, koperlegeringen',
  calculator_recommendation_group_quenchedAndTemperedNitridingSteels: 'Gehard en genitreerd staal',
  calculator_recommendation_group_springBearingSteels: 'Veer- en rollagerstaalsoorten',
  calculator_recommendation_group_stainlessSteels: 'Roestvrij staal',
  calculator_recommendation_gypsumMaterials: 'Gipsmateriaal',
  calculator_recommendation_hardboard: 'Hardboard',
  calculator_recommendation_hardwoodCrosswise: 'Hardhoud dwars',
  calculator_recommendation_headline_cuttingSpeed: 'Snijsnelheid',
  calculator_recommendation_headline_group: 'Materiaalgroep',
  calculator_recommendation_headline_material: 'Materialen',
  calculator_recommendation_headline_tensileStrength: 'Treksterkte',
  calculator_recommendation_headline_toothFeed: 'Aanvoer per tand',
  calculator_recommendation_HPLCoveredChipboard: 'HPL-beplakte spaanplaten',
  calculator_recommendation_OSBBoards: 'OSB-platen',
  calculator_recommendation_plasticCoatedChipboard: 'Melamine beplakte spaanplaten',
  calculator_recommendation_plywood: 'Multiplex',
  calculator_recommendation_pressedLaminatedWood: 'Gelamineerd fineerhout',
  calculator_recommendation_roughingChip: 'Schrobspaan',
  calculator_recommendation_sizingChip: 'Schlichtspaan',
  calculator_recommendation_softwoodCrosswise: 'Zachthout dwars',
  calculator_recommendation_solidWoodLengthwise: 'Massiefhout langs',
  calculator_recommendation_thermoplastics: 'Thermoplastische kunststoffen',
  calculator_recommendation_thermosettingPlastics: 'Duroplastische kunststoffen',
  calculator_recommendation_title_general: 'Aanbevolen waarden',
  calculator_recommendation_title_NE: 'Aanbevolen snijwaarden voor de non-ferro bewerking',
  calculator_recommendation_title_steel: 'Aanbevolen snijwaarden voor de staalbewerking',
  calculator_recommendation_title_toothFeed: 'Aanbevolen aanvoer per tand',
  calculator_recommendation_veneeredPanels: 'Gefineerde platen',
  calculator_recommendation_wroughtAluminumAlloys: 'Gesmede aluminiumlegeringen',
  calculator_reset: 'Reset',
  calculator_resetCalculator: 'Reset calculator',
  calculator_saw: 'Zagen',
  calculator_tabs_mill: 'Frezen',
  calculator_tabs_saw: 'Zagen',
  calculator_tabs_unit: 'Eenheden omrekening',
  calculator_title: 'Calculator',
  calculator_titleMill: 'Calculator - Frezen',
  calculator_titleSaw: 'Calculator - Zagen',
  calculator_titleUnit: 'Calculator - Eenheid',
  calculator_tool: 'Gereedschap',
  calculator_toolParameters: 'Gereedschap parameter',
  calculator_unit: 'Eenheid',
  calculator_unitConversion: 'Eenheid omrekening',
  calculator_units: 'Eenheden',
  calculator_unitToConvert: 'Om te rekenen eenheid',
  calculator_unitType_bar: 'Bar',
  calculator_unitType_britishThermalUnit: 'Britse Warmte-eenheid',
  calculator_unitType_centimeter: 'Centimeter',
  calculator_unitType_cubicFeet: 'Kubieke voet',
  calculator_unitType_cubicInch: 'Kubieke inch',
  calculator_unitType_cubicMeter: 'Kubieke meter',
  calculator_unitType_degreeCelsius: 'Graden Celsius',
  calculator_unitType_degreeFahrenheit: 'Graden Fahrenheit',
  calculator_unitType_feet: 'Foot',
  calculator_unitType_feetPerMinute: 'Foot per minuut',
  calculator_unitType_footPound: 'Foot-pound',
  calculator_unitType_gallon: 'US gallon',
  calculator_unitType_gram: 'Gram',
  calculator_unitType_horsepower: 'Paardenkracht',
  calculator_unitType_inch: 'Inch',
  calculator_unitType_inchPerMinute: 'Inch per minuut',
  calculator_unitType_inchPerSecond: 'Inch per seconde',
  calculator_unitType_joule: 'Joule',
  calculator_unitType_kelvin: 'Kelvin',
  calculator_unitType_kilocalories: 'Kilocalorieën',
  calculator_unitType_kilogram: 'Kilogram',
  calculator_unitType_kilopond: 'Kilopond',
  calculator_unitType_kilowatt: 'Kilowatt',
  calculator_unitType_kilowattHour: 'Kilowatt uur',
  calculator_unitType_liter: 'Liter',
  calculator_unitType_meter: 'Meter',
  calculator_unitType_meterPerMinute: 'Meter per minuut',
  calculator_unitType_meterPerSecond: 'Meter per seconde',
  calculator_unitType_metricHorsepower: 'Paardenkracht',
  calculator_unitType_micrometer: 'Micrometer',
  calculator_unitType_millimeter: 'Millimeter',
  calculator_unitType_newton: 'Newton',
  calculator_unitType_newtonMeter: 'Newtonmeter',
  calculator_unitType_ounce: 'Ounce',
  calculator_unitType_ounceForce: 'Ounce-force',
  calculator_unitType_ounceForceFeet: 'Ounce-force foot',
  calculator_unitType_pascal: 'Pascal',
  calculator_unitType_pound: 'Pound-force',
  calculator_unitType_poundForce: 'Pound-force',
  calculator_unitType_poundForceFeet: 'Pound-force foot',
  calculator_unitType_poundForceInch: 'Pound-force inch',
  calculator_unitType_poundForcePerSquareInch: 'Pound-force per square inch',
  calculator_unitType_squareCentimeter: 'Vierkante centimeter',
  calculator_unitType_squareFeet: 'Vierkante voet',
  calculator_unitType_squareInch: 'Vierkante inch',
  calculator_unitType_squareMeter: 'Vierkante meter',
  calculator_unitType_squareMillimeter: 'Vierkante millimeter',
  calculator_unitType_standardAtmosphere: 'Technische atmosfeer',
  calculator_unitType_tonne: 'Ton',
  calculator_unitType_watt: 'Watt',
  favorites_noFavorites: 'U heeft nog geen product als favoriet opgeslagen',
  favorites_titlePlural: 'Favorieten',
  favorites_titleSingular: 'Favoriet',
  language: 'Nederlands',
  legalInformation_imprint: 'Colofon',
  legalInformation_privacyPolicy: 'Privacy',
  legalInformation_title: 'Juridische informatie',
  leitzxpert: 'LeitzXPert',
  link_imprint: 'https://www.leitz.org/nl-nl/colofon',
  link_privacyPolicy: 'https://www.leitz.org/nl-nl/privacyverklaring',
  navigation_appLanguage: 'App taal',
  navigation_apps: 'Apps',
  navigation_back: 'Terug',
  navigation_calculator: 'Calculator',
  navigation_cancel: 'Cancel',
  navigation_close: 'Sluiten',
  navigation_done: 'Gedaan',
  navigation_favorites: 'Favorieten',
  navigation_language: 'Taal',
  navigation_productsearch: 'Product',
  navigation_profile: 'Profiel',
  navigation_search: 'Zoeken',
  navigation_section: 'Menu',
  navigation_support: 'Support',
  noMobile_appName: 'LeitzXPert',
  noMobile_availableFor: 'Voor Android en iOS beschikbaar',
  noMobile_availableSoon: 'Binnenkort leverbaar',
  noMobile_notAvailable: 'Deze pagina is niet geoptimaliseerd voor mobiele apparaten of voor schermen met een weergavebreedte van minder dan 960&nbsp;pixels en kan daarom niet worden weergegeven.',
  noMobile_recommendation: 'We raden u aan onze gratis LeitzXPert-app uit de Shop te installeren.',
  noMobile_title: 'Deze site is niet geoptimaliseerd voor mobiele apparaten en kleine schermen',
  product_applicationParameter_title: 'Toepassingsparameters',
  product_backTo: 'Terug naar',
  product_general_details: 'Details',
  product_general_images: 'Productafbeeldingen &amp; Video\'s',
  product_general_imagesNoMedia: 'Geen productafbeeldingen &amp; video\'s beschikbaar',
  product_general_maximizeDescription: 'Omschrijving tonen',
  product_general_minimizeDescription: 'Omschrijving minimaliseren',
  product_general_sketches: 'Schetsen &amp; Diagrammen',
  product_general_sketchesNoMedia: 'Geen schetsen &amp; diagrammen beschikbaar',
  product_general_title: 'Algemene informatie',
  product_noImage: 'Geen afbeelding beschikbaar',
  product_noInformation: 'Geen informatie beschikbaar',
  product_notFound: 'Product niet gevonden',
  product_productsearch: 'Product zoeken',
  product_relatedArticles_title: 'Andere afmetingen',
  product_relatedArticles_toProduct: 'Naar het product',
  product_spares_availability_availableAtShortNotice: 'Beschikbaar op korte termijn',
  product_spares_availability_availableExStock: 'Uit voorraad leverbaar',
  product_spares_availability_productionToOrder: 'Productie op bestelling',
  product_spares_titleBlade: 'Reserve messen',
  product_spares_titleGeneral: 'Reserve onderdelen',
  product_spares_titleSaw: 'Vervangingszaagbladen',
  product_tabs_applicationParameter_noData: 'Er zijn geen inzetparameters beschikbaar voor dit product',
  product_tabs_applicationParameter: 'Inzetparameters',
  product_tabs_downloads_noData: 'Er zijn geen downloads beschikbaar voor dit product',
  product_tabs_downloads: 'Downloads',
  product_tabs_general: 'Algemeen',
  product_tabs_link_noData: 'Er is geen link naar de website voor dit product',
  product_tabs_link: 'Link naar website',
  product_tabs_relatedArticles_noData: 'Er zijn geen andere afmetingen beschikbaar voor dit product',
  product_tabs_relatedArticles: 'Andere afmetingen',
  product_tabs_spares_noData: 'Er zijn geen reserveonderdelen beschikbaar voor dit product',
  product_tabs_spares: 'Onderdelen',
  product_tabs_technicalInfo_noData: 'Er is geen technische informatie beschikbaar voor dit product',
  product_tabs_technicalInfo: 'Technische info',
  product_tabs_toolApplicability_noData: 'Er is geen geschikt gereedschap beschikbaar voor dit product',
  product_tabs_toolApplicability: 'Geschiktheid van het gereedschap',
  product_technicalInfo_title: 'Technische info',
  product_toolApplicability_title: 'Geschiktheid van het gereedschap',
  product_tools: 'Gereedschappen',
  productsearch_actions_addToFavorites: 'Aan favorieten toevoegen',
  productsearch_actions_delete: 'Wissen',
  productsearch_actions_details: 'Details',
  productsearch_actions_removeFromFavorites: 'Uit favorieten verwijderen',
  productsearch_actions_removeFromHistory: 'Uit geschiedenis verwijderen',
  productsearch_code: 'Code',
  productsearch_codeInput_text: 'Scan het Productidentificatienummer om de gegevens van het product te krijgen',
  productsearch_codeInput_title: 'Data Matrix',
  productsearch_description: 'Met deze zoekopdracht kunt u een product vinden op identificatienummer of serienummer door het nummer handmatig in het invoerveld te typen of door een geschikte lezer te gebruiken',
  productsearch_error_code_text: 'De code kon niet worden gescand. Probeer het opnieuw',
  productsearch_error_noProduct_textLong: 'Er zijn geen productgegevens gevonden onder dit nummer, probeer het opnieuw met een ander nummer',
  productsearch_error_noProduct_textShort: 'Er is geen product gevonden met het ingevoerde ID of serienummer',
  productsearch_error_noProduct_title: 'Geen resultaat voor dit ID-nummer',
  productsearch_error_notEnoughDigits_textLong: 'Voer minimaal 4 tekens in',
  productsearch_error_notEnoughDigits_textShort: 'Minimaal 4 tekens',
  productsearch_error_rfid_text: 'RFID kon niet worden gedetecteerd. Probeer het opnieuw',
  productsearch_error_unknown_text: 'Er is een onbekende fout opgetreden, probeer het opnieuw of neem contact op met onze supportafdeling',
  productsearch_error_unknown_title: 'Er is een fout opgetreden',
  productsearch_history: 'Geschiedenis',
  productsearch_historyEmpty: 'U heeft nog niet naar producten gezocht. De laatst gezochte producten worden hier in chronologische volgorde weergegeven.',
  productsearch_id: 'ID-Nummer',
  productsearch_idInput_text: 'Voer het productidentificatienummer in om de gegevens van het product te krijgen',
  productsearch_idInput_title: 'ID-nummer invullen',
  productsearch_inputPlaceholder: 'ID-nummer invullen',
  productsearch_inputPlaceholderIDSerial: 'ID-nummer/serienummer ingeven',
  productsearch_loadProduct: 'Productdata laden',
  productsearch_openCamera: 'Camera openen',
  productsearch_readerInstruction: 'Sluit uw lezer aan om de streepjescodes te scannen.',
  productsearch_readerNotConnected: 'Lezer niet aangesloten',
  productsearch_readerNotRecognised: 'Apparaat niet herkend',
  productsearch_rfid_disabledText: 'NFC moet ingeschakeld zijn om producten te zoeken met behulp van RFID. Roep hiervoor de instellingen op uw smartphone op.',
  productsearch_rfid_disabledTitle: 'NFC niet geactiveerd',
  productsearch_rfid_openScanner: 'RFID scanner openen',
  productsearch_rfid_ready: 'Gereed om te scannen',
  productsearch_rfid: 'RFID',
  productsearch_rfidInput_text: 'Houd uw smartphone of een RFID-scanner tegen het product om uw gegevens op te halen',
  productsearch_rfidInput_title: 'RFID productscan',
  productsearch_showHistory: 'Geschiedenis tonen',
  productsearch_title: 'Product zoeken',
  productsearch_tryAgain: 'Opnieuw zoeken',
  start_appFunctions: 'App functies',
  start_button: 'Nu beginnen',
  start_calculator_description: 'Bepaal belangrijke toepassingsgegevens met onze praktische rekenprogramma\'s',
  start_calculator_title: 'Calculator',
  start_favorites_description: 'Sla de producten die u zoekt overzichtelijk op',
  start_favorites_title: 'Favorieten',
  start_productsearch_description: 'Ontdek alle gereedschapsgegevens, handleidingen en productbrochures voor ons standaardassortiment',
  start_productsearch_title: 'Product zoeken',
  start_skip: 'Overslaan',
  start_solutions: 'Oplossingen in nieuwe dimensies',
  start_support_description: 'Neem snel en eenvoudig contact op met het support-team van Leitz',
  start_support_title: 'Support',
  start_welcomeAt: 'Welkom bij',
  support_agreeToPrivacy: 'Ik ga akkoord met het privacybeleid.',
  support_branch: 'Vestiging',
  support_branchEdit: 'Vestiging wijzigen',
  support_contactDetails: 'Contactdata vestiging',
  support_contactType: 'Hoofdkantoor van de Leitz-bedrijvengroep',
  support_customerNumber: 'Klantnummer',
  support_dataInfo_text: 'Wij hebben uw gegevens nodig voor een snelle supportaanvraag, zodat u deze gegevens niet steeds opnieuw hoeft in te voeren bij het doen van een aanvraag.',
  support_dataInfo_title: 'Waarvoor hebben wij uw gegevens nodig?',
  support_eMail: 'Email',
  support_errorCustomerId: 'Klantnummer kan alleen uit cijfers bestaan',
  support_errorEmailEmpty: 'Email mag niet leeg zijn',
  support_errorEmailInvalid: 'Email moet geldig zijn',
  support_errorMessage: 'Bericht mag niet leeg zijn',
  support_errorPhoneNumber: 'Telefoonnummer mag niet leeg zijn',
  support_errorSubject: 'Thema moet geselecteerd zijn',
  support_inputPlaceholderPleaseSelect: 'Selecteer alstublieft',
  support_inputPlaceholderPleaseType: 'Vul in alstublieft',
  support_inputPlaceholderSelect: 'Selecteren',
  support_inputPlaceholderSelectBranch: 'Vestigingen selecteren',
  support_inputPlaceholderTypeHere: 'Hier invullen',
  support_leitzBranch: 'Leitz vestiging',
  support_message: 'Bericht',
  support_myData: 'Mijn data',
  support_noBranchSelected: 'Om een aanvraag te starten, moet u eerst een vestiging selecteren.',
  support_phone: 'Telefoon',
  support_privacyInformation: 'De verzonden gegevens worden alleen verwerkt om uw verzoek te verwerken. Door het formulier in te dienen, gaat u akkoord met ons privacybeleid.',
  support_privacyLink: 'Naar privacyverklaring',
  support_ProfileNotFilled: 'Om een aanvraag te starten, moeten eerst alle benodigde gegevens in het profiel worden aangemaakt.',
  support_request: 'Aanvraag',
  support_requiredFields: 'De met * gemarkeerde velden zijn verplicht.',
  support_sendingFailed: 'Uw aanvraag kon helaas niet worden verzonden. Probeer het later opnieuw.',
  support_sendRequest: 'Verzend aanvraag',
  support_sent_backToSearch: 'Terug naar product zoeken',
  support_sent_newRequest: 'Start een nieuwe aanvraag',
  support_sent_team: 'Uw LeitzXPert Team',
  support_sent_thankyou: 'Bedankt voor uw aanvraag. Wij streven ernaar deze zo snel mogelijk te verwerken.',
  support_sent_title: 'Uw aanvraag wordt verzonden',
  support_subject_agentVisit: 'Buitendienst bezoek',
  support_subject_callback: 'Terugbelverzoek',
  support_subject_consulting: 'Advies',
  support_subject_toolService: 'Gereedschapsservice',
  support_subject: 'Thema',
  support_title: 'Support',
  support_titleXpressRequest: 'Support - XPress aanvraag',
  support_toProfile: 'Naar profiel',
  support_xpress: 'LeitzXPress',
  support_xpressRequest: 'LeitzXPress Aanvraag',
  tracking_allow: 'Toestaan',
  tracking_deny: 'Afwijzen',
  tracking_information: 'Om de gebruikerservaring van onze app voortdurend te kunnen verbeteren, maken we gebruik van het verzamelen van anonieme gegevens. Wil je ons helpen?',
  tracking_title: 'Help ons',
  version: 'Versie',
};

export const branchesNL: Branch[] = [
  {
    name: 'Leitz GmbH & Co. KG',
    location: 'Oostenrijk - Riedau',
    email: 'office.riedau@leitz.org',
    phone: '+43 (0) 7764 8200 0',
    fax: '+43 (0) 7764 8200 111',
    website: 'http://www.leitz.at',
    address: `Leitzstraße 80
    4752 Riedau
    `,
    country: 'Oostenrijk',
  },
  {
    name: 'Leitz Tooling Systems Pty. Ltd.',
    location: 'Australië - Melbourne',
    email: 'sales@leitz.com.au',
    phone: '+61 (0) 3 9760 40 00',
    fax: '+61 (0) 3 9760 40 99',
    website: 'http://www.leitz.com.au',
    address: `2/55 Barry Street
    Bayswater VIC 3153
    `,
    country: 'Australië',
  },
  {
    name: 'Leitz-Service N.V.',
    location: 'België - Grimbergen',
    email: 'contact-be@leitz.org',
    phone: '+32 (0) 2251 60 47',
    fax: '+32 (0) 2252 14 36',
    website: 'https://www.leitz.org',
    address: `Industrieweg 15
    1850 Grimbergen
    `,
    country: 'België',
  },
  {
    name: 'Leitz Ferramentas e Sistemas de Precisão',
    location: 'Brazilië - Sao Sebastiao do Cai',
    email: 'contact@leitz.com.br',
    phone: '+55 51 3635 6600',
    fax: '',
    website: 'http://www.leitz.org',
    address: `Rua Leitz, 50 - Bairro Angico - CEP 95760-000
    Junto à Rodovia RS 122, Km 14
    Sao Sebastiao do Cai - RS
    `,
    country: 'Brazilië',
  },
  {
    name: 'Leitz Tooling Systems LP',
    location: 'Canada - Vaughan',
    email: 'infocanada@leitz.org',
    phone: '+1 (0) 905 760 0375',
    fax: '+1 (0) 905 760 8125',
    website: 'https://www.leitz.org/en-ca/',
    address: `435 Four Valley Drive
    Vaughan, Ontario L4K 5X5
    `,
    country: 'Canada',
  },
  {
    name: 'Leitz GmbH',
    location: 'Zwitserland - Lenzburg',
    email: 'kontakt-ch@leitz.org',
    phone: '+41 (0) 62 886 39 39',
    fax: '+41 (0) 62 886 39 40',
    website: 'http://www.leitz.org',
    address: `Hardstrasse 2
    Postfach 448
    5600 Lenzburg
    `,
    country: 'Zwitserland',
  },
  {
    name: 'Leitz Tooling Systems (China) Co., Ltd.',
    location: 'China - Nanjing',
    email: 'leitzchina@leitz.com.cn',
    phone: '+86 (0) 25 52103111',
    fax: '+86 (0) 25 52103777',
    website: 'http://www.leitz.com.cn',
    address: `No.9 Shengtong Road, Moling Sub-District, Jiangning Zone, 211111 Nanjing, Jiangsu Province
    `,
    country: 'China',
  },
  {
    name: 'Leitz-nástroje s.r.o.',
    location: 'Tsjechische Republiek - Praag',
    email: 'leitz@leitz.cz',
    phone: '+420 24 14 82 699',
    fax: '+420 24 14 80 786',
    website: 'http://www.leitz.org',
    address: `Leitzova 1267/1
    Praha 5, Košíře
    `,
    country: 'Tsjechische Republiek',
  },
  {
    name: 'Leitz Werkzeugdienst GmbH',
    location: 'Duitsland - Bielefeld',
    email: 'service-hotline@leitz.org',
    phone: '+49 (0) 5219 240 30',
    fax: '+49 (0) 5219 240 310',
    website: 'https://www.leitz.org/de-de/',
    address: `Lübberbrede 13
    33719 Bielefeld
    `,
    country: 'Duitsland',
  },
  {
    name: 'Emil Leitz GmbH',
    location: 'Duitsland - Oberkochen',
    email: 'service-hotline@leitz.org',
    phone: '+49 (0) 7364 950 0',
    fax: '+49 (0) 7364 950 660',
    website: 'https://www.leitz.org/de-de',
    address: `Leitzstraße 2
    73447 Oberkochen
    `,
    country: 'Duitsland',
  },
  {
    name: 'Leitz Werkzeugdienst GmbH',
    location: 'Duitsland - Troisdorf',
    email: 'service-hotline@leitz.org',
    phone: '+49 (0) 2241 947 10',
    fax: '+49 (0) 2241 947 132',
    website: 'https://www.leitz.org/de-de',
    address: `Industriestraße 12
    53842 Troisdorf
    `,
    country: 'Duitsland',
  },
  {
    name: 'Herramientas Leitz S.L.',
    location: 'Spanje - Vilassar de Dalt (Barcelona)',
    email: 'ventas@leitz.org',
    phone: '+34 937 508 417',
    fax: '+34 937 507 925',
    website: 'https://www.leitz.org/es-es/',
    address: `Narcís Monturiol, 11-13
    08339 Vilassar de Dalt (Barcelona)
    `,
    country: 'Spanje',
  },
  {
    name: 'Leitz Kes Metalli Oy',
    location: 'Finland - Uurainen',
    email: 'leitz@leitzkesmetalli.fi',
    phone: '+358 (40) 18 80 55 0',
    fax: '+358 (40) 60 33 59 3',
    website: 'http://www.leitz.org/fi/',
    address: `Hitsaajantie 7
    41230 Uurainen
    `,
    country: 'Finland',
  },
  {
    name: 'Leitz S.à.r.l., Colmar',
    location: 'Frankrijk - Colmar',
    email: 'leitz-france@leitz.org',
    phone: '+33 (0) 3 892 108 00',
    fax: '',
    website: 'http://www.leitz.fr',
    address: `8, Rue Emile Schwoerer
    68012 Colmar Cedex
    `,
    country: 'Frankrijk',
  },
  {
    name: 'Leitz Tooling UK Ltd.',
    location: 'Groot Brittannië - Harlow',
    email: 'salesuk@leitz.org',
    phone: '+44 (0) 1279 454 530',
    fax: '+44 (0) 1279 454 509',
    website: 'https://www.leitz.org/en-gb',
    address: `Flex Meadow, The Pinnacles
    Harlow,
    Essex, CM19 5TN
    `,
    country: 'Groot Brittannië',
  },
  {
    name: 'Leitz orodja d.o.o.',
    location: 'Kroatië - Kranj (Slovenië)',
    email: 'leitz@leitz.si',
    phone: '+386 (0) 4 238 12 10',
    fax: '+386 (0) 4 238 12 22',
    website: 'http://www.leitz.org',
    address: `Savska cesta 14
    4000 Kranj
    `,
    country: 'Slovenië',
  },
  {
    name: 'Leitz Hungária Szerszám Kft.',
    location: 'Hongarije - Érd',
    email: 'leitz@leitz.hu',
    phone: '+36 (0) 23 521 900',
    fax: '',
    website: 'http://www.leitz.org',
    address: `Kis-Duna u. 6.
    2030 Érd
    `,
    country: 'Hongarije',
  },
  {
    name: 'Leitz Tooling Systems India Pvt Ltd.',
    location: 'India - Bangalore',
    email: 'info@leitzindia.com',
    phone: '+91 (0) 90 083 025 82',
    fax: '',
    website: 'http://www.leitz.org/en-in/',
    address: `486 C, 14th Cross, 4th Phase,
    Peenya Industrial Area,
    Bangalore 560 058
    Karnataka
    `,
    country: 'India',
  },
  {
    name: 'Leitz Italia S.r.l.',
    location: 'Italië - Lana',
    email: 'lana@leitz.org',
    phone: '+39 0473 55 28 00',
    fax: '+39 0473 56 21 39',
    website: 'http://www.leitz.org',
    address: `Zona Industriale 9
    39011 Lana (BZ)
    `,
    country: 'Italië',
  },
  {
    name: 'Leitz Tooling Co. Ltd.',
    location: 'Japan - Yokohama',
    email: 'info@leitz.co.jp',
    phone: '+81 (0) 455 33 3020',
    fax: '+81 (0) 455 33 3021',
    website: 'http://www.leitz.co.jp',
    address: `2-7-2, Kita-Shinyokohama,
    Kohoku-ku, Yokohama 223-0059
    `,
    country: 'Japan',
  },
  {
    name: 'Leitz Baltic UAB',
    location: 'Litouwen  - Kaunas',
    email: 'baltic@leitz.org',
    phone: '+370 37 281 289',
    fax: '',
    website: 'http://www.leitz.org',
    address: `Kaštonų g. 56,
    54310  Giraitė, Kauno r.
    `,
    country: 'Litouwen',
  },
  {
    name: 'Leitz-Service SARL',
    location: 'Luxemburg - Holzem',
    email: 'contact-lu@leitz.org',
    phone: '+352 (0) 399 550',
    fax: '+352 (0) 399 852',
    website: 'http://www.leitz.org',
    address: `28 Route de Capellen
    8279 Holzem
    `,
    country: 'Luxemburg',
  },
  {
    name: 'Leitz México S.A. de C.V.',
    location: 'Mexico - El Marqués',
    email: 'info@leitz.com.mx',
    phone: '+52 (0) 442 296 6870',
    fax: '+52 (0) 442 296 6882',
    website: 'https://www.leitz.org/es-mx/',
    address: `Av. Acueducto No. 15
    Parque Industrial Bernardo Quintana
    C.P. 76246 El Marqués, Querétaro
    `,
    country: 'Mexico',
  },
  {
    name: 'Leitz Tooling (M) Sdn. Bhd.',
    location: 'Maleisië - Petaling',
    email: 'sales_m@leitztools.com',
    phone: '+603 80 81 22 26',
    fax: '+603 80 81 12 26',
    website: 'http://www.leitztools.com',
    address: `No. 15, Jalan TP 3/2
    UEP Industrial Park USJ
    47600 Subang Jaya
    Selangor
    `,
    country: 'Maleisië',
  },
  {
    name: 'Leitz Service BV',
    location: 'Nederland - Elst',
    email: 'elst@leitz-service.org',
    phone: '+31 (0)481 748 100',
    fax: '+31 (0)481 748 110',
    website: 'http://www.leitz.org',
    address: `Platinaweg 7-9
    6662 PR Elst
    `,
    country: 'Nederland',
  },
  {
    name: 'Leitz Tooling NZ Ltd.',
    location: 'Nieuw-Zeeland - Penrose',
    email: 'leitz@leitz.co.nz',
    phone: '+64 800 578 665',
    fax: '+64 800 568 6652',
    website: 'http://www.leitz.co.nz',
    address: `Unit 9B Hamlin’s Hill Commercial Centre
    930 Great South Road
    Penrose 1061
    Auckland
    `,
    country: 'Nieuw-Zeeland',
  },
  {
    name: 'Leitz Polska Sp. z o.o.',
    location: 'Polen - Radomsko',
    email: 'leitz@leitz.pl',
    phone: '+48 (0) 44 683 03 88',
    fax: '+48 (0) 44 683 04 77',
    website: 'http://www.leitz.pl',
    address: `ul. Duńska 4
    97-500 Radomsko
    `,
    country: 'Polen',
  },
  {
    name: 'Leitz Romania S.R.L.',
    location: 'Roemenië - Brasov',
    email: 'office-brasov@leitz.org',
    phone: '+40 (0) 268 406 246',
    fax: '',
    website: 'http://www.leitz.org',
    address: `B-dul Grivitei Nr. 1 W
    500177 Brasov
    `,
    country: 'Roemenië',
  },
  {
    name: 'OOO Leitz Instrumenti',
    location: 'Rusland - Moskou',
    email: 'info@leitz.ru',
    phone: '+7 495 120 11 70',
    fax: '',
    website: 'http://www.leitz.ru',
    address: `Geroya Rossii Solomatina st. 6, bld.9A
    pos. Zavoda Mosrentgen,
    108820, Moscow, Russia
    `,
    country: 'Rusland',
  },
  {
    name: 'Leitz Tooling Asia Pte Ltd.',
    location: 'Singapore - Singapore',
    email: 'sales@leitztools.com',
    phone: '+65 64 62 53 68',
    fax: '+65 64 62 12 09',
    website: 'https://www.leitz.org/en-sg',
    address: `1 Clementi Loop #04-04
    Singapore 129 808
    `,
    country: 'Singapore',
  },
  {
    name: 'Leitz orodja d.o.o.',
    location: 'Slovenië - Kranj',
    email: 'leitz@leitz.si',
    phone: '+386 (0) 4 238 12 10',
    fax: '+386 (0) 4 238 12 22',
    website: 'http://www.leitz.org',
    address: `Savska cesta 14
    4000 Kranj
    `,
    country: 'Slovenië',
  },
  {
    name: 'Leitz-nástroje s.r.o.',
    location: 'Slowakije - Bratislava',
    email: 'leitz@leitz.sk',
    phone: '+421 (0) 2 52 62 00 24',
    fax: '+421 (0) 2 52 62 00 26',
    website: 'http://www.leitz.org',
    address: `Stará Vajnorská 90
    831 04 Bratislava
    `,
    country: 'Slowakije',
  },
  {
    name: 'Leitz Tooling Co., Ltd',
    location: 'Thailand - Bangkok',
    email: 'san@leitztools.com',
    phone: '+66 (0) 2 807 59 90',
    fax: '+66 (0) 2 807 59 94',
    website: 'http://www.leitzthailand.com',
    address: `6 Phutthamonthon Sai 3 Rd
    Nongkangplu, Nongkaem
    Bangkok 10160
    `,
    country: 'Thailand',
  },
  {
    name: 'Leitz Kesici Takımlar San. Tic. A.Ş.',
    location: 'Turkije - Istanbul',
    email: 'leitz@leitz.com.tr',
    phone: '+90 262 677 17 27',
    fax: '+90 262 677 17 30',
    website: 'https://www.leitz.org/tr/',
    address: `Gebze Organize Sanayi Bölgesi
    1600. Sok. No: 1602
    41400 Gebze / Kocaeli
    `,
    country: 'Turkije',
  },
  {
    name: 'Leitz Tooling Asia Pte Ltd (Branch Office)',
    location: 'Taiwan - Tainan City',
    email: 'leitztaiwan@leitztools.com',
    phone: '+886 (0) 932 723 087',
    fax: '',
    website: 'http://www.leitz.org',
    address: `6F., No. 31, Sec. 3,
    Dongmen Rd.,
    East Dist., Tainan City 70172
    `,
    country: 'Taiwan',
  },
  {
    name: 'Leitz Instrumenty Ukraina LLC',
    location: 'Oekraïne - Kiev',
    email: 'kiev@leitz.org',
    phone: '+38 (0) 67 218 28 69',
    fax: '+38 (0) 67 218 28 69',
    website: 'http://www.leitz.com.ua',
    address: `Petropawlivska Borschagivka
    Wul. Soborna 2-B
    08130 Kiev
    `,
    country: 'Oekraïne',
  },
  {
    name: 'Leitz Tooling Systems LP',
    location: 'VERENIGDE STATEN - Grand Rapids',
    email: 'insidesales@leitz.org',
    phone: '+1 800 253 6070',
    fax: '+1 800 752 9391',
    website: 'https://www.leitz.org/en-us/',
    address: `4301 East Paris Avenue, S.E.
    Grand Rapids, MI 49512
    `,
    country: 'VERENIGDE STATEN',
  },
  {
    name: 'Leitz Tooling Vietnam Company Limited',
    location: 'Vietnam - Ho Chi Minh-stad',
    email: 'sales_ltv@leitz.co.th',
    phone: '+84 (0)25 136 829 44',
    fax: '+84 (0)25 136 829 45',
    website: 'https://www.leitz.org/vi/',
    address: `Rental factory C-4, Road N1-2, Long Duc Industrial Park,
    An Phuoc ward, Long Thanh district,
    Dong Nai province,
    Vietnam, 810000
    `,
    country: 'Vietnam',
  },
];
