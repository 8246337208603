
// import { RouteNames } from '@/models/Enum';
// import { Medium } from '@/models/Interface';
import { Options, Vue } from 'vue-class-component';
import ProductMediaModal from '../ProductMediaModal/ProductMediaModal.vue';

@Options({
  components: {
    ProductMediaModal,
  },
  props: {
    spare: Object,
  },
})
export default class ProductSpareItem extends Vue {
  // openMediaModal(media: Medium[]) {
  //   console.log('openMediaModal', this.$refs.mediaModal);
  //   (this.$refs.mediaModal as ProductMediaModal).open(media, 0);
  // }

  // openProduct(id: string) {
  //   console.log('openProduct', this.$refs.mediaModal);
  //   this.$router.push({ name: RouteNames.Product, params: { id } });
  //   setTimeout(() => {
  //     window.location.reload();
  //   }, 1);
  // }
}
