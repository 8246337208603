
import { CalculatorTool } from '@/models/Interface';
import Calculate from '@/services/Calculate';
import { Options, Vue } from 'vue-class-component';
import CalculatorHeadRow from '../CalculatorHeadRow/CalculatorHeadRow.vue';
import CalculatorRow from '../CalculatorRow/CalculatorRow.vue';

@Options({
  components: {
    CalculatorHeadRow,
    CalculatorRow,
  },
  props: {
    compare: Boolean,
    calculatorTool1: Object,
    calculatorTool2: Object,
  },
  computed: {
    quantities() {
      return Calculate.calculatorQuantities;
    },
    t1CuttingSpeed() {
      return Calculate
        .cuttingSpeedWood(
          parseFloat(this.calculatorTool1.diameter),
          parseFloat(this.calculatorTool1.rotationalSpeed),
        )?.toString() ?? '';
    },
    t2CuttingSpeed() {
      return Calculate
        .cuttingSpeedWood(
          parseFloat(this.calculatorTool2.diameter),
          parseFloat(this.calculatorTool2.rotationalSpeed),
        )?.toString() ?? '';
    },
    t1ToothFeed() {
      return Calculate
        .toothFeedWood(
          parseFloat(this.calculatorTool1.feedRate),
          parseFloat(this.calculatorTool1.rotationalSpeed),
          parseFloat(this.calculatorTool1.toothCount),
        )?.toString() ?? '';
    },
    t2ToothFeed() {
      return Calculate
        .toothFeedWood(
          parseFloat(this.calculatorTool2.feedRate),
          parseFloat(this.calculatorTool2.rotationalSpeed),
          parseFloat(this.calculatorTool2.toothCount),
        )?.toString() ?? '';
    },
    t1ChipSheetLength() {
      return Calculate
        .chipSheetLengthSaw(
          parseFloat(this.calculatorTool1.diameter),
          parseFloat(this.calculatorTool1.cuttingDepth),
          parseFloat(this.calculatorTool1.protrusionSawBlade),
        )?.toString() ?? '';
    },
    t2ChipSheetLength() {
      return Calculate
        .chipSheetLengthSaw(
          parseFloat(this.calculatorTool2.diameter),
          parseFloat(this.calculatorTool2.cuttingDepth),
          parseFloat(this.calculatorTool2.protrusionSawBlade),
        )?.toString() ?? '';
    },
    t1AverageChipThickness() {
      return Calculate
        .averageChipThicknessSaw(
          parseFloat(this.calculatorTool1.diameter),
          parseFloat(this.calculatorTool1.cuttingDepth),
          parseFloat(this.calculatorTool1.protrusionSawBlade),
          parseFloat(this.t1ToothFeed),
        )?.toString() ?? '';
    },
    t2AverageChipThickness() {
      return Calculate
        .averageChipThicknessSaw(
          parseFloat(this.calculatorTool2.diameter),
          parseFloat(this.calculatorTool2.cuttingDepth),
          parseFloat(this.calculatorTool2.protrusionSawBlade),
          parseFloat(this.t2ToothFeed),
        )?.toString() ?? '';
    },
    t1MaximumChipThickness() {
      return Calculate
        .maximumChipThicknessSaw(
          parseFloat(this.calculatorTool1.diameter),
          parseFloat(this.calculatorTool1.cuttingDepth),
          parseFloat(this.calculatorTool1.protrusionSawBlade),
          parseFloat(this.t1ToothFeed),
        )?.toString() ?? '';
    },
    t2MaximumChipThickness() {
      return Calculate
        .maximumChipThicknessSaw(
          parseFloat(this.calculatorTool2.diameter),
          parseFloat(this.calculatorTool2.cuttingDepth),
          parseFloat(this.calculatorTool2.protrusionSawBlade),
          parseFloat(this.t2ToothFeed),
        )?.toString() ?? '';
    },
    t1CutPath() {
      return Calculate
        .cutPathSaw(
          parseFloat(this.calculatorTool1.feedPath),
          parseFloat(this.calculatorTool1.diameter),
          parseFloat(this.calculatorTool1.cuttingDepth),
          parseFloat(this.calculatorTool1.protrusionSawBlade),
          parseFloat(this.t1ToothFeed),
          parseFloat(this.calculatorTool1.toothCount),
        )?.toString() ?? '';
    },
    t2CutPath() {
      return Calculate
        .cutPathSaw(
          parseFloat(this.calculatorTool2.feedPath),
          parseFloat(this.calculatorTool2.diameter),
          parseFloat(this.calculatorTool2.cuttingDepth),
          parseFloat(this.calculatorTool2.protrusionSawBlade),
          parseFloat(this.t2ToothFeed),
          parseFloat(this.calculatorTool2.toothCount),
        )?.toString() ?? '';
    },
  },
})
export default class CalculatorSawWoodCuttingSpeed extends Vue {
  calculatorTool1!: CalculatorTool;

  calculatorTool12!: CalculatorTool;
}
