
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
  props: {
  },
})
export default class NoImage extends Vue {
}
