
import VueModal from 'vue-modality-v3';
import { VueAgile } from 'vue-agile';
import { VideoPlayer } from '@videojs-player/vue';
import 'video.js/dist/video-js.css';
// import YouTube from 'vue3-youtube';
// import { LazyYoutube } from 'vue-lazytube';
import { Options, Vue } from 'vue-class-component';
import { Medium } from '@/models/Interface';
import { MediaType } from '@/models/Enum';
import LoaderElement from '@/elements/LoaderElement/LoaderElement.vue';

@Options({
  components: {
    LoaderElement,
    VueModal,
    VueAgile,
    VideoPlayer,
    // YouTube,
    // LazyYoutube,
  },
  props: {
    // product: {
    //   type: Object,
    //   default: null,
    // },
    // spares: {
    //   type: Object,
    //   default: null,
    // },
    // media: {
    //   type: Object,
    //   default: [],
    // },
    id: {
      type: String,
      default: '',
    },
    dimensions: {
      type: String,
      default: '',
    },
  },
})
export default class ProductMediaModal extends Vue {
  media: Medium[] = [];

  mediaType = MediaType;

  showProductInfo = false;

  currentIndex = 0;

  refreshVideo = false;

  mediaContainerWidth = 0;

  mediaContainerHeight = 0;

  mediaNavigationWidth = 0;

  mediaNavigationHeight = 0;

  open(media: Medium[], index: number, showProductInfo: boolean) {
    this.media = media;
    this.showProductInfo = showProductInfo;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs.modal as any).open();
    this.getContainerWidth();
    setTimeout(() => {
      this.goTo('thumbnails', index);
      this.goTo('main', index);
    }, 1);
  }

  close() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs.modal as any).hide();
    this.media = [];
  }

  getContainerWidth() {
    setTimeout(() => {
      const mediaContainer = this.$refs.mediaContainer as Element;
      if (mediaContainer) {
        this.mediaContainerWidth = mediaContainer.getBoundingClientRect().width;
        this.mediaContainerHeight = (this.mediaContainerWidth / 16) * 9;
      }

      const mediaNavigation = this.$refs.mediaNavigation as Element;
      if (mediaNavigation) {
        this.mediaNavigationHeight = mediaNavigation.getBoundingClientRect().height;
        this.mediaNavigationWidth = (this.mediaNavigationHeight / 9) * 16;
      }
    }, 1);
  }

  goTo(target: string, index: number) {
    if (this.media.length > 1) {
      let nextIndex = index;
      if (index >= this.media.length) {
        nextIndex = 0;
      } else if (index < 0) {
        nextIndex = this.media.length - 1;
      }
      (this.$refs[target] as any).goTo(nextIndex);
      this.currentIndex = nextIndex;
      this.refreshVideo = true;
      setTimeout(() => {
        this.refreshVideo = false;
      }, 1);
    }
  }

  loaded() {
    console.log('frameload', this.currentIndex);
  }
}
