import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "calculator-unit-input" }
const _hoisted_2 = { class: "calculator-unit-input__title font-size-14 color-black" }
const _hoisted_3 = { class: "calculator-unit-input__fields" }

export function render(_ctx, _cache) {
  const _component_TextInputElement = _resolveComponent("TextInputElement")
  const _component_DropdownElement = _resolveComponent("DropdownElement")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('calculator_unitToConvert')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_TextInputElement, {
        modelValue: _ctx.inputValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (_ctx.$emit('update:inputValue', $event))),
        type: "number",
        isSmall: true,
        alignment: "right"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_DropdownElement, {
        modelValue: _ctx.selectedInputUnit,
        id: "inputUnit",
        options: _ctx.inputUnitOptions,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (_ctx.$emit('update:selectedInputUnit', $event))),
        isSmall: true
      }, null, 8, ["modelValue", "options"])
    ])
  ]))
}