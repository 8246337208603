/* eslint-disable object-curly-newline */
/* eslint-disable max-len */

export default class Calculate {
  static physicalUnits = {
    bar: { label: 'bar', unit: 'bar' },
    britishThermalUnit: { label: 'britishThermalUnit', unit: 'Btu' },
    centimeter: { label: 'centimeter', unit: 'cm' },
    cubicFeet: { label: 'cubicFeet', unit: 'ft<sup>3</sup>' },
    cubicInch: { label: 'cubicInch', unit: 'in<sup>3</sup>' },
    cubicMeter: { label: 'cubicMeter', unit: 'm<sup>3</sup>' },
    degreeCelsius: { label: 'degreeCelsius', unit: '°C' },
    degreeFahrenheit: { label: 'degreeFahrenheit', unit: '°F' },
    feet: { label: 'feet', unit: 'ft' },
    feetPerMinute: { label: 'feetPerMinute', unit: 'ft/min' },
    footPound: { label: 'footPound', unit: 'ft lb' },
    gallon: { label: 'gallon', unit: 'gal' },
    gram: { label: 'gram', unit: 'g' },
    horsepower: { label: 'horsepower', unit: 'HP' },
    inch: { label: 'inch', unit: 'in' },
    inchPerMinute: { label: 'inchPerMinute', unit: 'in/min' },
    inchPerSecond: { label: 'inchPerSecond', unit: 'in/s' },
    joule: { label: 'joule', unit: 'J' },
    kelvin: { label: 'kelvin', unit: 'K' },
    kilocalories: { label: 'kilocalories', unit: 'kcal' },
    kilogram: { label: 'kilogram', unit: 'kg' },
    kilopond: { label: 'kilopond', unit: 'kp' },
    kilowatt: { label: 'kilowatt', unit: 'kW' },
    kilowattHour: { label: 'kilowattHour', unit: 'kWh' },
    liter: { label: 'liter', unit: 'l' },
    meter: { label: 'meter', unit: 'm' },
    meterPerMinute: { label: 'meterPerMinute', unit: 'm/min' },
    meterPerSecond: { label: 'meterPerSecond', unit: 'm/s' },
    metricHorsepower: { label: 'metricHorsepower', unit: 'PS' },
    micrometer: { label: 'micrometer', unit: 'µm' },
    millimeter: { label: 'millimeter', unit: 'mm' },
    newton: { label: 'newton', unit: 'N' },
    newtonMeter: { label: 'newtonMeter', unit: 'Nm' },
    ounce: { label: 'ounce', unit: 'oz' },
    ounceForce: { label: 'ounceForce', unit: 'ozf' },
    ounceForceFeet: { label: 'ounceForceFeet', unit: 'ozl ft' },
    pascal: { label: 'pascal', unit: 'Pa' },
    pound: { label: 'pound', unit: 'lb' },
    poundForce: { label: 'poundForce', unit: 'lbf' },
    poundForceFeet: { label: 'poundForceFeet', unit: 'lbf ft' },
    poundForceInch: { label: 'poundForceInch', unit: 'lbf in' },
    poundForcePerSquareInch: { label: 'poundForcePerSquareInch', unit: 'psi' },
    squareCentimeter: { label: 'squareCentimeter', unit: 'cm<sup>2</sup>' },
    squareFeet: { label: 'squareFeet', unit: 'ft<sup>2</sup>' },
    squareInch: { label: 'squareInch', unit: 'in<sup>2</sup>' },
    squareMeter: { label: 'squareMeter', unit: 'm<sup>2</sup>' },
    squareMillimeter: { label: 'squareMillimeter', unit: 'mm<sup>2</sup>' },
    standardAtmosphere: { label: 'standardAtmosphere', unit: 'atm' },
    tonne: { label: 'tonne', unit: 't' },
    watt: { label: 'watt', unit: 'W' },
  }

  static physicalQuantities = {
    area: { label: 'area', units: [Calculate.physicalUnits.squareMeter, Calculate.physicalUnits.squareCentimeter, Calculate.physicalUnits.squareMillimeter, Calculate.physicalUnits.squareInch, Calculate.physicalUnits.squareFeet] },
    energy: { label: 'energy', units: [Calculate.physicalUnits.joule, Calculate.physicalUnits.kilowattHour, Calculate.physicalUnits.kilocalories, Calculate.physicalUnits.britishThermalUnit, Calculate.physicalUnits.footPound] },
    length: { label: 'length', units: [Calculate.physicalUnits.meter, Calculate.physicalUnits.centimeter, Calculate.physicalUnits.millimeter, Calculate.physicalUnits.micrometer, Calculate.physicalUnits.inch, Calculate.physicalUnits.feet] },
    performance: { label: 'performance', units: [Calculate.physicalUnits.watt, Calculate.physicalUnits.kilowatt, Calculate.physicalUnits.metricHorsepower, Calculate.physicalUnits.horsepower] },
    power: { label: 'power', units: [Calculate.physicalUnits.newton, Calculate.physicalUnits.kilopond, Calculate.physicalUnits.poundForce, Calculate.physicalUnits.ounceForce] },
    pressure: { label: 'pressure', units: [Calculate.physicalUnits.bar, Calculate.physicalUnits.pascal, Calculate.physicalUnits.standardAtmosphere, Calculate.physicalUnits.poundForcePerSquareInch] },
    temperature: { label: 'temperature', units: [Calculate.physicalUnits.degreeCelsius, Calculate.physicalUnits.degreeFahrenheit, Calculate.physicalUnits.kelvin] },
    torque: { label: 'torque', units: [Calculate.physicalUnits.newtonMeter, Calculate.physicalUnits.poundForceFeet, Calculate.physicalUnits.poundForceInch, Calculate.physicalUnits.ounceForceFeet] },
    velocity: { label: 'velocity', units: [Calculate.physicalUnits.meterPerSecond, Calculate.physicalUnits.meterPerMinute, Calculate.physicalUnits.inchPerSecond, Calculate.physicalUnits.inchPerMinute, Calculate.physicalUnits.feetPerMinute] },
    volume: { label: 'volume', units: [Calculate.physicalUnits.cubicMeter, Calculate.physicalUnits.liter, Calculate.physicalUnits.cubicInch, Calculate.physicalUnits.cubicFeet, Calculate.physicalUnits.gallon] },
    weight: { label: 'weight', units: [Calculate.physicalUnits.kilogram, Calculate.physicalUnits.gram, Calculate.physicalUnits.tonne, Calculate.physicalUnits.pound, Calculate.physicalUnits.ounce] },
  }

  static calculatorQuantities = {
    averageChipThickness: { label: 'averageChipThickness', unit: 'mm', symbol: 'h<sub>m</sub>', decimalPlaces: 3 },
    chipSheetLength: { label: 'chipSheetLength', unit: 'mm', symbol: 'l<sub>c</sub>', decimalPlaces: 1 },
    cutPath: { label: 'cutPath', unit: 'm', symbol: 'l<sub>c tot</sub>', decimalPlaces: 0 },
    cutterStepDepth: { label: 'cutterStepDepth', unit: 'µm', symbol: 't<sub>fz</sub>', decimalPlaces: 1 },
    cuttingDepthMill: { label: 'cuttingDepthMill', unit: 'mm', symbol: 'a<sub>e</sub>', decimalPlaces: 1 },
    cuttingDepthSaw: { label: 'cuttingDepthSaw', unit: 'mm', symbol: 'a<sub>e</sub>', decimalPlaces: 1 },
    cuttingSpeed: { label: 'cuttingSpeed', unit: 'm/s', symbol: 'v<sub>c</sub>', decimalPlaces: 1 },
    cuttingSpeedMetal: { label: 'cuttingSpeed', unit: 'm/min', symbol: 'v<sub>c</sub>', decimalPlaces: 1 },
    diameter: { label: 'diameter', unit: 'mm', symbol: 'D', decimalPlaces: 2 },
    feedPath: { label: 'feedPath', unit: 'm', symbol: 'l<sub>f</sub>', decimalPlaces: 0 },
    feedPerRotation: { label: 'feedPerRotation', unit: 'mm', symbol: 'f', decimalPlaces: 2 },
    feedRate: { label: 'feedRate', unit: 'm/min', symbol: 'v<sub>f</sub>', decimalPlaces: 0 },
    feedRateMetal: { label: 'feedRate', unit: 'mm/min', symbol: 'v<sub>f</sub>', decimalPlaces: 0 },
    maximumChipThickness: { label: 'maximumChipThickness', unit: 'mm', symbol: 'h<sub>max</sub>', decimalPlaces: 3 },
    protrusionSawBlade: { label: 'protrusionSawBlade', unit: 'mm', symbol: 'p<sub>r</sub>', decimalPlaces: 0 },
    rotationalSpeed: { label: 'rotationalSpeed', unit: 'U/min', symbol: 'n', decimalPlaces: 0 },
    toothCount: { label: 'toothCount', unit: 'Z', symbol: 'Z', decimalPlaces: 0 },
    toothFeed: { label: 'toothFeed', unit: 'mm', symbol: 'f<sub>z</sub>', decimalPlaces: 2 },
  }

  private static calibrateInput(physicalQuantity: { label: string; units: { label: string; unit: string; }[]; }, inputUnit: { label: string; unit: string; }, value: number) {
    switch (physicalQuantity.label) {
      case Calculate.physicalQuantities.length.label:
        switch (inputUnit.label) {
          case Calculate.physicalUnits.meter.label: return value;
          case Calculate.physicalUnits.centimeter.label: return value / 100.0;
          case Calculate.physicalUnits.millimeter.label: return value / 1000.0;
          case Calculate.physicalUnits.micrometer.label: return value / 1000000.0;
          case Calculate.physicalUnits.inch.label: return value / 39.3701;
          case Calculate.physicalUnits.feet.label: return value / 3.28084;
          default: return 0.0;
        }
      case Calculate.physicalQuantities.area.label:
        switch (inputUnit.label) {
          case Calculate.physicalUnits.squareMeter.label: return value;
          case Calculate.physicalUnits.squareCentimeter.label: return value / 10000.0;
          case Calculate.physicalUnits.squareMillimeter.label: return value / 1000000.0;
          case Calculate.physicalUnits.squareInch.label: return value / 1550.003;
          case Calculate.physicalUnits.squareFeet.label: return value / 10.76391;
          default: return 0.0;
        }
      case Calculate.physicalQuantities.volume.label:
        switch (inputUnit.label) {
          case Calculate.physicalUnits.cubicMeter.label: return value;
          case Calculate.physicalUnits.liter.label: return value / 1000.0;
          case Calculate.physicalUnits.cubicInch.label: return value / 61023.76;
          case Calculate.physicalUnits.cubicFeet.label: return value / 35.31468;
          case Calculate.physicalUnits.gallon.label: return value / 264.1722;
          default: return 0.0;
        }
      case Calculate.physicalQuantities.weight.label:
        switch (inputUnit.label) {
          case Calculate.physicalUnits.kilogram.label: return value;
          case Calculate.physicalUnits.gram.label: return value / 1000.0;
          case Calculate.physicalUnits.tonne.label: return value / 0.001;
          case Calculate.physicalUnits.pound.label: return value / 2.20462;
          case Calculate.physicalUnits.ounce.label: return value / 35.27394;
          default: return 0.0;
        }
      case Calculate.physicalQuantities.power.label:
        switch (inputUnit.label) {
          case Calculate.physicalUnits.newton.label: return value;
          case Calculate.physicalUnits.kilopond.label: return value / 0.10197;
          case Calculate.physicalUnits.poundForce.label: return value / 0.22481;
          case Calculate.physicalUnits.ounceForce.label: return value / 3.59694;
          default: return 0.0;
        }
      case Calculate.physicalQuantities.velocity.label:
        switch (inputUnit.label) {
          case Calculate.physicalUnits.meterPerSecond.label: return value;
          case Calculate.physicalUnits.meterPerMinute.label: return value / 60.0;
          case Calculate.physicalUnits.inchPerSecond.label: return value / 39.3701;
          case Calculate.physicalUnits.inchPerMinute.label: return value / (39.3701 * 60.0);
          case Calculate.physicalUnits.feetPerMinute.label: return value / 196.85;
          default: return 0.0;
        }
      case Calculate.physicalQuantities.energy.label:
        switch (inputUnit.label) {
          case Calculate.physicalUnits.joule.label: return value;
          case Calculate.physicalUnits.kilowattHour.label: return value / 0.0000002777778;
          case Calculate.physicalUnits.kilocalories.label: return value / 0.000238846;
          case Calculate.physicalUnits.britishThermalUnit.label: return value / 0.000947817;
          case Calculate.physicalUnits.footPound.label: return value / 0.7375822;
          default: return 0.0;
        }
      case Calculate.physicalQuantities.pressure.label:
        switch (inputUnit.label) {
          case Calculate.physicalUnits.bar.label: return value;
          case Calculate.physicalUnits.pascal.label: return value / 100000.0;
          case Calculate.physicalUnits.standardAtmosphere.label: return value / 0.986923;
          case Calculate.physicalUnits.poundForcePerSquareInch.label: return value / 14.50377;
          default: return 0.0;
        }
      case Calculate.physicalQuantities.performance.label:
        switch (inputUnit.label) {
          case Calculate.physicalUnits.watt.label: return value;
          case Calculate.physicalUnits.kilowatt.label: return value * 1000.0;
          case Calculate.physicalUnits.metricHorsepower.label: return value / 0.00135962;
          case Calculate.physicalUnits.horsepower.label: return value / 0.00134102;
          default: return 0.0;
        }
      case Calculate.physicalQuantities.temperature.label:
        switch (inputUnit.label) {
          case Calculate.physicalUnits.degreeCelsius.label: return value;
          case Calculate.physicalUnits.degreeFahrenheit.label: return ((value - 32.0) * 5.0) / 9.0;
          case Calculate.physicalUnits.kelvin.label: return value - 273.15;
          default: return 0.0;
        }
      case Calculate.physicalQuantities.torque.label:
        switch (inputUnit.label) {
          case Calculate.physicalUnits.newtonMeter.label: return value;
          case Calculate.physicalUnits.poundForceInch.label: return value / 8.85075;
          case Calculate.physicalUnits.ounceForceFeet.label: return value / 11.8009705;
          case Calculate.physicalUnits.poundForceFeet.label: return value / 0.7375621;
          default: return 0.0;
        }
      default: return 0.0;
    }
  }

  static conversion(physicalQuantity: { label: string; units: { label: string; unit: string; }[]; }, inputUnit: { label: string; unit: string; }, outputUnit: { label: string; unit: string; }, value: number) {
    const input = this.calibrateInput(physicalQuantity, inputUnit, value);
    if (Number.isNaN(input)) return 0.0;
    switch (physicalQuantity.label) {
      case Calculate.physicalQuantities.length.label:
        switch (outputUnit.label) {
          case Calculate.physicalUnits.meter.label: return input;
          case Calculate.physicalUnits.centimeter.label: return input * 100.0;
          case Calculate.physicalUnits.millimeter.label: return input * 1000.0;
          case Calculate.physicalUnits.micrometer.label: return input * 1000000.0;
          case Calculate.physicalUnits.inch.label: return input * 39.3701;
          case Calculate.physicalUnits.feet.label: return input * 3.28084;
          default: return 0.0;
        }
      case Calculate.physicalQuantities.area.label:
        switch (outputUnit.label) {
          case Calculate.physicalUnits.squareMeter.label: return input;
          case Calculate.physicalUnits.squareCentimeter.label: return input * 10000.0;
          case Calculate.physicalUnits.squareMillimeter.label: return input * 1000000.0;
          case Calculate.physicalUnits.squareInch.label: return input * 1550.003;
          case Calculate.physicalUnits.squareFeet.label: return input * 10.76391;
          default: return 0.0;
        }
      case Calculate.physicalQuantities.volume.label:
        switch (outputUnit.label) {
          case Calculate.physicalUnits.cubicMeter.label: return input;
          case Calculate.physicalUnits.liter.label: return input * 1000.0;
          case Calculate.physicalUnits.cubicInch.label: return input * 61023.76;
          case Calculate.physicalUnits.cubicFeet.label: return input * 35.31468;
          case Calculate.physicalUnits.gallon.label: return input * 264.1722;
          default: return 0.0;
        }
      case Calculate.physicalQuantities.weight.label:
        switch (outputUnit.label) {
          case Calculate.physicalUnits.kilogram.label: return input;
          case Calculate.physicalUnits.gram.label: return input * 1000.0;
          case Calculate.physicalUnits.tonne.label: return input * 0.001;
          case Calculate.physicalUnits.pound.label: return input * 2.20462;
          case Calculate.physicalUnits.ounce.label: return input * 35.27394;
          default: return 0.0;
        }
      case Calculate.physicalQuantities.power.label:
        switch (outputUnit.label) {
          case Calculate.physicalUnits.newton.label: return input;
          case Calculate.physicalUnits.kilopond.label: return input * 0.10197;
          case Calculate.physicalUnits.poundForce.label: return input * 0.22481;
          case Calculate.physicalUnits.ounceForce.label: return input * 3.59694;
          default: return 0.0;
        }
      case Calculate.physicalQuantities.velocity.label:
        switch (outputUnit.label) {
          case Calculate.physicalUnits.meterPerSecond.label: return input;
          case Calculate.physicalUnits.meterPerMinute.label: return input * 60.0;
          case Calculate.physicalUnits.inchPerSecond.label: return input * 39.3701;
          case Calculate.physicalUnits.inchPerMinute.label: return input * (39.3701 * 60.0);
          case Calculate.physicalUnits.feetPerMinute.label: return input * 196.85;
          default: return 0.0;
        }
      case Calculate.physicalQuantities.energy.label:
        switch (outputUnit.label) {
          case Calculate.physicalUnits.joule.label: return input;
          case Calculate.physicalUnits.kilowattHour.label: return input * 0.0000002777778;
          case Calculate.physicalUnits.kilocalories.label: return input * 0.000238846;
          case Calculate.physicalUnits.britishThermalUnit.label: return input * 0.000947817;
          case Calculate.physicalUnits.footPound.label: return input * 0.7375822;
          default: return 0.0;
        }
      case Calculate.physicalQuantities.pressure.label:
        switch (outputUnit.label) {
          case Calculate.physicalUnits.bar.label: return input;
          case Calculate.physicalUnits.pascal.label: return input * 100000.0;
          case Calculate.physicalUnits.standardAtmosphere.label: return input * 0.986923;
          case Calculate.physicalUnits.poundForcePerSquareInch.label: return input * 14.50377;
          default: return 0.0;
        }
      case Calculate.physicalQuantities.performance.label:
        switch (outputUnit.label) {
          case Calculate.physicalUnits.watt.label: return input;
          case Calculate.physicalUnits.kilowatt.label: return input / 1000.0;
          case Calculate.physicalUnits.metricHorsepower.label: return input * 0.00135962;
          case Calculate.physicalUnits.horsepower.label: return input * 0.00134102;
          default: return 0.0;
        }
      case Calculate.physicalQuantities.temperature.label:
        switch (outputUnit.label) {
          case Calculate.physicalUnits.degreeCelsius.label: return input;
          case Calculate.physicalUnits.degreeFahrenheit.label: return (input * 1.8) + 32.0;
          case Calculate.physicalUnits.kelvin.label: return input + 273.15;
          default: return 0.0;
        }
      case Calculate.physicalQuantities.torque.label:
        switch (outputUnit.label) {
          case Calculate.physicalUnits.newtonMeter.label: return input;
          case Calculate.physicalUnits.poundForceInch.label: return input * 8.85075;
          case Calculate.physicalUnits.ounceForceFeet.label: return input * 11.8009705;
          case Calculate.physicalUnits.poundForceFeet.label: return value * 0.7375621;
          default: return 0.0;
        }
      default: return 0.0;
    }
  }

  static rotationalSpeedWood(diameter: number, cuttingSpeed: number) { // 0
    if (Number.isNaN(diameter) || Number.isNaN(cuttingSpeed)) return null;
    const result = (cuttingSpeed * 60000.0) / (diameter * Math.PI);
    if (Number.isNaN(result) || result === Infinity) return null;
    return Math.round(result);
  }

  static rotationalSpeedMetal(diameter: number, cuttingSpeed: number) { // 0
    if (Number.isNaN(diameter) || Number.isNaN(cuttingSpeed)) return null;
    const result = (cuttingSpeed * 1000.0) / (diameter * Math.PI);
    if (Number.isNaN(result) || result === Infinity) return null;
    return Math.round(result);
  }

  static feedRateWood(toothFeed: number, rotationalSpeed: number, toothCount: number) { // 0
    if (Number.isNaN(toothFeed) || Number.isNaN(rotationalSpeed) || Number.isNaN(toothCount)) return null;
    const result = (toothFeed * rotationalSpeed * toothCount) / 1000.0;
    if (Number.isNaN(result) || result === Infinity) return null;
    return Math.round(result);
  }

  static feedRateMetal(toothFeed: number, rotationalSpeed: number, toothCount: number) { // 0
    if (Number.isNaN(toothFeed) || Number.isNaN(rotationalSpeed) || Number.isNaN(toothCount)) return null;
    const result = toothFeed * rotationalSpeed * toothCount;
    if (Number.isNaN(result) || result === Infinity) return null;
    return Math.round(result);
  }

  static cuttingSpeedWood(diameter: number, rotationalSpeed: number) { // 1
    if (Number.isNaN(diameter) || Number.isNaN(rotationalSpeed)) return null;
    const result = (diameter * rotationalSpeed * Math.PI) / 60000.0;
    if (Number.isNaN(result) || result === Infinity) return null;
    return Math.round(result * 10.0) / 10.0;
  }

  static cuttingSpeedMetal(diameter: number, rotationalSpeed: number) { // 1
    if (Number.isNaN(diameter) || Number.isNaN(rotationalSpeed)) return null;
    const result = (diameter * rotationalSpeed * Math.PI) / 1000.0;
    if (Number.isNaN(result) || result === Infinity) return null;
    return Math.round(result * 10.0) / 10.0;
  }

  static toothFeedWood(feedRate: number, rotationalSpeed: number, toothCount: number) { // 2
    if (Number.isNaN(feedRate) || Number.isNaN(rotationalSpeed) || Number.isNaN(toothCount)) return null;
    const result = (feedRate * 1000.0) / (rotationalSpeed * toothCount);
    if (Number.isNaN(result) || result === Infinity) return null;
    return Math.round((Math.round(result * 1000000.0) / 1000000.0) * 100.0) / 100.0;
  }

  static toothFeedMetal(feedRate: number, rotationalSpeed: number, toothCount: number) { // 2
    if (Number.isNaN(feedRate) || Number.isNaN(rotationalSpeed) || Number.isNaN(toothCount)) return null;
    const result = feedRate / (rotationalSpeed * toothCount);
    if (Number.isNaN(result) || result === Infinity) return null;
    return Math.round((Math.round(result * 1000000.0) / 1000000.0) * 100.0) / 100.0;
  }

  static feedPerRotation(toothCount: number, toothFeed: number) { // 2
    if (Number.isNaN(toothCount) || Number.isNaN(toothFeed)) return null;
    const result = toothCount * toothFeed;
    if (Number.isNaN(result) || result === Infinity) return null;
    return (Math.round(result * 100.0) / 100.0);
  }

  static cutterStepDepth(diameter: number, toothFeed: number) { // 1
    if (Number.isNaN(diameter) || Number.isNaN(toothFeed)) return null;
    const result = ((toothFeed * toothFeed) / (4 * diameter)) * 1000.0;
    if (Number.isNaN(result) || result === Infinity) return null;
    return (Math.round(result * 10.0) / 10.0);
  }

  static chipSheetLengthMill(diameter: number, cuttingDepth: number) { // 1
    if (Number.isNaN(diameter) || Number.isNaN(cuttingDepth)) return null;
    const result = (diameter * Math.acos(((diameter / 2.0) - cuttingDepth) / (diameter / 2.0))) / 2.0;
    if (Number.isNaN(result) || result === Infinity) return null;
    return Math.round(result * 10.0) / 10.0;
  }

  static chipSheetLengthSaw(diameter: number, cuttingDepth: number, protrusionSawBlade: number) { // 1
    if (Number.isNaN(diameter) || Number.isNaN(cuttingDepth) || Number.isNaN(protrusionSawBlade)) return null;
    const result = (diameter * (Math.acos(((diameter / 2.0) - (protrusionSawBlade + cuttingDepth)) / (diameter / 2.0)) - Math.acos(((diameter / 2.0) - protrusionSawBlade) / (diameter / 2.0)))) / 2.0;
    if (Number.isNaN(result) || result === Infinity) return null;
    return Math.round(result * 10.0) / 10.0;
  }

  static averageChipThicknessMill(toothFeed: number, cuttingDepth: number, chipSheetLength: number) { // 3
    if (Number.isNaN(toothFeed) || Number.isNaN(cuttingDepth) || Number.isNaN(chipSheetLength)) return null;
    const result = (toothFeed * cuttingDepth) / chipSheetLength;
    if (Number.isNaN(result) || result === Infinity) return null;
    return Math.round(result * 1000.0) / 1000.0;
  }

  static averageChipThicknessSaw(diameter: number, cuttingDepth: number, protrusionSawBlade: number, toothFeed: number) { // 3
    if (Number.isNaN(diameter) || Number.isNaN(cuttingDepth) || Number.isNaN(protrusionSawBlade) || Number.isNaN(toothFeed)) return null;
    const result = (toothFeed * cuttingDepth) / ((diameter * (Math.acos(((diameter / 2.0) - (protrusionSawBlade + cuttingDepth)) / (diameter / 2.0)) - Math.acos(((diameter / 2.0) - protrusionSawBlade) / (diameter / 2.0)))) / 2.0);
    if (Number.isNaN(result) || result === Infinity) return null;
    return Math.round(result * 1000.0) / 1000.0;
  }

  static maximumChipThicknessMill(toothFeed: number, diameter: number, cuttingDepth: number) { // 3
    if (Number.isNaN(toothFeed) || Number.isNaN(diameter) || Number.isNaN(cuttingDepth)) return null;
    const result = toothFeed * Math.sin(Math.acos(((diameter / 2.0) - cuttingDepth) / (diameter / 2.0)));
    if (Number.isNaN(result) || result === Infinity) return null;
    return Math.round(result * 1000.0) / 1000.0;
  }

  static maximumChipThicknessSaw(diameter: number, cuttingDepth: number, protrusionSawBlade: number, toothFeed: number) { // 3
    if (Number.isNaN(diameter) || Number.isNaN(cuttingDepth) || Number.isNaN(protrusionSawBlade) || Number.isNaN(toothFeed)) return null;
    const result = Math.sin(Math.acos(((diameter / 2.0) - (protrusionSawBlade + cuttingDepth)) / (diameter / 2.0))) * toothFeed;
    if (Number.isNaN(result) || result === Infinity) return null;
    return Math.round(result * 1000.0) / 1000.0;
  }

  static cutPathMillWood(feedPath: number, feedRate: number, rotationalSpeed: number, diameter: number, cuttingDepth: number) { // 0
    if (Number.isNaN(feedPath) || Number.isNaN(feedRate) || Number.isNaN(rotationalSpeed) || Number.isNaN(diameter) || Number.isNaN(cuttingDepth)) return null;
    const result = (((feedPath / (1000.0 * feedRate)) * rotationalSpeed) * Math.PI * (diameter) * Math.acos(1.0 - (2.0 * cuttingDepth) / diameter)) / (2.0 * Math.PI);
    if (Number.isNaN(result) || result === Infinity) return null;
    return Math.round(result);
  }

  static cutPathMillMetal(feedPath: number, feedRate: number, rotationalSpeed: number, diameter: number, cuttingDepth: number) { // 0
    if (Number.isNaN(feedPath) || Number.isNaN(feedRate) || Number.isNaN(rotationalSpeed) || Number.isNaN(diameter) || Number.isNaN(cuttingDepth)) return null;
    const result = (((feedPath / (feedRate)) * rotationalSpeed) * Math.PI * (diameter) * Math.acos(1.0 - (2.0 * cuttingDepth) / diameter)) / (2.0 * Math.PI);
    if (Number.isNaN(result) || result === Infinity) return null;
    return Math.round(result);
  }

  static cutPathSaw(feedPath: number, diameter: number, cuttingDepth: number, protrusionSawBlade: number, toothFeed: number, toothCount: number) { // 0
    if (Number.isNaN(feedPath) || Number.isNaN(diameter) || Number.isNaN(cuttingDepth) || Number.isNaN(protrusionSawBlade) || Number.isNaN(toothFeed) || Number.isNaN(toothCount)) return null;
    const k6 = Math.acos(((diameter / 2.0) - protrusionSawBlade) / (diameter / 2.0));
    const k7 = Math.acos(((diameter / 2.0) - (protrusionSawBlade + cuttingDepth)) / (diameter / 2.0));
    const test = k7 - k6;
    const result = (feedPath * (diameter / 2.0) * test) / (toothFeed * toothCount);
    if (Number.isNaN(result) || result === Infinity) return null;
    return Math.round(result);
  }
}
