
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
  props: {
    icon: { type: String, default: null },
    label: String,
    isActive: Boolean,
    disabled: Boolean,
    uppercase: Boolean,
    isButton: Boolean,
    isBottom: Boolean,
  },
})
export default class TabMenuItem extends Vue {
}
