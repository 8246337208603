import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "product-details__content"
}
const _hoisted_2 = { class: "product-details__columns" }
const _hoisted_3 = {
  key: 0,
  class: "product-details__column"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "product-details__spare-title font-size-20 color-black" }
const _hoisted_6 = {
  key: 0,
  class: "product-details__spare-list"
}
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = {
  key: 1,
  class: "product-details__column"
}
const _hoisted_11 = {
  key: 2,
  class: "product-details__column"
}

export function render(_ctx, _cache) {
  const _component_SvgElement = _resolveComponent("SvgElement")
  const _component_ProductSpareItem = _resolveComponent("ProductSpareItem")
  const _component_ProductSpareImages = _resolveComponent("ProductSpareImages")
  const _component_ProductMediaModal = _resolveComponent("ProductMediaModal")
  const _component_NoImage = _resolveComponent("NoImage")

  return (_ctx.product.spares)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.product.spares.items)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.spareListToggles, (spareListToggle, index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["product-details__spare-header cursor-pointer", { 'is-disabled' : _ctx.product.spares.items.filter((item) => item.type === spareListToggle.type).length === 0 }]),
                      onClick: $event => (spareListToggle.expanded = !spareListToggle.expanded)
                    }, [
                      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t(spareListToggle.label)), 1),
                      _createElementVNode("div", {
                        class: _normalizeClass(["product-details__spare-number font-size-16 font-weight-medium", _ctx.product.spares.items.filter((item) => item.type === spareListToggle.type).length > 0 ? 'color-primary' : 'color-tertiary-2'])
                      }, _toDisplayString(_ctx.product.spares.items.filter((item) => item.type === spareListToggle.type).length), 3),
                      _createVNode(_component_SvgElement, {
                        src: "/icons/chevron_right.svg",
                        class: _normalizeClass(["product-details__spare-arrow", [{ 'is-expanded' : spareListToggle.expanded }, _ctx.product.spares.items.filter((item) => item.type === spareListToggle.type).length > 0 ? 'fill-primary' : 'fill-tertiary-3']])
                      }, null, 8, ["class"])
                    ], 10, _hoisted_4),
                    _createVNode(_Transition, { mode: "out-in" }, {
                      default: _withCtx(() => [
                        (spareListToggle.expanded)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.product.spares.items.filter((item) => item.type === spareListToggle.type), (spare) => {
                                return (_openBlock(), _createBlock(_component_ProductSpareItem, { spare: spare }, null, 8, ["spare"]))
                              }), 256)),
                              (spareListToggle.type == _ctx.spareType.Blade && _ctx.product.spares.textBlade)
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: "font-size-14 color-tertiary-2",
                                    innerHTML: _ctx.product.spares.textBlade
                                  }, null, 8, _hoisted_7))
                                : _createCommentVNode("", true),
                              (spareListToggle.type == _ctx.spareType.Saw && _ctx.product.spares.textSaw)
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 1,
                                    class: "font-size-14 color-tertiary-2",
                                    innerHTML: _ctx.product.spares.textSaw
                                  }, null, 8, _hoisted_8))
                                : _createCommentVNode("", true),
                              (spareListToggle.type == _ctx.spareType.General && _ctx.product.spares.textGeneral)
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 2,
                                    class: "font-size-14 color-tertiary-2",
                                    innerHTML: _ctx.product.spares.textGeneral
                                  }, null, 8, _hoisted_9))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ], 64))
                }), 256))
              ]))
            : _createCommentVNode("", true),
          (_ctx.product.spares.explodedViewMedia)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_ProductSpareImages, {
                  media: _ctx.product.spares.explodedViewMedia,
                  onSelectMedium: _cache[0] || (_cache[0] = $event => (_ctx.openMediaModal(_ctx.product.spares.explodedViewMedia, $event)))
                }, null, 8, ["media"]),
                _createVNode(_component_ProductMediaModal, {
                  ref: "mediaModal",
                  media: _ctx.product.spares.explodedViewMedia
                }, null, 8, ["media"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createVNode(_component_NoImage)
              ]))
        ])
      ]))
    : _createCommentVNode("", true)
}