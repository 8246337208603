
import { Options, Vue } from 'vue-class-component';
import ProductTable from '../ProductTable/ProductTable.vue';
import ProductMatrix from '../ProductMatrix/ProductMatrix.vue';

@Options({
  components: {
    ProductTable,
    ProductMatrix,
  },
  props: {
    product: Object,
  },
})
export default class ProductToolApplicability extends Vue {
}
