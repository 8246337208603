import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, renderSlot as _renderSlot, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-input-element__label"
}
const _hoisted_2 = ["for", "innerHTML"]
const _hoisted_3 = {
  key: 0,
  class: "font-size-14 font-weight-bold color-primary"
}
const _hoisted_4 = ["id", "placeholder", "disabled", "readonly", "type", "value"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 1,
  class: "text-input-element__error"
}
const _hoisted_7 = { class: "font-size-12 color-red" }

export function render(_ctx, _cache) {
  const _component_SvgElement = _resolveComponent("SvgElement")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["text-input-element", [{ 'is-small' : _ctx.isSmall }, { 'read-only' : _ctx.readOnly }, { 'highlighted' : _ctx.highlighted }, { 'has-error-label' : _ctx.error }, { 'has-error' : _ctx.errorLabel !== '' }]])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("label", {
            for: _ctx.id,
            class: "font-size-14 font-weight-bold color-tertiary-1",
            innerHTML: _ctx.label
          }, null, 8, _hoisted_2),
          (_ctx.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, " *"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["text-input-element__content", { 'disabled' : _ctx.disabled }])
    }, [
      (_ctx.prefixedIcon)
        ? (_openBlock(), _createBlock(_component_SvgElement, {
            key: 0,
            src: _ctx.prefixedIcon,
            class: "text-input-element__icon fill-primary text-input-element__icon"
          }, null, 8, ["src"]))
        : _createCommentVNode("", true),
      (!_ctx.readOnly)
        ? (_openBlock(), _createElementBlock("input", {
            key: 1,
            class: _normalizeClass(["text-input-element__text", [`text-align-${_ctx.alignment}`, {'has-prefix': _ctx.prefixedIcon}]]),
            id: _ctx.id,
            placeholder: _ctx.placeholder,
            disabled: _ctx.disabled,
            readonly: _ctx.readOnly,
            type: _ctx.type,
            value: _ctx.modelValue,
            onInput: _cache[0] || (_cache[0] = $event => (_ctx.$emit('update:modelValue', $event.target.value))),
            onKeyup: _cache[1] || (_cache[1] = $event => (_ctx.$emit('keyup', $event)))
          }, null, 42, _hoisted_4))
        : _createCommentVNode("", true),
      (_ctx.readOnly)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(["text-input-element__text", [`text-align-${_ctx.alignment}`, {'has-prefix': _ctx.prefixedIcon}]]),
            innerHTML: 
        (_ctx.modelValue !== '' && typeof Number(_ctx.modelValue) === 'number')
          ? _ctx.decimalPlaces !== undefined && _ctx.decimalPlaces !== null
            ? _ctx.$n(Number(_ctx.modelValue), 'decimal', { minimumFractionDigits: _ctx.decimalPlaces, maximumFractionDigits: _ctx.decimalPlaces })
            : _ctx.$n(Number(_ctx.modelValue), 'decimal')
          : _ctx.modelValue
      
          }, null, 10, _hoisted_5))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["text-input-element__slot", { 'is-empty' : !_ctx.$slots.default}])
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2)
    ], 2),
    (_ctx.errorLabel !== '')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.errorLabel), 1)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}