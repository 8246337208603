
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
  props: {
    media: Object,
  },
})
export default class ProductSpareImages extends Vue {
}
