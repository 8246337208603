import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx, _cache) {
  const _component_TabMenuItem = _resolveComponent("TabMenuItem")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tab-menu", { 'text-transform-uppercase' : _ctx.uppercase }])
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createBlock(_component_TabMenuItem, {
        icon: item.icon,
        label: _ctx.isLocalised ? _ctx.$t(`${_ctx.localisedPath}${item.name}`) : item.name,
        disabled: item.disabled && item.disabled === true,
        isActive: _ctx.selectedTab === index,
        onClick: $event => (_ctx.$emit('selectTab', index))
      }, null, 8, ["icon", "label", "disabled", "isActive", "onClick"]))
    }), 256))
  ], 2))
}