import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sidebar-footer" }
const _hoisted_2 = { class: "sidebar-footer__title font-size-10 color-secondary-2" }
const _hoisted_3 = { class: "sidebar-footer__language-code font-size-16 color-white text-transform-uppercase" }
const _hoisted_4 = { class: "sidebar-footer__menu" }

export function render(_ctx, _cache) {
  const _component_SvgElement = _resolveComponent("SvgElement")
  const _component_ContextMenu = _resolveComponent("ContextMenu")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('navigation_language')), 1),
    _createElementVNode("div", {
      class: "sidebar-footer__language cursor-pointer",
      onClick: _cache[0] || (_cache[0] = $event => (_ctx.toggleMenu()))
    }, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.currentLanguage.tag), 1),
      _createVNode(_component_SvgElement, {
        src: "/icons/chevron_right.svg",
        class: "sidebar-footer__icon fill-primary"
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ContextMenu, {
        open: _ctx.menuOpen,
        options: _ctx.menuOptions,
        activeOption: _ctx.currentLanguage.label,
        onOptionSelected: _cache[1] || (_cache[1] = $event => (_ctx.selectOption($event))),
        onCloseMenu: _cache[2] || (_cache[2] = $event => (_ctx.menuOpen = false)),
        alignment: "bottom-left",
        minWidth: "150px"
      }, null, 8, ["open", "options", "activeOption"])
    ])
  ]))
}