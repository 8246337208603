import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "calculator-head-row" }
const _hoisted_2 = { class: "calculator-head-row__title font-size-14 font-weight-bold color-black" }
const _hoisted_3 = { class: "calculator-head-row__headlines" }
const _hoisted_4 = { class: "calculator-head-row__symbol font-size-10 color-tertiary-2" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "calculator-head-row__tool font-size-10 color-tertiary-2 text-align-center" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 0,
  class: "calculator-head-row__tool font-size-10 color-tertiary-2 text-align-center"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.headline), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.symbol)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              innerHTML: _ctx.symbol
            }, null, 8, _hoisted_5))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('calculator_tool')), 1),
        (_ctx.compare)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, " 1"))
          : _createCommentVNode("", true)
      ]),
      (_ctx.compare)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t('calculator_tool')) + " 2", 1))
        : _createCommentVNode("", true)
    ])
  ]))
}