
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
  props: {
    open: Boolean,
    options: { type: Object, default: [] },
    activeOption: { type: String },
    alignment: { type: String, default: 'top-right' },
    minWidth: { type: String },
    isLocalised: { type: Boolean, default: false },
    localisedPath: { type: String, default: '' },
  },
})
export default class ContextMenu extends Vue {
  onClickOutside() {
    this.$emit('closeMenu');
  }

  selectOption(index: number) {
    this.$emit('optionSelected', index);
    this.$emit('closeMenu');
  }
}
