import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "support-view" }
const _hoisted_2 = {
  key: 0,
  class: "support-view__form"
}
const _hoisted_3 = { class: "support-view__title" }
const _hoisted_4 = { class: "font-size-28 color-black" }
const _hoisted_5 = { class: "support-view__content" }

export function render(_ctx, _cache) {
  const _component_SvgElement = _resolveComponent("SvgElement")
  const _component_SupportBranch = _resolveComponent("SupportBranch")
  const _component_SupportForm = _resolveComponent("SupportForm")
  const _component_LegalInformation = _resolveComponent("LegalInformation")
  const _component_SupportSuccess = _resolveComponent("SupportSuccess")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { mode: "out-in" }, {
      default: _withCtx(() => [
        (!_ctx.sentSuccessfully)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_SvgElement, {
                  src: "/icons/support.svg",
                  class: "support-view__icon fill-primary"
                }),
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('support_title')), 1)
              ]),
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(), _createBlock(_component_SupportBranch, {
                  key: _ctx.customerData.branch,
                  customerData: _ctx.customerData,
                  onOptionSelected: _cache[0] || (_cache[0] = $event => (_ctx.onBranchChange($event)))
                }, null, 8, ["customerData"])),
                _createVNode(_component_SupportForm, {
                  customerData: _ctx.customerData,
                  onSentSuccessfully: _cache[1] || (_cache[1] = $event => (_ctx.onSendSuccessfully($event))),
                  onSaveCustomerData: _cache[2] || (_cache[2] = $event => (_ctx.saveCustomerData($event)))
                }, null, 8, ["customerData"])
              ]),
              _createVNode(_component_LegalInformation, { class: "support-view__legal-information" })
            ]))
          : (_openBlock(), _createBlock(_component_SupportSuccess, {
              key: 1,
              onNewRequest: _cache[3] || (_cache[3] = $event => (_ctx.sentSuccessfully = false))
            }))
      ]),
      _: 1
    })
  ]))
}