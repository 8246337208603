
import DropdownElement from '@/elements/DropdownElement/DropdownElement.vue';
import TextInputElement from '@/elements/TextInputElement/TextInputElement.vue';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    DropdownElement,
    TextInputElement,
  },
  props: {
    inputValue: String,
    selectedInputUnit: String,
    inputUnitOptions: Object,
  },
})
export default class CalculatorUnitInput extends Vue {
}
