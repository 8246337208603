import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "support-branch" }
const _hoisted_2 = {
  key: 0,
  class: "support-branch__columns align-center"
}
const _hoisted_3 = { class: "support-branch__column" }
const _hoisted_4 = { class: "font-size-20 color-black" }
const _hoisted_5 = { class: "support-branch__selected" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "font-size-14 color-primary" }
const _hoisted_8 = { class: "support-branch__column" }
const _hoisted_9 = {
  key: 0,
  class: "support-branch__columns"
}
const _hoisted_10 = { class: "support-branch__column" }
const _hoisted_11 = { class: "support-branch__contact" }
const _hoisted_12 = { class: "support-branch__info" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "support-branch__contact" }
const _hoisted_15 = { class: "support-branch__info" }
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = { class: "support-branch__info" }
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { class: "support-branch__column" }
const _hoisted_20 = { class: "support-branch__contact" }
const _hoisted_21 = { class: "support-branch__info" }
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = {
  key: 1,
  class: "support-branch_empty-state"
}
const _hoisted_25 = ["innerHTML"]

export function render(_ctx, _cache) {
  const _component_DropdownElement = _resolveComponent("DropdownElement")
  const _component_SvgElement = _resolveComponent("SvgElement")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { mode: "out-in" }, {
      default: _withCtx(() => [
        (_ctx.branches && _ctx.loaded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (!_ctx.selectedBranch)
                  ? (_openBlock(), _createBlock(_component_DropdownElement, {
                      key: 0,
                      modelValueObject: _ctx.selectedBranch,
                      id: "subject",
                      label: _ctx.$t('support_branch'),
                      placeholder: _ctx.$t('support_inputPlaceholderSelectBranch'),
                      required: true,
                      options: _ctx.branches,
                      isObject: true,
                      optionPath: "location",
                      "onUpdate:modelValueObject": _cache[0] || (_cache[0] = $event => (_ctx.$emit('optionSelected', $event)))
                    }, null, 8, ["modelValueObject", "label", "placeholder", "options"]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('support_contactDetails')), 1),
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", {
                          class: "font-size-18 color-black",
                          innerHTML: _ctx.selectedBranch.name
                        }, null, 8, _hoisted_6),
                        _createElementVNode("div", {
                          class: "support-branch__edit cursor-pointer",
                          onClick: _cache[1] || (_cache[1] = $event => (_ctx.$emit('optionSelected', null)))
                        }, [
                          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('support_branchEdit')), 1),
                          _createVNode(_component_SvgElement, {
                            src: "/icons/edit.svg",
                            class: "fill-primary support-branch__edit-icon"
                          })
                        ])
                      ])
                    ], 64))
              ]),
              _createElementVNode("div", _hoisted_8, [
                (_ctx.selectedBranch)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _createVNode(_component_SvgElement, {
                            src: "/icons/telephone.svg",
                            class: _normalizeClass(["support-branch__icon", _ctx.selectedBranch.phone === '' ? 'fill-tertiary-2' : 'fill-primary'])
                          }, null, 8, ["class"]),
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("div", {
                              class: _normalizeClass(["font-size-14", _ctx.selectedBranch.phone === '' ? 'color-tertiary-2' : 'color-black']),
                              innerHTML: _ctx.selectedBranch.phone === '' ? '-' : _ctx.selectedBranch.phone
                            }, null, 10, _hoisted_13)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_14, [
                          _createVNode(_component_SvgElement, {
                            src: "/icons/fax.svg",
                            class: _normalizeClass(["support-branch__icon", _ctx.selectedBranch.fax === '' ? 'fill-tertiary-2' : 'fill-primary'])
                          }, null, 8, ["class"]),
                          _createElementVNode("div", _hoisted_15, [
                            _createElementVNode("div", {
                              class: _normalizeClass(["font-size-14", _ctx.selectedBranch.fax === '' ? 'color-tertiary-2' : 'color-black']),
                              innerHTML: _ctx.selectedBranch.fax === '' ? '-' : _ctx.selectedBranch.fax
                            }, null, 10, _hoisted_16)
                          ])
                        ]),
                        _createElementVNode("div", {
                          class: "support-branch__contact cursor-pointer is-clickable",
                          onClick: _cache[2] || (_cache[2] = $event => (_ctx.openWebsite(_ctx.selectedBranch.website)))
                        }, [
                          _createVNode(_component_SvgElement, {
                            src: "/icons/web.svg",
                            class: _normalizeClass(["support-branch__icon", _ctx.selectedBranch.website === '' ? 'fill-tertiary-2' : 'fill-primary'])
                          }, null, 8, ["class"]),
                          _createElementVNode("div", _hoisted_17, [
                            _createElementVNode("div", {
                              class: _normalizeClass(["font-size-14", _ctx.selectedBranch.website === '' ? 'color-tertiary-2' : 'color-black']),
                              innerHTML: _ctx.selectedBranch.website === '' ? '-' : _ctx.selectedBranch.website
                            }, null, 10, _hoisted_18)
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("div", _hoisted_20, [
                          _createVNode(_component_SvgElement, {
                            src: "/icons/map.svg",
                            class: _normalizeClass(["support-branch__icon", _ctx.selectedBranch.address === '' ? 'fill-tertiary-2' : 'fill-primary'])
                          }, null, 8, ["class"]),
                          _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("div", {
                              class: _normalizeClass(["font-size-14", _ctx.selectedBranch.address === '' ? 'color-tertiary-2' : 'color-black']),
                              innerHTML: _ctx.selectedBranch.address === '' ? '-' : _ctx.selectedBranch.address
                            }, null, 10, _hoisted_22),
                            _createElementVNode("div", {
                              class: _normalizeClass(["font-size-14", _ctx.selectedBranch.country === '' ? 'color-tertiary-2' : 'color-black']),
                              innerHTML: _ctx.selectedBranch.country === '' ? '-' : _ctx.selectedBranch.country
                            }, null, 10, _hoisted_23)
                          ])
                        ])
                      ])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_24, [
                      _createElementVNode("div", {
                        class: "font-size-16 color-tertiary-1",
                        innerHTML: _ctx.$t('support_noBranchSelected')
                      }, null, 8, _hoisted_25)
                    ]))
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}