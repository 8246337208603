
import { Options, Vue } from 'vue-class-component';
import HistoryItem from '../HistoryItem/HistoryItem.vue';

@Options({
  components: {
    HistoryItem,
  },
  props: {
    historyProducts: Object,
  },
})
export default class History extends Vue {
}
