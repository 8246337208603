import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "support-form__title" }
const _hoisted_2 = { class: "font-size-24" }
const _hoisted_3 = { class: "text-transform-uppercase" }
const _hoisted_4 = { class: "support-form__content" }
const _hoisted_5 = { class: "support-form__line" }
const _hoisted_6 = { class: "support-form__footer" }
const _hoisted_7 = { class: "support-form__button" }
const _hoisted_8 = {
  key: 0,
  class: "support-form__error-icon"
}
const _hoisted_9 = { class: "support-form__button" }
const _hoisted_10 = {
  key: 1,
  class: "search__error font-size-12 color-red"
}
const _hoisted_11 = { class: "font-size-14 color-tertiary-2" }
const _hoisted_12 = { class: "font-size-14 color-tertiary-2" }

export function render(_ctx, _cache) {
  const _component_TextInputElement = _resolveComponent("TextInputElement")
  const _component_DropdownElement = _resolveComponent("DropdownElement")
  const _component_TextAreaElement = _resolveComponent("TextAreaElement")
  const _component_CheckboxElement = _resolveComponent("CheckboxElement")
  const _component_ReCaptcha = _resolveComponent("ReCaptcha")
  const _component_SvgElement = _resolveComponent("SvgElement")
  const _component_ButtonElement = _resolveComponent("ButtonElement")
  const _component_LoaderElement = _resolveComponent("LoaderElement")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["support-form", { 'is-disabled' : !_ctx.customerData.branch }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('support_xpress')), 1),
        _createTextVNode(),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('support_request')), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_TextInputElement, {
          id: "customerId",
          modelValue: _ctx.customerId,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = $event => ((_ctx.customerId) = $event)),
            _cache[1] || (_cache[1] = $event => (_ctx.customerIdErrorMessage = ''))
          ],
          label: _ctx.$t('support_customerNumber'),
          placeholder: _ctx.$t('support_inputPlaceholderTypeHere'),
          required: false,
          error: true,
          errorLabel: _ctx.customerIdErrorMessage === '' ? '' : _ctx.$t(_ctx.customerIdErrorMessage)
        }, null, 8, ["modelValue", "label", "placeholder", "errorLabel"]),
        _createVNode(_component_TextInputElement, {
          id: "email",
          modelValue: _ctx.email,
          "onUpdate:modelValue": [
            _cache[2] || (_cache[2] = $event => ((_ctx.email) = $event)),
            _cache[3] || (_cache[3] = $event => (_ctx.emailErrorMessage = ''))
          ],
          label: _ctx.$t('support_eMail'),
          placeholder: _ctx.$t('support_inputPlaceholderTypeHere'),
          required: true,
          error: true,
          errorLabel: _ctx.emailErrorMessage === '' ? '' : _ctx.$t(_ctx.emailErrorMessage)
        }, null, 8, ["modelValue", "label", "placeholder", "errorLabel"]),
        _createVNode(_component_TextInputElement, {
          id: "phoneNumber",
          modelValue: _ctx.phoneNumber,
          "onUpdate:modelValue": [
            _cache[4] || (_cache[4] = $event => ((_ctx.phoneNumber) = $event)),
            _cache[5] || (_cache[5] = $event => (_ctx.phoneNumberErrorMessage = ''))
          ],
          label: _ctx.$t('support_phone'),
          placeholder: _ctx.$t('support_inputPlaceholderTypeHere'),
          required: true,
          error: true,
          errorLabel: _ctx.phoneNumberErrorMessage === '' ? '' : _ctx.$t(_ctx.phoneNumberErrorMessage)
        }, null, 8, ["modelValue", "label", "placeholder", "errorLabel"])
      ]),
      _createVNode(_component_DropdownElement, {
        id: "subject",
        modelValue: _ctx.subject,
        "onUpdate:modelValue": [
          _cache[6] || (_cache[6] = $event => ((_ctx.subject) = $event)),
          _cache[7] || (_cache[7] = $event => (_ctx.subjectErrorMessage = ''))
        ],
        label: _ctx.$t('support_subject'),
        placeholder: _ctx.$t('support_inputPlaceholderSelect'),
        required: true,
        isLocalised: "",
        options: _ctx.subjectOptions,
        error: true,
        errorLabel: _ctx.subjectErrorMessage === '' ? '' : _ctx.$t(_ctx.subjectErrorMessage)
      }, null, 8, ["modelValue", "label", "placeholder", "options", "errorLabel"]),
      _createVNode(_component_TextAreaElement, {
        id: "message",
        modelValue: _ctx.message,
        "onUpdate:modelValue": [
          _cache[8] || (_cache[8] = $event => ((_ctx.message) = $event)),
          _cache[9] || (_cache[9] = $event => (_ctx.messageErrorMessage = ''))
        ],
        label: _ctx.$t('support_message'),
        placeholder: _ctx.$t('support_inputPlaceholderTypeHere'),
        required: true,
        error: true,
        errorLabel: _ctx.messageErrorMessage === '' ? '' : _ctx.$t(_ctx.messageErrorMessage)
      }, null, 8, ["modelValue", "label", "placeholder", "errorLabel"]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_CheckboxElement, {
          id: "privacy",
          modelValue: _ctx.privacyPolicyAccepted,
          "onUpdate:modelValue": [
            _cache[10] || (_cache[10] = $event => ((_ctx.privacyPolicyAccepted) = $event)),
            _cache[11] || (_cache[11] = $event => (_ctx.errorPrivacyPolicy = false))
          ],
          label: _ctx.$t('support_agreeToPrivacy'),
          required: "",
          error: _ctx.errorPrivacyPolicy,
          clickableLink: _ctx.$t('support_privacyLink'),
          onOnLinkCLicked: _cache[12] || (_cache[12] = $event => (_ctx.openWebsite(_ctx.$t('link_privacyPolicy'))))
        }, null, 8, ["modelValue", "label", "error", "clickableLink"]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_ReCaptcha, {
            onOnResult: _cache[13] || (_cache[13] = $event => (_ctx.reCaptchaResult = $event))
          }),
          _createVNode(_Transition, { mode: "out-in" }, {
            default: _withCtx(() => [
              (_ctx.reCaptchaResult === false)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(_component_SvgElement, {
                      src: "/icons/close.svg",
                      class: "fill-white"
                    })
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_ButtonElement, {
            label: _ctx.$t('support_sendRequest'),
            onClick: _cache[14] || (_cache[14] = $event => (_ctx.send(_ctx.$t('support_xpress'), _ctx.$t('support_request'), _ctx.$t(_ctx.subject), _ctx.$t('support_leitzBranch'), _ctx.$t('support_customerNumber'), _ctx.$t('support_phone'), _ctx.$t('support_message')))),
            disabled: !_ctx.customerData.branch
          }, null, 8, ["label", "disabled"]),
          _createVNode(_Transition, { mode: "out-in" }, {
            default: _withCtx(() => [
              (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_LoaderElement, {
                    key: 0,
                    class: "support-form__loader"
                  }))
                : (_ctx.sendingFailed)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('support_sendingFailed')), 1))
                  : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('support_requiredFields')), 1),
        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('support_privacyInformation')), 1)
      ])
    ])
  ], 2))
}