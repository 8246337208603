import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "checkbox" }
const _hoisted_2 = { class: "checkbox__content" }
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "font-size-14 color-tertiary-1" }
const _hoisted_5 = {
  key: 0,
  class: "font-size-14 font-weight-bold color-primary"
}
const _hoisted_6 = ["id", "checked", "disabled", "modelValue"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", {
        for: _ctx.id,
        class: _normalizeClass(["checkbox__label", { 'disabled' : _ctx.disabled }])
      }, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.label), 1),
        (_ctx.required)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, " *"))
          : _createCommentVNode("", true),
        _createElementVNode("input", {
          class: "checkbox__input",
          id: _ctx.id,
          type: "checkbox",
          checked: _ctx.modelValue,
          disabled: _ctx.disabled,
          modelValue: _ctx.modelValue,
          onChange: _cache[0] || (_cache[0] = $event => (_ctx.$emit('update:modelValue', $event.target.checked)))
        }, null, 40, _hoisted_6),
        _createElementVNode("span", {
          class: _normalizeClass(["checkbox__checkmark cursor-pointer", { 'checked' : _ctx.modelValue}, { 'has-error' : _ctx.error }])
        }, null, 2)
      ], 10, _hoisted_3)
    ]),
    (_ctx.clickableLink !== '')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "checkbox__link font-size-14 color-primary cursor-pointer",
          onClick: _cache[1] || (_cache[1] = $event => (_ctx.$emit('onLinkCLicked')))
        }, _toDisplayString(_ctx.clickableLink), 1))
      : _createCommentVNode("", true)
  ]))
}