import { Language, Branch } from '@/models/Interface';
import { de, branchesDE } from './de';
import { en, branchesEN } from './en';
import { fr, branchesFR } from './fr';
import { nl, branchesNL } from './nl';
import zh from './zh';

const messages: Record<string, Record<string, string>> = {
  de, en, fr, nl, zh,
};

const numberFormats = {
  de: {
    decimal: {
      style: 'decimal', maximumFractionDigits: 4,
      // style: 'decimal', minimumSignificantDigits: 3, maximumSignificantDigits: 5,
    },
  },
  en: {
    decimal: {
      style: 'decimal', maximumFractionDigits: 4,
      // style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2,
    },
  },
  fr: {
    decimal: {
      style: 'decimal', maximumFractionDigits: 4,
      // style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2,
    },
  },
  nl: {
    decimal: {
      style: 'decimal', maximumFractionDigits: 4,
      // style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2,
    },
  },
  zh: {
    decimal: {
      style: 'decimal', maximumFractionDigits: 4,
      // style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2,
    },
  },
};

// TODO: add all languages
const languages: Language[] = [
  { label: 'Deutsch', tag: 'de', flag: 2 },
  { label: 'English', tag: 'en', flag: 3 },
  { label: 'Français', tag: 'fr', flag: 7 },
  { label: 'Nederlands', tag: 'nl', flag: 11 },
  { label: '中文', tag: 'zh', flag: 3 },
];

const branches: Branch[][] = [
  [],
  [],
  branchesDE, // flag: 2
  branchesEN, // flag: 3
  [],
  [],
  [],
  branchesFR, // flag: 7
  [],
  [],
  [],
  branchesNL, // flag: 11
];

export { messages };
export { numberFormats };
export { languages };
export { branches };
