import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "calculator-row__title" }
const _hoisted_2 = { class: "calculator-row__label font-size-14 color-black" }
const _hoisted_3 = { class: "calculator-row__unit font-size-10 color-tertiary-2" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "calculator-row__symbol font-size-10 color-black" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx, _cache) {
  const _component_TextInputElement = _resolveComponent("TextInputElement")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["calculator-row", { 'is-last' : _ctx.lastRow }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.isConversion 
        ? _ctx.$t(`calculator_unitType_${_ctx.quantity.label}`)
        : _ctx.$t(`calculator_parameterType_${_ctx.quantity.label}`)), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", {
          innerHTML: _ctx.quantity.unit
        }, null, 8, _hoisted_4)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", {
        innerHTML: _ctx.quantity.symbol
      }, null, 8, _hoisted_6)
    ]),
    _createVNode(_component_TextInputElement, {
      class: _normalizeClass(["font-size-10", `${_ctx.isConversion ? 'calculator-row__unit' : 'calculator-row__input'}`]),
      type: "number",
      modelValue: _ctx.t1,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (_ctx.$emit('update:t1', $event))),
      isSmall: true,
      alignment: "right",
      readOnly: _ctx.readOnly || _ctx.isResult || _ctx.isConversion,
      highlighted: _ctx.isResult || _ctx.isConversion,
      decimalPlaces: _ctx.quantity.decimalPlaces
    }, {
      default: _withCtx(() => [
        (_ctx.isConversion)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: "calculator-row__input-unit font-size-12 color-white",
              innerHTML: _ctx.quantity.unit
            }, null, 8, _hoisted_7))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["class", "modelValue", "readOnly", "highlighted", "decimalPlaces"]),
    (_ctx.compare)
      ? (_openBlock(), _createBlock(_component_TextInputElement, {
          key: 0,
          class: "calculator-row__input",
          type: "number",
          modelValue: _ctx.t2,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (_ctx.$emit('update:t2', $event))),
          isSmall: true,
          alignment: "right",
          readOnly: _ctx.readOnly || _ctx.isResult || _ctx.isConversion,
          highlighted: _ctx.isResult || _ctx.isConversion,
          decimalPlaces: _ctx.quantity.decimalPlaces
        }, null, 8, ["modelValue", "readOnly", "highlighted", "decimalPlaces"]))
      : _createCommentVNode("", true)
  ], 2))
}