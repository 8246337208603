import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "product-media-preview" }
const _hoisted_2 = { class: "product-media-preview__title" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "product-media-preview__images" }
const _hoisted_5 = ["src", "onClick"]
const _hoisted_6 = ["onClick"]

export function render(_ctx, _cache) {
  const _component_SvgElement = _resolveComponent("SvgElement")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "font-size-16 color-black",
        innerHTML: _ctx.title
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files.slice(0, 6), (file, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (file.type === _ctx.mediaType.Image)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: file.url,
                onerror: "this.src='/icons/no_product_image.svg'",
                class: "product-media-preview__image cursor-pointer",
                onClick: $event => (_ctx.$emit('selectMedium', index))
              }, null, 8, _hoisted_5))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "product-media-preview__placeholder cursor-pointer",
                onClick: $event => (_ctx.$emit('selectMedium', index))
              }, [
                _createVNode(_component_SvgElement, { src: "/icons/play.svg" })
              ], 8, _hoisted_6))
        ], 64))
      }), 256)),
      (_ctx.files.length > 6)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "product-media-preview__image-overflow font-size-16 color-white cursor-pointer",
            onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('selectMedium', 0)))
          }, "+ " + _toDisplayString(_ctx.files.length - 6), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}