import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx, _cache) {
  const _component_SvgElement = _resolveComponent("SvgElement")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tab-menu-item cursor-pointer", [
    { 'has-icon' : _ctx.icon },
    { 'is-active' : _ctx.isActive },
    { 'is-disabled' : _ctx.disabled },
    { 'is-bottom' : _ctx.isBottom },
  ]])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["tab-menu-item__content", { 'is-disabled' : _ctx.disabled }])
    }, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_SvgElement, {
            key: 0,
            src: _ctx.icon,
            class: "tab-menu-item__icon fill-primary"
          }, null, 8, ["src"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["tab-menu-item__label", [_ctx.isActive ? 'color-primary' : 'color-tertiary-1', { 'text-transform-uppercase' : _ctx.uppercase }]]),
        innerHTML: _ctx.label
      }, null, 10, _hoisted_1),
      _createVNode(_component_SvgElement, {
        src: "/icons/chevron_right.svg",
        class: _normalizeClass(["tab-menu-item__arrow", _ctx.isActive ? 'fill-tertiary-2' : 'fill-primary'])
      }, null, 8, ["class"])
    ], 2)
  ], 2))
}