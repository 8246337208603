import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "calculator__form" }
const _hoisted_2 = { class: "calculator__section" }
const _hoisted_3 = { class: "calculator__section" }
const _hoisted_4 = { class: "calculator__section" }
const _hoisted_5 = { class: "calculator__section" }
const _hoisted_6 = { class: "calculator__section" }

export function render(_ctx, _cache) {
  const _component_CalculatorHeadRow = _resolveComponent("CalculatorHeadRow")
  const _component_CalculatorRow = _resolveComponent("CalculatorRow")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CalculatorHeadRow, {
        headline: _ctx.$t('calculator_toolParameters'),
        compare: _ctx.compare
      }, null, 8, ["headline", "compare"]),
      _createVNode(_component_CalculatorRow, {
        quantity: _ctx.quantities.diameter,
        compare: _ctx.compare,
        t1: _ctx.calculatorTool1.diameter,
        "onUpdate:t1": _cache[0] || (_cache[0] = $event => ((_ctx.calculatorTool1.diameter) = $event)),
        t2: _ctx.calculatorTool2.diameter,
        "onUpdate:t2": _cache[1] || (_cache[1] = $event => ((_ctx.calculatorTool2.diameter) = $event))
      }, null, 8, ["quantity", "compare", "t1", "t2"]),
      _createVNode(_component_CalculatorRow, {
        quantity: _ctx.quantities.cuttingSpeed,
        compare: _ctx.compare,
        t1: _ctx.calculatorTool1.cuttingSpeed,
        "onUpdate:t1": _cache[2] || (_cache[2] = $event => ((_ctx.calculatorTool1.cuttingSpeed) = $event)),
        t2: _ctx.calculatorTool2.cuttingSpeed,
        "onUpdate:t2": _cache[3] || (_cache[3] = $event => ((_ctx.calculatorTool2.cuttingSpeed) = $event))
      }, null, 8, ["quantity", "compare", "t1", "t2"]),
      _createVNode(_component_CalculatorRow, {
        quantity: _ctx.quantities.toothFeed,
        compare: _ctx.compare,
        t1: _ctx.calculatorTool1.toothFeed,
        "onUpdate:t1": _cache[4] || (_cache[4] = $event => ((_ctx.calculatorTool1.toothFeed) = $event)),
        t2: _ctx.calculatorTool2.toothFeed,
        "onUpdate:t2": _cache[5] || (_cache[5] = $event => ((_ctx.calculatorTool2.toothFeed) = $event))
      }, null, 8, ["quantity", "compare", "t1", "t2"]),
      _createVNode(_component_CalculatorRow, {
        quantity: _ctx.quantities.toothCount,
        compare: _ctx.compare,
        t1: _ctx.calculatorTool1.toothCount,
        "onUpdate:t1": _cache[6] || (_cache[6] = $event => ((_ctx.calculatorTool1.toothCount) = $event)),
        t2: _ctx.calculatorTool2.toothCount,
        "onUpdate:t2": _cache[7] || (_cache[7] = $event => ((_ctx.calculatorTool2.toothCount) = $event))
      }, null, 8, ["quantity", "compare", "t1", "t2"]),
      _createVNode(_component_CalculatorRow, {
        quantity: _ctx.quantities.cuttingDepthSaw,
        compare: _ctx.compare,
        t1: _ctx.calculatorTool1.cuttingDepth,
        "onUpdate:t1": _cache[8] || (_cache[8] = $event => ((_ctx.calculatorTool1.cuttingDepth) = $event)),
        t2: _ctx.calculatorTool2.cuttingDepth,
        "onUpdate:t2": _cache[9] || (_cache[9] = $event => ((_ctx.calculatorTool2.cuttingDepth) = $event))
      }, null, 8, ["quantity", "compare", "t1", "t2"]),
      _createVNode(_component_CalculatorRow, {
        quantity: _ctx.quantities.protrusionSawBlade,
        compare: _ctx.compare,
        t1: _ctx.calculatorTool1.protrusionSawBlade,
        "onUpdate:t1": _cache[10] || (_cache[10] = $event => ((_ctx.calculatorTool1.protrusionSawBlade) = $event)),
        t2: _ctx.calculatorTool2.protrusionSawBlade,
        "onUpdate:t2": _cache[11] || (_cache[11] = $event => ((_ctx.calculatorTool2.protrusionSawBlade) = $event))
      }, null, 8, ["quantity", "compare", "t1", "t2"]),
      _createVNode(_component_CalculatorRow, {
        quantity: _ctx.quantities.feedPath,
        compare: _ctx.compare,
        t1: _ctx.calculatorTool1.feedPath,
        "onUpdate:t1": _cache[12] || (_cache[12] = $event => ((_ctx.calculatorTool1.feedPath) = $event)),
        t2: _ctx.calculatorTool2.feedPath,
        "onUpdate:t2": _cache[13] || (_cache[13] = $event => ((_ctx.calculatorTool2.feedPath) = $event)),
        lastRow: ""
      }, null, 8, ["quantity", "compare", "t1", "t2"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_CalculatorHeadRow, {
        headline: _ctx.$t('calculator_calculationOf_rotationalSpeed'),
        symbol: "n",
        compare: _ctx.compare
      }, null, 8, ["headline", "compare"]),
      _createVNode(_component_CalculatorRow, {
        quantity: _ctx.quantities.diameter,
        compare: _ctx.compare,
        t1: _ctx.calculatorTool1.diameter,
        "onUpdate:t1": _cache[14] || (_cache[14] = $event => ((_ctx.calculatorTool1.diameter) = $event)),
        t2: _ctx.calculatorTool2.diameter,
        "onUpdate:t2": _cache[15] || (_cache[15] = $event => ((_ctx.calculatorTool2.diameter) = $event)),
        readOnly: ""
      }, null, 8, ["quantity", "compare", "t1", "t2"]),
      _createVNode(_component_CalculatorRow, {
        quantity: _ctx.quantities.cuttingSpeed,
        compare: _ctx.compare,
        t1: _ctx.calculatorTool1.cuttingSpeed,
        "onUpdate:t1": _cache[16] || (_cache[16] = $event => ((_ctx.calculatorTool1.cuttingSpeed) = $event)),
        t2: _ctx.calculatorTool2.cuttingSpeed,
        "onUpdate:t2": _cache[17] || (_cache[17] = $event => ((_ctx.calculatorTool2.cuttingSpeed) = $event)),
        readOnly: ""
      }, null, 8, ["quantity", "compare", "t1", "t2"]),
      _createVNode(_component_CalculatorRow, {
        quantity: _ctx.quantities.rotationalSpeed,
        compare: _ctx.compare,
        t1: _ctx.t1RotationalSpeed,
        "onUpdate:t1": _cache[18] || (_cache[18] = $event => ((_ctx.t1RotationalSpeed) = $event)),
        t2: _ctx.t2RotationalSpeed,
        "onUpdate:t2": _cache[19] || (_cache[19] = $event => ((_ctx.t2RotationalSpeed) = $event)),
        isResult: "",
        lastRow: ""
      }, null, 8, ["quantity", "compare", "t1", "t2"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_CalculatorHeadRow, {
        headline: _ctx.$t('calculator_calculationOf_feedRate'),
        symbol: "v<sub>f</sub>",
        compare: _ctx.compare
      }, null, 8, ["headline", "compare"]),
      _createVNode(_component_CalculatorRow, {
        quantity: _ctx.quantities.toothFeed,
        compare: _ctx.compare,
        t1: _ctx.calculatorTool1.toothFeed,
        "onUpdate:t1": _cache[20] || (_cache[20] = $event => ((_ctx.calculatorTool1.toothFeed) = $event)),
        t2: _ctx.calculatorTool2.toothFeed,
        "onUpdate:t2": _cache[21] || (_cache[21] = $event => ((_ctx.calculatorTool2.toothFeed) = $event)),
        readOnly: ""
      }, null, 8, ["quantity", "compare", "t1", "t2"]),
      _createVNode(_component_CalculatorRow, {
        quantity: _ctx.quantities.rotationalSpeed,
        compare: _ctx.compare,
        t1: _ctx.t1RotationalSpeed,
        "onUpdate:t1": _cache[22] || (_cache[22] = $event => ((_ctx.t1RotationalSpeed) = $event)),
        t2: _ctx.t2RotationalSpeed,
        "onUpdate:t2": _cache[23] || (_cache[23] = $event => ((_ctx.t2RotationalSpeed) = $event)),
        readOnly: ""
      }, null, 8, ["quantity", "compare", "t1", "t2"]),
      _createVNode(_component_CalculatorRow, {
        quantity: _ctx.quantities.toothCount,
        compare: _ctx.compare,
        t1: _ctx.calculatorTool1.toothCount,
        "onUpdate:t1": _cache[24] || (_cache[24] = $event => ((_ctx.calculatorTool1.toothCount) = $event)),
        t2: _ctx.calculatorTool2.toothCount,
        "onUpdate:t2": _cache[25] || (_cache[25] = $event => ((_ctx.calculatorTool2.toothCount) = $event)),
        readOnly: ""
      }, null, 8, ["quantity", "compare", "t1", "t2"]),
      _createVNode(_component_CalculatorRow, {
        quantity: _ctx.quantities.feedRate,
        compare: _ctx.compare,
        t1: _ctx.t1FeedRate,
        "onUpdate:t1": _cache[26] || (_cache[26] = $event => ((_ctx.t1FeedRate) = $event)),
        t2: _ctx.t2FeedRate,
        "onUpdate:t2": _cache[27] || (_cache[27] = $event => ((_ctx.t2FeedRate) = $event)),
        isResult: "",
        lastRow: ""
      }, null, 8, ["quantity", "compare", "t1", "t2"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_CalculatorHeadRow, {
        headline: _ctx.$t('calculator_calculationOf_chipThicknessAndChipSheetLength'),
        symbol: "h, l<sub>c</sub>",
        compare: _ctx.compare
      }, null, 8, ["headline", "compare"]),
      _createVNode(_component_CalculatorRow, {
        quantity: _ctx.quantities.diameter,
        compare: _ctx.compare,
        t1: _ctx.calculatorTool1.diameter,
        "onUpdate:t1": _cache[28] || (_cache[28] = $event => ((_ctx.calculatorTool1.diameter) = $event)),
        t2: _ctx.calculatorTool2.diameter,
        "onUpdate:t2": _cache[29] || (_cache[29] = $event => ((_ctx.calculatorTool2.diameter) = $event)),
        readOnly: ""
      }, null, 8, ["quantity", "compare", "t1", "t2"]),
      _createVNode(_component_CalculatorRow, {
        quantity: _ctx.quantities.cuttingDepthSaw,
        compare: _ctx.compare,
        t1: _ctx.calculatorTool1.cuttingDepth,
        "onUpdate:t1": _cache[30] || (_cache[30] = $event => ((_ctx.calculatorTool1.cuttingDepth) = $event)),
        t2: _ctx.calculatorTool2.cuttingDepth,
        "onUpdate:t2": _cache[31] || (_cache[31] = $event => ((_ctx.calculatorTool2.cuttingDepth) = $event)),
        readOnly: ""
      }, null, 8, ["quantity", "compare", "t1", "t2"]),
      _createVNode(_component_CalculatorRow, {
        quantity: _ctx.quantities.protrusionSawBlade,
        compare: _ctx.compare,
        t1: _ctx.calculatorTool1.protrusionSawBlade,
        "onUpdate:t1": _cache[32] || (_cache[32] = $event => ((_ctx.calculatorTool1.protrusionSawBlade) = $event)),
        t2: _ctx.calculatorTool2.protrusionSawBlade,
        "onUpdate:t2": _cache[33] || (_cache[33] = $event => ((_ctx.calculatorTool2.protrusionSawBlade) = $event)),
        readOnly: ""
      }, null, 8, ["quantity", "compare", "t1", "t2"]),
      _createVNode(_component_CalculatorRow, {
        quantity: _ctx.quantities.averageChipThickness,
        compare: _ctx.compare,
        t1: _ctx.t1AverageChipThickness,
        "onUpdate:t1": _cache[34] || (_cache[34] = $event => ((_ctx.t1AverageChipThickness) = $event)),
        t2: _ctx.t2AverageChipThickness,
        "onUpdate:t2": _cache[35] || (_cache[35] = $event => ((_ctx.t2AverageChipThickness) = $event)),
        isResult: ""
      }, null, 8, ["quantity", "compare", "t1", "t2"]),
      _createVNode(_component_CalculatorRow, {
        quantity: _ctx.quantities.maximumChipThickness,
        compare: _ctx.compare,
        t1: _ctx.t1MaximumChipThickness,
        "onUpdate:t1": _cache[36] || (_cache[36] = $event => ((_ctx.t1MaximumChipThickness) = $event)),
        t2: _ctx.t2MaximumChipThickness,
        "onUpdate:t2": _cache[37] || (_cache[37] = $event => ((_ctx.t2MaximumChipThickness) = $event)),
        isResult: ""
      }, null, 8, ["quantity", "compare", "t1", "t2"]),
      _createVNode(_component_CalculatorRow, {
        quantity: _ctx.quantities.chipSheetLength,
        compare: _ctx.compare,
        t1: _ctx.t1ChipSheetLength,
        "onUpdate:t1": _cache[38] || (_cache[38] = $event => ((_ctx.t1ChipSheetLength) = $event)),
        t2: _ctx.t2ChipSheetLength,
        "onUpdate:t2": _cache[39] || (_cache[39] = $event => ((_ctx.t2ChipSheetLength) = $event)),
        isResult: "",
        lastRow: ""
      }, null, 8, ["quantity", "compare", "t1", "t2"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_CalculatorHeadRow, {
        headline: _ctx.$t('calculator_calculationOf_cutPath'),
        symbol: "l<sub>c tot</sub>",
        compare: _ctx.compare
      }, null, 8, ["headline", "compare"]),
      _createVNode(_component_CalculatorRow, {
        quantity: _ctx.quantities.diameter,
        compare: _ctx.compare,
        t1: _ctx.calculatorTool1.diameter,
        "onUpdate:t1": _cache[40] || (_cache[40] = $event => ((_ctx.calculatorTool1.diameter) = $event)),
        t2: _ctx.calculatorTool2.diameter,
        "onUpdate:t2": _cache[41] || (_cache[41] = $event => ((_ctx.calculatorTool2.diameter) = $event)),
        readOnly: ""
      }, null, 8, ["quantity", "compare", "t1", "t2"]),
      _createVNode(_component_CalculatorRow, {
        quantity: _ctx.quantities.feedRate,
        compare: _ctx.compare,
        t1: _ctx.t1FeedRate,
        "onUpdate:t1": _cache[42] || (_cache[42] = $event => ((_ctx.t1FeedRate) = $event)),
        t2: _ctx.t2FeedRate,
        "onUpdate:t2": _cache[43] || (_cache[43] = $event => ((_ctx.t2FeedRate) = $event)),
        readOnly: ""
      }, null, 8, ["quantity", "compare", "t1", "t2"]),
      _createVNode(_component_CalculatorRow, {
        quantity: _ctx.quantities.rotationalSpeed,
        compare: _ctx.compare,
        t1: _ctx.t1RotationalSpeed,
        "onUpdate:t1": _cache[44] || (_cache[44] = $event => ((_ctx.t1RotationalSpeed) = $event)),
        t2: _ctx.t2RotationalSpeed,
        "onUpdate:t2": _cache[45] || (_cache[45] = $event => ((_ctx.t2RotationalSpeed) = $event)),
        readOnly: ""
      }, null, 8, ["quantity", "compare", "t1", "t2"]),
      _createVNode(_component_CalculatorRow, {
        quantity: _ctx.quantities.cuttingDepthSaw,
        compare: _ctx.compare,
        t1: _ctx.calculatorTool1.cuttingDepth,
        "onUpdate:t1": _cache[46] || (_cache[46] = $event => ((_ctx.calculatorTool1.cuttingDepth) = $event)),
        t2: _ctx.calculatorTool2.cuttingDepth,
        "onUpdate:t2": _cache[47] || (_cache[47] = $event => ((_ctx.calculatorTool2.cuttingDepth) = $event)),
        readOnly: ""
      }, null, 8, ["quantity", "compare", "t1", "t2"]),
      _createVNode(_component_CalculatorRow, {
        quantity: _ctx.quantities.feedPath,
        compare: _ctx.compare,
        t1: _ctx.calculatorTool1.feedPath,
        "onUpdate:t1": _cache[48] || (_cache[48] = $event => ((_ctx.calculatorTool1.feedPath) = $event)),
        t2: _ctx.calculatorTool2.feedPath,
        "onUpdate:t2": _cache[49] || (_cache[49] = $event => ((_ctx.calculatorTool2.feedPath) = $event)),
        readOnly: ""
      }, null, 8, ["quantity", "compare", "t1", "t2"]),
      _createVNode(_component_CalculatorRow, {
        quantity: _ctx.quantities.cutPath,
        compare: _ctx.compare,
        t1: _ctx.t1CutPath,
        "onUpdate:t1": _cache[50] || (_cache[50] = $event => ((_ctx.t1CutPath) = $event)),
        t2: _ctx.t2CutPath,
        "onUpdate:t2": _cache[51] || (_cache[51] = $event => ((_ctx.t2CutPath) = $event)),
        isResult: "",
        lastRow: ""
      }, null, 8, ["quantity", "compare", "t1", "t2"])
    ])
  ]))
}