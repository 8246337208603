
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
  props: {
    icon: { type: String, default: null },
    iconSize: { type: String, default: '15px' },
    label: String,
    disabled: Boolean,
    uppercase: Boolean,
  },
})
export default class TabMenuButton extends Vue {
}
