import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "calculator" }
const _hoisted_2 = { class: "calculator__columns" }
const _hoisted_3 = { class: "calculator__column" }
const _hoisted_4 = { class: "calculator__title font-size-20 color-black" }
const _hoisted_5 = { class: "calculator__content" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "calculator__column" }
const _hoisted_8 = { class: "calculator__content" }
const _hoisted_9 = { class: "calculator__compare" }
const _hoisted_10 = { class: "font-size-14 color-tertiary-2" }

export function render(_ctx, _cache) {
  const _component_Recommendation = _resolveComponent("Recommendation")
  const _component_DropdownElement = _resolveComponent("DropdownElement")
  const _component_TabControlElement = _resolveComponent("TabControlElement")
  const _component_ToggleElement = _resolveComponent("ToggleElement")
  const _component_CalculatorMillWoodCuttingSpeed = _resolveComponent("CalculatorMillWoodCuttingSpeed")
  const _component_CalculatorMillWoodRotationalSpeed = _resolveComponent("CalculatorMillWoodRotationalSpeed")
  const _component_CalculatorMillMetalCuttingSpeed = _resolveComponent("CalculatorMillMetalCuttingSpeed")
  const _component_CalculatorMillMetalRotationalSpeed = _resolveComponent("CalculatorMillMetalRotationalSpeed")
  const _component_CalculatorSawWoodCuttingSpeed = _resolveComponent("CalculatorSawWoodCuttingSpeed")
  const _component_CalculatorSawWoodRotationalSpeed = _resolveComponent("CalculatorSawWoodRotationalSpeed")
  const _component_CalculatorSawMetalCuttingSpeed = _resolveComponent("CalculatorSawMetalCuttingSpeed")
  const _component_CalculatorSawMetalRotationalSpeed = _resolveComponent("CalculatorSawMetalRotationalSpeed")
  const _component_CalculatorUnitConversion = _resolveComponent("CalculatorUnitConversion")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('calculator_calculation')) + " " + _toDisplayString(_ctx.$t(`calculator_tabs_${_ctx.type}`)), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("img", {
            class: "calculator__image",
            src: `/images/calculator_${_ctx.type}.jpg`
          }, null, 8, _hoisted_6),
          (_ctx.type === _ctx.calculatorRouteType.Mill && _ctx.material === _ctx.calculatorRouteMaterial.Wood)
            ? (_openBlock(), _createBlock(_component_Recommendation, {
                key: 0,
                type: "toothFeedMillWood",
                title: "calculator_recommendation_title_toothFeed"
              }))
            : _createCommentVNode("", true),
          ((_ctx.type === _ctx.calculatorRouteType.Mill || _ctx.type === _ctx.calculatorRouteType.Saw) && _ctx.material === _ctx.calculatorRouteMaterial.Metal)
            ? (_openBlock(), _createBlock(_component_Recommendation, {
                key: 1,
                type: "cuttingParametersNE",
                title: "calculator_recommendation_title_NE"
              }))
            : _createCommentVNode("", true),
          (_ctx.type === _ctx.calculatorRouteType.Saw && _ctx.material === _ctx.calculatorRouteMaterial.Wood)
            ? (_openBlock(), _createBlock(_component_Recommendation, {
                key: 2,
                type: "toothFeedSawWood",
                title: "calculator_recommendation_title_toothFeed"
              }))
            : _createCommentVNode("", true),
          (_ctx.type === _ctx.calculatorRouteType.Saw && _ctx.material === _ctx.calculatorRouteMaterial.Metal)
            ? (_openBlock(), _createBlock(_component_Recommendation, {
                key: 3,
                type: "cuttingParametersSteel",
                title: "calculator_recommendation_title_steel"
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.type !== _ctx.calculatorRouteType.Unit)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_DropdownElement, {
                  modelValue: _ctx.material,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (_ctx.selectMaterial($event))),
                  id: "material",
                  label: _ctx.$t('calculator_material_title'),
                  isLocalised: "",
                  options: _ctx.materialOptions,
                  localisedPath: "calculator_material_"
                }, null, 8, ["modelValue", "label", "options"]),
                _createVNode(_component_TabControlElement, {
                  modelValue: _ctx.procedure,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (_ctx.selectProcedure($event))),
                  tabs: _ctx.procedureOptions,
                  isLocalised: "",
                  localisedPath: "calculator_calculationType_"
                }, null, 8, ["modelValue", "tabs"]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('calculator_compareTools')), 1),
                  _createVNode(_component_ToggleElement, {
                    modelValue: _ctx.compare,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (_ctx.$emit('update:compare', $event)))
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createVNode(_Transition, { mode: "out-in" }, {
                default: _withCtx(() => [
                  (_ctx.type === _ctx.calculatorRouteType.Mill)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (_ctx.material === _ctx.calculatorRouteMaterial.Wood)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              (_ctx.procedure === _ctx.calculatorRouteProcedure.CuttingSpeed)
                                ? (_openBlock(), _createBlock(_component_CalculatorMillWoodCuttingSpeed, {
                                    key: 0,
                                    compare: _ctx.compare,
                                    calculatorTool1: _ctx.calculatorTool1,
                                    calculatorTool2: _ctx.calculatorTool2
                                  }, null, 8, ["compare", "calculatorTool1", "calculatorTool2"]))
                                : (_ctx.procedure === _ctx.calculatorRouteProcedure.RotationalSpeed)
                                  ? (_openBlock(), _createBlock(_component_CalculatorMillWoodRotationalSpeed, {
                                      key: 1,
                                      compare: _ctx.compare,
                                      calculatorTool1: _ctx.calculatorTool1,
                                      calculatorTool2: _ctx.calculatorTool2
                                    }, null, 8, ["compare", "calculatorTool1", "calculatorTool2"]))
                                  : _createCommentVNode("", true)
                            ], 64))
                          : (_ctx.material === _ctx.calculatorRouteMaterial.Metal)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                (_ctx.procedure === _ctx.calculatorRouteProcedure.CuttingSpeed)
                                  ? (_openBlock(), _createBlock(_component_CalculatorMillMetalCuttingSpeed, {
                                      key: 0,
                                      compare: _ctx.compare,
                                      calculatorTool1: _ctx.calculatorTool1,
                                      calculatorTool2: _ctx.calculatorTool2
                                    }, null, 8, ["compare", "calculatorTool1", "calculatorTool2"]))
                                  : (_ctx.procedure === _ctx.calculatorRouteProcedure.RotationalSpeed)
                                    ? (_openBlock(), _createBlock(_component_CalculatorMillMetalRotationalSpeed, {
                                        key: 1,
                                        compare: _ctx.compare,
                                        calculatorTool1: _ctx.calculatorTool1,
                                        calculatorTool2: _ctx.calculatorTool2
                                      }, null, 8, ["compare", "calculatorTool1", "calculatorTool2"]))
                                    : _createCommentVNode("", true)
                              ], 64))
                            : _createCommentVNode("", true)
                      ], 64))
                    : (_ctx.type === _ctx.calculatorRouteType.Saw)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          (_ctx.material === _ctx.calculatorRouteMaterial.Wood)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                (_ctx.procedure === _ctx.calculatorRouteProcedure.CuttingSpeed)
                                  ? (_openBlock(), _createBlock(_component_CalculatorSawWoodCuttingSpeed, {
                                      key: 0,
                                      compare: _ctx.compare,
                                      calculatorTool1: _ctx.calculatorTool1,
                                      calculatorTool2: _ctx.calculatorTool2
                                    }, null, 8, ["compare", "calculatorTool1", "calculatorTool2"]))
                                  : (_ctx.procedure === _ctx.calculatorRouteProcedure.RotationalSpeed)
                                    ? (_openBlock(), _createBlock(_component_CalculatorSawWoodRotationalSpeed, {
                                        key: 1,
                                        compare: _ctx.compare,
                                        calculatorTool1: _ctx.calculatorTool1,
                                        calculatorTool2: _ctx.calculatorTool2
                                      }, null, 8, ["compare", "calculatorTool1", "calculatorTool2"]))
                                    : _createCommentVNode("", true)
                              ], 64))
                            : (_ctx.material === _ctx.calculatorRouteMaterial.Metal)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  (_ctx.procedure === _ctx.calculatorRouteProcedure.CuttingSpeed)
                                    ? (_openBlock(), _createBlock(_component_CalculatorSawMetalCuttingSpeed, {
                                        key: 0,
                                        compare: _ctx.compare,
                                        calculatorTool1: _ctx.calculatorTool1,
                                        calculatorTool2: _ctx.calculatorTool2
                                      }, null, 8, ["compare", "calculatorTool1", "calculatorTool2"]))
                                    : (_ctx.procedure === _ctx.calculatorRouteProcedure.RotationalSpeed)
                                      ? (_openBlock(), _createBlock(_component_CalculatorSawMetalRotationalSpeed, {
                                          key: 1,
                                          compare: _ctx.compare,
                                          calculatorTool1: _ctx.calculatorTool1,
                                          calculatorTool2: _ctx.calculatorTool2
                                        }, null, 8, ["compare", "calculatorTool1", "calculatorTool2"]))
                                      : _createCommentVNode("", true)
                                ], 64))
                              : _createCommentVNode("", true)
                        ], 64))
                      : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ], 64))
          : (_ctx.type === _ctx.calculatorRouteType.Unit)
            ? (_openBlock(), _createBlock(_component_CalculatorUnitConversion, { key: 1 }))
            : _createCommentVNode("", true)
      ])
    ])
  ]))
}