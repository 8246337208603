
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
  props: {
  },
})
export default class AppLinks extends Vue {
  linkIOS = process.env.VUE_APP_LINK_IOS;

  linkAndroid = process.env.VUE_APP_LINK_ANDROID;

  openAppLink(url: string) {
    console.log('openAppLink', this);
    window.open(url, '_blank', 'noopener');
  }
}
