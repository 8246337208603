import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "product-details__content"
}
const _hoisted_2 = { class: "product-details__title font-size-20 color-black" }
const _hoisted_3 = { class: "product-details__columns" }
const _hoisted_4 = {
  key: 0,
  class: "product-details__column"
}
const _hoisted_5 = {
  key: 1,
  class: "product-details__column"
}
const _hoisted_6 = { class: "product-details__technical-info-texts" }
const _hoisted_7 = {
  key: 0,
  class: "product-details__technical-info-text"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]

export function render(_ctx, _cache) {
  const _component_ProductTable = _resolveComponent("ProductTable")

  return (_ctx.product.applicationParameter)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('product_applicationParameter_title')), 1),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.product.applicationParameter.attributes)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_ctx.product.applicationParameter.attributes)
                  ? (_openBlock(), _createBlock(_component_ProductTable, {
                      key: 0,
                      table: _ctx.product.applicationParameter.attributes
                    }, null, 8, ["table"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.product.applicationParameter.textRotationalSpeed)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  (_ctx.product.applicationParameter.textRotationalSpeed)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        (_ctx.product.applicationParameter.textRotationalSpeed.title)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: "font-size-14 color-tertiary-1",
                              innerHTML: _ctx.product.applicationParameter.textRotationalSpeed.title
                            }, null, 8, _hoisted_8))
                          : _createCommentVNode("", true),
                        (_ctx.product.applicationParameter.textRotationalSpeed.content)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 1,
                              class: "font-size-14 color-tertiary-2",
                              innerHTML: _ctx.product.applicationParameter.textRotationalSpeed.content
                            }, null, 8, _hoisted_9))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}