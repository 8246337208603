
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import LanguageStore from '@/stores/languages';
import ProductStore from '@/stores/product';
import Search from '@/components/Search/Search.vue';
import History from '@/components/History/History.vue';
import { Options, Vue } from 'vue-class-component';
import SdkService from '@/services/SdkService';
import { EtrackerAreas, RouteNames } from '@/models/Enum';
import { HistoryProduct, Product } from '@/models/Interface';
import LegalInformation from '@/components/LegalInformation/LegalInformation.vue';

@Options({
  components: {
    Search,
    History,
    LegalInformation,
  },
})
export default class SearchView extends Vue {
  isCollapsed = false;

  loading = false;

  showErrorDigits = false;

  showErrorNoProduct = false;

  historyProducts: HistoryProduct[] = [];

  placholderHeight = 0;

  created() {
    document.dispatchEvent(new CustomEvent('etrackerPageAreaChanged', { detail: EtrackerAreas.search }));
    this.historyProducts = this.getHistory();
  }

  mounted() {
    this.getPlaceholderHeight();
  }

  searchProduct(id: string) {
    if (id.length < 4) {
      this.showErrorDigits = true;
      setTimeout(() => {
        this.showErrorDigits = false;
      }, 3000);
    } else {
      this.loading = true;
      const languageStore = LanguageStore();
      const language = languageStore.getLanguageByTag(this.$i18n.locale);
      const languageNumber = language ? language.flag : null;
      SdkService.getProduct(id, languageNumber, (productById) => {
        if (productById) {
          this.loading = false;
          this.onProductFound(productById);
        } else {
          SdkService.getProductBySerialNumber(id, languageNumber, (productBySerialNumber) => {
            this.loading = false;
            if (productBySerialNumber) {
              this.onProductFound(productBySerialNumber);
            } else {
              this.showErrorNoProduct = true;
              (this.$refs.search as Search).clearId();
            }
          });
        }
        setTimeout(() => {
          this.showErrorNoProduct = false;
        }, 5000);
      });
    }
  }

  onProductFound(product: Product) {
    const productStore = ProductStore();
    productStore.setProduct(product);
    this.addProductToHistory(product.id);
    this.$router.push({
      name: RouteNames.Product,
      params: { id: product.id },
    });
  }

  getHistory() {
    const historyString = localStorage.history;
    let history: HistoryProduct[] = [];
    try {
      history = JSON.parse(historyString);
    } catch {
      history = [];
      this.saveHistory(history);
    }
    for (let index = history.length - 1; index >= 0; index -= 1) {
      const item = history[index];
      if (!(item.historyId && item.productId && item.searchedAt)) {
        history.splice(index, 1);
      }
    }
    this.saveHistory(history);
    return history;
  }

  saveHistory(history: HistoryProduct[]) {
    const filteredHistory = SearchView.filterHistory(history);
    localStorage.history = JSON.stringify(filteredHistory);
    this.historyProducts = filteredHistory;
  }

  addProductToHistory(productId: string) {
    const history = this.getHistory();

    const historyItem: HistoryProduct = {
      productId,
      historyId: uuid(),
      searchedAt: moment.now(),
    };

    history.unshift(historyItem);

    this.saveHistory(history);
  }

  static filterHistory(history: HistoryProduct[]) {
    const filteredHistory = history
      .filter((item, index) => history.findIndex((i) => i.productId === item.productId) === index);
    const trimmedHistory = filteredHistory.slice(0, 20);
    return trimmedHistory;
  }

  deleteProductFromHistory(historyId: string) {
    const history = this.getHistory();
    const index = history.findIndex((element) => element.historyId === historyId);
    history.splice(index, 1);
    this.saveHistory(history);
  }

  onScroll() {
    const searchViewElement = this.$refs.searchview as Element;
    const scrollOffsetTop = searchViewElement.getBoundingClientRect().top;
    if (scrollOffsetTop <= 0) {
      this.isCollapsed = true;
    } else {
      this.isCollapsed = false;
    }
  }

  getPlaceholderHeight() {
    const searchElement = (this.$refs.search as Search).$el as Element;
    this.placholderHeight = searchElement.getBoundingClientRect().height;
  }
}
