import { Branch } from '@/models/Interface';

export const en = {
  calculator_calculation: 'Calculation',
  calculator_calculationOf_chipSheetLengthAndChipThickness: 'Calculation of chip arc length and chip thickness',
  calculator_calculationOf_chipThicknessAndChipSheetLength: 'Calculation of chip thickness and chip arc length',
  calculator_calculationOf_cutPath: 'Calculation of cutting length',
  calculator_calculationOf_cutterStepDepth: 'Calculation of depth of cutter marks',
  calculator_calculationOf_cuttingSpeed: 'Calculation of cutting speed',
  calculator_calculationOf_feedPerTooth: 'Calculation of feed per tooth',
  calculator_calculationOf_feedRate: 'Calculation of feed rate',
  calculator_calculationOf_rotationalSpeed: 'Calculation of rotational speed (RPM)',
  calculator_calculationType_cuttingSpeed: 'Cutting speed',
  calculator_calculationType_rotationalSpeed: 'Rotational speed (RPM)',
  calculator_compareTools: 'Compare tools',
  calculator_material_metal: 'Metals',
  calculator_material_title: 'Material',
  calculator_material_wood: 'Wood and wood-based materials',
  calculator_mill: 'Milling',
  calculator_parameterType_averageChipThickness: 'Mean chip thickness',
  calculator_parameterType_chipSheetLength: 'Chip arc length',
  calculator_parameterType_cutPath: 'Cutting length',
  calculator_parameterType_cutterStepDepth: 'Depth of cutter marks related to feed per tooth',
  calculator_parameterType_cuttingDepthMill: 'Radial cutting depth',
  calculator_parameterType_cuttingDepthSaw: 'Cutting depth',
  calculator_parameterType_cuttingSpeed: 'Cutting speed',
  calculator_parameterType_diameter: 'Diameter',
  calculator_parameterType_feedPath: 'Feed distance',
  calculator_parameterType_feedPerRotation: 'Feed per revolution',
  calculator_parameterType_feedRate: 'Feed rate',
  calculator_parameterType_maximumChipThickness: 'Maximum chip thickness',
  calculator_parameterType_protrusionSawBlade: 'Sawblade protrusion',
  calculator_parameterType_rotationalSpeed: 'Rotational speed (RPM)',
  calculator_parameterType_toothCount: 'Number of teeth',
  calculator_parameterType_toothFeed: 'Feed per tooth',
  calculator_quantityType_area: 'Area',
  calculator_quantityType_energy: 'Energy',
  calculator_quantityType_length: 'Length',
  calculator_quantityType_performance: 'Power',
  calculator_quantityType_power: 'Mass',
  calculator_quantityType_pressure: 'Pressure',
  calculator_quantityType_temperature: 'Temperature',
  calculator_quantityType_torque: 'Torque',
  calculator_quantityType_velocity: 'Speed, Velocity',
  calculator_quantityType_volume: 'Volume',
  calculator_quantityType_weight: 'Weight',
  calculator_recommendation_blockboards: 'Block boards',
  calculator_recommendation_castAluminumAlloys: 'Cast aluminium alloys',
  calculator_recommendation_cementBondedWoodMaterials: 'Cement-bound wood-based materials',
  calculator_recommendation_favorableRange: 'Favourable range',
  calculator_recommendation_fineSizingChip: 'Fine-finish chip',
  calculator_recommendation_group_aluminiumCastWroughtAlloys: 'Cast aluminium and wrought alloys',
  calculator_recommendation_group_brassShortLongChipping: 'Brass, short and long chipping',
  calculator_recommendation_group_bronze: 'Bronze',
  calculator_recommendation_group_constructionSteels: 'Structural steel',
  calculator_recommendation_group_copperAlloys: 'Copper, copper alloys',
  calculator_recommendation_group_quenchedAndTemperedNitridingSteels: 'Tempering and nitriding steel',
  calculator_recommendation_group_springBearingSteels: 'Spring and roller bearing steel',
  calculator_recommendation_group_stainlessSteels: 'Stainless steel',
  calculator_recommendation_gypsumMaterials: 'Gypsum materials',
  calculator_recommendation_hardboard: 'Hardboard',
  calculator_recommendation_hardwoodCrosswise: 'Hardwood across the grain',
  calculator_recommendation_headline_cuttingSpeed: 'Cutting speed',
  calculator_recommendation_headline_group: 'Material group',
  calculator_recommendation_headline_material: 'Materials',
  calculator_recommendation_headline_tensileStrength: 'Tensile strength',
  calculator_recommendation_headline_toothFeed: 'Feed per tooth',
  calculator_recommendation_HPLCoveredChipboard: 'Chipboard HPL laminated',
  calculator_recommendation_OSBBoards: 'OSB boards',
  calculator_recommendation_plasticCoatedChipboard: 'Chipboard melamine laminated',
  calculator_recommendation_plywood: 'Plywood',
  calculator_recommendation_pressedLaminatedWood: 'Laminated veneer lumber (LVL)',
  calculator_recommendation_roughingChip: 'Roughing chip',
  calculator_recommendation_sizingChip: 'Finishing chip',
  calculator_recommendation_softwoodCrosswise: 'Softwood across the grain',
  calculator_recommendation_solidWoodLengthwise: 'Solid wood along the grain',
  calculator_recommendation_thermoplastics: 'Thermoplastics',
  calculator_recommendation_thermosettingPlastics: 'Duroplastics',
  calculator_recommendation_title_general: 'Recommended values',
  calculator_recommendation_title_NE: 'Reccommended cutting parameters for non-ferrous machining',
  calculator_recommendation_title_steel: 'Reccommended cutting parameters for steel machining',
  calculator_recommendation_title_toothFeed: 'Recommended feed per tooth',
  calculator_recommendation_veneeredPanels: 'Veneered panels',
  calculator_recommendation_wroughtAluminumAlloys: 'Wrought aluminium alloys',
  calculator_reset: 'Reset',
  calculator_resetCalculator: 'Reset calculator',
  calculator_saw: 'Sawing',
  calculator_tabs_mill: 'Milling',
  calculator_tabs_saw: 'Sawing',
  calculator_tabs_unit: 'Unit conversion',
  calculator_title: 'Calculator',
  calculator_titleMill: 'Calculator - Milling',
  calculator_titleSaw: 'Calculator - Sawing',
  calculator_titleUnit: 'Calculator - Unit',
  calculator_tool: 'Tool',
  calculator_toolParameters: 'Tool parameters',
  calculator_unit: 'Unit',
  calculator_unitConversion: 'Unit Conversion',
  calculator_units: 'Units',
  calculator_unitToConvert: 'Unit to be converted',
  calculator_unitType_bar: 'Bar',
  calculator_unitType_britishThermalUnit: 'British thermal unit',
  calculator_unitType_centimeter: 'Centimetre',
  calculator_unitType_cubicFeet: 'Cubic foot',
  calculator_unitType_cubicInch: 'Cubic inch',
  calculator_unitType_cubicMeter: 'Cubic metre',
  calculator_unitType_degreeCelsius: 'Degree Celsius',
  calculator_unitType_degreeFahrenheit: 'Degree Fahrenheit',
  calculator_unitType_feet: 'Foot',
  calculator_unitType_feetPerMinute: 'Feet per minute',
  calculator_unitType_footPound: 'Foot-pound',
  calculator_unitType_gallon: 'US gallon',
  calculator_unitType_gram: 'Gram',
  calculator_unitType_horsepower: 'Horsepower',
  calculator_unitType_inch: 'Inch',
  calculator_unitType_inchPerMinute: 'Inches per minute',
  calculator_unitType_inchPerSecond: 'Inches per second',
  calculator_unitType_joule: 'Joule',
  calculator_unitType_kelvin: 'Kelvin',
  calculator_unitType_kilocalories: 'Kilocalories',
  calculator_unitType_kilogram: 'Kilogram',
  calculator_unitType_kilopond: 'Kilopond',
  calculator_unitType_kilowatt: 'Kilowatt',
  calculator_unitType_kilowattHour: 'Kilowatt hours',
  calculator_unitType_liter: 'Litre',
  calculator_unitType_meter: 'Metre',
  calculator_unitType_meterPerMinute: 'Metres per minute',
  calculator_unitType_meterPerSecond: 'Metres per second',
  calculator_unitType_metricHorsepower: 'Horsepower',
  calculator_unitType_micrometer: 'Micrometre',
  calculator_unitType_millimeter: 'Millimetre',
  calculator_unitType_newton: 'Newton',
  calculator_unitType_newtonMeter: 'Newton metre',
  calculator_unitType_ounce: 'Ounce',
  calculator_unitType_ounceForce: 'Ounce-force',
  calculator_unitType_ounceForceFeet: 'Ounce-force foot',
  calculator_unitType_pascal: 'Pascal',
  calculator_unitType_pound: 'Pound-force',
  calculator_unitType_poundForce: 'Pound-force',
  calculator_unitType_poundForceFeet: 'Pound-force foot',
  calculator_unitType_poundForceInch: 'Pound-force inch',
  calculator_unitType_poundForcePerSquareInch: 'Pound-force per square inch',
  calculator_unitType_squareCentimeter: 'Square centimeter',
  calculator_unitType_squareFeet: 'Square feet',
  calculator_unitType_squareInch: 'Square inch',
  calculator_unitType_squareMeter: 'Square metre',
  calculator_unitType_squareMillimeter: 'Square millimetre',
  calculator_unitType_standardAtmosphere: 'Physical atmosphere',
  calculator_unitType_tonne: 'Tonne',
  calculator_unitType_watt: 'Watt',
  favorites_noFavorites: 'You have not yet saved any products as favourites.',
  favorites_titlePlural: 'Favourites',
  favorites_titleSingular: 'Favourite',
  language: 'English',
  legalInformation_imprint: 'Imprint',
  legalInformation_privacyPolicy: 'Privacy',
  legalInformation_title: 'Legal information',
  leitzxpert: 'LeitzXPert',
  link_imprint: 'https://www.leitz.org/en/imprint',
  link_privacyPolicy: 'https://www.leitz.org/en/privacy',
  navigation_appLanguage: 'App Language',
  navigation_apps: 'Apps',
  navigation_back: 'Back',
  navigation_calculator: 'Calculator',
  navigation_cancel: 'Cancel',
  navigation_close: 'Close',
  navigation_done: 'Done',
  navigation_favorites: 'Favourites',
  navigation_language: 'Language',
  navigation_productsearch: 'Product search',
  navigation_profile: 'Profile',
  navigation_search: 'Search',
  navigation_section: 'Section',
  navigation_support: 'Support',
  noMobile_appName: 'LeitzXPert',
  noMobile_availableFor: 'Available for Android and iOS',
  noMobile_availableSoon: 'Available soon',
  noMobile_notAvailable: 'This page is not optimized for mobile devices and for screens with a display width below 960&nbsp;pixels and can therefore not be displayed.',
  noMobile_recommendation: 'We recommend to install our free LeitzXPert app from the Store.',
  noMobile_title: 'This page is not optimized for mobile devices and small screens',
  product_applicationParameter_title: 'Application parameters',
  product_backTo: 'Back to',
  product_general_details: 'Details',
  product_general_images: 'Product images &amp; videos',
  product_general_imagesNoMedia: 'No product images &amp; videos available',
  product_general_maximizeDescription: 'Show description',
  product_general_minimizeDescription: 'Minimise description',
  product_general_sketches: 'Sketches &amp; diagrams',
  product_general_sketchesNoMedia: 'No sketches &amp; diagrams available',
  product_general_title: 'General information',
  product_noImage: 'No image available',
  product_noInformation: 'No information available',
  product_notFound: 'Product was not found',
  product_productsearch: 'Product search',
  product_relatedArticles_title: 'Additional dimensions',
  product_relatedArticles_toProduct: 'To the product',
  product_spares_availability_availableAtShortNotice: 'Available at short notice',
  product_spares_availability_availableExStock: 'Available from stock',
  product_spares_availability_productionToOrder: 'Made to order',
  product_spares_titleBlade: 'Spare knives',
  product_spares_titleGeneral: 'Spare parts',
  product_spares_titleSaw: 'Spare circular sawblades',
  product_tabs_applicationParameter_noData: 'There are no application parameters available for this product',
  product_tabs_applicationParameter: 'Application parameters',
  product_tabs_downloads_noData: 'There are no downloads available for this product',
  product_tabs_downloads: 'Downloads',
  product_tabs_general: 'General',
  product_tabs_link_noData: 'There is no website link available for this product',
  product_tabs_link: 'Link to website',
  product_tabs_relatedArticles_noData: 'There are no additional dimensions available for this product',
  product_tabs_relatedArticles: 'Additional dimensions',
  product_tabs_spares_noData: 'There are no spare parts available for this product',
  product_tabs_spares: 'Spare parts',
  product_tabs_technicalInfo_noData: 'There is no technical info available for this product',
  product_tabs_technicalInfo: 'Technical info',
  product_tabs_toolApplicability_noData: 'There is no tool suitability available for this product',
  product_tabs_toolApplicability: 'Tool suitability',
  product_technicalInfo_title: 'Technical info',
  product_toolApplicability_title: 'Tool suitability',
  product_tools: 'Tools',
  productsearch_actions_addToFavorites: 'Add to favourites',
  productsearch_actions_delete: 'Delete',
  productsearch_actions_details: 'Details',
  productsearch_actions_removeFromFavorites: 'Remove from favourites',
  productsearch_actions_removeFromHistory: 'Remove from history',
  productsearch_code: 'Code',
  productsearch_codeInput_text: 'Scan the product identification number to get the product data',
  productsearch_codeInput_title: 'Data Matrix',
  productsearch_description: 'This search allows you to find a product by its identification number or serial number by manually typing the number into the input field or by using a compatible reader.',
  productsearch_error_code_text: 'The code could not be scanned. Please try again',
  productsearch_error_noProduct_textLong: 'No product data could be found with this number, please try again with a different number',
  productsearch_error_noProduct_textShort: 'No product could be found with the entered ID or serial number',
  productsearch_error_noProduct_title: 'No result for this ID number',
  productsearch_error_notEnoughDigits_textLong: 'Please enter at least 4 characters',
  productsearch_error_notEnoughDigits_textShort: 'at least 4 characters',
  productsearch_error_rfid_text: 'RFID could not be read. Please try again',
  productsearch_error_unknown_text: 'An unknown error has occurred, please try again or contact our support team',
  productsearch_error_unknown_title: 'An error has occurred',
  productsearch_history: 'History',
  productsearch_historyEmpty: 'You have not searched for any products yet. The most recently searched products are listed here in chronological order.',
  productsearch_id: 'ID number',
  productsearch_idInput_text: 'Enter product identification number to get the data of the product',
  productsearch_idInput_title: 'Enter ID number',
  productsearch_inputPlaceholder: 'Enter ID number',
  productsearch_inputPlaceholderIDSerial: 'Enter ID number/serial number',
  productsearch_loadProduct: 'Load product data',
  productsearch_openCamera: 'Open camera',
  productsearch_readerInstruction: 'Connect your reader to scan the barcodes.',
  productsearch_readerNotConnected: 'Reader not connected',
  productsearch_readerNotRecognised: 'Device not recognised',
  productsearch_rfid_disabledText: 'To search for products using RFID, NFC must be activated. To do this, call up the settings on your smartphone.',
  productsearch_rfid_disabledTitle: 'NFC not enabled',
  productsearch_rfid_openScanner: 'Open RFID scanner',
  productsearch_rfid_ready: 'Ready to scan',
  productsearch_rfid: 'RFID',
  productsearch_rfidInput_text: 'Hold your smartphone or a RFID scanner to the product to get your data.',
  productsearch_rfidInput_title: 'RFID product scan',
  productsearch_showHistory: 'Show history',
  productsearch_title: 'Product search',
  productsearch_tryAgain: 'Try again',
  start_appFunctions: 'App features',
  start_button: 'Start now',
  start_calculator_description: 'Find out important application data with our practical calculators',
  start_calculator_title: 'Calculator',
  start_favorites_description: 'Save the products you are looking for in a convenienct and easy way',
  start_favorites_title: 'Favourites',
  start_productsearch_description: 'Discover all tool data, instructions and product brochures of our standard program',
  start_productsearch_title: 'Product search',
  start_skip: 'skip',
  start_solutions: 'Solutions in new dimensions',
  start_support_description: 'Contact the Leitz support team quickly and easily',
  start_support_title: 'Support',
  start_welcomeAt: 'Welcome to',
  support_agreeToPrivacy: 'I agree with the Privacy Policy.',
  support_branch: 'Branch office',
  support_branchEdit: 'Change branch office',
  support_contactDetails: 'Contact details branch office',
  support_contactType: 'Leitz Group Head Quarters',
  support_customerNumber: 'Customer number',
  support_dataInfo_text: 'We require your data for a quick support request, so you do not have to enter this data repeatedly while making a request.',
  support_dataInfo_title: 'Why do we need your data?',
  support_eMail: 'Email',
  support_errorCustomerId: 'Customer number may only consist of numbers',
  support_errorEmailEmpty: 'Email must not be empty',
  support_errorEmailInvalid: 'Email must be valid',
  support_errorMessage: 'Message must not be empty',
  support_errorPhoneNumber: 'Phone number must not be empty',
  support_errorSubject: 'Topic must be selected',
  support_inputPlaceholderPleaseSelect: 'Please select',
  support_inputPlaceholderPleaseType: 'Please enter',
  support_inputPlaceholderSelect: 'Select',
  support_inputPlaceholderSelectBranch: 'Select branch offices',
  support_inputPlaceholderTypeHere: 'Enter here',
  support_leitzBranch: 'Leitz branch',
  support_message: 'Message',
  support_myData: 'My data',
  support_noBranchSelected: 'To start an enquiry you must first select a branch office.',
  support_phone: 'Phone',
  support_privacyInformation: 'The submitted data will be collected only for the purpose of processing your request. By submitting the form, you agree to our privacy policy.',
  support_privacyLink: 'To the privacy policy',
  support_ProfileNotFilled: 'To start an enquiry, you must first enter all the required data in your profile.',
  support_request: 'Request',
  support_requiredFields: 'The fields marked with * are mandatory.',
  support_sendingFailed: 'Your request could not be sent. Please try again later.',
  support_sendRequest: 'Send request',
  support_sent_backToSearch: 'Back to product search',
  support_sent_newRequest: 'Start new request',
  support_sent_team: 'Your LeitzXPert Team',
  support_sent_thankyou: 'Thank you for your enquiry. We do our best to process it as soon as possible.',
  support_sent_title: 'Your request has been sent',
  support_subject_agentVisit: 'Representative visit',
  support_subject_callback: 'Callback',
  support_subject_consulting: 'Consultation',
  support_subject_toolService: 'Tool service',
  support_subject: 'Topic',
  support_title: 'Support',
  support_titleXpressRequest: 'Support - XPress request',
  support_toProfile: 'To the profile',
  support_xpress: 'LeitzXPress',
  support_xpressRequest: 'LeitzXPress Request',
  tracking_allow: 'Allow',
  tracking_deny: 'Deny',
  tracking_information: 'In order to constantly improve the user experience of our app, we use the collection of anonymous data. Would you like to support us in this?',
  tracking_title: 'Help us',
  version: 'Version',
};

export const branchesEN: Branch[] = [
  {
    name: 'Leitz GmbH & Co. KG',
    location: 'Austria - Riedau',
    email: 'office.riedau@leitz.org',
    phone: '+43 (0) 7764 8200 0',
    fax: '+43 (0) 7764 8200 111',
    website: 'http://www.leitz.at',
    address: `Leitzstraße 80
    4752 Riedau
    `,
    country: 'Austria',
  },
  {
    name: 'Leitz Tooling Systems Pty. Ltd.',
    location: 'Australia - Melbourne',
    email: 'sales@leitz.com.au',
    phone: '+61 (0) 3 9760 40 00',
    fax: '+61 (0) 3 9760 40 99',
    website: 'http://www.leitz.com.au',
    address: `2/55 Barry Street
    Bayswater VIC 3153
    `,
    country: 'Australia',
  },
  {
    name: 'Leitz-Service N.V.',
    location: 'Belgium - Grimbergen',
    email: 'contact-be@leitz.org',
    phone: '+32 (0) 2251 60 47',
    fax: '+32 (0) 2252 14 36',
    website: 'https://www.leitz.org',
    address: `Industrieweg 15
    1850 Grimbergen
    `,
    country: 'Belgium',
  },
  {
    name: 'Leitz Ferramentas e Sistemas de Precisão',
    location: 'Brazil - Sao Sebastiao do Cai',
    email: 'contact@leitz.com.br',
    phone: '+55 51 3635 6600',
    fax: '',
    website: 'http://www.leitz.org',
    address: `Rua Leitz, 50 - Bairro Angico - CEP 95760-000
    Junto à Rodovia RS 122, Km 14
    Sao Sebastiao do Cai - RS
    `,
    country: 'Brazil',
  },
  {
    name: 'Leitz Tooling Systems LP',
    location: 'Canada - Vaughan',
    email: 'infocanada@leitz.org',
    phone: '+1 (0) 905 760 0375',
    fax: '+1 (0) 905 760 8125',
    website: 'https://www.leitz.org/en-ca/',
    address: `435 Four Valley Drive
    Vaughan, Ontario L4K 5X5
    `,
    country: 'Canada',
  },
  {
    name: 'Leitz GmbH',
    location: 'Switzerland - Lenzburg',
    email: 'kontakt-ch@leitz.org',
    phone: '+41 (0) 62 886 39 39',
    fax: '+41 (0) 62 886 39 40',
    website: 'http://www.leitz.org',
    address: `Hardstrasse 2
    Postfach 448
    5600 Lenzburg
    `,
    country: 'Switzerland',
  },
  {
    name: 'Leitz Tooling Systems (China) Co., Ltd.',
    location: 'China - Nanjing',
    email: 'leitzchina@leitz.com.cn',
    phone: '+86 (0) 25 52103111',
    fax: '+86 (0) 25 52103777',
    website: 'http://www.leitz.com.cn',
    address: `No.9 Shengtong Road, Moling Sub-District, Jiangning Zone, 211111 Nanjing, Jiangsu Province
    `,
    country: 'China',
  },
  {
    name: 'Leitz-nástroje s.r.o.',
    location: 'Czech Republic - Prague',
    email: 'leitz@leitz.cz',
    phone: '+420 24 14 82 699',
    fax: '+420 24 14 80 786',
    website: 'http://www.leitz.org',
    address: `Leitzova 1267/1
    Praha 5, Košíře
    `,
    country: 'Czech Republic',
  },
  {
    name: 'Leitz Werkzeugdienst GmbH',
    location: 'Germany - Bielefeld',
    email: 'service-hotline@leitz.org',
    phone: '+49 (0) 5219 240 30',
    fax: '+49 (0) 5219 240 310',
    website: 'https://www.leitz.org/de-de/',
    address: `Lübberbrede 13
    33719 Bielefeld
    `,
    country: 'Germany',
  },
  {
    name: 'Emil Leitz GmbH',
    location: 'Germany - Oberkochen',
    email: 'service-hotline@leitz.org',
    phone: '+49 (0) 7364 950 0',
    fax: '+49 (0) 7364 950 660',
    website: 'https://www.leitz.org/de-de',
    address: `Leitzstraße 2
    73447 Oberkochen
    `,
    country: 'Germany',
  },
  {
    name: 'Leitz Werkzeugdienst GmbH',
    location: 'Germany - Troisdorf',
    email: 'service-hotline@leitz.org',
    phone: '+49 (0) 2241 947 10',
    fax: '+49 (0) 2241 947 132',
    website: 'https://www.leitz.org/de-de',
    address: `Industriestraße 12
    53842 Troisdorf
    `,
    country: 'Germany',
  },
  {
    name: 'Herramientas Leitz S.L.',
    location: 'Spain - Vilassar de Dalt (Barcelona)',
    email: 'ventas@leitz.org',
    phone: '+34 937 508 417',
    fax: '+34 937 507 925',
    website: 'https://www.leitz.org/es-es/',
    address: `Narcís Monturiol, 11-13
    08339 Vilassar de Dalt (Barcelona)
    `,
    country: 'Spain',
  },
  {
    name: 'Leitz Kes Metalli Oy',
    location: 'Finland - Uurainen',
    email: 'leitz@leitzkesmetalli.fi',
    phone: '+358 (40) 18 80 55 0',
    fax: '+358 (40) 60 33 59 3',
    website: 'http://www.leitz.org/fi/',
    address: `Hitsaajantie 7
    41230 Uurainen
    `,
    country: 'Finland',
  },
  {
    name: 'Leitz S.à.r.l., Colmar',
    location: 'France - Colmar',
    email: 'leitz-france@leitz.org',
    phone: '+33 (0) 3 892 108 00',
    fax: '',
    website: 'http://www.leitz.fr',
    address: `8, Rue Emile Schwoerer
    68012 Colmar Cedex
    `,
    country: 'France',
  },
  {
    name: 'Leitz Tooling UK Ltd.',
    location: 'Great Britain - Harlow',
    email: 'salesuk@leitz.org',
    phone: '+44 (0) 1279 454 530',
    fax: '+44 (0) 1279 454 509',
    website: 'https://www.leitz.org/en-gb',
    address: `Flex Meadow, The Pinnacles
    Harlow,
    Essex, CM19 5TN
    `,
    country: 'Great Britain',
  },
  {
    name: 'Leitz orodja d.o.o.',
    location: 'Croatia - Kranj (Slovenia)',
    email: 'leitz@leitz.si',
    phone: '+386 (0) 4 238 12 10',
    fax: '+386 (0) 4 238 12 22',
    website: 'http://www.leitz.org',
    address: `Savska cesta 14
    4000 Kranj
    `,
    country: 'Slovenia',
  },
  {
    name: 'Leitz Hungária Szerszám Kft.',
    location: 'Hungary - Érd',
    email: 'leitz@leitz.hu',
    phone: '+36 (0) 23 521 900',
    fax: '',
    website: 'http://www.leitz.org',
    address: `Kis-Duna u. 6.
    2030 Érd
    `,
    country: 'Hungary',
  },
  {
    name: 'Leitz Tooling Systems India Pvt Ltd.',
    location: 'India - Bangalore',
    email: 'info@leitzindia.com',
    phone: '+91 (0) 90 083 025 82',
    fax: '',
    website: 'http://www.leitz.org/en-in/',
    address: `486 C, 14th Cross, 4th Phase,
    Peenya Industrial Area,
    Bangalore 560 058
    Karnataka
    `,
    country: 'India',
  },
  {
    name: 'Leitz Italia S.r.l.',
    location: 'Italy - Lana',
    email: 'lana@leitz.org',
    phone: '+39 0473 55 28 00',
    fax: '+39 0473 56 21 39',
    website: 'http://www.leitz.org',
    address: `Zona Industriale 9
    39011 Lana (BZ)
    `,
    country: 'Italy',
  },
  {
    name: 'Leitz Tooling Co. Ltd.',
    location: 'Japan - Yokohama',
    email: 'info@leitz.co.jp',
    phone: '+81 (0) 455 33 3020',
    fax: '+81 (0) 455 33 3021',
    website: 'http://www.leitz.co.jp',
    address: `2-7-2, Kita-Shinyokohama,
    Kohoku-ku, Yokohama 223-0059
    `,
    country: 'Japan',
  },
  {
    name: 'Leitz Baltic UAB',
    location: 'Lithuania - Kaunas',
    email: 'baltic@leitz.org',
    phone: '+370 37 281 289',
    fax: '',
    website: 'http://www.leitz.org',
    address: `Kaštonų g. 56,
    54310  Giraitė, Kauno r.
    `,
    country: 'Lithuania',
  },
  {
    name: 'Leitz-Service SARL',
    location: 'Luxembourg - Holzem',
    email: 'contact-lu@leitz.org',
    phone: '+352 (0) 399 550',
    fax: '+352 (0) 399 852',
    website: 'http://www.leitz.org',
    address: `28 Route de Capellen
    8279 Holzem
    `,
    country: 'Luxembourg',
  },
  {
    name: 'Leitz México S.A. de C.V.',
    location: 'Mexico - El Marqués',
    email: 'info@leitz.com.mx',
    phone: '+52 (0) 442 296 6870',
    fax: '+52 (0) 442 296 6882',
    website: 'https://www.leitz.org/es-mx/',
    address: `Av. Acueducto No. 15
    Parque Industrial Bernardo Quintana
    C.P. 76246 El Marqués, Querétaro
    `,
    country: 'Mexico',
  },
  {
    name: 'Leitz Tooling (M) Sdn. Bhd.',
    location: 'Malaysia - Petaling',
    email: 'sales_m@leitztools.com',
    phone: '+603 80 81 22 26',
    fax: '+603 80 81 12 26',
    website: 'http://www.leitztools.com',
    address: `No. 15, Jalan TP 3/2
    UEP Industrial Park USJ
    47600 Subang Jaya
    Selangor
    `,
    country: 'Malaysia',
  },
  {
    name: 'Leitz Service BV',
    location: 'Netherlands - Elst',
    email: 'elst@leitz-service.org',
    phone: '+31 (0)481 748 100',
    fax: '+31 (0)481 748 110',
    website: 'http://www.leitz.org',
    address: `Platinaweg 7-9
    6662 PR Elst
    `,
    country: 'Netherlands',
  },
  {
    name: 'Leitz Tooling NZ Ltd.',
    location: 'New Zealand - Penrose',
    email: 'leitz@leitz.co.nz',
    phone: '+64 800 578 665',
    fax: '+64 800 568 6652',
    website: 'http://www.leitz.co.nz',
    address: `Unit 9B Hamlin’s Hill Commercial Centre
    930 Great South Road
    Penrose 1061
    Auckland
    `,
    country: 'New Zealand',
  },
  {
    name: 'Leitz Polska Sp. z o.o.',
    location: 'Poland - Radomsko',
    email: 'leitz@leitz.pl',
    phone: '+48 (0) 44 683 03 88',
    fax: '+48 (0) 44 683 04 77',
    website: 'http://www.leitz.pl',
    address: `ul. Duńska 4
    97-500 Radomsko
    `,
    country: 'Poland',
  },
  {
    name: 'Leitz Romania S.R.L.',
    location: 'Romania - Brasov',
    email: 'office-brasov@leitz.org',
    phone: '+40 (0) 268 406 246',
    fax: '',
    website: 'http://www.leitz.org',
    address: `B-dul Grivitei Nr. 1 W
    500177 Brasov
    `,
    country: 'Romania',
  },
  {
    name: 'OOO Leitz Instrumenti',
    location: 'Russia - Moscow',
    email: 'info@leitz.ru',
    phone: '+7 495 120 11 70',
    fax: '',
    website: 'http://www.leitz.ru',
    address: `Geroya Rossii Solomatina st. 6, bld.9A
    pos. Zavoda Mosrentgen,
    108820, Moscow, Russia
    `,
    country: 'Russia',
  },
  {
    name: 'Leitz Tooling Asia Pte Ltd.',
    location: 'Singapore - Singapore',
    email: 'sales@leitztools.com',
    phone: '+65 64 62 53 68',
    fax: '+65 64 62 12 09',
    website: 'https://www.leitz.org/en-sg',
    address: `1 Clementi Loop #04-04
    Singapore 129 808
    `,
    country: 'Singapore',
  },
  {
    name: 'Leitz orodja d.o.o.',
    location: 'Slovenia - Kranj',
    email: 'leitz@leitz.si',
    phone: '+386 (0) 4 238 12 10',
    fax: '+386 (0) 4 238 12 22',
    website: 'http://www.leitz.org',
    address: `Savska cesta 14
    4000 Kranj
    `,
    country: 'Slovenia',
  },
  {
    name: 'Leitz-nástroje s.r.o.',
    location: 'Slovakia - Bratislava',
    email: 'leitz@leitz.sk',
    phone: '+421 (0) 2 52 62 00 24',
    fax: '+421 (0) 2 52 62 00 26',
    website: 'http://www.leitz.org',
    address: `Stará Vajnorská 90
    831 04 Bratislava
    `,
    country: 'Slovakia',
  },
  {
    name: 'Leitz Tooling Co., Ltd',
    location: 'Thailand - Bangkok',
    email: 'san@leitztools.com',
    phone: '+66 (0) 2 807 59 90',
    fax: '+66 (0) 2 807 59 94',
    website: 'http://www.leitzthailand.com',
    address: `6 Phutthamonthon Sai 3 Rd
    Nongkangplu, Nongkaem
    Bangkok 10160
    `,
    country: 'Thailand',
  },
  {
    name: 'Leitz Kesici Takımlar San. Tic. A.Ş.',
    location: 'Turkey - Istanbul',
    email: 'leitz@leitz.com.tr',
    phone: '+90 262 677 17 27',
    fax: '+90 262 677 17 30',
    website: 'https://www.leitz.org/tr/',
    address: `Gebze Organize Sanayi Bölgesi
    1600. Sok. No: 1602
    41400 Gebze / Kocaeli
    `,
    country: 'Turkey',
  },
  {
    name: 'Leitz Tooling Asia Pte Ltd (Branch Office)',
    location: 'Taiwan - Tainan City',
    email: 'leitztaiwan@leitztools.com',
    phone: '+886 (0) 932 723 087',
    fax: '',
    website: 'http://www.leitz.org',
    address: `6F., No. 31, Sec. 3,
    Dongmen Rd.,
    East Dist., Tainan City 70172
    `,
    country: 'Taiwan',
  },
  {
    name: 'Leitz Instrumenty Ukraina LLC',
    location: 'Ukraine - Kiev',
    email: 'kiev@leitz.org',
    phone: '+38 (0) 67 218 28 69',
    fax: '+38 (0) 67 218 28 69',
    website: 'http://www.leitz.com.ua',
    address: `Petropawlivska Borschagivka
    Wul. Soborna 2-B
    08130 Kiev
    `,
    country: 'Ukraine',
  },
  {
    name: 'Leitz Tooling Systems LP',
    location: 'USA - Grand Rapids',
    email: 'insidesales@leitz.org',
    phone: '+1 800 253 6070',
    fax: '+1 800 752 9391',
    website: 'https://www.leitz.org/en-us/',
    address: `4301 East Paris Avenue, S.E.
    Grand Rapids, MI 49512
    `,
    country: 'USA',
  },
  {
    name: 'Leitz Tooling Vietnam Company Limited',
    location: 'Vietnam - Ho Chi Minh City',
    email: 'sales_ltv@leitz.co.th',
    phone: '+84 (0)25 136 829 44',
    fax: '+84 (0)25 136 829 45',
    website: 'https://www.leitz.org/vi/',
    address: `Rental factory C-4, Road N1-2, Long Duc Industrial Park,
    An Phuoc ward, Long Thanh district,
    Dong Nai province,
    Vietnam, 810000
    `,
    country: 'Vietnam',
  },
];
