
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
  props: {
    iconSrc: String,
    label: String,
    isActive: { type: Boolean, default: false },
  },
})
export default class SidebarItem extends Vue {
}
