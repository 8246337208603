import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["title", "disabled"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["button cursor-pointer", _ctx.variant === 'secondary' ? 'is-secondary' : 'is-primary']),
    title: _ctx.title,
    disabled: _ctx.disabled
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "button__text",
          innerHTML: _ctx.label
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}