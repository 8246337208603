import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_VueRecaptcha = _resolveComponent("VueRecaptcha")

  return (_openBlock(), _createBlock(_component_VueRecaptcha, {
    sitekey: _ctx.siteKey,
    language: _ctx.languageTag,
    "load-recaptcha-script": true,
    onVerify: _ctx.handleSuccess,
    onError: _ctx.handleError
  }, null, 8, ["sitekey", "language", "onVerify", "onError"]))
}