import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "history-item__content"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "history-item__text" }
const _hoisted_4 = { class: "history-item__tags" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "history-item__menu-container" }
const _hoisted_9 = {
  key: 1,
  class: "history-item__error-message"
}
const _hoisted_10 = ["innerHTML"]

export function render(_ctx, _cache) {
  const _component_SvgElement = _resolveComponent("SvgElement")
  const _component_ContextMenu = _resolveComponent("ContextMenu")
  const _component_LoaderElement = _resolveComponent("LoaderElement")

  return (_openBlock(), _createElementBlock("div", {
    class: "history-item cursor-pointer",
    onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.openProduct()), ["self"]))
  }, [
    _createVNode(_Transition, { mode: "out-in" }, {
      default: _withCtx(() => [
        (_ctx.product)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("img", {
                src: _ctx.product.productImageUrl,
                class: "history-item__image",
                onerror: "this.src='/icons/no_product_image.svg'"
              }, null, 8, _hoisted_2),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", {
                    class: "font-size-12 color-primary",
                    innerHTML: _ctx.product.id
                  }, null, 8, _hoisted_5),
                  _createElementVNode("div", {
                    class: "font-size-12 color-white",
                    innerHTML: _ctx.product.dimensions
                  }, null, 8, _hoisted_6)
                ]),
                _createElementVNode("div", {
                  class: "font-size-14 color-black",
                  innerHTML: _ctx.product.title
                }, null, 8, _hoisted_7)
              ]),
              _createElementVNode("div", {
                class: "history-item__menu",
                onClick: _cache[2] || (_cache[2] = $event => (_ctx.toggleMenu()))
              }, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_SvgElement, {
                    src: "/icons/more.svg",
                    class: "fill-black"
                  }),
                  _createVNode(_component_ContextMenu, {
                    open: _ctx.menuOpen,
                    options: _ctx.menuOptions,
                    onOptionSelected: _cache[0] || (_cache[0] = $event => (_ctx.selectOption($event))),
                    onCloseMenu: _cache[1] || (_cache[1] = $event => (_ctx.menuOpen = false)),
                    isLocalised: "",
                    localisedPath: "productsearch_actions_"
                  }, null, 8, ["open", "options"])
                ])
              ])
            ]))
          : (_ctx.error)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("span", {
                  class: "color-red",
                  innerHTML: _ctx.$t('productsearch_error_unknown_title')
                }, null, 8, _hoisted_10)
              ]))
            : (_openBlock(), _createBlock(_component_LoaderElement, {
                key: 2,
                class: "history-item__loader"
              }))
      ]),
      _: 1
    })
  ]))
}