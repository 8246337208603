
import DropdownElement from '@/elements/DropdownElement/DropdownElement.vue';
import TabControlElement from '@/elements/TabControlElement/TabControlElement.vue';
import ToggleElement from '@/elements/ToggleElement/ToggleElement.vue';
import {
  CalculatorRouteMaterial, CalculatorRouteProcedure, CalculatorRouteType, TabNames,
} from '@/models/Enum';
import Calculate from '@/services/Calculate';
import { Options, Vue } from 'vue-class-component';
import CalculatorMillMetalCuttingSpeed from '../CalculatorMillMetalCuttingSpeed/CalculatorMillMetalCuttingSpeed.vue';
import CalculatorMillMetalRotationalSpeed from '../CalculatorMillMetalRotationalSpeed/CalculatorMillMetalRotationalSpeed.vue';
import CalculatorMillWoodCuttingSpeed from '../CalculatorMillWoodCuttingSpeed/CalculatorMillWoodCuttingSpeed.vue';
import CalculatorMillWoodRotationalSpeed from '../CalculatorMillWoodRotationalSpeed/CalculatorMillWoodRotationalSpeed.vue';
import CalculatorSawMetalCuttingSpeed from '../CalculatorSawMetalCuttingSpeed/CalculatorSawMetalCuttingSpeed.vue';
import CalculatorSawMetalRotationalSpeed from '../CalculatorSawMetalRotationalSpeed/CalculatorSawMetalRotationalSpeed.vue';
import CalculatorSawWoodCuttingSpeed from '../CalculatorSawWoodCuttingSpeed/CalculatorSawWoodCuttingSpeed.vue';
import CalculatorSawWoodRotationalSpeed from '../CalculatorSawWoodRotationalSpeed/CalculatorSawWoodRotationalSpeed.vue';
import CalculatorUnitConversion from '../CalculatorUnitConversion/CalculatorUnitConversion.vue';
import Recommendation from '../Recommendation/Recommendation.vue';

@Options({
  components: {
    Recommendation,
    DropdownElement,
    TabControlElement,
    ToggleElement,
    CalculatorMillWoodCuttingSpeed,
    CalculatorMillWoodRotationalSpeed,
    CalculatorMillMetalCuttingSpeed,
    CalculatorMillMetalRotationalSpeed,
    CalculatorSawWoodCuttingSpeed,
    CalculatorSawWoodRotationalSpeed,
    CalculatorSawMetalCuttingSpeed,
    CalculatorSawMetalRotationalSpeed,
    CalculatorUnitConversion,
  },
  props: {
    type: String,
    material: String,
    procedure: String,
    calculatorTool1: Object,
    calculatorTool2: Object,
    compare: Boolean,
  },
})
export default class Calculator extends Vue {
  type!: CalculatorRouteType;

  material!: CalculatorRouteMaterial;

  procedure!: CalculatorRouteProcedure;

  tabNames = TabNames;

  calculatorRouteType = CalculatorRouteType;

  calculatorRouteMaterial = CalculatorRouteMaterial;

  calculatorRouteProcedure = CalculatorRouteProcedure;

  materialOptions = [CalculatorRouteMaterial.Wood, CalculatorRouteMaterial.Metal];

  procedureOptions = [
    CalculatorRouteProcedure.CuttingSpeed,
    CalculatorRouteProcedure.RotationalSpeed,
  ];

  physicalQuantity = Calculate.physicalQuantities.length.label;

  physicalQuantityOptions = Object.values(Calculate.physicalQuantities)
    .map((quantity) => quantity.label);

  selectMaterial(material: string) {
    this.$emit('selectMaterial', material);
  }

  selectProcedure(procedure: string) {
    this.$emit('selectProcedure', procedure);
  }
}
