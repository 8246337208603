
import { Options, Vue } from 'vue-class-component';
import ProductDownloadItem from '../ProductDownloadItem/ProductDownloadItem.vue';
import ProductTabEmptyState from '../ProductTabEmptyState/ProductTabEmptyState.vue';

@Options({
  components: {
    ProductDownloadItem,
    ProductTabEmptyState,
  },
  props: {
    product: Object,
  },
})
export default class ProductDownloads extends Vue {
}
