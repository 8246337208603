import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "calculator-view" }
const _hoisted_2 = { class: "calculator-view__title" }
const _hoisted_3 = { class: "font-size-28 color-black" }
const _hoisted_4 = { class: "calculator-view__content" }

export function render(_ctx, _cache) {
  const _component_SvgElement = _resolveComponent("SvgElement")
  const _component_TabMenuItem = _resolveComponent("TabMenuItem")
  const _component_TabMenuButton = _resolveComponent("TabMenuButton")
  const _component_TabMenu = _resolveComponent("TabMenu")
  const _component_Calculator = _resolveComponent("Calculator")
  const _component_LegalInformation = _resolveComponent("LegalInformation")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SvgElement, {
        src: "/icons/calculator.svg",
        class: "calculator-view__icon fill-primary"
      }),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('calculator_title')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_TabMenu, { uppercase: true }, {
        default: _withCtx(() => [
          _createVNode(_component_TabMenuItem, {
            label: _ctx.$t(`calculator_tabs_${_ctx.calculatorRouteType.Mill}`),
            icon: "/icons/mill.svg",
            isActive: _ctx.type === _ctx.calculatorRouteType.Mill,
            onClick: _cache[0] || (_cache[0] = $event => (_ctx.selectType(_ctx.calculatorRouteType.Mill)))
          }, null, 8, ["label", "isActive"]),
          _createVNode(_component_TabMenuItem, {
            label: _ctx.$t(`calculator_tabs_${_ctx.calculatorRouteType.Saw}`),
            icon: "/icons/saw.svg",
            isActive: _ctx.type === _ctx.calculatorRouteType.Saw,
            onClick: _cache[1] || (_cache[1] = $event => (_ctx.selectType(_ctx.calculatorRouteType.Saw)))
          }, null, 8, ["label", "isActive"]),
          _createVNode(_component_TabMenuItem, {
            label: _ctx.$t(`calculator_tabs_${_ctx.calculatorRouteType.Unit}`),
            icon: "/icons/unit.svg",
            isActive: _ctx.type === _ctx.calculatorRouteType.Unit,
            onClick: _cache[2] || (_cache[2] = $event => (_ctx.selectType(_ctx.calculatorRouteType.Unit)))
          }, null, 8, ["label", "isActive"]),
          _createVNode(_component_TabMenuButton, {
            label: _ctx.$t('calculator_resetCalculator'),
            icon: "/icons/reset.svg",
            iconSize: "20px",
            onButtonClicked: _cache[3] || (_cache[3] = $event => (_ctx.reset()))
          }, null, 8, ["label"])
        ]),
        _: 1
      }),
      _createVNode(_Transition, { mode: "out-in" }, {
        default: _withCtx(() => [
          (!_ctx.reload)
            ? (_openBlock(), _createBlock(_component_Calculator, {
                key: 0,
                calculatorTool1: _ctx.calculatorTool1,
                calculatorTool2: _ctx.calculatorTool2,
                compare: _ctx.compare,
                "onUpdate:compare": _cache[4] || (_cache[4] = $event => ((_ctx.compare) = $event)),
                type: _ctx.type,
                material: _ctx.material,
                procedure: _ctx.procedure,
                onSelectMaterial: _cache[5] || (_cache[5] = $event => (_ctx.selectMaterial($event))),
                onSelectProcedure: _cache[6] || (_cache[6] = $event => (_ctx.selectProcedure($event)))
              }, null, 8, ["calculatorTool1", "calculatorTool2", "compare", "type", "material", "procedure"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_LegalInformation)
  ]))
}