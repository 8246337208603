
import { RouteNames } from '@/models/Enum';
import { Options, Vue } from 'vue-class-component';
import ButtonElement from '@/elements/ButtonElement/ButtonElement.vue';

@Options({
  components: {
    ButtonElement,
  },
  props: {
    relatedArticle: Object,
  },
})
export default class ProductRelatedArticleItem extends Vue {
  routeNames = RouteNames;

  expanded = false;
}
