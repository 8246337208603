
import SvgElement from '@/elements/SvgElement/SvgElement.vue';
import { RouteNames } from '@/models/Enum';
import { Options, Vue } from 'vue-class-component';
import AppLinks from '../AppLinks/AppLinks.vue';
import MobileApp from '../MobileApp/MobileApp.vue';

@Options({
  components: {
    SvgElement,
    AppLinks,
    MobileApp,
  },
  watch: {
    $route: {
      handler: 'onRouteChanged',
    },
  },
})
export default class NoMobileOverlay extends Vue {
  isRedirect = false;

  onRouteChanged() {
    if (this.$route.name === RouteNames.App) {
      this.isRedirect = true;
    } else {
      this.isRedirect = false;
    }
  }
}
