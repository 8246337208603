import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "calculator__unit" }
const _hoisted_2 = { class: "calculator__units" }

export function render(_ctx, _cache) {
  const _component_DropdownElement = _resolveComponent("DropdownElement")
  const _component_CalculatorUnitInput = _resolveComponent("CalculatorUnitInput")
  const _component_CalculatorRow = _resolveComponent("CalculatorRow")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DropdownElement, {
      modelValue: _ctx.selectedQuantity,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.selectedQuantity) = $event)),
      id: "physicalQuantity",
      label: _ctx.$t('calculator_unit'),
      options: _ctx.physicalQuantityOptions,
      isLocalised: "",
      localisedPath: "calculator_quantityType_"
    }, null, 8, ["modelValue", "label", "options"]),
    _createVNode(_component_CalculatorUnitInput, {
      inputValue: _ctx.inputValue,
      "onUpdate:inputValue": _cache[1] || (_cache[1] = $event => ((_ctx.inputValue) = $event)),
      selectedInputUnit: _ctx.selectedInputUnit,
      "onUpdate:selectedInputUnit": _cache[2] || (_cache[2] = $event => ((_ctx.selectedInputUnit) = $event)),
      inputUnitOptions: _ctx.inputUnitOptions
    }, null, 8, ["inputValue", "selectedInputUnit", "inputUnitOptions"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.physicalQuantity.units, (unit, index) => {
        return (_openBlock(), _createBlock(_component_CalculatorRow, {
          quantity: unit,
          t1: _ctx.unitValues[index],
          "onUpdate:t1": $event => ((_ctx.unitValues[index]) = $event),
          t2: _ctx.unitValues[index],
          "onUpdate:t2": $event => ((_ctx.unitValues[index]) = $event),
          isConversion: "",
          lastRow: index === _ctx.physicalQuantity.units.length - 1
        }, null, 8, ["quantity", "t1", "onUpdate:t1", "t2", "onUpdate:t2", "lastRow"]))
      }), 256))
    ])
  ]))
}