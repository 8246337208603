import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "search-view__content",
  ref: "searchview"
}

export function render(_ctx, _cache) {
  const _component_Search = _resolveComponent("Search")
  const _component_History = _resolveComponent("History")
  const _component_LegalInformation = _resolveComponent("LegalInformation")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["search-view", { 'is-collapsed' : _ctx.isCollapsed }]),
    onScroll: _cache[4] || (_cache[4] = $event => (_ctx.onScroll()))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Search, {
        ref: "search",
        isCollapsed: _ctx.isCollapsed,
        loading: _ctx.loading,
        errorMsg: _ctx.showErrorDigits ? _ctx.$t('productsearch_error_notEnoughDigits_textLong') : (_ctx.showErrorNoProduct ? _ctx.$t('productsearch_error_noProduct_textShort') : null),
        onUpdate: _cache[0] || (_cache[0] = $event => (_ctx.showErrorDigits = false, _ctx.showErrorNoProduct = false)),
        onSearchProduct: _cache[1] || (_cache[1] = $event => (_ctx.searchProduct($event)))
      }, null, 8, ["isCollapsed", "loading", "errorMsg"]),
      (_ctx.isCollapsed)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            style: _normalizeStyle(`height: ${_ctx.placholderHeight}px;`),
            class: "search-view__placeholder"
          }, null, 4))
        : _createCommentVNode("", true),
      (_openBlock(), _createBlock(_component_History, {
        key: _ctx.historyProducts.length,
        historyProducts: _ctx.historyProducts,
        onDeleteHistoryItem: _cache[2] || (_cache[2] = $event => (_ctx.deleteProductFromHistory($event))),
        onOpenProduct: _cache[3] || (_cache[3] = $event => (_ctx.addProductToHistory($event)))
      }, null, 8, ["historyProducts"])),
      _createVNode(_component_LegalInformation)
    ], 512)
  ], 34))
}