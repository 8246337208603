/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable dot-notation */
import he from 'he';
import { MediaType, ProductCalculatorType, SpareType } from '@/models/Enum';
import {
  ProductPreview,
  Medium,
  TableEntry,
  TechnicalInfo,
  Matrix,
  ToolApplicability,
  ApplicationParameter,
  RelatedArticle,
  RelatedArticles,
  Spare,
  Spares,
  DownloadSection,
  DownloadFile,
  Downloads,
  Product,
  CalculatorTool,
  DescriptionEntry,
  InfoText,
} from '@/models/Interface';
import { branches } from '@/i18n';

export default class MappingService {
  private static videoTypes = ['mp4'];

  static getContent(response: any) {
    try {
      return response.data.hits.hits[0]['_source'];
    } catch (error) {
      return null;
    }
  }

  static getContentArray(response: any) {
    try {
      return response.data.hits.hits.map((hit: any) => hit['_source']);
    } catch (error) {
      return [];
    }
  }

  static getProductId(source: any) {
    try {
      return source.product_id;
    } catch (error) {
      return null;
    }
  }

  static getArticleId(source: any) {
    try {
      return source['SNR_IDN_NR'];
    } catch (error) {
      return null;
    }
  }

  static mapProductPreview(articleSource: any, productSource: any) {
    const id = MappingService.getProperty(articleSource, ['sku']) ?? '';

    let title = MappingService.getProperty(articleSource, ['attributes', '1175', 'value']) ?? '';
    title = MappingService.decode(title);

    let dimensions = MappingService.getProperty(articleSource, ['attributes', '1180', 'value']) ?? '';
    dimensions = MappingService.decode(dimensions);

    const productImageUrls = MappingService.getList(productSource, ['files', '2100', 'files']);
    const productImageUrl = productImageUrls ? productImageUrls[0] : '';

    const productPreview: ProductPreview = {
      id,
      title,
      productImageUrl,
      dimensions,
    };

    return productPreview;
  }

  static mapProduct(articleSource: any, productSource: any, language: number) {
    /** general ************************************************************* */

    const id = MappingService.getProperty(articleSource, ['sku']) ?? '';

    let title = MappingService.getProperty(articleSource, ['attributes', '1175', 'value']) ?? '';
    title = MappingService.decode(title);

    let subtitle = MappingService.getProperty(productSource, ['attributes', '1025', 'value']) ?? '';
    subtitle = MappingService.decode(subtitle);

    let dimensions = MappingService.getProperty(articleSource, ['attributes', '1180', 'value']) ?? '';
    dimensions = MappingService.decode(dimensions);

    let productMedia: Medium[] | null = [];
    const productMediaObject = MappingService.getObject(productSource, ['files', '2100']);
    if (productMedia && productMediaObject) {
      productMedia = productMedia.concat(MappingService.getMedia(productMediaObject, false));
    }
    if (productMedia && productMedia.length === 0) {
      productMedia = null;
    }

    const websiteUrl = MappingService.getProperty(productSource, ['attributes', '367183', 'value']);

    let productDescription: DescriptionEntry[] | null = [];
    const descriptionEntryIds = MappingService.getReferences(productSource, ['references', '367226']);
    // const descriptionEntryIds = ['1040', '1045', '1050', '1055'];
    descriptionEntryIds.forEach((entryId) => {
      const productDescriptionTitle = MappingService.getProperty(productSource, ['attributes', entryId, 'title']);
      const productDescriptionText = MappingService.getProperty(productSource, ['attributes', entryId, 'value']);
      if (productDescriptionTitle && productDescriptionText) {
        productDescription?.push({
          title: MappingService.decode(productDescriptionTitle),
          text: MappingService.decode(productDescriptionText),
        });
      }
    });
    if (productDescription && productDescription.length === 0) {
      productDescription = null;
    }

    let pictograms: Medium[] | null = [];
    const pictogramsIds = MappingService.getReferences(productSource, ['references', '367219']);
    // const pictogramsIds = ['2110', '2115', '2120', '2125', '2130', '2135', '2140', '2145'];
    pictogramsIds.forEach((pictogramsId) => {
      const pictogramsObject = MappingService.getObject(productSource, ['files', pictogramsId]);
      if (pictograms && pictogramsObject) {
        pictograms = pictograms.concat(MappingService.getMedia(pictogramsObject, true));
      }
    });
    if (pictograms && pictograms.length === 0) {
      pictograms = null;
    }

    /** technicalInfo ************************************************************* */

    let technicalInfoAttributes: TableEntry[] | null = [];
    const technicalInfoAttributesIds = MappingService.getReferences(productSource, ['references', '367220']);
    // const technicalInfoAttributesIds = ['2040', '1275', '1230', '367131', '367137', '367139', '367140', '367141', '367142'];
    technicalInfoAttributesIds.forEach((technicalInfoAttributesId) => {
      const technicalInfoAttributeLabel = MappingService.getProperty(articleSource, ['attributes', technicalInfoAttributesId, 'title']);
      const technicalInfoAttributeObject = MappingService.getObject(articleSource, ['attributes', technicalInfoAttributesId]);
      if (technicalInfoAttributeLabel && technicalInfoAttributeLabel !== '' && technicalInfoAttributeObject) {
        const values = MappingService.getObjectContent(technicalInfoAttributeObject, technicalInfoAttributesId, language);
        if (values && values.length > 0) {
          technicalInfoAttributes?.push({ label: MappingService.decode(technicalInfoAttributeLabel), value: values });
        }
      }
    });
    if (technicalInfoAttributes && technicalInfoAttributes.length === 0) {
      technicalInfoAttributes = null;
    }

    let technicalInfoToolTable: TableEntry[] | null = [];
    const technicalInfoToolTableIds = MappingService.getReferences(productSource, ['references', '2260']);
    technicalInfoToolTableIds.forEach((technicalInfoToolTableId) => {
      const technicalInfoToolTableLabel = MappingService.getProperty(articleSource, ['attributes', technicalInfoToolTableId, 'title']);
      const technicalInfoToolTableObject = MappingService.getObject(articleSource, ['attributes', technicalInfoToolTableId]);
      if (technicalInfoToolTableLabel && technicalInfoToolTableLabel !== '' && technicalInfoToolTableObject) {
        const values = MappingService.getObjectContent(technicalInfoToolTableObject, technicalInfoToolTableId, language);
        if (values && values.length > 0) {
          technicalInfoToolTable?.push({ label: MappingService.decode(technicalInfoToolTableLabel), value: values });
        }
      }
    });
    if (technicalInfoToolTable && technicalInfoToolTable.length === 0) {
      technicalInfoToolTable = null;
    }

    const technicalInfoTextRotationalSpeedLabel = MappingService.getProperty(productSource, ['attributes', '1075', 'title']);
    const technicalInfoTextRotationalSpeedValue = MappingService.getProperty(productSource, ['attributes', '1075', 'value']);
    let technicalInfoTextRotationalSpeed: InfoText | null = null;
    if (technicalInfoTextRotationalSpeedValue && technicalInfoTextRotationalSpeedValue !== '') {
      technicalInfoTextRotationalSpeed = {
        title: MappingService.decode(technicalInfoTextRotationalSpeedLabel),
        content: MappingService.decode(technicalInfoTextRotationalSpeedValue),
      };
    }

    let technicalInfoTableText = MappingService.getProperty(productSource, ['attributes', '1065', 'value']);
    technicalInfoTableText = MappingService.decode(technicalInfoTableText);
    let technicalInfoNoticeText = MappingService.getProperty(productSource, ['attributes', '1080', 'value']);
    technicalInfoNoticeText = MappingService.decode(technicalInfoNoticeText);
    let technicalInfoAdditionalText = MappingService.getProperty(productSource, ['attributes', '1085', 'value']);
    technicalInfoAdditionalText = MappingService.decode(technicalInfoAdditionalText);

    let technicalInfo: TechnicalInfo | null = null;
    if (
      technicalInfoToolTable
      || technicalInfoAttributes
      || technicalInfoTextRotationalSpeed
      || technicalInfoTableText
      || technicalInfoNoticeText
      || technicalInfoAdditionalText
    ) {
      technicalInfo = {
        toolTable: technicalInfoToolTable,
        attributes: technicalInfoAttributes,
        textRotationalSpeed: technicalInfoTextRotationalSpeed,
        tableText: technicalInfoTableText,
        noticeText: technicalInfoNoticeText,
        additionalText: technicalInfoAdditionalText,
      };
    }

    /** toolApplicability ************************************************************* */

    let toolApplicabilityAttributes: TableEntry[] | null = [];
    const toolApplicabilityAttributesIds = MappingService.getReferences(productSource, ['references', '367221']);
    // const toolApplicabilityAttributesIds = ['367162', '2060', '1865', '367115'];
    toolApplicabilityAttributesIds.forEach((toolApplicabilityAttributesId) => {
      const applicabilityAttributeLabel = MappingService.getProperty(articleSource, ['attributes', toolApplicabilityAttributesId, 'title']);
      const applicabilityAttributeObject = MappingService.getObject(articleSource, ['attributes', toolApplicabilityAttributesId]);
      if (applicabilityAttributeLabel && applicabilityAttributeLabel !== '' && applicabilityAttributeObject) {
        const values = MappingService.getObjectContent(applicabilityAttributeObject, toolApplicabilityAttributesId, language);
        if (values && values.length > 0) {
          toolApplicabilityAttributes?.push({ label: MappingService.decode(applicabilityAttributeLabel), value: values });
        }
      }
    });
    if (toolApplicabilityAttributes && toolApplicabilityAttributes.length === 0) {
      toolApplicabilityAttributes = null;
    }

    const toolApplicabilityEngineTypeTitle = MappingService.getProperty(articleSource, ['attributes', '367194', 'title']);
    const toolApplicabilityEngineTypeObject = MappingService.getProperty(articleSource, ['attributes', '367194', 'items']);
    const toolApplicabilityEngineType = MappingService.getMatrixContent(toolApplicabilityEngineTypeObject);

    const toolApplicabilityWorkpieceMaterialTitle = MappingService.getProperty(articleSource, ['attributes', '367195', 'title']);
    const toolApplicabilityWorkpieceMaterialObject = MappingService.getProperty(articleSource, ['attributes', '367195', 'items']);
    const toolApplicabilityWorkpieceMaterial = MappingService.getMatrixContent(toolApplicabilityWorkpieceMaterialObject);

    let toolApplicability: ToolApplicability | null = null;
    if (
      toolApplicabilityAttributes
      || toolApplicabilityEngineType.length > 0
      || toolApplicabilityWorkpieceMaterial.length > 0
    ) {
      toolApplicability = {
        attributes: toolApplicabilityAttributes,
        engineTypeTitle: toolApplicabilityEngineTypeTitle,
        engineType: toolApplicabilityEngineType.length > 0 ? toolApplicabilityEngineType : null,
        workpieceMaterialTitle: toolApplicabilityWorkpieceMaterialTitle,
        workpieceMaterial: toolApplicabilityWorkpieceMaterial.length > 0 ? toolApplicabilityWorkpieceMaterial : null,
      };
    }

    /** applicationParameter ************************************************************* */

    let applicationParameterAttributes: TableEntry[] | null = [];
    const applicationParameterAttributesIds = MappingService.getReferences(productSource, ['references', '367222']);
    // const applicationParameterAttributesIds = ['1205', '1787', '1788', '1295', '1675', '1555', '1551', '1935', '1940'];
    applicationParameterAttributesIds.forEach((applicationParameterAttributesId) => {
      const applicationParameterAttributesLabel = MappingService.getProperty(articleSource, ['attributes', applicationParameterAttributesId, 'title']);
      const applicationParameterAttributesObject = MappingService.getObject(articleSource, ['attributes', applicationParameterAttributesId]);
      if (applicationParameterAttributesLabel && applicationParameterAttributesLabel !== '' && applicationParameterAttributesObject) {
        const values = MappingService.getObjectContent(applicationParameterAttributesObject, applicationParameterAttributesId, language);
        if (values && values.length > 0) {
          applicationParameterAttributes?.push({ label: MappingService.decode(applicationParameterAttributesLabel), value: values });
        }
      }
    });
    if (applicationParameterAttributes && applicationParameterAttributes.length === 0) {
      applicationParameterAttributes = null;
    }

    const applicationParameterTextRotationalSpeedLabel = MappingService.getProperty(productSource, ['attributes', '1075', 'title']);
    const applicationParameterTextRotationalSpeedValue = MappingService.getProperty(productSource, ['attributes', '1075', 'value']);
    let applicationParameterTextRotationalSpeed: InfoText | null = null;
    if (applicationParameterTextRotationalSpeedValue && applicationParameterTextRotationalSpeedValue !== '') {
      applicationParameterTextRotationalSpeed = {
        title: MappingService.decode(applicationParameterTextRotationalSpeedLabel),
        content: MappingService.decode(applicationParameterTextRotationalSpeedValue),
      };
    }

    let applicationParameter: ApplicationParameter | null = null;

    if (
      applicationParameterAttributes
      || applicationParameterTextRotationalSpeed
    ) {
      applicationParameter = {
        attributes: applicationParameterAttributes,
        textRotationalSpeed: applicationParameterTextRotationalSpeed,
      };
    }

    /** relatedArticles **************************************************** */

    const relatedArticles: RelatedArticles = {
      title: subtitle,
      items: null,
    };

    /** spares ************************************************************* */

    const sparesItems: Spare[] = [];

    const sparesItemsBladeAttributeIds = MappingService.getReferences(productSource, ['references', '2265']);
    const sparesItemsBladeIds = MappingService.getList(productSource, ['relations', '2200', 'articles']);
    sparesItemsBladeIds?.forEach((sparesItemsBladeId) => {
      sparesItems.push({
        id: sparesItemsBladeId, type: SpareType.Blade, title: null, number: null, attributes: null, attributeIds: sparesItemsBladeAttributeIds,
      });
    });

    const sparesItemsSawAttributeIds = MappingService.getReferences(productSource, ['references', '2270']);
    const sparesItemsSawIds = MappingService.getList(productSource, ['relations', '2205', 'articles']);
    sparesItemsSawIds?.forEach((sparesItemsSawId) => {
      sparesItems.push({
        id: sparesItemsSawId, type: SpareType.Saw, title: null, number: null, attributes: null, attributeIds: sparesItemsSawAttributeIds,
      });
    });

    const sparesItemsGeneralAttributeIds = MappingService.getReferences(productSource, ['references', '2275']);
    const sparesItemsGeneralIds = MappingService.getList(productSource, ['relations', '2210', 'articles']);
    sparesItemsGeneralIds?.forEach((sparesItemsGeneralId) => {
      sparesItems.push({
        id: sparesItemsGeneralId, type: SpareType.General, title: null, number: null, attributes: null, attributeIds: sparesItemsGeneralAttributeIds,
      });
    });

    let sparesTextBlade = MappingService.getProperty(productSource, ['attributes', '1095', 'value']);
    sparesTextBlade = MappingService.decode(sparesTextBlade);

    let sparesTextSaw = MappingService.getProperty(productSource, ['attributes', '1100', 'value']);
    sparesTextSaw = MappingService.decode(sparesTextSaw);

    let sparesTextGeneral = MappingService.getProperty(productSource, ['attributes', '1090', 'value']);
    sparesTextGeneral = MappingService.decode(sparesTextGeneral);

    let sparesExplodedViewMedia: Medium[] | null = [];
    const sparesExplodedViewMediaObject = MappingService.getObject(productSource, ['files', '2160']);
    if (sparesExplodedViewMedia && sparesExplodedViewMediaObject) {
      sparesExplodedViewMedia = sparesExplodedViewMedia.concat(MappingService.getMedia(sparesExplodedViewMediaObject, false));
    }
    if (sparesExplodedViewMedia && sparesExplodedViewMedia.length === 0) {
      sparesExplodedViewMedia = null;
    }

    let spares: Spares | null = null;
    if (
      sparesItems.length > 0
      || sparesTextBlade
      || sparesTextSaw
      || sparesTextGeneral
      || sparesExplodedViewMedia
    ) {
      spares = {
        items: sparesItems.length > 0 ? sparesItems : null,
        textBlade: sparesTextBlade,
        textSaw: sparesTextSaw,
        textGeneral: sparesTextGeneral,
        explodedViewMedia: sparesExplodedViewMedia,
      };
    }

    /** downloads ************************************************************* */

    const downloadsSections: DownloadSection[] = [];
    const downloadsSectionsIds = MappingService.getReferences(productSource, ['references', '367223']);
    // const downloadsSectionsIds = ['367177', '367178'];
    downloadsSectionsIds.forEach((downloadsSectionsId) => {
      const sectionLabel = MappingService.getProperty(productSource, ['files', downloadsSectionsId, 'label']);
      const urls = MappingService.getList(productSource, ['files', downloadsSectionsId, 'files']);
      const labels = MappingService.getList(productSource, ['files', downloadsSectionsId, 'names']);
      const downloadFiles: DownloadFile[] = [];
      urls?.forEach((url, index) => {
        if (url !== '') {
          const type = 'pdf'; // TODO get dymaic value
          const size = '2,4Mb'; // is not used
          let label = labels ? (MappingService.decode(labels[index])) : '';
          if (label === '') {
            label = (MappingService.decode(sectionLabel)) ?? title;
          }
          downloadFiles.push({
            type, url, label, size,
          });
        }
      });
      if (downloadFiles.length > 0) {
        downloadsSections.push({ title: (MappingService.decode(sectionLabel)) ?? '', downloadFiles });
      }
    });

    let downloads: Downloads | null = null;
    if (downloadsSections.length > 0) {
      downloads = { sections: downloadsSections };
    }

    /** productImagesAndVideos ************************************************************* */

    let productImagesAndVideos: Medium[] | null = [];
    // const productImagesAndVideosIds = ['2100', '2165'];
    const productImagesAndVideosIds = MappingService.getReferences(productSource, ['references', '367224']);
    // const productImagesAndVideosIds = ['2100'];
    productImagesAndVideosIds.forEach((productImagesAndVideosId) => {
      const productImagesAndVideosObject = MappingService.getObject(productSource, ['files', productImagesAndVideosId]);
      if (productImagesAndVideos && productImagesAndVideosObject) {
        productImagesAndVideos = productImagesAndVideos.concat(MappingService.getMedia(productImagesAndVideosObject, false));
      }
    });
    if (productImagesAndVideos && productImagesAndVideos.length === 0) {
      productImagesAndVideos = null;
    }

    const youTubeVideoId = MappingService.getProperty(productSource, ['attributes', '367182', 'value']);
    const youTubeVideoLabel = MappingService.getProperty(productSource, ['attributes', '367182', 'title']);
    if (youTubeVideoId) {
      const label = MappingService.decode(youTubeVideoLabel) ?? title;
      productImagesAndVideos?.push({
        type: MediaType.YouTube, url: `https://www.youtube.com/embed/${youTubeVideoId}`, label, description: null,
      });
    }

    if (productImagesAndVideos && productImagesAndVideos.length === 0) {
      productImagesAndVideos = null;
    }

    /** sketchesAndDiagrams ************************************************************* */

    let sketchesAndDiagrams: Medium[] | null = [];
    const sketchesAndDiagramsIds = MappingService.getReferences(productSource, ['references', '367225']);
    // const sketchesAndDiagramsIds = ['2150', '2155', '2160', '2165'];
    sketchesAndDiagramsIds.forEach((sketchesAndDiagramsId) => {
      const sketchesAndDiagramsObject = MappingService.getObject(productSource, ['files', sketchesAndDiagramsId]);
      if (sketchesAndDiagrams && sketchesAndDiagramsObject) {
        sketchesAndDiagrams = sketchesAndDiagrams.concat(MappingService.getMedia(sketchesAndDiagramsObject, false));
      }
    });
    if (sketchesAndDiagrams && sketchesAndDiagrams.length === 0) {
      sketchesAndDiagrams = null;
    }

    /** calculator ************************************************************* */

    const calculatorTypeIds = MappingService.getKeys(articleSource, ['attributes', '2040', 'items']);
    let calculatorType = ProductCalculatorType.Mill;
    if (calculatorTypeIds.includes('423')) {
      calculatorType = ProductCalculatorType.Saw;
    }

    const toothCount = MappingService.getProperty(articleSource, ['attributes', '367098', 'value']);
    const diameter = MappingService.getProperty(articleSource, ['attributes', '1212', 'value']);

    const calculatorToolValues: CalculatorTool = {
      averageChipThickness: '',
      chipSheetLength: '',
      cutPath: '',
      cutterStepDepth: '',
      cuttingDepth: '',
      cuttingSpeed: '',
      diameter: diameter ?? '',
      feedPath: '',
      feedPerRotation: '',
      feedRate: '',
      maximumChipThickness: '',
      protrusionSawBlade: '',
      rotationalSpeed: '',
      toothCount: toothCount ?? '',
      toothFeed: '',
    };

    /** product ************************************************************* */

    const product: Product = {
      id,
      title,
      subtitle,
      dimensions,
      productMedia,
      pictograms,
      productDescription,
      websiteUrl,
      technicalInfo,
      toolApplicability,
      applicationParameter,
      spares,
      relatedArticles,
      downloads,
      productImagesAndVideos,
      sketchesAndDiagrams,
      calculatorType,
      calculatorToolValues,
    };
    return product;
  }

  static mapSpare(articleSource: any, spare: Spare, language: number) {
    let title = MappingService.getProperty(articleSource, ['attributes', '1175', 'value']) ?? '';
    title = MappingService.decode(title);

    // TODO add route to attribute
    const number: string | null = null;

    let attributes: TableEntry[] | null = [];
    spare.attributeIds?.forEach((attributeId) => {
      const attributeLabel = MappingService.getProperty(articleSource, ['attributes', attributeId, 'title']);
      const attributeObject = MappingService.getObject(articleSource, ['attributes', attributeId]);
      if (attributeLabel !== null && attributeLabel !== '' && attributeObject) {
        const attributeValues = MappingService.getObjectContent(attributeObject, attributeId, language);
        if (attributeValues && attributeValues.length > 0) {
          attributes?.push({ label: attributeLabel, value: attributeValues });
        }
      }
    });
    if (attributes.length === 0) {
      attributes = null;
    }

    const filledSpare: Spare = {
      title,
      id: spare.id,
      type: spare.type,
      number,
      attributes,
      attributeIds: null,
    };
    return filledSpare;
  }

  static mapRelatedArticles(relatedArticlesSource: any, productSource: any, language: number) {
    let relatedArticles: RelatedArticle[] | null = [];

    const attributesIds = MappingService.getReferences(productSource, ['references', '2260']);

    const localizedRelatedArticles = (relatedArticlesSource as any[]).filter((article: any) => article.id.endsWith(`-${language}`));

    const sortAttributes = MappingService.getReferences(productSource, ['references', '1140']);
    sortAttributes.push('367123');
    sortAttributes.reverse();

    sortAttributes.forEach((sortAttribute) => {
      localizedRelatedArticles.sort((a, b) => {
        let valueA: string | number | null = MappingService.getProperty(a, ['attributes', sortAttribute, 'value']);
        if (valueA == null) {
          const objectA = MappingService.getObject(a, ['attributes', sortAttribute]);
          valueA = MappingService.getObjectContent(objectA, sortAttribute, language)?.toString() ?? '';
        }
        valueA = Number.isNaN(Number(valueA)) ? valueA : Number(valueA);

        let valueB: string | number | null = MappingService.getProperty(b, ['attributes', sortAttribute, 'value']);
        if (valueB == null) {
          const objectB = MappingService.getObject(b, ['attributes', sortAttribute]);
          valueB = MappingService.getObjectContent(objectB, sortAttribute, language)?.toString() ?? '';
        }
        valueB = Number.isNaN(Number(valueB)) ? valueB : Number(valueB);

        if (valueA < valueB) {
          return -1;
        }
        if (valueA > valueB) {
          return 1;
        }
        return 0;
      });
    });

    localizedRelatedArticles.forEach((articleSource: any) => {
      const id = MappingService.getProperty(articleSource, ['sku']) ?? '';

      let title = MappingService.getProperty(articleSource, ['attributes', '1175', 'value']) ?? '';
      title = MappingService.decode(title);

      let dimensions = MappingService.getProperty(articleSource, ['attributes', '1180', 'value']) ?? '';
      dimensions = MappingService.decode(dimensions);

      let attributes: TableEntry[] | null = [];
      attributesIds.forEach((attributesId) => {
        const attributesLabel = MappingService.getProperty(articleSource, ['attributes', attributesId, 'title']);
        const attributesObject = MappingService.getObject(articleSource, ['attributes', attributesId]);
        if (attributesLabel && attributesLabel !== '' && attributesObject) {
          const values = MappingService.getObjectContent(attributesObject, attributesId, language);
          if (values && values.length > 0) {
            attributes?.push({ label: MappingService.decode(attributesLabel), value: values });
          }
        }
      });

      if (attributes && attributes.length === 0) {
        attributes = null;
      }

      const relatedArticle: RelatedArticle = {
        id,
        title,
        dimensions,
        attributes,
      };
      relatedArticles?.push(relatedArticle);
    });

    if (relatedArticles && relatedArticles.length === 0) {
      relatedArticles = null;
    }

    return relatedArticles;
  }

  static mapBranches(language: number | null) {
    try {
      return branches[language ?? 3];
    } catch {
      return branches[3];
    }
  }

  private static getProperty(element: any, keys: string[]): string | null {
    const value = element[keys[0]];
    keys.shift();
    if (!value) return null;
    if (keys.length === 0) return value;
    return MappingService.getProperty(value, keys);
  }

  private static getObject(element: any, keys: string[]): any | null {
    const value = element[keys[0]];
    keys.shift();
    if (!value) return null;
    if (keys.length === 0) return value;
    return MappingService.getObject(value, keys);
  }

  private static getList(element: any, keys: string[]): string[] | null {
    const value = element[keys[0]];
    keys.shift();
    if (!value) return null;
    if (keys.length === 0) return value;
    return MappingService.getList(value, keys);
  }

  private static getValues(element: any, keys: string[]): string[] {
    const value = element[keys[0]];
    keys.shift();
    if (!value) return [];
    if (keys.length === 0) return Object.values(value);
    return MappingService.getValues(value, keys);
  }

  private static getKeys(element: any, keys: string[]): string[] {
    const value = element[keys[0]];
    keys.shift();
    if (!value) return [];
    if (keys.length === 0) return Object.keys(value);
    return MappingService.getKeys(value, keys);
  }

  private static getReferences(element: any, keys: string[]): string[] {
    const value = element[keys[0]];
    keys.shift();
    if (!value) return [];
    if (keys.length === 0) {
      const sorted = value.items_sorted;
      const items = sorted ?? value.items;
      if (!items) return [];
      const array = Object.entries(items);
      if (!array || !array.length) return [];
      if (sorted) {
        array.sort((a, b) => {
          const valueA = Number(a[0]);
          const valueB = Number(b[0]);
          if (valueA < valueB) {
            return -1;
          }
          if (valueA > valueB) {
            return 1;
          }
          return 0;
        });
      }
      return array.map((item) => item[1] as string);
    }
    return MappingService.getReferences(value, keys);
  }

  private static getObjectContent(objectElement: any, id: string, language: number) {
    if (objectElement.value) {
      if (objectElement.value !== '') {
        const unit = objectElement.unit ?? '';
        const valueUnit = unit === '' ? `${objectElement.value}` : `${objectElement.value} ${unit}`;
        const values: string [] = [MappingService.decode(valueUnit)];
        return values;
      }
    } else if (objectElement.items) {
      const values = MappingService.getValues(objectElement, ['items']);
      if (values.length > 0) {
        values.forEach((value, index) => {
          values[index] = MappingService.decode(value);
        });
        if (id === '1260') {
          let availability: string[];
          switch (language) {
            // TODO: add all languages
            // case <flag>: availability = ['<product_spares_availability_productionToOrder>', '<product_spares_availability_availableExStock>', '<product_spares_availability_availableAtShortNotice>']; break;
            case 2: availability = ['Fertigung auf Bestellung', 'Ab Lager lieferbar', 'Kurzfristig lieferbar']; break;
            case 3: availability = ['Made to order', 'Available from stock', 'Available at short notice']; break;
            case 7: availability = ['Fabrication sur commande', 'Disponible en stock', 'Disponible à court terme']; break;
            case 11: availability = ['Productie op bestelling', 'Uit voorraad leverbaar', 'Beschikbaar op korte termijn']; break;
            default: availability = ['Made to order', 'Available from stock', 'Available at short notice']; break;
          }
          const index = Number(values[0] ?? 0);
          values[0] = index < availability.length ? availability[index] : availability[0];
        }
        return values;
      }
    }
    return null;
  }

  private static getMatrixContent(array: any) {
    const matrixArray: Matrix[] = [];
    if (array) {
      array.forEach((element: any) => {
        const matrixElement: Matrix = {
          label: MappingService.decode(element.label) ?? '',
          children: [],
        };
        if (element.children) {
          matrixElement.children = MappingService.getMatrixContent(element.children);
        }
        matrixArray.push(matrixElement);
      });
    }
    return matrixArray;
  }

  private static getMedia(objectElement: any, getName: boolean) {
    const media: Medium[] = [];
    const urls = MappingService.getList(objectElement, ['files']);
    const labels = MappingService.getList(objectElement, ['names']);
    const captionsTop = MappingService.getList(objectElement, ['caption_top']);
    const captionsBottom = MappingService.getList(objectElement, ['caption_bottom']);
    urls?.forEach((url, index) => {
      const dotIndex = url.lastIndexOf('.');
      const extension = url.substring(dotIndex + 1);
      let type = MediaType.Image;
      if (MappingService.videoTypes.includes(extension.toLowerCase())) {
        type = MediaType.Video;
      }
      let label = '';
      if (getName) {
        label = labels ? (MappingService.decode(labels[index])) : '';
      }
      let description: string[] | null = [];
      const captionTop = captionsTop ? (MappingService.decode(captionsTop[index])) : '';
      if (getName) {
        if (captionTop && captionTop !== '') {
          description.push(captionTop);
        }
      } else {
        label = captionTop ?? '';
      }
      const captionBottom = captionsBottom ? (MappingService.decode(captionsBottom[index])) : '';
      if (captionBottom && captionBottom !== '') {
        description.push(captionBottom);
      }
      if (description.length === 0) {
        description = null;
      }
      media?.push({
        type, url, label, description,
      });
    });
    return media;
  }

  private static decode(text: string | null) {
    if (!text) {
      return null;
    }
    try {
      const decodedString = he.decode(text) ?? text;
      return decodedString;
    } catch (error) {
      return text;
    }
  }
}
