
import MobileApp from '@/components/MobileApp/MobileApp.vue';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    MobileApp,
  },
})
export default class MobileView extends Vue {
}
