
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
  props: {
    type: { type: String, default: 'roller' },
  },
})
export default class LoaderElement extends Vue {
}
