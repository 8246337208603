
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
  props: {
    id: String,
    label: String,
    modelValue: Boolean,
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    clickableLink: { type: String, default: '' },
  },
})
export default class CheckboxElement extends Vue {
}
