
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
  props: {
    headline: { type: String, default: '' },
    symbol: String,
    compare: { type: Boolean, default: false },
  },
})
export default class CalculatorHeadRow extends Vue {
}
