
import LanguageStore from '@/stores/languages';
import ProductStore from '@/stores/product';
import ProductDetails from '@/components/ProductDetails/ProductDetails.vue';
import ProductGeneral from '@/components/ProductGeneral/ProductGeneral.vue';
import TabMenu from '@/components/TabMenu/TabMenu.vue';
import LoaderElement from '@/elements/LoaderElement/LoaderElement.vue';
import { EtrackerAreas, RouteNames } from '@/models/Enum';
import { Product } from '@/models/Interface';
import SdkService from '@/services/SdkService';
import { Options, Vue } from 'vue-class-component';
import TabMenuItem from '@/components/TabMenuItem/TabMenuItem.vue';
import TabMenuButton from '@/components/TabMenuButton/TabMenuButton.vue';
import LegalInformation from '@/components/LegalInformation/LegalInformation.vue';

@Options({
  components: {
    TabMenu,
    TabMenuItem,
    TabMenuButton,
    ProductDetails,
    ProductGeneral,
    LoaderElement,
    LegalInformation,
  },
  watch: {
    '$i18n.locale': {
      handler: 'getProduct',
    },
    '$route.params.id': {
      handler: 'getProduct',
    },
  },
})
export default class ProductView extends Vue {
  productStore = ProductStore();

  product: Product | null = null

  routeNames = RouteNames;

  loading = false;

  tabs = [
    { name: RouteNames.ProductGeneral },
    { name: RouteNames.ProductTechnicalInfo, property: 'technicalInfo' },
    { name: RouteNames.ProductToolApplicability, property: 'toolApplicability' },
    { name: RouteNames.ProductApplicationParameter, property: 'applicationParameter' },
    { name: RouteNames.ProductSpares, property: 'spares' },
    { name: RouteNames.ProductRelatedArticles, property: 'relatedArticles' },
    { name: RouteNames.ProductDownloads, property: 'downloads' },
  ]

  created() {
    const routeName = this.$route.name;
    if (routeName && typeof routeName === 'string') {
      if (Object.values(RouteNames).includes(routeName as RouteNames)) {
        ProductView.setEtrackerPath(routeName);
      }
    }
    this.getProduct(false);
  }

  unmounted() {
    this.productStore.deleteProduct();
  }

  getProduct(reload = true) {
    this.loading = true;
    const { id } = this.$route.params;
    if (id && typeof id === 'string') {
      const productFromStore = this.productStore.getProduct;
      if (!reload && productFromStore && productFromStore.id === id) {
        this.product = productFromStore;
        this.loading = false;
      } else {
        const languageStore = LanguageStore();
        const language = languageStore.getLanguageByTag(this.$i18n.locale);
        const languageNumber = language ? language.flag : null;
        SdkService.getProduct(id, languageNumber, (product) => {
          this.productStore.setProduct(product);
          this.product = product;
          this.loading = false;
          if (!this.product) {
            console.log('NO PRODUCT');
          } else if (
            this.$route.name !== RouteNames.ProductGeneral
            && !this.product[this.$route.name as keyof Product]
          ) {
            this.goToTab(RouteNames.ProductGeneral);
          }
        });
      }
    }
  }

  goToTab(tab: RouteNames) {
    ProductView.setEtrackerPath(tab);
    this.$router.push({ name: tab });
  }

  static setEtrackerPath(tab: string) {
    const tabIndex = Object.keys(EtrackerAreas).findIndex((el) => el === tab);
    const tabLabel = Object.values(EtrackerAreas)[tabIndex];
    const path = `${EtrackerAreas.product}/${tabLabel}`;
    document.dispatchEvent(new CustomEvent('etrackerPageAreaChanged', { detail: path }));
  }

  goBack() {
    this.$router.push({ name: RouteNames.Search });
  }

  openWebsite(url: string) {
    console.log('openWebsite', this.product);
    window.open(url, '_blank', 'noopener');
  }
}
