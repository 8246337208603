import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["checked"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["toggle-element cursor-pointer", { 'checked' : _ctx.modelValue }])
  }, [
    _createElementVNode("input", {
      class: "toggle-element__input",
      type: "checkbox",
      checked: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = $event => (_ctx.$emit('update:modelValue', $event.target.checked)))
    }, null, 40, _hoisted_1),
    _createElementVNode("span", {
      class: "toggle-element__slider",
      style: _normalizeStyle(`left: ${_ctx.modelValue ? 18 : 2}px;'}`)
    }, null, 4)
  ], 2))
}