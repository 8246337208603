

import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    src: String,
  },
})
export default class SvgElement extends Vue {
  src!: string;

  mounted() {
    const svgFile = new XMLHttpRequest();
    let svgContent = '';
    svgFile.open('GET', this.src, false);
    svgFile.onreadystatechange = () => {
      if (svgFile.readyState === 4) {
        if (svgFile.status === 200 || svgFile.status === 0) {
          svgContent = svgFile.responseText;
        }
      }
    };
    svgFile.send(null);
    const parser = new DOMParser();
    const svgElement = parser.parseFromString(svgContent, 'text/html').querySelector('svg');
    const svgParentElement = this.$el;
    if (svgElement !== null) {
      const svgAttributes = svgParentElement.attributes;
      for (let i = svgAttributes.length - 1; i >= 0; i -= 1) {
        const attribute = svgAttributes[i];
        if (attribute.name !== 'class') {
          svgElement.setAttribute(attribute.name, attribute.value);
          svgParentElement.removeAttribute(attribute.name);
        }
      }
      svgElement.setAttribute('class', 'svg');
      svgParentElement.appendChild(svgElement);
    }
  }
}
