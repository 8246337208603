
import { MediaType } from '@/models/Enum';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
  props: {
    title: String,
    files: Object,
  },
})
export default class ProductMediaPreview extends Vue {
  mediaType = MediaType;
}
