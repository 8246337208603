
import NoMobileOverlay from '@/components/NoMobileOverlay/NoMobileOverlay.vue';
import Sidebar from '@/components/Sidebar/Sidebar.vue';
import { RouteNames } from '@/models/Enum';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    Sidebar,
    NoMobileOverlay,
  },
  watch: {
    '$route.params.language': {
      handler: 'updateLanguage',
    },
  },
})
export default class App extends Vue {
  routeNames = RouteNames;

  reload = false;

  created() {
    // const languagesStore = LanguagesStore();
    // const languages = languagesStore.getLanguageList;
    // const browserLanguageTag = navigator.language.substring(0, 2);
    // const browserLanguage = languages.find((language) => language.tag === browserLanguageTag);
    // if (browserLanguage) {
    //   this.$i18n.locale = browserLanguage.tag;
    // }
    window.onpopstate = ((event) => {
      this.reload = true;
      setTimeout(() => {
        this.reload = false;
      }, 1);
    });
  }

  // updated() {
  //   console.log('APP UPDATED', this.$route, this.$i18n.locale);
  //   const languageTag = this.$route.params.language;
  //   if (languageTag && typeof languageTag === 'string') {
  //     if (languageTag !== this.$i18n.locale) {
  //       this.$i18n.locale = languageTag;
  //     }
  //   }
  // }

  updateLanguage() {
    const languageTag = this.$route.params.language;
    if (languageTag && typeof languageTag === 'string') {
      if (languageTag !== this.$i18n.locale) {
        this.$i18n.locale = languageTag;
      }
    }
  }
}
