import { createApp } from 'vue';

import axios from 'axios';
import VueAxios from 'vue-axios';

import { createPinia } from 'pinia';

import { createI18n } from 'vue-i18n';
import { messages, numberFormats, languages } from '@/i18n';

import LanguagesStore from '@/stores/languages';

import SvgElement from '@/elements/SvgElement/SvgElement.vue';

import vClickOutside from 'click-outside-vue3';

import App from './views/App/App.vue';
import './registerServiceWorker';
import router from './router';

const app = createApp(App);

const pinia = createPinia();
app.use(pinia);

const languagesStore = LanguagesStore();
languagesStore.setLanguageList(languages);

const defaultLanguage = languagesStore.getDefaultLanguage();
const i18n = createI18n({
  locale: defaultLanguage.tag,
  messages,
  numberFormats,
});
app.use(i18n);

app.use(router);

app.use(VueAxios, axios);

app.use(vClickOutside);

app.component('SvgElement', SvgElement);

app.mount('#app');
