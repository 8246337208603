
import DropdownElement from '@/elements/DropdownElement/DropdownElement.vue';
import { Branch, CustomerData } from '@/models/Interface';
import LanguageStore from '@/stores/languages';
import SdkService from '@/services/SdkService';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    DropdownElement,
  },
  props: {
    branches: Object,
    // selectedBranch: Object,
    customerData: Object,
  },
  watch: {
    '$i18n.locale': {
      handler: 'setBranch',
    },
  },
})
export default class SupportBranch extends Vue {
  branches: Branch[] | null = null;

  selectedBranch: Branch | null = null;

  customerData!: CustomerData;

  loaded = false;

  created() {
    this.setBranch();
  }

  setBranch() {
    const languageStore = LanguageStore();
    const language = languageStore.getLanguageByTag(this.$i18n.locale);
    const languageNumber = language ? language.flag : null;
    SdkService.getBranches(languageNumber, (branches) => {
      branches?.sort((a, b) => (a.location < b.location ? -1 : 1));
      this.branches = branches;

      if (this.branches && this.customerData.branch) {
        // if (this.branches?.includes(this.customerData.branch)) {
        //   this.selectedBranch = this.customerData.branch;
        // }
        const selectedBranch = this.branches
          .find((branch) => branch.email === this.customerData.branch?.email);
        if (selectedBranch) {
          this.selectedBranch = selectedBranch;
        }
      }
      setTimeout(() => {
        this.loaded = true;
      }, 1);
      // setTimeout(() => {
      //   this.$emit('optionSelected', this.branches ? this.branches[0] : null);
      // }, 100);
    });
  }

  openWebsite(url: string) {
    console.log('openWebsite', this.selectedBranch);
    if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
      window.open(url, '_blank', 'noopener');
    }
  }
}
