
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
  props: {
  },
})
export default class LegalInformation extends Vue {
  openWebsite(url: string) {
    console.log('openWebsite', this);
    window.open(url, '_blank', 'noopener');
  }
}
