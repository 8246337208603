import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "product-table" }
const _hoisted_2 = { class: "product-table__row" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "fonts-size-14 color-black text-align-right" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.table, (entry) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "fonts-size-12 color-tertiary-2",
          innerHTML: entry.label
        }, null, 8, _hoisted_3),
        _createElementVNode("div", _hoisted_4, [
          (typeof entry.value === 'string')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                innerHTML: entry.value
              }, null, 8, _hoisted_5))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(entry.value, (row) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "",
                  innerHTML: row
                }, null, 8, _hoisted_6))
              }), 256))
        ])
      ]))
    }), 256))
  ]))
}