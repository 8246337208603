
import { Options, Vue } from 'vue-class-component';
import { UseVirtualList } from '@vueuse/components';
import ProductRelatedArticleItem from '../ProductRelatedArticleItem/ProductRelatedArticleItem.vue';

@Options({
  components: {
    ProductRelatedArticleItem,
    UseVirtualList,
  },
  props: {
    product: Object,
  },
})
export default class ProductRelatedArticles extends Vue {
}
