import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dropdown-element__label"
}
const _hoisted_2 = ["for", "innerHTML"]
const _hoisted_3 = {
  key: 0,
  class: "font-size-14 font-weight-bold color-primary"
}
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["value", "innerHTML"]
const _hoisted_6 = ["id"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 0,
  class: "dropdown-element__options"
}
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["onClick", "innerHTML"]
const _hoisted_12 = {
  key: 1,
  class: "text-input-element__error"
}
const _hoisted_13 = { class: "font-size-12 color-red" }

export function render(_ctx, _cache) {
  const _component_SvgElement = _resolveComponent("SvgElement")
  const _directive_click_outside = _resolveDirective("click-outside")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["dropdown-element", { 'is-open' : _ctx.dropdownOpen }, { 'is-small' : _ctx.isSmall }, { 'has-error-label' : _ctx.error }, { 'has-error' : _ctx.errorLabel !== '' }]),
    onFocusout: _cache[2] || (_cache[2] = $event => (_ctx.dropdownOpen = false)),
    tabindex: "auto"
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("label", {
            for: _ctx.id,
            class: "font-size-14 font-weight-bold color-tertiary-1",
            innerHTML: _ctx.label
          }, null, 8, _hoisted_2),
          (_ctx.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, " *"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("select", {
      class: "dropdown-element__hidden",
      disabled: _ctx.disabled
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("option", {
          value: _ctx.isObject ? option[_ctx.optionPath] : option,
          innerHTML: _ctx.isLocalised ? _ctx.$t(`${_ctx.localisedPath}${option}`) : (_ctx.isObject ? option[_ctx.optionPath] : option)
        }, null, 8, _hoisted_5))
      }), 256))
    ], 8, _hoisted_4),
    _createElementVNode("div", {
      id: _ctx.id,
      class: _normalizeClass(["dropdown-element__content", [{ 'disabled' : _ctx.disabled }]])
    }, [
      _createElementVNode("div", {
        class: "cursor-pointer dropdown-element__selection",
        onClick: _cache[0] || (_cache[0] = $event => (_ctx.dropdownOpen = !_ctx.dropdownOpen))
      }, [
        (_ctx.placeholder && _ctx.modelValue === '' && !_ctx.modelValueObject)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "dropdown-element__placeholder",
              innerHTML: _ctx.placeholder
            }, null, 8, _hoisted_7))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "dropdown-element__selected-option",
              innerHTML: _ctx.isLocalised ? _ctx.$t(`${_ctx.localisedPath}${_ctx.modelValue}`) : (_ctx.isObject ? _ctx.modelValueObject[_ctx.optionPath] : _ctx.modelValue)
            }, null, 8, _hoisted_8)),
        _createVNode(_component_SvgElement, {
          src: "/icons/chevron_right.svg",
          class: "fill-primary dropdown-element__icon"
        })
      ]),
      (_ctx.dropdownOpen)
        ? _withDirectives((_openBlock(), _createElementBlock("ul", _hoisted_9, [
            (_ctx.placeholder)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 0,
                  class: "font-size-16 color-tertiary-2 cursor-pointer dropdown-element__option",
                  onClick: _cache[1] || (_cache[1] = $event => (_ctx.isObject ? _ctx.selectOptionObject(null) : _ctx.selectOption(''))),
                  innerHTML: _ctx.placeholder
                }, null, 8, _hoisted_10))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "font-size-16 color-black cursor-pointer dropdown-element__option",
                onClick: $event => (_ctx.isObject ? _ctx.selectOptionObject(option) : _ctx.selectOption(option)),
                innerHTML: _ctx.isLocalised ? _ctx.$t(`${_ctx.localisedPath}${option}`) : (_ctx.isObject ? option[_ctx.optionPath] : option)
              }, null, 8, _hoisted_11))
            }), 256))
          ])), [
            [_directive_click_outside, _ctx.onClickOutside]
          ])
        : _createCommentVNode("", true)
    ], 10, _hoisted_6),
    (_ctx.errorLabel !== '')
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.errorLabel), 1)
        ]))
      : _createCommentVNode("", true)
  ], 34))
}