import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "product-media-modal" }
const _hoisted_2 = { class: "product-media-modal__header" }
const _hoisted_3 = {
  class: "product-media-modal__content",
  ref: "mediaContainer"
}
const _hoisted_4 = { class: "product-media-modal__frame" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "product-media-modal__image" }
const _hoisted_7 = ["src"]
const _hoisted_8 = ["width", "height", "src"]
const _hoisted_9 = {
  key: 3,
  class: "product-media-modal__description"
}
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = {
  key: 0,
  class: "product-media-modal__navigation",
  ref: "mediaNavigation"
}
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["src"]

export function render(_ctx, _cache) {
  const _component_SvgElement = _resolveComponent("SvgElement")
  const _component_video_player = _resolveComponent("video-player")
  const _component_LoaderElement = _resolveComponent("LoaderElement")
  const _component_VueAgile = _resolveComponent("VueAgile")
  const _component_VueModal = _resolveComponent("VueModal")

  return (_openBlock(), _createBlock(_component_VueModal, {
    ref: "modal",
    "hide-header": "",
    "hide-footer": "",
    centered: "",
    "no-close-on-backdrop": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_SvgElement, {
            src: "/icons/close.svg",
            class: "product-media-modal__close fill-tertiary-1 cursor-pointer",
            onClick: _cache[0] || (_cache[0] = $event => (_ctx.close()))
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_VueAgile, {
            ref: "main",
            dots: false,
            navButtons: false,
            infinite: _ctx.media.length > 1,
            onAfterChange: _cache[2] || (_cache[2] = $event => (_ctx.goTo('thumbnails', $event.currentSlide)))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.media, (medium) => {
                return (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("div", {
                    class: "product-media-modal__title font-size-20 color-black",
                    innerHTML: medium.label
                  }, null, 8, _hoisted_5),
                  _createElementVNode("div", _hoisted_6, [
                    (medium.type === _ctx.mediaType.Image)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          class: "product-media-modal__image-content",
                          src: medium.url,
                          onerror: "this.src='/icons/no_product_image.svg'"
                        }, null, 8, _hoisted_7))
                      : (medium.type === _ctx.mediaType.Video)
                        ? (_openBlock(), _createBlock(_component_video_player, {
                            key: _ctx.refreshVideo,
                            ref_for: true,
                            ref: "videoplayer",
                            class: "product-media-modal__image-content video-player vjs-big-play-centered",
                            width: _ctx.mediaContainerWidth,
                            height: _ctx.mediaContainerHeight,
                            src: medium.url,
                            poster: medium.url,
                            crossorigin: "anonymous",
                            controls: "",
                            volume: 0.6,
                            "playback-rates": [0.7, 1.0, 1.5, 2.0]
                          }, null, 8, ["width", "height", "src", "poster", "volume", "playback-rates"]))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            (_openBlock(), _createElementBlock("iframe", {
                              class: "product-media-modal__image-content",
                              key: _ctx.refreshVideo,
                              width: _ctx.mediaContainerWidth,
                              height: _ctx.mediaContainerHeight,
                              src: medium.url,
                              frameborder: "0",
                              allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                              allowfullscreen: "",
                              onLoad: _cache[1] || (_cache[1] = $event => (_ctx.loaded()))
                            }, null, 40, _hoisted_8)),
                            (_ctx.loading)
                              ? (_openBlock(), _createBlock(_component_LoaderElement, {
                                  key: 0,
                                  class: "product-media-modal__loader"
                                }))
                              : _createCommentVNode("", true)
                          ], 64)),
                    (medium.description)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(medium.description, (description) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: "font-size-14 color-white",
                              innerHTML: description
                            }, null, 8, _hoisted_10))
                          }), 256))
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              }), 256))
            ]),
            _: 1
          }, 8, ["infinite"]),
          (_ctx.media.length > 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createVNode(_component_SvgElement, {
                  src: "/icons/arrow_right.svg",
                  class: "product-media-modal__arrow fill-tertiary-2 cursor-pointer rotate-180",
                  onClick: _cache[3] || (_cache[3] = $event => (_ctx.goTo('main', _ctx.currentIndex - 1)))
                }),
                _createVNode(_component_VueAgile, {
                  ref: "thumbnails",
                  slidesToShow: _ctx.media.length < 6 ? _ctx.media.length : 6,
                  dots: false,
                  navButtons: false,
                  asNavFor: [_ctx.$refs.main],
                  class: "product-media-modal__slider"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.media, (medium, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "product-media-modal__preview-image cursor-pointer",
                        onClick: $event => (_ctx.goTo('main', index))
                      }, [
                        (medium.type === _ctx.mediaType.Image)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              src: medium.url,
                              onerror: "this.src='/icons/no_product_image.svg'",
                              class: _normalizeClass({'is-active' : index === _ctx.currentIndex})
                            }, null, 10, _hoisted_13))
                          : (_openBlock(), _createElementBlock("div", {
                              key: 1,
                              class: _normalizeClass(["is-placeholder", {'is-active' : index === _ctx.currentIndex}])
                            }, [
                              _createVNode(_component_SvgElement, { src: "/icons/play.svg" })
                            ], 2))
                      ], 8, _hoisted_12))
                    }), 256))
                  ]),
                  _: 1
                }, 8, ["slidesToShow", "asNavFor"]),
                _createVNode(_component_SvgElement, {
                  src: "/icons/arrow_right.svg",
                  class: "product-media-modal__arrow fill-tertiary-2 cursor-pointer",
                  onClick: _cache[4] || (_cache[4] = $event => (_ctx.goTo('main', _ctx.currentIndex + 1)))
                })
              ], 512))
            : _createCommentVNode("", true)
        ], 512)
      ])
    ]),
    _: 1
  }, 512))
}